import React from "react";
import { Redirect, Switch, useRouteMatch } from "react-router-dom";
import SearchQuestion from "../../../components/member/question/search/SearchQuestion";
import MainSearch from "../../../components/member/search/MainSearch";
import PrivateRoute from "../PrivateRoute";

const SearchRoutes = () => {
    let { path } = useRouteMatch();

    return (
        <Switch>
            <PrivateRoute exact path={`${path}/`} component={MainSearch} />
            <PrivateRoute
                exact
                path={`${path}/watch`}
                component={SearchQuestion}
            />
            <Redirect from="*" to={`${path}/`} />
        </Switch>
    );
};

export default SearchRoutes;
