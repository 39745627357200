import {
    ADD_PAGE,
    GET_PAGE,
    GET_PAGES,
    PAGE_LOADING,
    DELETE_PAGE,
    PAGE_NOT_FOUND,
} from "../actions/types";

const initialState = {
    pages: [],
    page: null,
    loading: true,
    error: {},
    pageNotFound: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case PAGE_LOADING:
            return {
                ...state,
                loading: true,
            };

        case ADD_PAGE:
            return {
                ...state,
                pages: [action.payload, ...state.pages],
            };

        case GET_PAGE:
            return {
                ...state,
                page: action.payload,
                loading: false,
            };

        case GET_PAGES:
            return {
                ...state,
                pages: action.payload,
                loading: false,
            };

        case DELETE_PAGE:
            return {
                ...state,
                pages: state.pages.filter(
                    (page) => page._id !== action.payload
                ),
            };

        case PAGE_NOT_FOUND:
            return {
                ...state,
                page: null,
                loading: false,
                pageNotFound: true,
            };

        default:
            return state;
    }
}
