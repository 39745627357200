import React, { Component } from "react";
import Moment from "react-moment";

class CommentList extends Component {
    render() {
        const comList = this.props.comment_ques.map((comm) => (
            <div className="feed-item" key={comm._id}>
                <img
                    src={
                        comm.user_id.image
                            ? `https://seatrials-bucket.s3.us-east-2.amazonaws.com/client/${comm.user_id.image}`
                            : "../assets/admin/img/user/user-12.jpg"
                    }
                    alt="user"
                    className="img-responsive profile-photo-sm"
                    onError={(e) => {
                        e.target.onerror = null;
                        e.target.src =
                            "https://seatrials-bucket.s3.us-east-2.amazonaws.com/main/noimage.jpg";
                    }}
                />
                <div className="live-activity">
                    <h4 className="profile-link">
                        {comm.user_id.user_name ? comm.user_id.user_name : ""}{" "}
                    </h4>
                    <p
                        className="text-muted-sub"
                        dangerouslySetInnerHTML={{ __html: comm.comments }}
                    />

                    <p className="text-muted">
                        <Moment format="lll">{comm.insert_date}</Moment>{" "}
                    </p>
                </div>
            </div>
        ));
        return (
            <div className="wrapper">
                <ul className="media-list underline m-b-15 p-b-15">
                    {comList}
                </ul>
            </div>
        );
    }
}
export default CommentList;
