import React, { Component } from "react";
import { Link } from "react-router-dom";

class EngineList extends Component {
    render() {
        const engineContent = this.props.engines.map((engine) => (
            <Link
                to={`/question/${engine.ZSLUG}`}
                key={engine._id}
                className="directory-list"
            >
                {engine.Z_PK}
            </Link>
        ));
        return engineContent;
    }
}
export default EngineList;
