// Question reducer related constants
export const V1_GET_QUESTION = "V1_GET_QUESTION";
export const V1_GET_BOOKMARK = "V1_GET_BOOKMARK";
export const V1_GET_HIDE = "V1_GET_HIDE";
export const V1_GET_PROFILE = "V1_GET_PROFILE";
export const V1_QUESTION_LOADING = "V1_QUESTION_LOADING";

// Comments reducer related constants
export const V1_APPEND_COMMENT = "V1_APPEND_COMMENT";
export const V1_DELETE_COMMENT = "V1_DELETE_COMMENT";
export const V1_GET_ERRORS = "V1_GET_ERRORS";
