import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";
import Container from "../content/Container.jsx";
import { Panel, PanelHeader, PanelBody } from "../common/panel/Panel.jsx";
import TextFieldArea from "../common/textfield/TextFieldArea";
import TextAreaVideo from "../common/textfield/TextAreaVideo";
//import TextFieldOption from "./../common/textfield/TextFieldOption";
//import AlertBox from "../common/textfield/AlertBox";
import ReactNotification from "react-notifications-component";
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import {
    updateSubscriber,
    getSubscriberbyId,
} from "../../../actions/subscriberActions.jsx";
import isEmpty from "../../../validation/is-empty";

class EditSubscriber extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            user_name: "",
            subscription_type: "",
            mode: "",
            status: "",
            alert: false,
            AlertBox: false,
            visible: false,
            visible1: true,
            visible2: true,
            file: "",
            imagePreview: "",
            imagePreviewUrl: "",
            errors: {},
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.fileChangedHandler = this.fileChangedHandler.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }
    handleCancel() {
        this.setState({
            email: "",
            user_name: "",
            subscription_type: "",
            mode: "",
            status: "",
        });
    }

    componentDidMount() {
        if (this.props.match.params.Id) {
            this.props.getSubscriberbyId(this.props.match.params.Id);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({ errors: nextProps.errors });
        }
        if (nextProps.subscriber.subscriber) {
            const subscriber = nextProps.subscriber.subscriber;

            subscriber._id = !isEmpty(subscriber._id) ? subscriber._id : "";
            subscriber.email = !isEmpty(subscriber.email)
                ? subscriber.email
                : "";
            subscriber.user_name = !isEmpty(subscriber.user_name)
                ? subscriber.user_name
                : "";
            subscriber.subscription_type = !isEmpty(
                subscriber.subscription_type
            )
                ? subscriber.subscription_type
                : "";
            subscriber.mode = !isEmpty(subscriber.mode) ? subscriber.mode : "";
            subscriber.status = !isEmpty(subscriber.status)
                ? subscriber.status
                : "";

            subscriber.image = !isEmpty(subscriber.image)
                ? subscriber.image
                : "./../../../assets/admin/img/noimage.jpg";

            // Set component fields state for blog
            this.setState({
                id: subscriber._id,
                email: subscriber.email,
                user_name: subscriber.user_name,
                subscription_type: subscriber.subscription_type,
                mode: subscriber.mode,
                status: subscriber.status,
                imagePreviewUrl: subscriber.image,
            });
        }
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    fileChangedHandler(e) {
        this.setState({
            file: e.target.files[0],
        });
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            this.setState({
                file: file,
                imagePreviewUrl: reader.result,
            });
        };
        reader.readAsDataURL(file);
    }

    onSubmit(e) {
        e.preventDefault();
        const subs_id = this.props.match.params.Id;
        const formData = new FormData();
        formData.append("email", this.state.email);
        formData.append("user_name", this.state.user_name);
        formData.append("subscription_type", this.state.subscription_type);
        formData.append("status", this.state.status);
        formData.append("image", this.state.file);
        console.log(formData);
        this.props.updateSubscriber(subs_id, formData, this.props.history);

        store.addNotification({
            title: "Success",
            message: "Client Saved successfully",
            type: "success",
            insert: "top",
            container: "top-center",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: { duration: 2000 },
            dismissable: { click: true },
            // content: notificationContent
        });
    }

    render() {
        const { errors } = this.state;
        return (
            <React.Fragment>
                <ReactNotification />
                <Container>
                    <div className="content">
                        <div>
                            <ol className="breadcrumb float-xl-right">
                                <li className="breadcrumb-item">
                                    <Link to="/admin/dashboard">Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item active">
                                    Edit Subscription
                                </li>
                            </ol>
                            <h1 className="page-header">Edit Subscription </h1>
                            <div className="row">
                                <div className="col-xl-12">
                                    <Panel>
                                        <PanelHeader>
                                            Edit Subscription
                                        </PanelHeader>
                                        <PanelBody className="panel-form">
                                            <div className="panel-form">
                                                <form
                                                    className="form-horizontal form-bordered"
                                                    onSubmit={this.onSubmit}
                                                >
                                                    <TextFieldArea
                                                        label="Email"
                                                        placeholder="example@example.com"
                                                        name="email"
                                                        type="text"
                                                        value={this.state.email}
                                                        onChange={this.onChange}
                                                        error={errors.email}
                                                        striks="*"
                                                    />

                                                    <TextFieldArea
                                                        label="User Name"
                                                        placeholder="User Name"
                                                        name="user_name"
                                                        type="text"
                                                        value={
                                                            this.state.user_name
                                                        }
                                                        onChange={this.onChange}
                                                        error={errors.user_name}
                                                        striks="*"
                                                    />

                                                    <div className="form-group row ">
                                                        <label className="col-lg-3 col-form-label">
                                                            Subscription Status{" "}
                                                            <span className="text-danger">
                                                                *
                                                            </span>
                                                        </label>
                                                        <div className="col-lg-5">
                                                            <select
                                                                className={classnames(
                                                                    "form-control ",
                                                                    {
                                                                        "is-invalid":
                                                                            errors.status,
                                                                    }
                                                                )}
                                                                data-size="10"
                                                                data-live-search="true"
                                                                data-style="btn-white"
                                                                name="subscription_type"
                                                                value={
                                                                    this.state
                                                                        .subscription_type
                                                                }
                                                                onChange={
                                                                    this
                                                                        .onChange
                                                                }
                                                            >
                                                                <option value="">
                                                                    Select
                                                                    Status
                                                                </option>
                                                                <option value="Trial">
                                                                    Trial
                                                                </option>
                                                                <option value="1 Month Access">
                                                                    1 Month
                                                                    Access
                                                                </option>
                                                                <option value="3 Month Access">
                                                                    3 Month
                                                                    Access
                                                                </option>{" "}
                                                                <option value="6 Month Access">
                                                                    6 Month
                                                                    Access
                                                                </option>{" "}
                                                                <option value="12 Month Access">
                                                                    12 Month
                                                                    Access
                                                                </option>
                                                                <option value="Life Time Access">
                                                                    Life Time
                                                                    Access
                                                                </option>
                                                            </select>

                                                            {errors.subscription_type && (
                                                                <div
                                                                    className="invalid-feedback"
                                                                    style={{
                                                                        display:
                                                                            "block",
                                                                    }}
                                                                >
                                                                    {
                                                                        errors.subscription_type
                                                                    }
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/*    <TextFieldOption
                          label="Cost"
                          type="radio"
                          name="coupan_type"
                          checked1={this.state.mode}
                          checked2={this.state.mode}
                          value1="Free"
                          value2="Paid"
                          option1="Free"
                          option2="Paid"
                          onChange={this.onChange}
                          />--*/}

                                                    <TextAreaVideo
                                                        //  src={this.state.imagePreviewUrl}
                                                        type="file"
                                                        name="page_image"
                                                        text="upload new image"
                                                        label="Upload Image"
                                                        //  value={this.state.file}
                                                        onChange={
                                                            this
                                                                .fileChangedHandler
                                                        }
                                                        imgextension={[
                                                            ".jpg",
                                                            ".gif",
                                                            ".png",
                                                            ".jpeg",
                                                        ]}
                                                        upload="Upload Image"
                                                    />

                                                    <div className="form-group row ">
                                                        <label className="col-lg-3 col-form-label">
                                                            Account Status{" "}
                                                            <span className="text-danger">
                                                                *
                                                            </span>
                                                        </label>
                                                        <div className="col-lg-5">
                                                            <select
                                                                className={classnames(
                                                                    "form-control ",
                                                                    {
                                                                        "is-invalid":
                                                                            errors.status,
                                                                    }
                                                                )}
                                                                data-size="10"
                                                                data-live-search="true"
                                                                data-style="btn-white"
                                                                name="status"
                                                                value={
                                                                    this.state
                                                                        .status
                                                                }
                                                                onChange={
                                                                    this
                                                                        .onChange
                                                                }
                                                            >
                                                                <option value="">
                                                                    Select
                                                                    Status
                                                                </option>
                                                                <option value="Active">
                                                                    Active
                                                                </option>
                                                                <option value="Inactive">
                                                                    Inactive
                                                                </option>
                                                                <option value="Ban">
                                                                    Ban
                                                                </option>
                                                            </select>

                                                            {errors.type && (
                                                                <div
                                                                    className="invalid-feedback"
                                                                    style={{
                                                                        display:
                                                                            "block",
                                                                    }}
                                                                >
                                                                    {
                                                                        errors.type
                                                                    }
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="form-group row m-b-15">
                                                        <label className="col-lg-3 col-form-label">
                                                            Preview
                                                        </label>
                                                        <div className="col-lg-9">
                                                            <img
                                                                src={`https://seatrials-bucket.s3.us-east-2.amazonaws.com/client/${this.state.imagePreviewUrl}`}
                                                                className="img height-75"
                                                                alt=""
                                                                width="100%"
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <div className="col-md-12 offset-md-5">
                                                            <button
                                                                type="submit"
                                                                className="btn btn-sm btn-primary m-r-5"
                                                            >
                                                                Submit
                                                            </button>

                                                            <button
                                                                type="button"
                                                                onClick={
                                                                    this
                                                                        .handleCancel
                                                                }
                                                                className="btn btn-sm btn-default"
                                                            >
                                                                Cancel
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </PanelBody>
                                    </Panel>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </React.Fragment>
        );
    }
}

EditSubscriber.propTypes = {
    updateSubscriber: PropTypes.func.isRequired,
    getSubscriberbyId: PropTypes.func.isRequired,
    admin: PropTypes.object.isRequired,
    subscriber: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    admin: state.admin,
    subscriber: state.subscriber,
    errors: state.errors,
});

export default connect(mapStateToProps, {
    updateSubscriber,
    getSubscriberbyId,
})(withRouter(EditSubscriber));
