import React from "react";
import { Link } from "react-router-dom";
class Footer extends React.Component {
    constructor(props) {
        super(props);

        var date = new Date();
        this.state = {
            year: date.getFullYear(),
        };
    }
    render() {
        return (
            <div className="admin-footer">
                <div id="footer" className="footer">
                    &copy; {this.state.year} All rights reserved. Crafted with{" "}
                    <i className="fas fa-heart"></i> by{" "}
                    <Link
                        to="#"
                        onClick={() =>
                            window.open(
                                "https://www.eleveratech.com/",
                                "_blank"
                            )
                        }
                    >
                        Eleveratech.com{" "}
                    </Link>
                </div>
            </div>
        );
    }
}

export default Footer;
