import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";
import Container from "../content/Container.jsx";
import { Panel, PanelHeader, PanelBody } from "../common/panel/Panel.jsx";
import TextFieldArea from "../common/textfield/TextFieldArea";
import TextFieldOption from "../common/textfield/TextFieldOption";
//import AlertBox from "../common/textfield/AlertBox";
import ReactNotification from "react-notifications-component";
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import CoupanList from "./CoupanList.jsx";
import Spinner from "../common/textfield/Spinner";
import { getCoupans, addCoupans } from "../../../actions/coupanActions.jsx";

class GenarateCodes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subscription: "",
            code_type: "",
            code_off: "",
            alert: false,
            alertcoupan: false,
            AlertBox: false,
            visible: false,
            imagePreviewUrl: "",
            errors: {},
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onDismiss = this.onDismiss.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }
    componentDidMount() {
        this.props.getCoupans();
    }
    onDismiss() {
        this.setState({ alertcoupan: false, visible1: false, visible2: false });
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({ errors: nextProps.errors });
        }
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleCancel(e) {
        this.setState({
            subscription: "",
            code_type: "",
            code_off: "",
        });
    }

    onSubmit(e) {
        e.preventDefault();
        const formData = {
            subscription: this.state.subscription,
            code_type: this.state.code_type,
            code_off: this.state.code_off,
        };
        this.props.addCoupans(formData, this.props.history);

        this.setState({
            alert: true,
            alertcoupan: true,
            subscription: "",
            code_type: "",
            code_off: "",
        });
    }
    render() {
        const { coupans, loading } = this.props.coupan;

        let mainCoupanContent;
        if (coupans === null || loading) {
            mainCoupanContent = <Spinner />;
        } else {
            //console.log(blogcats);
            if (coupans.length > 0) {
                mainCoupanContent = <CoupanList coupans={coupans} />;
            } else {
                mainCoupanContent = (
                    <div className="row">
                        <div className="col-12">
                            <Panel>
                                <PanelHeader>Promo Code List</PanelHeader>
                                <PanelBody>
                                    <div className="note note-secondary m-b-15">
                                        <div className="note-icon">
                                            <i className="fa fa-lightbulb"></i>
                                        </div>
                                        <div className="note-content text-right">
                                            <h4>
                                                <b>This Table Is Empty</b>
                                            </h4>
                                            <p>
                                                {" "}
                                                No data found to show here. This
                                                table is empty.{" "}
                                            </p>
                                        </div>
                                    </div>
                                </PanelBody>
                            </Panel>
                        </div>
                    </div>
                );
            }
        }
        const { errors } = this.state;
        return (
            <React.Fragment>
                <ReactNotification />
                <Container>
                    <div className="content">
                        <div>
                            <ol className="breadcrumb float-xl-right">
                                <li className="breadcrumb-item">
                                    <Link to="/admin/dashboard">Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item active">
                                    Generate Coupan Code
                                </li>
                            </ol>
                            <h1 className="page-header">
                                Generate Coupan Code{" "}
                            </h1>
                            <div className="row">
                                <div className="col-xl-12">
                                    <Panel>
                                        <PanelHeader>
                                            Generate Coupan Code
                                        </PanelHeader>
                                        <PanelBody className="panel-form">
                                            <div className="panel-form">
                                                <span className="hide-noti ">
                                                    {errors.coupanSuccess &&
                                                        store.addNotification({
                                                            title: "Success!",
                                                            message:
                                                                "Coupon code generated successfully.",
                                                            type: "success",
                                                            insert: "top",
                                                            container:
                                                                "top-center",
                                                            animationIn: [
                                                                "animated",
                                                                "fadeIn",
                                                            ],
                                                            animationOut: [
                                                                "animated",
                                                                "fadeOut",
                                                            ],
                                                            dismiss: {
                                                                duration: 2000,
                                                            },
                                                            dismissable: {
                                                                click: true,
                                                            },
                                                            // content: notificationContent
                                                        })}
                                                </span>

                                                <form
                                                    className="form-horizontal form-bordered"
                                                    onSubmit={this.onSubmit}
                                                >
                                                    <div className="form-group row ">
                                                        <label className="col-lg-3 col-form-label">
                                                            Subscription Type{" "}
                                                            <span className="text-danger">
                                                                *
                                                            </span>
                                                        </label>
                                                        <div className="col-lg-9">
                                                            <select
                                                                className={classnames(
                                                                    "form-control ",
                                                                    {
                                                                        "is-invalid":
                                                                            errors.subscription,
                                                                    }
                                                                )}
                                                                data-size="10"
                                                                data-live-search="true"
                                                                data-style="btn-white"
                                                                name="subscription"
                                                                value={
                                                                    this.state
                                                                        .subscription
                                                                }
                                                                onChange={
                                                                    this
                                                                        .onChange
                                                                }
                                                            >
                                                                <option value="">
                                                                    Select
                                                                    Subscription
                                                                </option>
                                                                <option value="all">
                                                                    Applicable
                                                                    to All
                                                                </option>
                                                                <option value="one_month">
                                                                    One Month
                                                                    Subscription
                                                                </option>
                                                                <option value="three_month">
                                                                    Three Month
                                                                    Subscription
                                                                </option>
                                                                <option value="life_time">
                                                                    Life Time
                                                                    Subscription
                                                                </option>
                                                            </select>

                                                            {errors.subscription && (
                                                                <div
                                                                    className="invalid-feedback"
                                                                    style={{
                                                                        display:
                                                                            "block",
                                                                    }}
                                                                >
                                                                    {
                                                                        errors.subscription
                                                                    }
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <TextFieldOption
                                                        label="Promo-Code"
                                                        type="radio"
                                                        name="code_type"
                                                        checked1={
                                                            this.state
                                                                .code_type ===
                                                            "Expiry"
                                                        }
                                                        checked2={
                                                            this.state
                                                                .code_type ===
                                                            "Non-Expiry"
                                                        }
                                                        value1="Expiry"
                                                        value2="Non-Expiry"
                                                        option1="Expiry"
                                                        option2="Non Expiry"
                                                        onChange={this.onChange}
                                                        error={errors.code_type}
                                                        striks="*"
                                                    />

                                                    <TextFieldArea
                                                        label="Offer(in %)"
                                                        placeholder="offer in %"
                                                        name="code_off"
                                                        type="text"
                                                        value={
                                                            this.state.code_off
                                                        }
                                                        onChange={this.onChange}
                                                        error={errors.code_off}
                                                        striks="*"
                                                    />

                                                    <div className="form-group row">
                                                        <div className="col-md-12 offset-md-5">
                                                            <button
                                                                type="submit"
                                                                className="btn btn-sm btn-primary m-r-5"
                                                            >
                                                                Submit
                                                            </button>

                                                            <button
                                                                type="button"
                                                                onClick={
                                                                    this
                                                                        .handleCancel
                                                                }
                                                                className="btn btn-sm btn-default"
                                                            >
                                                                Cancel
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </PanelBody>
                                    </Panel>
                                </div>
                            </div>

                            {/*-----------table-------------------*/}
                            <div className="row">
                                <div className="col-xl-12">
                                    {mainCoupanContent}
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </React.Fragment>
        );
    }
}

GenarateCodes.propTypes = {
    getCoupans: PropTypes.func.isRequired,
    addCoupans: PropTypes.func.isRequired,
    coupan: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    coupan: state.coupan,
    errors: state.errors,
});

export default connect(mapStateToProps, { getCoupans, addCoupans })(
    GenarateCodes
);
