import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const AdminRoute = ({
    component: Component,
    admin: { isAuthenticated, loading },
    ...rest
}) => (
    <Route
        {...rest}
        render={(props) =>
            !isAuthenticated && !loading ? (
                <Redirect to="/admin" />
            ) : (
                <Component {...props} />
            )
        }
    />
);

AdminRoute.propTypes = {
    admin: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    admin: state.admin,
});

export default connect(mapStateToProps)(AdminRoute);
