import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../common/common.jsx";
import Header from "../common/Header";
import Footer from "../common/Footer";

class Privacy extends Component {
    render() {
        return (
            <div>
                <Header />
                {/* <!-- Breadcrumb -->*/}
                <nav className="breadcrumb">
                    <div className="container">
                        <Link className="breadcrumb-item" to="/">
                            Home
                        </Link>
                        <span className="breadcrumb-item active">
                            Privacy Policy
                        </span>
                    </div>
                </nav>
                {/*<!-- Breadcrumb End -->*/}

                {/*<!-- About -->*/}
                <div className="weare-section">
                    <div className="container">
                        <div className="abt-wrapper">
                            <h1>Privacy Policy</h1>
                            <p>
                                Mariner License Prep is a commercial
                                multiplatform application andthis service is
                                provided by Mariner License Prep, LLC and is
                                intended for use as is.
                            </p>
                            <div className="clearfix bottom"></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-xs-12">
                                <div className="wearetext-right">
                                    <h3>
                                        This page is used to inform website
                                        visitors regarding Mariner License Prep,
                                        LLC policies with the collection, use,
                                        and disclosure of Personal Information
                                        for users of thisService.
                                    </h3>

                                    <p>
                                        If you choose to use this Service, then
                                        you agree to the collection and use of
                                        information in relation to this policy.
                                        The Personal Information that is
                                        collected is used for improving and
                                        providing this Service. Your information
                                        will not be used or shared with any
                                        outside party.
                                        <br />
                                        <br />
                                        The terms used in this Privacy Policy
                                        include the policies from the Terms and
                                        Conditions, which is accessible at its
                                        respective page.
                                    </p>
                                </div>
                            </div>

                            {/*<!--<div className="col-sm-4 col-xs-12">
								<div className="ads1 pull-right"><img src="<?=base_url()?>assets/user_assets/images/ads2.jpg" alt="ads2"></div>
							</div>-->*/}
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-xs-12 wearetext-right one-up">
                                <h3 className="privacy-sub-head">
                                    Information Collection and Use
                                </h3>
                                <p>
                                    For a better experience, while using our
                                    Service, we may require you to provide us
                                    with certain personally identifiable
                                    information. The information that we request
                                    is retained on your device and/or secure
                                    servers. This information is not shared with
                                    any untrusted outside parties.
                                </p>

                                <p>
                                    The application does use third party
                                    services that may collect information used
                                    to identify you.
                                </p>

                                <h3 className="privacy-sub-head">
                                    Analytics / Log Data
                                </h3>
                                <p>
                                    We want to inform you that whenever you use
                                    this Service, in a case of an error/bug in
                                    the applicationwe collect data and
                                    information (through third party products).
                                    This Log Data may include information such
                                    as your device Internet Protocol (“IP”)
                                    address, device name, operating system
                                    version, the configuration of the
                                    application when utilizing my Service, the
                                    time and date of your use of the Service,
                                    and other statistics. This information is
                                    not shared with any untrusted outside
                                    parties.
                                </p>

                                <h3 className="privacy-sub-head">Cookies</h3>
                                <p>
                                    Cookies are files with small amount of data
                                    that are commonly used an anonymous unique
                                    identifier. These are sent to your browser
                                    from the website that you visit and are
                                    stored on your device’s internal memory.
                                </p>
                                <p>
                                    This Service does not use these “cookies”
                                    explicitly. However, the application may use
                                    third party code and libraries that use
                                    “cookies” to collection information and to
                                    improve their services. By using this
                                    service, you agree to the collection of your
                                    cookies by Mariner License Prep, LLC or
                                    trusted third parties.
                                </p>

                                <h3 className="privacy-sub-head">
                                    Service Providers
                                </h3>
                                <p className="p-sub">
                                    We may employ third-party companies and
                                    individuals due to the following reasons:
                                </p>

                                <ul className="privacy-list">
                                    <li>To facilitate our Service;</li>
                                    <li>
                                        To provide the Service on our behalf;
                                    </li>
                                    <li>
                                        To perform Service-related services; or
                                    </li>
                                    <li>
                                        To assist us in analyzing how our
                                        Service is used.
                                    </li>
                                    <li>
                                        Mariner License Prep, LLC wants to
                                        inform users of this possibility that
                                        these third parties might have access to
                                        your basic information such as name,
                                        email, and bookmarked questions. These
                                        trusted third parties are obligated not
                                        to disclose or use the information for
                                        any other purpose than improving the
                                        service we provide.
                                    </li>
                                </ul>

                                <h3 className="privacy-sub-head">Security</h3>
                                <p>
                                    We value your trust in providing us your
                                    Personal Information, thus we are use
                                    state-of-the-art commercially acceptable
                                    means of protecting it. However, no method
                                    of transmission over the internet, or method
                                    of electronic storage is 100% secure or
                                    reliable, and we cannot guarantee its
                                    absolute security.
                                </p>

                                <h3 className="privacy-sub-head">
                                    Links to Other Sites
                                </h3>
                                <p>
                                    This Service may contain links to other
                                    sites. If you click on a third-party link,
                                    you will be directed to that site. Note that
                                    these external sites are not operated by
                                    Mariner License Prep, LLC . Therefore,
                                    westrongly advise you to review the Privacy
                                    Policy of these websites. We have no control
                                    over and assume no responsibility for the
                                    content, privacy policies, or practices of
                                    any third-party sites or services.
                                </p>

                                <h3 className="privacy-sub-head">
                                    Children’s Privacy
                                </h3>
                                <p>
                                    These Services do not address anyone under
                                    the age of 13. We do not knowingly collect
                                    personally identifiable information from
                                    children under 13. In the case we discover
                                    that a child under 13 has provided Mariner
                                    License Prep, LLCwith personal information,
                                    Mariner License Prep will immediately delete
                                    this from our servers. If you are a parent
                                    or guardian and you are aware that your
                                    child has provided us with personal
                                    information, please contact us so that we
                                    will be able to do necessary actions.
                                </p>

                                <h3 className="privacy-sub-head">
                                    Changes to This Privacy Policy
                                </h3>
                                <p>
                                    We may update our Privacy Policy from time
                                    to time. Thus, you are advised to review
                                    this page periodically for any changes. We
                                    will notify you of any changes by posting
                                    the new Privacy Policy on this page. These
                                    changes are effective immediately after they
                                    are posted on this page.
                                </p>

                                <h3 className="privacy-sub-head">Contact Us</h3>
                                <p>
                                    If you have any questions or suggestions
                                    about my Privacy Policy, do not hesitate to
                                    contact{" "}
                                    <span>
                                        <a href="mailto:support@seatrials.net">
                                            support@seatrials.net
                                        </a>
                                    </span>{" "}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                {/*<!-- About End -->*/}
                <Footer />
            </div>
        );
    }
}
export default Privacy;
