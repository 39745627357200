import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import Directory from "../../components/users/directory/Directory";
import MainQuestion from "../../components/users/newquestion/main/MainQuestion";
import SiteQuestion from "../../components/users/newquestion/sitemap/SiteQuestion";

const QuestionRoutes = () => {
    let { path } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={`${path}/directory`} component={Directory} />
            <Route exact path={`${path}/watch`} component={MainQuestion} />
            <Route exact path={`${path}/:Id`} component={SiteQuestion} />
        </Switch>
    );
};

export default QuestionRoutes;
