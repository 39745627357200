import React, { Component } from "react";
import { Link } from "react-router-dom";

class DeckSubCatNav extends Component {
    render() {
        const bookSubCatlist = this.props.deck_subcats.map((subs) => (
            <li key={subs.ZINSUBCATEGORY}>
                <Link
                    to={`/user/watch?mode=normal&type=subcat&id=${subs.ZINSUBCATEGORY}&num=1&zid=${subs._id}&zinbookcat=${subs.ZINBOOKCATEGORY}`}
                >
                    {" "}
                    {subs.ZSUBCATEGORYNAME}
                </Link>
            </li>
        ));
        return bookSubCatlist;
    }
}
export default DeckSubCatNav;
