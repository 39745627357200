import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
    getEngineUser,
    getDeckUser,
    getUserDeckBook,
    getUserEngineBook,
} from "../../../../actions/questionActions.jsx";
import { getTopic } from "../../../../actions/testActions.jsx";
import { getCurrentProfile } from "../../../../actions/userActions";
import StudyDeck from "./nav/StudyDeck.jsx";
import StudyEngine from "./nav/StudyEngine.jsx";
//import Deck from "./nav/Deck.jsx";
//import Engine from "./nav/Engine.jsx";
import isEmpty from "../../../../validation/is-empty";

class SidebarNavList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            engine_slug: "",
            deck_slug: "",
            deck_id: "",
            engine_id: "",
            question_type: "",
            studyactive: 0,
            deckactive: 0,
            engineactive: 0,
            questionactive: 0,
            testactive: 0,
            commentactive: 0,
            forumactive: 0,
            accountactive: 0,
            book1active: 0,
            study: "",
            resume: "",
        };
        this.handleStudyExpand = this.handleStudyExpand.bind(this);
        this.handleDeckExpand = this.handleDeckExpand.bind(this);
        this.handleEngineExpand = this.handleEngineExpand.bind(this);
        this.handleQuestionExpand = this.handleQuestionExpand.bind(this);
        this.handleTestExpand = this.handleTestExpand.bind(this);
        this.handleCommentExpand = this.handleCommentExpand.bind(this);
        this.handleAccountExpand = this.handleAccountExpand.bind(this);
        this.handleForumExpand = this.handleForumExpand.bind(this);
        this.handleBook1Expand = this.handleBook1Expand.bind(this);
    }

    handleBook1Expand(e) {
        e.preventDefault();
        this.setState((state) => ({
            book1active: !this.state.book1active,
        }));
    }

    handleForumExpand(e) {
        e.preventDefault();
        this.setState((state) => ({
            forumactive: !this.state.forumactive,
        }));
    }

    handleAccountExpand(e) {
        e.preventDefault();
        this.setState((state) => ({
            accountactive: !this.state.accountactive,
        }));
    }

    handleCommentExpand(e) {
        e.preventDefault();
        this.setState((state) => ({
            commentactive: !this.state.commentactive,
        }));
    }

    handleTestExpand(e) {
        e.preventDefault();
        this.setState((state) => ({
            testactive: !this.state.testactive,
        }));
    }

    handleQuestionExpand(e) {
        e.preventDefault();
        this.setState((state) => ({
            questionactive: !this.state.questionactive,
        }));
    }

    handleEngineExpand(e) {
        e.preventDefault();
        this.setState((state) => ({
            engineactive: !this.state.engineactive,
        }));
    }

    handleDeckExpand(e) {
        e.preventDefault();
        this.setState((state) => ({
            deckactive: !this.state.deckactive,
        }));
    }

    handleStudyExpand(e) {
        e.preventDefault();
        this.setState((state) => ({
            studyactive: !this.state.studyactive,
        }));
    }

    componentDidMount() {
        this.props.getUserDeckBook();
        this.props.getUserEngineBook();
        this.props.getTopic();
        // this.props.getDeckNav();
        // this.props.getEngineNav();
        this.props.getEngineUser();
        this.props.getDeckUser();
        this.props.getCurrentProfile();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.profile.profile) {
            const profile = nextProps.profile.profile;
            // console.log(profile);
            // If profile field doesnt exist, make empty string
            profile.question_type = !isEmpty(profile.question_type)
                ? profile.question_type
                : "";
            profile.unsubs_date = !isEmpty(profile.unsubs_date)
                ? profile.unsubs_date
                : "";
            profile.study = !isEmpty(profile.study) ? profile.study : "";
            profile.resume = !isEmpty(profile.resume) ? profile.resume : "";

            // Set component fields state
            this.setState({
                question_type: profile.question_type,
                study: profile.study,
                resume: profile.resume,
            });
        }

        if (nextProps.question.engine) {
            const engine = nextProps.question.engine;
            engine._id = !isEmpty(engine._id) ? engine._id : "";
            engine.Z_PK = !isEmpty(engine.Z_PK) ? engine.Z_PK : "";
            engine.ZSLUG = !isEmpty(engine.ZSLUG) ? engine.ZSLUG : "";

            // Set component fields state for blog
            this.setState({
                engine_slug: engine.ZSLUG,
                engine_id: engine.Z_PK,
                engine_zid: engine._id,
            });
        }

        if (nextProps.question.deck) {
            const deck = nextProps.question.deck;
            deck._id = !isEmpty(deck._id) ? deck._id : "";
            deck.Z_PK = !isEmpty(deck.Z_PK) ? deck.Z_PK : "";
            deck.ZSLUG = !isEmpty(deck.ZSLUG) ? deck.ZSLUG : "";

            // Set component fields state for blog
            this.setState({
                deck_slug: deck.ZSLUG,
                deck_id: deck.Z_PK,
                deck_zid: deck._id,
            });
        }
    }

    render() {
        //  const { topics ,loading} = this.props.test;
        const { deck_books, engine_books } = this.props.question;
        // const { deck_navs, engine_navs } = this.props.question;
        let deckBookContent;
        if (deck_books.length > 0) {
            deckBookContent = <StudyDeck deck_books={deck_books} />;
        }
        let engineBookContent;
        if (engine_books.length > 0) {
            engineBookContent = <StudyEngine engine_books={engine_books} />;
        }

        let studyContent;

        if (this.state.question_type === "Deck") {
            studyContent = (
                <ul
                    className={
                        "sub-menu " +
                        (this.state.studyactive ? "d-block " : "d-none")
                    }
                >
                    <li>
                        <Link
                            to={`/user/watch?mode=normal&type=bookcat&id=2&num=1&zid=${this.state.deck_zid}`}
                        >
                            All Deck
                        </Link>
                    </li>
                    {deckBookContent}
                </ul>
            );
        } else {
            studyContent = (
                <ul
                    className={
                        "sub-menu " +
                        (this.state.studyactive ? "d-block " : "d-none")
                    }
                >
                    <li>
                        <Link
                            to={`/user/watch?mode=normal&type=bookcat&id=1&num=1&zid=${this.state.engine_zid}`}
                        >
                            All Engine
                        </Link>
                    </li>
                    {engineBookContent}
                </ul>
            );
        }

        return (
            <div>
                <ul className="nav">
                    <li className="nav-header">Navigation</li>
                    <li className="closed has-sub ">
                        <Link to="/dashboard">
                            <i className="fa fa-th"></i> <span>Dashboard </span>
                        </Link>
                    </li>

                    <li
                        className={
                            (this.state.studyactive
                                ? "active expand "
                                : "closed") + "has-sub"
                        }
                    >
                        <Link to="/" onClick={this.handleStudyExpand}>
                            <b className="caret"></b>{" "}
                            <i className="fas fa-book"></i> <span>Study </span>
                        </Link>
                        {studyContent}
                    </li>

                    <li className="closed has-sub ">
                        <Link to={this.state.study}>
                            {" "}
                            <i className="fas fa-play"></i>{" "}
                            <span>Resume {this.state.resume} </span>
                        </Link>
                    </li>

                    <li className="closed ">
                        <Link to="/user/search">
                            <i className="fa fa-search"></i>{" "}
                            <span>Search </span>
                        </Link>
                    </li>

                    <li
                        className={
                            (this.state.testactive
                                ? "active expand "
                                : "closed") + "has-sub"
                        }
                    >
                        <Link to="/user/test" onClick={this.handleTestExpand}>
                            {" "}
                            <b className="caret"></b>
                            <i className="fas fa-pencil-alt"></i>
                            <span>Tests </span>
                        </Link>
                        <ul
                            className={
                                "sub-menu " +
                                (this.state.testactive ? "d-block " : "d-none")
                            }
                        >
                            <li className="closed ">
                                <Link to="/user/test/generate-test">
                                    <span>Generate Test </span>
                                </Link>
                            </li>
                            <li className="closed ">
                                <Link to="/user/test/manage-test">
                                    <span>Manage Tests </span>
                                </Link>
                            </li>
                        </ul>
                    </li>

                    <li
                        className={
                            (this.state.questionactive
                                ? "active expand "
                                : "closed") + "has-sub"
                        }
                    >
                        <Link to="/" onClick={this.handleQuestionExpand}>
                            {" "}
                            <b className="caret"></b>
                            <i className="far fa-list-alt"></i>
                            <span>Logged </span>
                        </Link>
                        <ul
                            className={
                                "sub-menu " +
                                (this.state.questionactive
                                    ? "d-block "
                                    : "d-none")
                            }
                        >
                            <li className="closed ">
                                <Link to="/user/manage-question/bookmarked-question">
                                    {" "}
                                    <span>Total Bookmarked Question </span>
                                </Link>
                            </li>
                            <li className="closed ">
                                <Link to="/user/manage-question/hidden-question">
                                    {" "}
                                    <span>Total Hidden Question </span>
                                </Link>
                            </li>
                            <li className="closed ">
                                <Link to="/user/manage-question/all-logged">
                                    <span>Total Logged Question </span>
                                </Link>
                            </li>
                            <li className="closed ">
                                <Link to="/user/manage-question/by-topic">
                                    <span>Total Question By Topic </span>
                                </Link>
                            </li>
                        </ul>
                    </li>

                    <li
                        className={
                            (this.state.commentactive
                                ? "active expand "
                                : "closed") + "has-sub"
                        }
                    >
                        <Link
                            to="/user/manage-comments"
                            onClick={this.handleCommentExpand}
                        >
                            <b className="caret"></b>
                            <i className="far fa-comments"></i>
                            <span>Comments </span>
                        </Link>
                        <ul
                            className={
                                "sub-menu " +
                                (this.state.commentactive
                                    ? "d-block "
                                    : "d-none")
                            }
                        >
                            <li className="closed ">
                                <Link to="/user/manage-comments">
                                    <span>Manage Comments </span>
                                </Link>
                            </li>
                        </ul>
                    </li>

                    {/* <li
                        className={
                            (this.state.forumactive
                                ? "active expand "
                                : "closed") + "has-sub"
                        }
                    >
                        <Link to="/user/forum" onClick={this.handleForumExpand}>
                            <b className="caret"></b>
                            <i className="fab fa-wpforms"></i>{" "}
                            <span>Forum </span>
                        </Link>
                        <ul
                            className={
                                "sub-menu " +
                                (this.state.forumactive ? "d-block " : "d-none")
                            }
                        >
                            <li className="closed ">
                                <Link to="/user/forum">
                                    <span>View Forum </span>
                                </Link>
                            </li>
                            <li className="closed ">
                                <Link to="/user/forum/add-thread">
                                    <span>Add Thread </span>
                                </Link>
                            </li>
                            <li className="closed ">
                                <Link to="/user/forum/forum-manage">
                                    <span>Manage Forum </span>
                                </Link>
                            </li>
                            <li className="closed ">
                                <Link to="/user/forum/forum-comment">
                                    <span>Manage Forum Comments </span>
                                </Link>
                            </li>
                        </ul>
                    </li> */}

                    <li
                        className={
                            (this.state.accountactive
                                ? "active expand "
                                : "closed") + "has-sub"
                        }
                    >
                        <Link to="/" onClick={this.handleAccountExpand}>
                            <b className="caret"></b>
                            <i className="fas fa-cog"></i>{" "}
                            <span>Settings </span>
                        </Link>
                        <ul
                            className={
                                "sub-menu " +
                                (this.state.accountactive
                                    ? "d-block "
                                    : "d-none")
                            }
                        >
                            <li className="closed ">
                                <Link to="/user/account/profile">
                                    <span>Profile </span>
                                </Link>
                            </li>
                            <li className="closed ">
                                <Link to="/user/account/subscription">
                                    <span>Access Plans </span>
                                </Link>
                            </li>

                            <li className="closed ">
                                <Link to="/user/account/settings">
                                    <span>Change Email/Password </span>
                                </Link>
                            </li>

                            <li className="closed ">
                                <Link to="/user/account/contact">
                                    <span>Contact </span>
                                </Link>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        );
    }
}

SidebarNavList.propTypes = {
    getUserDeckBook: PropTypes.func.isRequired,
    getUserEngineBook: PropTypes.func.isRequired,
    // getDeckNav: PropTypes.func.isRequired,
    getDeckUser: PropTypes.func.isRequired,
    getEngineUser: PropTypes.func.isRequired,
    // getEngineNav: PropTypes.func.isRequired,
    getTopic: PropTypes.func.isRequired,
    getCurrentProfile: PropTypes.func.isRequired,
    test: PropTypes.object.isRequired,
    question: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    test: state.test,
    question: state.question,
    profile: state.profile,
    auth: state.auth,
});

export default connect(mapStateToProps, {
    getTopic,
    getDeckUser,
    getEngineUser,
    getCurrentProfile,
    getUserDeckBook,
    getUserEngineBook,
})(SidebarNavList);
