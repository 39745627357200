import React, { useEffect } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import BlogPageDetails from "../../components/users/blog/BlogPageDetails";
import ChangePassword from "../../components/users/auth/ChangePassword";
import Dashboard from "../../components/member/dashboard/Dashboard";
import EmailVerify from "../../components/users/auth/EmailVerify";
import Forgotpassword from "../../components/users/auth/Forgotpassword";
import Login from "../../components/users/auth/Login";
import Register from "../../components/users/auth/Register";
import PageDetails from "../../components/users/blog/PageDetails";
import About from "../../components/users/layout/About";
import UserContact from "../../components/users/layout/Contact";
import Disclaimer from "../../components/users/layout/Disclaimer";
import Faq from "../../components/users/layout/Faq";
import Landing from "../../components/users/layout/Landing";
import Pricing from "../../components/users/layout/Pricing";
import Privacy from "../../components/users/layout/Privacy";
import Welcome from "../../components/users/layout/WelCome";
import PaymentNew from "../../components/users/payment/PaymentNew";
import Sitemap from "../../components/users/sitemap/Sitemap";
import PrivateRoute from "./PrivateRoute";
// import ForumRoutes from "./ForumRoutes";
import QuestionRoutes from "./QuestionRoutes";
import SearchRoutes from "./SearchRoutes";
import UserRoutes from "./User";
import QuestionUser from "../../components/member/question/unpaid/QuestionUser";
import Payment from "../../components/users/payment/Payment";
import { loadUser } from "../../actions/userActions";
import store from "../../store";
import PageNotFound from "../../components/PageNotFound";

const PublicRoutes = () => {
    let { path } = useRouteMatch();

    useEffect(() => {
        store.dispatch(loadUser());
    }, []);

    return (
        <Switch>
            {/* Stand alone routes */}
            <Route exact path={`${path}`} component={Landing} />

            {/* Start of Auth Routes */}
            <Route exact path={`${path}login`} component={Login} />
            <Route exact path={`${path}register`} component={Register} />
            <Route
                exact
                path={`${path}forgot-password`}
                component={Forgotpassword}
            />
            <Route
                exact
                path={`${path}change-user-password`}
                component={ChangePassword}
            />
            <Route exact path={`${path}email-verify`} component={EmailVerify} />
            {/* End of Auth Routes */}

            {/* Start of Common Routes accessible without login */}
            <Route exact path={`${path}about`} component={About} />
            <Route exact path={`${path}contact`} component={UserContact} />
            <Route exact path={`${path}disclaimer`} component={Disclaimer} />
            <Route exact path={`${path}faq`} component={Faq} />
            <Route exact path={`${path}pricing`} component={Pricing} />
            <Route exact path={`${path}privacy`} component={Privacy} />
            <Route exact path={`${path}site-map`} component={Sitemap} />
            <Route exact path={`${path}payment/:Id`} component={PaymentNew} />
            <Route exact path={`${path}preview/:Id`} component={PageDetails} />
            {/* End of Common Routes accessible without login */}

            {/* Start of Provate Routes accessible with login only */}
            <PrivateRoute path={`${path}dashboard`} component={Dashboard} />
            <PrivateRoute exact path={`${path}welcome`} component={Welcome} />
            <PrivateRoute
                exact
                path={`${path}users/watch`}
                component={QuestionUser}
            />
            <PrivateRoute
                exact
                path={`${path}payment-subscribe/:Id`}
                component={Payment}
            />
            {/* End of Provate Routes accessible with login only */}

            {/* Start of Nested routes */}
            <Route path={`${path}search`} component={SearchRoutes} />
            <Route path={`${path}question`} component={QuestionRoutes} />
            {/* <Route path={`${path}forum`} component={ForumRoutes} /> */}
            <Route path={`${path}user`} component={UserRoutes} />
            {/* End of Nested routes */}

            <Route exact path={`${path}:Id`} component={BlogPageDetails} />

            <Route path="*" component={PageNotFound} />
        </Switch>
    );
};

export default PublicRoutes;
