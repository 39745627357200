import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "../common/common.jsx";
import Header from "../common/Header";
import Footer from "../common/Footer";
import {
    getDeckBook,
    getEngineBook,
} from "../../../actions/questionActions.jsx";
import { getActivePage } from "../../../actions/pageActions.jsx";
import DeckList from "./DeckList.jsx";
import EngineList from "./EngineList.jsx";
import TopContent from "./TopContent.jsx";

class Sitemap extends Component {
    componentDidMount() {
        this.props.getDeckBook();
        this.props.getEngineBook();
        this.props.getActivePage();
    }
    render() {
        const { deck_books, engine_books } = this.props.question;
        let deckBookContent;
        if (deck_books.length > 0) {
            deckBookContent = <DeckList deck_books={deck_books} />;
        }
        let engineBookContent;
        if (engine_books.length > 0) {
            engineBookContent = <EngineList engine_books={engine_books} />;
        }
        const { pages } = this.props.page;
        let pageContent;
        if (pages.length > 0) {
            pageContent = <TopContent pages={pages} />;
        }
        return (
            <React.Fragment>
                <Header />

                <nav class="breadcrumb">
                    <div class="container">
                        <Link class="breadcrumb-item" to="index.html">
                            Home
                        </Link>
                        <span class="breadcrumb-item active">Site Map</span>
                    </div>
                </nav>

                <div class="weare-section">
                    <div class="container">
                        <div class="row">
                            <div class="col-sm-12 col-xs-12">
                                <h1>Site Map</h1>
                                <div class="clearfix bottom"></div>
                            </div>

                            <div class="col-sm-8 col-xs-12">
                                <div class="sitemap">
                                    <div class="row">
                                        <div class="col-sm-4 col-xs-12">
                                            <ul>
                                                <li>General Site</li>
                                                <li></li>
                                                <li>
                                                    <Link to="/">index</Link>
                                                </li>
                                                <li>
                                                    <Link to="/faq">FAQ</Link>
                                                </li>
                                                <li>
                                                    <Link to="/about">
                                                        About
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/search">
                                                        Search
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/forum">
                                                        Forum
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/contact">
                                                        Contact
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/disclaimer">
                                                        Terms &amp; Conditions
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/question/directory">
                                                        Directory
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>

                                        <div class="col-sm-4 col-xs-12">
                                            <ul>
                                                <li>Deck Questions</li>
                                                {deckBookContent}
                                            </ul>
                                        </div>

                                        <div class="col-sm-4 col-xs-12">
                                            <ul>
                                                <li>Engine Questions</li>
                                                {engineBookContent}
                                            </ul>
                                        </div>
                                        <div class="clearfix mt-5"></div>
                                        <div class="col-sm-4 col-xs-12">
                                            <ul>
                                                <li>Top Content</li>
                                                {pageContent}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </React.Fragment>
        );
    }
}

Sitemap.propTypes = {
    getDeckBook: PropTypes.func.isRequired,
    getEngineBook: PropTypes.func.isRequired,
    getActivePage: PropTypes.func.isRequired,
    question: PropTypes.object.isRequired,
    page: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    question: state.question,
    page: state.page,
});

export default connect(mapStateToProps, {
    getDeckBook,
    getEngineBook,
    getActivePage,
})(Sitemap);
