export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const GET_ERRORS = "GET_ERRORS";

export const ADMIN_LOGIN_SUCCESS = "ADMIN_LOGIN_SUCCESS";
export const ADMIN_LOADED = "ADMIN_LOADED";
export const ADMIN_AUTH_ERROR = "ADMIN_AUTH_ERROR";
export const GET_ADMIN_PROFILE = "GET_ADMIN_PROFILE";
export const GET_ADMIN_PROFILES = "GET_ADMIN_PROFILES";
export const UPDATE_ADMIN_PROFILE = "UPDATE_ADMIN_PROFILE";
export const ADMIN_LOGOUT = "ADMIN_LOGOUT";
export const ADMIN_CLEAR_PROFILE = "ADMIN_CLEAR_PROFILE";
export const ADMIN_PROFILE_LOADING = "ADMIN_PROFILE_LOADING";
export const ADMIN_PROFILE_ERROR = "ADMIN_PROFILE_ERROR";

export const GET_BILLING = "GET_BILLING";
export const GET_PRICE = "GET_PRICE";

export const ADD_PAGE = "ADD_PAGE";
export const GET_PAGE = "GET_PAGE";
export const GET_PAGES = "GET_PAGES";
export const PAGE_LOADING = "PAGE_LOADING";
export const DELETE_PAGE = "DELETE_PAGE";
export const PAGE_NOT_FOUND = "PAGE_NOT_FOUND";

export const ADD_COUPAN = "ADD_COUPAN";
export const GET_COUPAN = "GET_COUPAN";
export const GET_COUPANS = "GET_COUPANS";
export const USED_COUPANS = "USED_COUPANS";
export const COUPAN_EMAILS = "COUPAN_EMAILS";
export const GET_PRO_EMAIL = "GET_PRO_EMAIL";
export const COUPAN_LOADING = "COUPAN_LOADING";
export const DELETE_COUPAN = "DELETE_COUPAN";
export const DELETE_EMAILS = "DELETE_EMAILS";

export const ADD_SUBSCRIBER = "ADD_SUBSCRIBER";
export const GET_SUBSCRIBER = "GET_SUBSCRIBER";
export const GET_SUBSCRIBERS = "GET_SUBSCRIBERS";
export const SUBSCRIBER_LOADING = "SUBSCRIBER_LOADING";
export const DELETE_SUBSCRIBER = "DELETE_SUBSCRIBER";

export const GET_INVOICE = "GET_INVOICE";
export const GET_INVOICES = "GET_INVOICES";
export const INVOICE_LOADING = "INVOICE_LOADING";

export const GET_COMMENT = "GET_COMMENT";
export const GET_COMMENTS = "GET_COMMENTS";
export const GET_COMMENT_LISTS = "GET_COMMENT_LISTS";
export const COMMENT_LOADING = "COMMENT_LOADING";
export const DELETE_COMMENT = "DELETE_COMMENT";
export const COMMENT_COUNT = "COMMENT_COUNT";
export const COMMENT_QUESTIONS = "COMMENT_QUESTIONS";
export const ADD_COMMENT = "ADD_COMMENT";

export const DASHBOARD_USER = "DASHBOARD_USER";
export const DASHBOARD_SUBSCRIBER = "DASHBOARD_SUBSCRIBER";
export const DASHBOARD_TREVENUE = "DASHBOARD_TREVENUE";
export const DASHBOARD_NREVENUE = "DASHBOARD_NREVENUE";
export const DASHBOARD_WEBSITES = "DASHBOARD_WEBSITES";
export const DASHBOARD_ANDROIDS = "DASHBOARD_ANDROIDS";
export const DASHBOARD_IOS = "DASHBOARD_IOS";
export const DASHBOARD_COMMENTS = "DASHBOARD_COMMENTS";
export const DASHBOARD_LOADING = "DASHBOARD_LOADING";

export const GET_DECK = "GET_DECK";
export const GET_DECK_BOOKS = "GET_DECK_BOOKS";
export const GET_DECK_CATS = "GET_DECK_CATS";
export const GET_DECK_SUBCATS = "GET_DECK_SUBCATS";
export const GET_ENGINE = "GET_ENGINE";
export const GET_ENGINE_BOOKS = "GET_ENGINE_BOOKS";
export const GET_ENGINE_CATS = "GET_ENGINE_CATS";
export const GET_ENGINE_SUBCATS = "GET_ENGINE_SUBCATS";
export const QUESTION_LOADING = "QUESTION_LOADING";
export const GET_QUESTION = "GET_QUESTION";
export const GET_PRIVIOUS = "GET_PRIVIOUS";
export const GET_NEXT = "GET_NEXT";
export const TOTAL_QUESTION = "TOTAL_QUESTION";
export const GET_DECK_NAVS = "GET_DECK_NAVS";
export const GET_ENGINE_NAVS = "GET_ENGINE_NAVS";
export const GET_BOOKMARK = "GET_BOOKMARK";
export const GET_HIDE = "GET_HIDE";
export const GET_NUM = "GET_NUM";

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const GET_NAVS = "GET_NAVS";

export const CLEAR_PROFILE = "CLEAR_PROFILE";
export const PROFILE_LOADING = "PROFILE_LOADING";
export const GET_PROFILE = "GET_PROFILE";
export const PROFILE_ERROR = "PROFILE_ERROR";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const GET_PROFILES = "GET_PROFILES";

export const GET_TOPICS = "GET_TOPICS";
export const GET_TESTS = "GET_TESTS";
export const GET_TEST_DETAILS = "GET_TEST_DETAILS";
export const TEST_LOADING = "TEST_LOADING";
export const CLEAR_TEST = "CLEAR_TEST";
export const DELETE_TEST = "DELETE_TEST";
export const GET_TEST = "GET_TEST";
export const GET_WRITE_TESTS = "GET_WRITE_TESTS";
export const GET_TOTAL_COUNT = "GET_TOTAL_COUNT";
export const GET_TEST_LISTS = "GET_TEST_LISTS";
export const GET_TEST_LIST = "GET_TEST_LIST";
export const GET_WRITE_COUNT = "GET_WRITE_COUNT";

export const GET_BOOKMARKEDS = "GET_BOOKMARKEDS";
export const DELETE_BOOKMARK = "DELETE_BOOKMARK";
export const GET_HIDDENS = "GET_HIDDENS";
export const GET_LOGGEDS = "GET_LOGGEDS";
export const GET_LOGGED_TOPICS = "GET_LOGGED_TOPICS";
export const LOGGED_LOADING = "LOGGED_LOADING";
export const UN_HIDE = "UN_HIDE";
export const UN_BOOKMARKED = "UN_BOOKMARKED";
export const GET_BOOK_LISTS = "GET_BOOK_LISTS";
export const DELETE_ALL_LOGGED = "DELETE_ALL_LOGGED";
export const DELETE_ALL_BOOKMARKED = "DELETE_ALL_BOOKMARKED";
export const UNHIDE_ALL_QUESTIONS = "UNHIDE_ALL_QUESTIONS";

export const GET_SEARCHS = "GET_SEARCHS";
export const SEARCH_LOADING = "SEARCH_LOADING";

export const DIRECTORY_DECKS = "DIRECTORY_DECKS";
export const DIRECTORY_ENGINS = "DIRECTORY_ENGINS";
export const DIRECTORY_DECK_COUNT = "DIRECTORY_DECK_COUNT";
export const DIRECTORY_ENGINE_COUNT = "DIRECTORY_ENGINE_COUNT";
export const DIRECTORY_LOADING = "DIRECTORY_LOADING";

export const ADD_FORUM = "ADD_FORUM";
export const GET_FORUM = "GET_FORUM";
export const GET_FORUMS = "GET_FORUMS";
export const GET_REPLY = "GET_REPLY";
export const GET_REPLYS = "GET_REPLYS";
export const DELETE_FORUM = "DELETE_FORUM";
export const DELETE_REPLY = "DELETE_REPLY";
export const FORUM_LOADING = "FORUM_LOADING";

export const FORUM_COMMENTS = "FORUM_COMMENTS";
export const FORUM_COMMENT = "FORUM_COMMENT";

export const COUNT_GENERAL = "COUNT_GENERAL";
export const COUNT_DECK = "COUNT_DECK";
export const COUNT_ENGINE = "COUNT_ENGINE";
export const COUNT_HELP = "COUNT_HELP";
export const GET_NEW_GENERAL = "GET_NEW_GENERAL";
export const GET_NEW_DECK = "GET_NEW_DECK";
export const GET_NEW_ENGINE = "GET_NEW_ENGINE";
export const GET_NEW_HELP = "GET_NEW_HELP";

export const DASH_BOOK = "DASH_BOOK";
export const DASH_HIDDEN = "DASH_HIDDEN";
export const DASH_LOGGED = "DASH_LOGGED";

export const PAYMENT_INPROGRESS = "PAYMENT_INPROGRESS";
