import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";
import Container from "../content/Container.jsx";
import { Panel, PanelHeader, PanelBody } from "../common/panel/Panel.jsx";
import TextFieldArea from "../common/textfield/TextFieldArea";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import ReactNotification from "react-notifications-component";
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { addPages } from "../../../actions/pageActions.jsx";

class AddPages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page_title: "",
            data: "",
            slug: "",
            description: "",
            image_alt: "",
            meta_desc: "",
            tags: "",
            type: "",
            alert: false,
            AlertBox: false,
            visible: false,
            visible1: true,
            visible2: true,
            file: "",
            imagePreview: "",
            imagePreviewUrl: "",
            errors: {},
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.fileChangedHandler = this.fileChangedHandler.bind(this);
        this.onDismiss = this.onDismiss.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    onDismiss() {
        this.setState({ visible: false, visible1: false, visible2: false });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({ errors: nextProps.errors });
        }
    }

    handleChange(evt) {
        this.setState({
            data: evt,
        });
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleCancel(e) {
        this.setState({
            page_title: "",
            data: "",
            description: "",
            image_alt: "",
            meta_desc: "",
            tags: "",
            status: "",
            slug: "",
        });
    }
    fileChangedHandler(e) {
        this.setState({
            file: e.target.files[0],
            imagePreviewUrl: URL.createObjectURL(e.target.files[0]),
        });
    }

    onSubmit(e) {
        e.preventDefault();
        //console.log(this.state.file);
        const formData = new FormData();
        formData.append("page_title", this.state.page_title);
        formData.append("slug", this.state.slug);
        formData.append("description", this.state.data);
        formData.append("image_alt", this.state.image_alt);
        formData.append("meta_desc", this.state.meta_desc);
        formData.append("tags", this.state.tags);
        formData.append("type", this.state.type);
        formData.append("page_image", this.state.file);
        this.props.addPages(formData, this.props.history);

        this.setState({
            alert: true,
            // page_title: "",
            // data:"",
            // description: "",
            // image_alt: "",
            // meta_desc: "",
            // tags: "",
            //  type: "",
        });
    }

    render() {
        const { errors } = this.state;

        return (
            <React.Fragment>
                <ReactNotification />
                <Container>
                    <div className="content">
                        <span className="hide-noti ">
                            {errors.pagesuccess &&
                                store.addNotification({
                                    title: "Success",
                                    message: errors.pagesuccess,
                                    type: "success",
                                    insert: "top",
                                    container: "top-center",
                                    animationIn: ["animated", "fadeIn"],
                                    animationOut: ["animated", "fadeOut"],
                                    dismiss: { duration: 3000 },
                                    dismissable: { click: true },
                                    // content: notificationContent
                                })}
                        </span>
                        <ol className="breadcrumb float-xl-right">
                            <li className="breadcrumb-item">
                                <Link to="/admin/dashboard">Dashboard</Link>
                            </li>
                            <li className="breadcrumb-item active">
                                Create pages
                            </li>
                        </ol>
                        <h1 className="page-header">Create Pages </h1>
                        <div className="row">
                            <div className="col-xl-12">
                                <Panel>
                                    <PanelHeader>Create Pages</PanelHeader>
                                    <PanelBody className="panel-form">
                                        <div className="panel-form">
                                            <form
                                                className="form-horizontal form-bordered"
                                                onSubmit={this.onSubmit}
                                            >
                                                <TextFieldArea
                                                    label="Title"
                                                    placeholder="Title"
                                                    name="page_title"
                                                    type="text"
                                                    value={
                                                        this.state.page_title
                                                    }
                                                    onChange={this.onChange}
                                                    error={errors.page_title}
                                                    striks="*"
                                                />

                                                <TextFieldArea
                                                    label="Slug"
                                                    placeholder="Slug"
                                                    name="slug"
                                                    type="text"
                                                    value={this.state.slug}
                                                    onChange={this.onChange}
                                                    error={errors.slug}
                                                    striks="*"
                                                />

                                                <div className="form-group row">
                                                    <label className="col-lg-3 col-form-label">
                                                        Description
                                                        <span className="text-danger">
                                                            *
                                                        </span>
                                                    </label>
                                                    <div className="col-lg-9">
                                                        <SunEditor
                                                            name="data"
                                                            type="text"
                                                            value={
                                                                this.state.data
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                            height="200"
                                                        />
                                                        {errors.description && (
                                                            <div
                                                                className="invalid-feedback"
                                                                style={{
                                                                    display:
                                                                        "block",
                                                                }}
                                                            >
                                                                {
                                                                    errors.description
                                                                }
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <label className="col-lg-3 col-form-label">
                                                        Upload Image
                                                    </label>
                                                    <div
                                                        className="col-lg-9"
                                                        align="center"
                                                    >
                                                        <div className="row">
                                                            <div className="col-lg-3 d-flex flex-column justify-content-center">
                                                                <label
                                                                    className="btn btn-primary"
                                                                    htmlFor="inputImage"
                                                                    title="Upload  file"
                                                                >
                                                                    Upload
                                                                </label>
                                                                <input
                                                                    id="inputImage"
                                                                    className="hide"
                                                                    type="file"
                                                                    name="page_image"
                                                                    onChange={
                                                                        this
                                                                            .fileChangedHandler
                                                                    }
                                                                    imgextension={[
                                                                        ".jpg",
                                                                        ".gif",
                                                                        ".png",
                                                                        ".jpeg",
                                                                    ]}
                                                                />
                                                            </div>
                                                            <div className="col-lg-9 d-flex">
                                                                <img
                                                                    src={`${this.state.imagePreviewUrl}`}
                                                                    className="img height-75"
                                                                    alt=""
                                                                    width="100%"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <TextFieldArea
                                                    label="Image alt text"
                                                    placeholder="Image alt text"
                                                    name="image_alt"
                                                    type="text"
                                                    value={this.state.image_alt}
                                                    onChange={this.onChange}
                                                    error={errors.image_alt}
                                                />

                                                <TextFieldArea
                                                    label="Meta Description"
                                                    placeholder="Meta Description"
                                                    name="meta_desc"
                                                    type="text"
                                                    value={this.state.meta_desc}
                                                    onChange={this.onChange}
                                                    error={errors.meta_desc}
                                                />

                                                <TextFieldArea
                                                    label="Tags"
                                                    placeholder="Tags"
                                                    name="tags"
                                                    type="text"
                                                    value={this.state.tags}
                                                    onChange={this.onChange}
                                                    error={errors.tags}
                                                />

                                                <div className="form-group row ">
                                                    <label className="col-lg-3 col-form-label">
                                                        Status{" "}
                                                        <span className="text-danger">
                                                            *
                                                        </span>
                                                    </label>
                                                    <div className="col-lg-5">
                                                        <select
                                                            className={classnames(
                                                                "form-control ",
                                                                {
                                                                    "is-invalid":
                                                                        errors.status,
                                                                }
                                                            )}
                                                            data-size="10"
                                                            data-live-search="true"
                                                            data-style="btn-white"
                                                            name="type"
                                                            value={
                                                                this.state.type
                                                            }
                                                            onChange={
                                                                this.onChange
                                                            }
                                                        >
                                                            <option value="">
                                                                Select Status
                                                            </option>
                                                            <option value="published">
                                                                Published
                                                            </option>
                                                            <option value="draft">
                                                                Draft
                                                            </option>
                                                        </select>

                                                        {errors.type && (
                                                            <div
                                                                className="invalid-feedback"
                                                                style={{
                                                                    display:
                                                                        "block",
                                                                }}
                                                            >
                                                                {errors.type}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                {/* <div className="form-group row m-b-15">
                              <label className="col-lg-3 col-form-label">
                                Preview
                              </label>
                              <div className="col-lg-9">
                                <img
                                  src={this.state.imagePreviewUrl}
                                  className="img height-75"
                                  alt=""
                                  width="100%"
                                />
                              </div>
                            </div> */}

                                                <div className="form-group row">
                                                    <div className="col-md-12 offset-md-5">
                                                        <button
                                                            type="submit"
                                                            className="btn btn-sm btn-primary m-r-5"
                                                        >
                                                            Submit
                                                        </button>

                                                        <button
                                                            type="button"
                                                            onClick={
                                                                this
                                                                    .handleCancel
                                                            }
                                                            className="btn btn-sm btn-default"
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </PanelBody>
                                </Panel>
                            </div>
                        </div>
                    </div>
                </Container>
            </React.Fragment>
        );
    }
}

AddPages.propTypes = {
    addPages: PropTypes.func.isRequired,
    admin: PropTypes.object.isRequired,
    page: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    admin: state.admin,
    errors: state.errors,
});

export default connect(mapStateToProps, { addPages })(withRouter(AddPages));
