import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import Search from "../../components/users/search/Search";
import SearchQuestionNew from "../../components/users/newquestion/search/SearchQuestion";

const SearchRoutes = () => {
    let { path } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={`${path}/`} component={Search} />
            <Route exact path={`${path}/view`} component={SearchQuestionNew} />
        </Switch>
    );
};

export default SearchRoutes;
