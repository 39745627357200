import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import queryString from "qs";
import TextFieldArea from "../common/textfield/TextFieldArea";
import { userlogin } from "../../../actions/userActions.jsx";
import ReactNotification from "react-notifications-component";
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
//import  logo from "../../.././assets/user/images/logo.png";

class Login extends Component {
    constructor() {
        super();
        this.state = {
            email: "",
            password: "",
            visible: false,
            visible1: true,
            errors: {},
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        // console.log(this.props.auth.isAuthenticated1)
        if (this.props.auth.isAuthenticated1) {
            this.props.history.push("/dashboard");
        }
        const queryStringValues = queryString.parse(
            this.props.location.search.slice(1)
        );

        if (queryStringValues.veri) {
            // User is redirected by us after registration.
            store.addNotification({
                title: "Success",
                message: "Email verified Succesfully Please Login",
                type: "success",
                insert: "top",
                container: "top-center",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: { duration: 3000 },
                dismissable: { click: true },
                // content: notificationContent
            });
        }

        if (queryStringValues.mail) {
            // User is redirected by us after registration.
            store.addNotification({
                title: "Success",
                message:
                    "Email with password reset link is sent. Please check your email",
                type: "success",
                insert: "top",
                container: "top-center",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: { duration: 3000 },
                dismissable: { click: true },
                // content: notificationContent
            });
        }

        if (queryStringValues.password) {
            // User is redirected by us after registration.
            store.addNotification({
                title: "Success",
                message:
                    "Password Changed Successfully. Please Login with new password",
                type: "success",
                insert: "top",
                container: "top-center",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: { duration: 3000 },
                dismissable: { click: true },
                // content: notificationContent
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.auth.isAuthenticated1) {
            // console.log("Hii")
            this.props.history.push("/dashboard");
        }

        if (nextProps.errors) {
            this.setState({ errors: nextProps.errors });
        }
    }

    onSubmit(e) {
        e.preventDefault();
        const adminloginData = {
            email: this.state.email,
            password: this.state.password,
        };
        // console.log(adminloginData);
        this.props.userlogin(adminloginData);
        localStorage.removeItem("time");
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value, errors: "" });
    }
    render() {
        const { errors } = this.state;
        return (
            <div>
                <ReactNotification />
                <span className="hide-noti">
                    {this.state.errors.error &&
                        store.addNotification({
                            title: "Error!",
                            message: this.state.errors.error,
                            type: "danger",
                            insert: "top",
                            container: "top-center",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: { duration: 3000 },
                            dismissable: { click: true },
                            // content: notificationContent
                        })}
                </span>
                <div className="loginbg">
                    <div className="container">
                        <div className="row">
                            <form
                                className="form-signin"
                                id="login_form"
                                onSubmit={this.onSubmit}
                            >
                                <div className="text-center mb-4">
                                    <Link to="/">
                                        <img
                                            src="https://seatrials-bucket.s3.us-east-2.amazonaws.com/main/logo.png"
                                            alt="Marinerlicenseprep"
                                            className="login_logo"
                                            width="400"
                                        />
                                    </Link>
                                </div>
                                <div className="form-label-group">
                                    <div className="row">
                                        <TextFieldArea
                                            label="Email Address"
                                            placeholder="Email Address"
                                            name="email"
                                            type="email"
                                            value={this.state.email}
                                            onChange={this.onChange}
                                            error={errors.email}
                                        />

                                        <TextFieldArea
                                            label="Password"
                                            placeholder="Password"
                                            name="password"
                                            type="password"
                                            value={this.state.password}
                                            onChange={this.onChange}
                                            error={errors.password}
                                        />
                                    </div>
                                </div>
                                <div className="form-label-group">
                                    <div className="row">
                                        <div className="col-sm-12 col-xs-12">
                                            <button
                                                className="btn btn-lg btn-primary btn-block"
                                                type="submit"
                                            >
                                                LOGIN
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-label-group">
                                    <div className="row">
                                        <div className="col-sm-6 col-xs-12">
                                            <p className="text-muted text-center">
                                                <a href="/forgot-password">
                                                    <i className="fa fa-lock"></i>{" "}
                                                    Forgot password?{" "}
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <p className="mt-4 mb-3 text-muted text-center">
                                    Don't have account ?{" "}
                                    <Link to="/register">Sign Up</Link>.
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Login.propTypes = {
    userlogin: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
});

export default connect(mapStateToProps, { userlogin })(Login);
