import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../common/common.jsx";
import Header from "../common/Header";
import Footer from "../common/Footer";

class WelCome extends Component {
    render() {
        return (
            <React.Fragment>
                <Header />
                <div className="content-box">
                    <div className="hero-image">
                        <div className="container">
                            <div className="row">
                                <div className="slider-text-wrapper">
                                    <h1>
                                        <span>Welcome to Sea Trials</span>
                                    </h1>
                                    {/* <h6>
                                        Up-to-date questions. Directly released
                                        from U.S. Coast Guard.
                                    </h6> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <section id="screen">
                    <div className="container">
                        <div className="row">
                            <h3 className="privacy-sub-head">
                                1. We have upgraded your account to premium
                                access for 1 weeks for FREE.{" "}
                            </h3>

                            <h3 className="privacy-sub-head">
                                2. The Question bank we use is directly released
                                from the U.S Coast Guard! To understand more
                                about the question bank we have created, click{" "}
                                <a
                                    href="./understanding-what-to-study"
                                    target="_blank"
                                >
                                    here
                                </a>
                                .
                            </h3>

                            <h3 className="privacy-sub-head">
                                3. If you have any comments, suggestions, or
                                issues. Please reach out to us at{" "}
                                <a href="mailto:support@seatrials.net">
                                    support@seatrials.net
                                </a>
                            </h3>
                        </div>
                    </div>
                </section>

                <div className="testimonial">
                    <div className="container">
                        <div
                            id="carouselExampleIndicators"
                            className="carousel slide"
                            data-ride="carousel"
                        >
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <div className="carousel-caption">
                                        <h2 className="privacy-sub-head">
                                            Study on Android, iOS, macOS, or Windows{" "}
                                        </h2>

                                        <div className="download-buttons wow fadeInUp">
                                            <Link
                                                to="#"
                                                onClick={() =>
                                                    window.open(
                                                        "https://play.google.com/store/apps/details?id=com.MarinerLicensePrep.MLP",
                                                        "_blank"
                                                    )
                                                }
                                            >
                                                <img
                                                    src="https://seatrials-bucket.s3.us-east-2.amazonaws.com/main/playstore.png"
                                                    
                                                    width="130"
                                                    alt="Download from Play Store"
                                                    className="m-r-5"
                                                />
                                            </Link>
                                            <Link
                                                to="#"
                                                onClick={() =>
                                                    window.open(
                                                        "https://apps.apple.com/us/app/mariner-license-prep/id1362793782",
                                                        "_blank"
                                                    )
                                                }
                                            >
                                                <img
                                                    src="https://seatrials-bucket.s3.us-east-2.amazonaws.com/main/appstore.png"
                                                    width="130"
                                                    alt="Download from App Store"
                                                    className="m-r-5"
                                                />
                                            </Link>                                            
                                            <Link
                                                to="#"
                                                onClick={() =>
                                                    window.open(
                                                        "https://apps.microsoft.com/detail/9nb13wgm2fxl",
                                                        "_blank"
                                                    )
                                                }
                                            >
                                                <img
                                                    src="https://seatrials-bucket.s3.us-east-2.amazonaws.com/main/windows-badge.png"
                                                    width="130"
                                                    alt="Download for Windows"
                                                    className="m-r-5"
                                                />
                                            </Link>
                                        </div>
                                        <h2>Or Study Online</h2>
                                        <div
                                            className="download-buttons wow fadeInUp"
                                            style={{ visibility: "visible;" }}
                                        >
                                            <Link
                                                to="/dashboard"
                                                className="btn btn-lg btn-primary mt-4"
                                                type="LOGIN"
                                            >
                                                Continue
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="content">*/}

                <Footer />
            </React.Fragment>
        );
    }
}
export default WelCome;
