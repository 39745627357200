import React from "react";
import { Redirect, Switch, useRouteMatch } from "react-router-dom";
import TestQuestion from "../../../components/member/question/test/TestQuestion";
import GenerateTest from "../../../components/member/test/GenerateTest";
import ManageTest from "../../../components/member/test/ManageTest";
import PrintAnswerKey from "../../../components/member/test/pdf/PrintAnswerKey";
import PrintBlankTest from "../../../components/member/test/pdf/PrintBlankTest";
import PrintResult from "../../../components/member/test/pdf/PrintResult";
import PrintTest from "../../../components/member/test/PrintTest";
import TestModel from "../../../components/member/test/TestModel";
import TestResult from "../../../components/member/test/TestResult";
import PrivateRoute from "../PrivateRoute";

const TestRoutes = () => {
    let { path } = useRouteMatch();

    return (
        <Switch>
            <PrivateRoute
                exact
                path={`${path}/generate-test`}
                component={GenerateTest}
            />
            <PrivateRoute
                exact
                path={`${path}/manage-test`}
                component={ManageTest}
            />
            <PrivateRoute
                exact
                path={`${path}/watch`}
                component={TestQuestion}
            />
            <PrivateRoute
                exact
                path={`${path}/print-blank/:Id`}
                component={PrintBlankTest}
            />
            <PrivateRoute
                exact
                path={`${path}/answer-key/:Id`}
                component={PrintAnswerKey}
            />
            <PrivateRoute
                exact
                path={`${path}/print-result/:Id`}
                component={PrintResult}
            />
            <PrivateRoute
                exact
                path={`${path}/result-model/:Id`}
                component={TestModel}
            />
            <PrivateRoute
                exact
                path={`${path}/result/:Id`}
                component={TestResult}
            />
            <PrivateRoute
                exact
                path={`${path}/print/:Id`}
                component={PrintTest}
            />
            <Redirect from="*" to={`${path}/generate-test`}></Redirect>
        </Switch>
    );
};

export default TestRoutes;
