import axios from "axios";
//import { setAlert } from "./alertActions";
import {
    GET_QUESTION,
    GET_PRIVIOUS,
    GET_NEXT,
    GET_DECK,
    GET_DECK_BOOKS,
    GET_DECK_CATS,
    GET_DECK_SUBCATS,
    GET_ENGINE,
    GET_BOOKMARK,
    //DELETE_BOOKMARK,
    GET_HIDE,
    GET_ENGINE_BOOKS,
    GET_ENGINE_CATS,
    GET_ENGINE_SUBCATS,
    TOTAL_QUESTION,
    QUESTION_LOADING,
    GET_DECK_NAVS,
    GET_ENGINE_NAVS,
    GET_PROFILE,
    GET_NUM,
    GET_ERRORS,
    CLEAR_ERRORS,
} from "./types";

// Clear errors
export const clearQuesErrors = () => {
    return {
        type: CLEAR_ERRORS,
    };
};

// Edit Page
export const postNum = (editData) => (dispatch) => {
    axios
        .post(`/api/mlp_question/page_num`, editData)
        .then((res) =>
            dispatch({
                type: GET_NUM,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            })
        );
};

// Edit Page
export const postNumfree = (editData) => (dispatch) => {
    axios
        .post(`/api/mlp_question/page_num_free`, editData)
        .then((res) =>
            dispatch({
                type: GET_NUM,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            })
        );
};

// Get Page by id
export const getDeck = () => (dispatch) => {
    dispatch(setQuestionLoading());
    axios
        .get("/api/mlp_question/deck_nav")
        .then((res) =>
            dispatch({
                type: GET_DECK,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_DECK,
                payload: null,
            })
        );
};

// Get Next link by id
export const getNextLink = (type, id, num, mode, zinbookcat) => (dispatch) => {
    dispatch(setQuestionLoading());
    axios
        .get(
            `/api/mlp_question/next_link/${type}/${id}/${num}/${mode}/${zinbookcat}`
        )
        .then((res) =>
            dispatch({
                type: GET_NEXT,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_NEXT,
                payload: null,
            })
        );
};

// Get Next link by id
export const getPreviousLink =
    (type, id, num, mode, zinbookcat) => (dispatch) => {
        dispatch(setQuestionLoading());
        axios
            .get(
                `/api/mlp_question/prev_link/${type}/${id}/${num}/${mode}/${zinbookcat}`
            )
            .then((res) =>
                dispatch({
                    type: GET_PRIVIOUS,
                    payload: res.data,
                })
            )
            .catch((err) =>
                dispatch({
                    type: GET_PRIVIOUS,
                    payload: null,
                })
            );
    };
// Get Next link by id
export const getUserNextLink =
    (type, id, num, mode, zinbookcat) => (dispatch) => {
        dispatch(setQuestionLoading());
        axios
            .get(
                `/api/mlp_question/user_next_link/${type}/${id}/${num}/${mode}/${zinbookcat}`
            )
            .then((res) =>
                dispatch({
                    type: GET_NEXT,
                    payload: res.data,
                })
            )
            .catch((err) =>
                dispatch({
                    type: GET_NEXT,
                    payload: null,
                })
            );
    };

// Get Next link by id
export const getUserPreviousLink =
    (type, id, num, mode, zinbookcat) => (dispatch) => {
        dispatch(setQuestionLoading());
        axios
            .get(
                `/api/mlp_question/user_prev_link/${type}/${id}/${num}/${mode}/${zinbookcat}`
            )
            .then((res) =>
                dispatch({
                    type: GET_PRIVIOUS,
                    payload: res.data,
                })
            )
            .catch((err) =>
                dispatch({
                    type: GET_PRIVIOUS,
                    payload: null,
                })
            );
    };

// Get Engine by id
export const getQuestion = (slug) => (dispatch) => {
    dispatch(setQuestionLoading());
    axios
        .get(`/api/mlp_question/question_all/${slug}`)
        .then((res) =>
            dispatch({
                type: GET_QUESTION,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_QUESTION,
                payload: null,
            })
        );
};

// Get Engine by id
export const getQuestionbySlug = (slug) => (dispatch) => {
    dispatch(setQuestionLoading());
    axios
        .get(`/api/mlp_question/question_slug/${slug}`)
        .then((res) =>
            dispatch({
                type: GET_QUESTION,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_QUESTION,
                payload: null,
            })
        );
};

// Get Engine by id
export const submitQuestion = (quesdata) => (dispatch) => {
    // dispatch(setQuestionLoading());
    axios
        .post(`/api/mlp_question/sub_question/`, quesdata)
        .then((res) =>
            dispatch({
                type: GET_QUESTION,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_QUESTION,
                payload: null,
            })
        );
};

// Get Engine by id
export const getResume = (mode, type, id, num, zid) => (dispatch) => {
    dispatch(setQuestionLoading());
    axios
        .get(
            `/api/mlp_question/resume_ques/${mode}/${type}/${id}/${num}/${zid}`
        )
        .then((res) =>
            dispatch({
                type: GET_PROFILE,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_PROFILE,
                payload: {},
            })
        );
};
// Get Engine by id
export const getEngine = () => (dispatch) => {
    dispatch(setQuestionLoading());
    axios
        .get("/api/mlp_question/engine_nav")
        .then((res) =>
            dispatch({
                type: GET_ENGINE,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_ENGINE,
                payload: null,
            })
        );
};

// Get Total Question by id
export const totalQues = (type, id) => (dispatch) => {
    dispatch(setQuestionLoading());
    axios
        .get(`/api/mlp_question/total_ques/${type}/${id}`)
        .then((res) =>
            dispatch({
                type: TOTAL_QUESTION,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: TOTAL_QUESTION,
                payload: null,
            })
        );
};

// Get Total Question by id
export const totalUserQues = (type, id) => (dispatch) => {
    dispatch(setQuestionLoading());
    axios
        .get(`/api/mlp_question/total_user_ques/${type}/${id}`)
        .then((res) =>
            dispatch({
                type: TOTAL_QUESTION,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: TOTAL_QUESTION,
                payload: null,
            })
        );
};

// Get User Deck Book
export const getUserDeckBook = () => (dispatch) => {
    // dispatch(setQuestionLoading());
    axios
        .get("/api/mlp_question/deck_book_user")
        .then((res) =>
            dispatch({
                type: GET_DECK_BOOKS,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_DECK_BOOKS,
                payload: null,
            })
        );
};

export const getUserDeckCat = (id) => (dispatch) => {
    //  dispatch(setQuestionLoading());
    axios
        .get(`/api/mlp_question/userdeck_cat/${id}`)
        .then((res) =>
            dispatch({
                type: GET_DECK_CATS,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_DECK_CATS,
                payload: null,
            })
        );
};

export const getuserDeckSubCat = (id) => (dispatch) => {
    // dispatch(setQuestionLoading());
    axios
        .get(`/api/mlp_question/userdeck_subcat/${id}`)
        .then((res) =>
            dispatch({
                type: GET_DECK_SUBCATS,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_DECK_SUBCATS,
                payload: null,
            })
        );
};

// Get User Engine Book

export const getUserEngineBook = () => (dispatch) => {
    // dispatch(setQuestionLoading());
    axios
        .get("/api/mlp_question/engine_book_user")
        .then((res) =>
            dispatch({
                type: GET_ENGINE_BOOKS,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_ENGINE_BOOKS,
                payload: null,
            })
        );
};

// Get Engine Subcat Cat
export const getuserEngineSubCat = (id) => (dispatch) => {
    //  dispatch(setQuestionLoading());
    axios
        .get(`/api/mlp_question/user_engine_subcat/${id}`)
        .then((res) =>
            dispatch({
                type: GET_ENGINE_SUBCATS,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_ENGINE_SUBCATS,
                payload: null,
            })
        );
};

// Get Deck Book
export const getDeckBook = () => (dispatch) => {
    // dispatch(setQuestionLoading());
    axios
        .get("/api/mlp_question/deck_book_nav")
        .then((res) =>
            dispatch({
                type: GET_DECK_BOOKS,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_DECK_BOOKS,
                payload: null,
            })
        );
};

// Get Deck Book Cat
export const getDeckCat = (id) => (dispatch) => {
    //  dispatch(setQuestionLoading());
    axios
        .get(`/api/mlp_question/deck_cat/${id}`)
        .then((res) =>
            dispatch({
                type: GET_DECK_CATS,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_DECK_CATS,
                payload: null,
            })
        );
};

// Get Deck Book Cat
export const getDeckSubCat = (id) => (dispatch) => {
    // dispatch(setQuestionLoading());
    axios
        .get(`/api/mlp_question/deck_subcat/${id}`)
        .then((res) =>
            dispatch({
                type: GET_DECK_SUBCATS,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_DECK_SUBCATS,
                payload: null,
            })
        );
};

// Get Page by id
export const getEngineBook = () => (dispatch) => {
    // dispatch(setQuestionLoading());
    axios
        .get("/api/mlp_question/engine_book_nav")
        .then((res) =>
            dispatch({
                type: GET_ENGINE_BOOKS,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_ENGINE_BOOKS,
                payload: null,
            })
        );
};

// Get Deck Book Cat
export const getEngineCat = (id) => (dispatch) => {
    //  dispatch(setQuestionLoading());
    axios
        .get(`/api/mlp_question/engine_cat/${id}`)
        .then((res) =>
            dispatch({
                type: GET_ENGINE_CATS,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_ENGINE_CATS,
                payload: null,
            })
        );
};

// Get Deck Book Cat
export const getEngineSubCat = (id) => (dispatch) => {
    // dispatch(setQuestionLoading());
    axios
        .get(`/api/mlp_question/engine_subcat/${id}`)
        .then((res) =>
            dispatch({
                type: GET_ENGINE_SUBCATS,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_ENGINE_SUBCATS,
                payload: null,
            })
        );
};

//==============user Nav for before login starts======================
// Get Deck Book
export const userDeckBook = () => (dispatch) => {
    // dispatch(setQuestionLoading());
    axios
        .get("/api/mlp_question/deck_book_nav")
        .then((res) =>
            dispatch({
                type: GET_DECK_BOOKS,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_DECK_BOOKS,
                payload: null,
            })
        );
};

// Get Deck Book Cat
export const userDeckCat = (id) => (dispatch) => {
    //  dispatch(setQuestionLoading());
    axios
        .get(`/api/mlp_question/deck_cat/${id}`)
        .then((res) =>
            dispatch({
                type: GET_DECK_CATS,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_DECK_CATS,
                payload: null,
            })
        );
};

// Get Deck Book Cat
export const userDeckSubCat = (id) => (dispatch) => {
    // dispatch(setQuestionLoading());
    axios
        .get(`/api/mlp_question/deck_subcat/${id}`)
        .then((res) =>
            dispatch({
                type: GET_DECK_SUBCATS,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_DECK_SUBCATS,
                payload: null,
            })
        );
};

// Get Page by id
export const userEngineBook = () => (dispatch) => {
    // dispatch(setQuestionLoading());
    axios
        .get("/api/mlp_question/engine_book_nav")
        .then((res) =>
            dispatch({
                type: GET_ENGINE_BOOKS,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_ENGINE_BOOKS,
                payload: null,
            })
        );
};

// Get Deck Book Cat
export const userEngineSubCat = (id) => (dispatch) => {
    // dispatch(setQuestionLoading());
    axios
        .get(`/api/mlp_question/engine_subcat/${id}`)
        .then((res) =>
            dispatch({
                type: GET_ENGINE_SUBCATS,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_ENGINE_SUBCATS,
                payload: null,
            })
        );
};
//==============user nav for before login end======================
//==============user nav for after login Starts======================
// Get Deck navigation
export const getDeckNav = () => (dispatch) => {
    dispatch(setQuestionLoading());
    axios
        .get("/api/mlp_question/deck_navigation")
        .then((res) =>
            dispatch({
                type: GET_DECK_NAVS,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_DECK_NAVS,
                payload: null,
            })
        );
};

// Get Engine navigation
export const getEngineNav = () => (dispatch) => {
    dispatch(setQuestionLoading());
    axios
        .get("/api/mlp_question/engine_navigation")
        .then((res) =>
            dispatch({
                type: GET_ENGINE_NAVS,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_ENGINE_NAVS,
                payload: null,
            })
        );
};
//==============user nav for after login Ends======================

// Question loading
export const setQuestionLoading = () => {
    return {
        type: QUESTION_LOADING,
    };
};

// Bookmark Question
export const BookmarkQuestion = (Id) => (dispatch) => {
    axios
        .get(`/api/mlp_question/bookmark_ques/${Id}`)
        .then((res) =>
            dispatch({
                type: GET_BOOKMARK,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_BOOKMARK,
                payload: null,
            })
        );
};

// Bookmark Question
export const UnBookmarkQuestion = (Id) => (dispatch) => {
    axios
        .delete(`/api/mlp_question/unbookmark_ques/${Id}`)
        .then((res) =>
            dispatch({
                type: GET_BOOKMARK,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_BOOKMARK,
                payload: null,
            })
        );
};

// Bookmark Question
export const getbook = (Id) => (dispatch) => {
    axios
        .get(`/api/mlp_question/get_bookmark/${Id}`)
        .then((res) =>
            dispatch({
                type: GET_BOOKMARK,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_BOOKMARK,
                payload: null,
            })
        );
};

// Bookmark Question
export const getHide = (Id) => (dispatch) => {
    axios
        .get(`/api/mlp_question/get_hide/${Id}`)
        .then((res) =>
            dispatch({
                type: GET_HIDE,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_HIDE,
                payload: null,
            })
        );
};

// Hide Question
export const HideQuestion = (Id) => (dispatch) => {
    axios
        .get(`/api/mlp_question/hide_ques/${Id}`)
        .then((res) =>
            dispatch({
                type: GET_HIDE,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_HIDE,
                payload: null,
            })
        );
};

// Get Page by id
export const getDeckUser = () => (dispatch) => {
    dispatch(setQuestionLoading());
    axios
        .get("/api/mlp_question/deck_license")
        .then((res) =>
            dispatch({
                type: GET_DECK,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_DECK,
                payload: null,
            })
        );
};

// Get Page by id
export const getEngineUser = () => (dispatch) => {
    dispatch(setQuestionLoading());
    axios
        .get("/api/mlp_question/engine_license")
        .then((res) =>
            dispatch({
                type: GET_ENGINE,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_ENGINE,
                payload: null,
            })
        );
};
