import axios from "axios";

import {
    V1_GET_QUESTION,
    V1_GET_HIDE,
    V1_GET_BOOKMARK,
    V1_GET_PROFILE,
    V1_QUESTION_LOADING
} from "./types";

// Question loading
const setQuestionLoading = () => {
    return {
        type: V1_QUESTION_LOADING,
    };
};
// Get Engine by id
export const getQuestion =
    (
        slug,
        type = "",
        id = "",
        num = "",
        mode = "",
        zinbookcat = "",
        isPrivate = false
    ) =>
    (dispatch) => {
        dispatch(setQuestionLoading());
        axios
            .get(
                `/api/v1/${
                    isPrivate ? "private" : "public"
                }/question/${slug}?mode=${mode}&type=${type}&id=${id}&num=${num}&zinbookcat=${zinbookcat}`
            )
            .then((res) =>
                dispatch({
                    type: V1_GET_QUESTION,
                    payload: res.data,
                })
            )
            .catch((err) =>
                dispatch({
                    type: V1_GET_QUESTION,
                    payload: null,
                })
            );
    };

// Bookmark Question
export const bookmarkQuestion = (Id, successCallback) => (dispatch) => {
    axios
        .get(`/api/v1/private/bookmark_question/${Id}`)
        .then((res) => {
            dispatch({
                type: V1_GET_BOOKMARK,
                payload: res.data,
            });
            successCallback();
        })
        .catch((err) =>
            dispatch({
                type: V1_GET_BOOKMARK,
                payload: null,
            })
        );
};

// Bookmark Question
export const unBookmarkQuestion = (Id, successCallback) => (dispatch) => {
    axios
        .delete(`/api/v1/private/unbookmark_question/${Id}`)
        .then((res) => {
            dispatch({
                type: V1_GET_BOOKMARK,
                payload: false,
            });
            successCallback();
        })
        .catch((err) =>
            dispatch({
                type: V1_GET_BOOKMARK,
                payload: null,
            })
        );
};

export const hideQuestion = (Id, successCallback) => (dispatch) => {
    axios
        .get(`/api/v1/private/hide_question/${Id}`)
        .then((res) => {
            dispatch({
                type: V1_GET_HIDE,
                payload: res.data,
            });
            successCallback();
        })
        .catch((err) =>
            dispatch({
                type: V1_GET_HIDE,
                payload: null,
            })
        );
};

// Get Engine by id
export const getResume = (mode, type, id, num, zid, zinbookcat) => (dispatch) => {
    dispatch(setQuestionLoading());
    axios
        .get(
            `/api/mlp_question/resume_ques/${mode}/${type}/${id}/${num}/${zid}/${zinbookcat}`
        )
        .then((res) =>
            dispatch({
                type: V1_GET_PROFILE,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: V1_GET_PROFILE,
                payload: {},
            })
        );
};

// Get Engine by id
export const submitQuestion = (quesdata) => (dispatch) => {
    axios
        .post(`/api/mlp_question/sub_question/`, quesdata)
        .then((res) => console.log(res))
        .catch((err) => console.log(err));
};
