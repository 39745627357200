import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "./../common/common.jsx";
import TextLoginField from "./../common/textfield/TextLoginField";
import { forgotAdmin } from "../../../actions/adminActions.jsx";

class AdminForgotpassword extends React.Component {
    constructor() {
        super();
        this.state = {
            email: "",
            errors: {},
        };
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({ errors: nextProps.errors });
        }
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    onSubmit(e) {
        e.preventDefault();
        const passwordData = {
            email: this.state.email,
        };
        this.props.forgotAdmin(passwordData, this.props.history);
    }

    render() {
        const { errors } = this.state;
        return (
            <React.Fragment>
                <div className="login-cover">
                    <div className="login-cover-image news-forgot "></div>
                    <div className="login-cover-bg"></div>
                </div>
                <div className="login login-v2">
                    <div className="login-header">
                        <div className="brand">
                            <br />
                            <b>Forgot Password</b>
                            <small>
                                enter your registered email id to change
                                password
                            </small>
                        </div>
                        <div className="icon">
                            <i className="fa fa-lock"></i>
                        </div>
                    </div>
                    <div className="login-content">
                        <form
                            className="margin-bottom-0"
                            onSubmit={this.onSubmit}
                        >
                            <TextLoginField
                                placeholder="Email Address"
                                name="email"
                                type="email"
                                value={this.state.email}
                                onChange={this.onChange}
                                error={errors.email}
                            />

                            <div className="login-buttons">
                                <button
                                    type="submit"
                                    className="btn btn-success btn-block btn-lg"
                                >
                                    Reset my password
                                </button>
                            </div>
                            <div className="m-t-20">
                                <Link to="/admin">Login</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

AdminForgotpassword.propTypes = {
    forgotAdmin: PropTypes.func.isRequired,
    admin: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    admin: state.admin,
    errors: state.errors,
});

export default connect(mapStateToProps, { forgotAdmin })(AdminForgotpassword);
