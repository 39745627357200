import React, { Component } from "react";
import { getDeckSubCat } from "../../../../actions/questionActions.jsx";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { DropdownButton, Dropdown } from "react-bootstrap";
import DeckSubCatNav from "./DeckSubCatNav";

class DeckCatNav extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: "1",
        };
    }
    onChangecategoryClick(id) {
        this.props.getDeckSubCat(id);
        //console.log(id)
        // this.setState({ bookId: id });
    }
    render() {
        const { deck_subcats } = this.props.question;
        let deckSubCatContent;
        if (deck_subcats.length > 0) {
            deckSubCatContent = <DeckSubCatNav deck_subcats={deck_subcats} />;
        }
        const bookCatlist = this.props.deck_cats.map((cats) => (
            <DropdownButton
                //href="/deck"
                key={`right ${cats._id}`}
                onClick={this.onChangecategoryClick.bind(
                    this,
                    cats.ZINCATEGORY
                )}
                id="dropdown-button-drop-right"
                drop="right"
                variant="secondary"
                title={cats.ZCATEGORYNAME}
            >
                <Dropdown.Item
                    href={`/question/watch?mode=normal&type=cat&id=${cats.ZINCATEGORY}&num=1&zid=${cats._id}`}
                >{`All ${cats.ZCATEGORYNAME}`}</Dropdown.Item>
                {deckSubCatContent}
            </DropdownButton>
        ));
        return <div>{bookCatlist}</div>;
    }
}

DeckCatNav.propTypes = {
    getDeckSubCat: PropTypes.func.isRequired,
    question: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    question: state.question,
});

export default connect(mapStateToProps, { getDeckSubCat })(DeckCatNav);
