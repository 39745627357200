import React, { Component } from "react";
import { Link } from "react-router-dom";

class Register extends Component {
    render() {
        return (
            <div>
                <div className="registerbg">
                    <div className="container">
                        <div className="row">
                            <div className="form-signin">
                                <div className="text-center mb-4">
                                    <Link to="/">
                                        <img
                                            src="https://seatrials-bucket.s3.us-east-2.amazonaws.com/main/logo.png"
                                            alt="Marinerlicenseprep"
                                            className="login_logo"
                                            width="400"
                                        />
                                    </Link>
                                </div>

                                <div className="form-label-group mt-5">
                                    There is an updated version of the
                                    application available to use. Please visit
                                    the new application at{" "}
                                    <a href="https://app.seatrials.net/login/">
                                        https://app.seatrials.net/login/
                                    </a>
                                </div>
                                <p className="mt-4 mb-3 text-muted text-center">
                                    {" "}
                                    Already have account yet{" "}
                                    <a href="https://app.seatrials.net/login/">
                                        Login
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Register;
