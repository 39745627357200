import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../common/common.jsx";
import Header from "../common/Header";
import Footer from "../common/Footer";
import PlanTile from "../../member/account/PlanTile.jsx";

class Pricing extends Component {
    constructor(props) {
        super(props);
        this.state = { activeTab: "1" };
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    }
    render() {
        return (
            <div>
                <Header />
                {/*-- Breadcrumb --*/}
                <nav className="breadcrumb">
                    <div className="container">
                        <Link className="breadcrumb-item" to="/">
                            Home
                        </Link>
                        <span className="breadcrumb-item active">Pricing</span>
                    </div>
                </nav>
                {/*-- Breadcrumb End --*/}
                {/**<!-- pricing Section --> */}
                {/*<!-- Contac Section -->*/}
                <div className="pricing-bg">
                    <div className="container">
                        <div className="row price-wrap">
                            <h1 className="heading">Access Plans</h1>
                            <h3>
                                We do NOT believe in Recurring Billing.
                                <br /> You will only be charged once!
                            </h3>
                        </div>

                        <div className="row">
                            <div className="col-sm-4 col-xs-12">
                                <PlanTile
                                    planPrice={54.99}
                                    planTitle={"+12"}
                                    planId={3}
                                    discount={54}
                                    navigationUrl={'/register'}
                                />
                            </div>
                            <div className="col-sm-4 col-xs-12">
                                <PlanTile
                                    planPrice={34.99}
                                    planTitle={"+6"}
                                    planId={2}
                                    isPopular={true}
                                    discount={42}
                                    navigationUrl={'/register'}
                                />
                            </div>
                            <div className="col-sm-4 col-xs-12">
                                <PlanTile
                                    planPrice={9.99}
                                    planTitle={"+1"}
                                    planId={1}
                                    navigationUrl={'/register'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/*<!-- price Section End --*/}
                <section className="section faq-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <h1 className="heading">
                                    Do you have{" "}
                                    <span className="bold">questions?</span>
                                </h1>
                                <p className="lead">
                                    Answers to your Frequently Asked Questions
                                </p>
                                {/*<!--	<p className="text-muted">Here are the answers to some of the most common questions we hear from our 
												appreciated customers</p>-->*/}
                            </div>
                            <div className="col-md-8">
                                <div
                                    className="accordion accordion-clean"
                                    id="common-faqs-accordion"
                                >
                                    <div className="card mb-3">
                                        <div
                                            className="card-header"
                                            id="headingOne"
                                        >
                                            <button
                                                onClick={() => {
                                                    this.toggleTab("1");
                                                }}
                                                className="btn btn-link lnk-faq"
                                            >
                                                {" "}
                                                Where is the question bank from?
                                                Are they accurate?
                                            </button>
                                        </div>
                                        <div
                                            id="q1"
                                            className={
                                                "collapse " +
                                                (this.state.activeTab === "1"
                                                    ? "in "
                                                    : "")
                                            }
                                        >
                                            <div className="card-body">
                                                {" "}
                                                The question bank, all
                                                illustrations, and study
                                                material on this site has been
                                                DIRECTLY released from the
                                                United States Coast Guard. These
                                                questions are the most accurate
                                                and up-to-date out there.All
                                                tests since 2014 have been
                                                generated based off of this
                                                question bank and every year we
                                                add new questions.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card mb-3">
                                        <div
                                            className="card-header"
                                            id="headingTwo"
                                        >
                                            <button
                                                onClick={() => {
                                                    this.toggleTab("2");
                                                }}
                                                className="btn btn-link lnk-faq"
                                            >
                                                Does my subscription give me
                                                access to the iOS App too?
                                            </button>
                                        </div>
                                        <div
                                            id="q2"
                                            className={
                                                "collapse " +
                                                (this.state.activeTab === "2"
                                                    ? "in "
                                                    : "")
                                            }
                                        >
                                            <div className="card-body">
                                                {" "}
                                                Yes! After getting a premium
                                                subscription, you have access to
                                                this website and the iOS App by
                                                simply logging in with your
                                                information.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card mb-3">
                                        <div
                                            className="card-header"
                                            id="headingThree"
                                        >
                                            <button
                                                onClick={() => {
                                                    this.toggleTab("3");
                                                }}
                                                className="btn btn-link lnk-faq"
                                            >
                                                {" "}
                                                What does favoriting a question
                                                do?
                                            </button>
                                        </div>
                                        <div
                                            id="q3"
                                            className={
                                                "collapse " +
                                                (this.state.activeTab === "3"
                                                    ? "in "
                                                    : "")
                                            }
                                        >
                                            <div className="card-body">
                                                When you favorite a question,
                                                this allows you to come back to
                                                it later. You can view it from
                                                your dashboard and review the
                                                questions that are giving you
                                                trouble.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card mb-3">
                                        <div
                                            className="card-header"
                                            id="headingThree"
                                        >
                                            <button
                                                onClick={() => {
                                                    this.toggleTab("4");
                                                }}
                                                className="btn btn-link lnk-faq"
                                            >
                                                What does hiding a question do?
                                            </button>
                                        </div>
                                        <div
                                            id="q4"
                                            className={
                                                "collapse " +
                                                (this.state.activeTab === "4"
                                                    ? "in "
                                                    : "")
                                            }
                                        >
                                            <div className="card-body">
                                                Hiding a question removes the
                                                specific questions from the bank
                                                you are studying. This allows
                                                you to reduce the questions
                                                which you find extremely easy
                                                and don’t want to see again. You
                                                can always undo this from your
                                                dashboard.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card mb-3">
                                        <div
                                            className="card-header"
                                            id="headingThree"
                                        >
                                            <button
                                                onClick={() => {
                                                    this.toggleTab("5");
                                                }}
                                                className="btn btn-link lnk-faq"
                                            >
                                                How do I generate a test?
                                            </button>
                                        </div>
                                        <div
                                            id="q5"
                                            className={
                                                "collapse " +
                                                (this.state.activeTab === "5"
                                                    ? "in "
                                                    : "")
                                            }
                                        >
                                            <div className="card-body">
                                                By going to the user dashboard,
                                                you can generate a realistic
                                                practice test from any section
                                                you would like. This allows you
                                                to either print it or take the
                                                test on the computer.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Footer />
            </div>
        );
    }
}

export default Pricing;
