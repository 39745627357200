import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Container from "../content/Container.jsx";
import { Panel, PanelHeader, PanelBody } from "./../common/panel/Panel.jsx";
import TextFieldArea from "./../common/textfield/TextFieldArea";
import classnames from "classnames";
import queryString from "qs";
import { getTopic } from "../../../actions/testActions.jsx";
import { getSearch } from "../../../actions/searchActions.jsx";
import { loadUser, sendVeriEmail } from "../../../actions/userActions";
import TopicList from "./TopicList.jsx";
import TopicListEngine from "./TopicListEngine.jsx";
import SearchTable from "./SearchTable.jsx";
import isEmpty from "../../../validation/is-empty";
import { Alert } from "reactstrap";
import ReactNotification from "react-notifications-component";
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import Spinner from "../common/textfield/Spinner";

class MainSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            keyword: "",
            topic: "",
            area: "",
            verification_status: "",
            user_id: "",
            errors: {},
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    handleCancel() {
        this.setState({ keyword: "", topic: "", area: "" });
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    componentDidMount() {
        this.props.getTopic();
        this.props.loadUser();
        const queryStringValues = queryString.parse(
            this.props.location.search.slice(1)
        );

        if (queryStringValues.keyword === undefined) {
            // console.log("hii3")
            this.props.getSearch(1);
        } else if (queryStringValues.keyword === "") {
            // console.log("hii1")
            this.props.getSearch(
                1,
                queryStringValues.topic,
                queryStringValues.area
            );
        } else {
            // console.log("hii")
            this.props.getSearch(
                queryStringValues.keyword,
                queryStringValues.topic,
                queryStringValues.area
            );
            localStorage.setItem("keyword", queryStringValues.keyword);
            localStorage.setItem("topic", queryStringValues.topic);
            localStorage.setItem("area", queryStringValues.area);
        }
    }

    componentWillReceiveProps(nextProps) {
        const queryStringValues = queryString.parse(
            this.props.location.search.slice(1)
        );
        const queryStringValuesn = queryString.parse(
            nextProps.location.search.slice(1)
        );
        if (queryStringValues.keyword !== queryStringValuesn.keyword) {
            this.props.getSearch(
                queryStringValuesn.keyword,
                queryStringValuesn.topic,
                queryStringValuesn.area
            );
            localStorage.setItem("keyword", queryStringValues.keyword);
            localStorage.setItem("topic", queryStringValues.topic);
            localStorage.setItem("area", queryStringValues.area);
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors,
            });
        }

        this.setState({
            keyword: localStorage.getItem("keyword"),
            topic: localStorage.getItem("topic"),
            area: localStorage.getItem("area"),
        });
        if (nextProps.auth.user) {
            const profile = nextProps.auth.user;
            // console.log(profile);
            // If profile field doesnt exist, make empty string
            profile.question_type = !isEmpty(profile.question_type)
                ? profile.question_type
                : "";

            // Set component fields state
            this.setState({
                question_type: profile.question_type,
            });
        }

        if (nextProps.auth.user) {
            const user = nextProps.auth.user;
            user._id = !isEmpty(user._id) ? user._id : "";
            user.verification_status = !isEmpty(user.verification_status)
                ? user.verification_status
                : "";
            this.setState({
                verification_status: user.verification_status,
                user_id: user._id,
            });
        }
    }

    handleValidation() {
        let fields = this.state;
        let errors = {};
        let formIsValid = true;

        //Email validation
        if (!fields["keyword"]) {
            formIsValid = false;
            errors["keyword"] = "Keyword cannot be empty";
        }

        this.setState({ errors: errors });

        if (formIsValid === false) {
            return formIsValid;
        }
    }

    onSubmit(e) {
        this.handleValidation();
        localStorage.setItem("keyword", this.state.keyword);
        localStorage.setItem("topic", this.state.topic);
        localStorage.setItem("area", this.state.area);
        this.props.getSearch(
            this.state.keyword,
            this.state.topic,
            this.state.area
        );
    }

    onEmailClick(id) {
        this.props.sendVeriEmail(id);
        store.addNotification({
            title: "Success!",
            message:
                " An email is sent to your registered email id. Click on the link to verify.",
            type: "success",
            insert: "top",
            container: "top-center",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: { duration: 3000 },
            dismissable: { click: true },
            // content: notificationContent
        });
    }

    render() {
        const { errors } = this.state;
        const { topics } = this.props.test;
        const { searchs, loading } = this.props.search;
        let topicContent;

        // if (topics === null|| loading ) {
        //  topicContent = <option>Wait..</option>;
        // } else {
        if (topics.length > 0) {
            if (this.state.question_type === "Deck") {
                topicContent = (
                    <select
                        className={classnames("form-control ", {
                            "is-invalid": errors.topic,
                        })}
                        data-size="10"
                        data-live-search="true"
                        data-style="btn-white"
                        name="topic"
                        value={this.state.topic}
                        onChange={this.onChange}
                    >
                        <option value="deck-all">All Deck</option>
                        <TopicList topics={topics} />
                    </select>
                );
            } else {
                topicContent = (
                    <select
                        className={classnames("form-control ", {
                            "is-invalid": errors.topic,
                        })}
                        data-size="10"
                        data-live-search="true"
                        data-style="btn-white"
                        name="topic"
                        value={this.state.topic}
                        onChange={this.onChange}
                    >
                        <option value="engine-all">All Engine</option>
                        <TopicListEngine topics={topics} />
                    </select>
                );
            }

            // }
        }
        let searchContent;
        if ((searchs === "") | loading) {
            searchContent = <Spinner />;
        } else {
            if (searchs.length > 0) {
                searchContent = <SearchTable searchs={searchs} />;
            } else {
                searchContent = (
                    <div className="row">
                        <div className="col-12">
                            <Panel className="member-panel">
                                <PanelHeader>Search Result</PanelHeader>
                                <PanelBody>
                                    <div className="note note-secondary m-b-15">
                                        <div className="note-icon">
                                            <i className="fa fa-lightbulb"></i>
                                        </div>
                                        <div className="note-content text-right">
                                            <h4>
                                                <b>This Table Is Empty</b>
                                            </h4>
                                            <p>
                                                {" "}
                                                No data found to show here. This
                                                table is empty.{" "}
                                            </p>
                                        </div>
                                    </div>
                                </PanelBody>
                            </Panel>
                        </div>
                    </div>
                );
            }
        }

        let EmailVerificationContent;
        if (this.state.verification_status === "No") {
            EmailVerificationContent = (
                <Alert
                    color="danger"
                    isOpen={this.state.visible}
                    toggle={this.onDismiss}
                >
                    Your Email verification is pending!
                    <Link
                        to="#"
                        onClick={this.onEmailClick.bind(
                            this,
                            this.state.user_id
                        )}
                        className="alert-link"
                    >
                        {" "}
                        resend verification link
                    </Link>
                    .
                </Alert>
            );
        }
        return (
            <React.Fragment>
                <ReactNotification />
                <Container>
                    <div className="content">
                        {EmailVerificationContent}
                        <ol className="breadcrumb float-xl-right">
                            <li className="breadcrumb-item">
                                <Link to="/dashboard">Dashboard</Link>
                            </li>
                            <li className="breadcrumb-item active">Search</li>
                        </ol>
                        <h1 className="page-header">Search </h1>
                        <div className="row">
                            <div className="col-xl-12">
                                <Panel className="member-panel">
                                    <PanelHeader>Generate Test</PanelHeader>
                                    <PanelBody className="panel-form">
                                        <form
                                            className="form-horizontal form-bordered"
                                            onSubmit={this.onSubmit}
                                        >
                                            <fieldset>
                                                <TextFieldArea
                                                    label="Keyword(s)"
                                                    placeholder="Keyword(s)"
                                                    name="keyword"
                                                    type="text"
                                                    value={this.state.keyword}
                                                    onChange={this.onChange}
                                                    error={
                                                        this.state.errors[
                                                            "keyword"
                                                        ]
                                                    }
                                                    striks="*"
                                                />

                                                <div className="form-group row ">
                                                    <label className="col-lg-3 col-form-label">
                                                        Select A Topic
                                                    </label>
                                                    <div className="col-lg-9">
                                                        {topicContent}

                                                        {errors.topic && (
                                                            <div
                                                                className="invalid-feedback"
                                                                style={{
                                                                    display:
                                                                        "block",
                                                                }}
                                                            >
                                                                {errors.topic}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="form-group row ">
                                                    <label className="col-lg-3 col-form-label">
                                                        Search Area
                                                    </label>
                                                    <div className="col-lg-9">
                                                        <select
                                                            className={classnames(
                                                                "form-control ",
                                                                {
                                                                    "is-invalid":
                                                                        errors.area,
                                                                }
                                                            )}
                                                            data-size="10"
                                                            data-live-search="true"
                                                            data-style="btn-white"
                                                            name="area"
                                                            value={
                                                                this.state.area
                                                            }
                                                            onChange={
                                                                this.onChange
                                                            }
                                                        >
                                                            <option value="all">
                                                                All
                                                            </option>
                                                            <option value="question">
                                                                Questions
                                                            </option>
                                                            <option value="question_refrence">
                                                                Question
                                                                Reference
                                                            </option>
                                                            <option value="question_number">
                                                                Question Number
                                                            </option>
                                                            <option value="answer">
                                                                Answers
                                                            </option>
                                                        </select>

                                                        {errors.area && (
                                                            <div
                                                                className="invalid-feedback"
                                                                style={{
                                                                    display:
                                                                        "block",
                                                                }}
                                                            >
                                                                {errors.area}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <div className="col-md-12 offset-md-5">
                                                        <button
                                                            type="submit"
                                                            className="btn btn-sm btn-primary m-r-5"
                                                        >
                                                            Submit
                                                        </button>
                                                        <button
                                                            type="button"
                                                            onClick={
                                                                this
                                                                    .handleCancel
                                                            }
                                                            className="btn btn-sm btn-default"
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </form>
                                    </PanelBody>
                                </Panel>
                            </div>
                            <div className="col-md-12">{searchContent}</div>
                        </div>
                    </div>
                </Container>
            </React.Fragment>
        );
    }
}
MainSearch.propTypes = {
    getSearch: PropTypes.func.isRequired,
    getTopic: PropTypes.func.isRequired,
    loadUser: PropTypes.func.isRequired,
    sendVeriEmail: PropTypes.func.isRequired,
    test: PropTypes.object.isRequired,
    search: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    test: state.test,
    search: state.search,
    auth: state.auth,
    errors: state.errors,
});

export default connect(mapStateToProps, {
    getTopic,
    getSearch,
    loadUser,
    sendVeriEmail,
})(MainSearch);
