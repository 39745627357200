import {
    GET_QUESTION,
    GET_PRIVIOUS,
    GET_NEXT,
    GET_DECK,
    GET_DECK_BOOKS,
    GET_DECK_CATS,
    GET_DECK_SUBCATS,
    GET_ENGINE,
    GET_ENGINE_BOOKS,
    GET_ENGINE_CATS,
    GET_ENGINE_SUBCATS,
    QUESTION_LOADING,
    TOTAL_QUESTION,
    GET_DECK_NAVS,
    GET_ENGINE_NAVS,
    GET_BOOKMARK,
    DELETE_BOOKMARK,
    GET_HIDE,
    GET_NUM,
} from "../actions/types";

const initialState = {
    hide: null,
    bookmark: null,
    question: null,
    previous: [],
    next: [],
    deck: null,
    num: null,
    deck_books: [],
    deck_cats: [],
    deck_subcats: [],
    engine: null,
    engine_books: [],
    engine_cats: [],
    engine_subcats: [],
    deck_navs: [],
    engine_navs: [],
    total: null,
    loading: true,
    error: {},
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case QUESTION_LOADING:
            return {
                ...state,
                loading: true,
            };

        case GET_NUM:
            return {
                ...state,
                num: payload,
                loading: false,
            };

        case GET_BOOKMARK:
            return {
                ...state,
                bookmark: payload,
                loading: false,
            };

        case DELETE_BOOKMARK:
            return {
                ...state,

                bookmark: state.bookmark.filter(
                    (bookmark) => bookmark._id !== payload
                ),
            };

        case GET_HIDE:
            return {
                ...state,
                hide: payload,
                loading: false,
            };

        case GET_QUESTION:
            return {
                ...state,
                question: payload,
                loading: false,
            };

        case GET_PRIVIOUS:
            return {
                ...state,
                previous: payload,
                loading: false,
            };

        case GET_NEXT:
            return {
                ...state,
                next: payload,
                loading: false,
            };

        case GET_DECK:
            return {
                ...state,
                deck: action.payload,
                loading: false,
            };

        case GET_DECK_BOOKS:
            return {
                ...state,
                deck_books: action.payload,
                loading: false,
            };

        case GET_DECK_CATS:
            return {
                ...state,
                deck_cats: action.payload,
                // loading: false
            };

        case GET_DECK_SUBCATS:
            return {
                ...state,
                deck_subcats: action.payload,
                // loading: false
            };

        case GET_ENGINE:
            return {
                ...state,
                engine: action.payload,
                loading: false,
            };

        case GET_ENGINE_BOOKS:
            return {
                ...state,
                engine_books: action.payload,
                loading: false,
            };

        case GET_ENGINE_CATS:
            return {
                ...state,
                engine_cats: action.payload,
                loading: false,
            };

        case GET_ENGINE_SUBCATS:
            return {
                ...state,
                engine_subcats: action.payload,
                loading: false,
            };

        case TOTAL_QUESTION:
            return {
                ...state,
                total: action.payload,
                loading: false,
            };

        case GET_DECK_NAVS:
            return {
                ...state,
                deck_navs: action.payload,
                loading: false,
            };

        case GET_ENGINE_NAVS:
            return {
                ...state,
                engine_navs: action.payload,
                loading: false,
            };

        default:
            return state;
    }
}
