import React, { Component } from "react";
import ReactTable from "react-table";
import { Link } from "react-router-dom";
import { Panel, PanelHeader } from "../common/panel/Panel.jsx";
import "react-table/react-table.css";

class ByTopicList extends Component {
    constructor(props) {
        super(props);
        //this.data = makeData();
        this.defaultSorted = [
            {
                id: "_id",
                desc: false,
            },
        ];
        this.tableColumns = [
            {
                Header: "Id",
                id: "row",
                maxWidth: 50,
                filterable: false,
                Cell: (row) => {
                    return <div>{row.index + 1}</div>;
                },
            },

            {
                Header: "Category",
                accessor: "doc",
                Cell: (row) => (
                    <span>
                        {row.original.doc.ZINBOOKCATEGORY === "2" ? (
                            <span>Deck</span>
                        ) : (
                            <span>Engine</span>
                        )}
                    </span>
                ),
            },

            {
                Header: "Book Name",
                id: "row",
                accessor: "_id",
                Cell: (row) => <span>{row.original._id.ZBOOKNAME}</span>,
            },
            {
                Header: "Actions",
                accessor: "_id",
                filterable: false,
                sortable: false,
                Cell: (row) => (
                    <div>
                        <Link
                            to={`/user/manage-question/topic-list/${row.original._id.ZINBOOK}`}
                            className="btn btn-outline btn-info btn-sm m-r-5 "
                        >
                            <i className="fas fa-sm m-r-2 fa-list"></i>{" "}
                            {row.original._id.ZBOOKNAME} List
                        </Link>
                    </div>
                ),
            },
        ];
    }

    render() {
        const data = this.props.logged_topics;

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-12">
                        <Panel className="member-panel">
                            <PanelHeader>
                                List of All Logged Question By Topic
                            </PanelHeader>

                            <ReactTable
                                filterable
                                useSortBy
                                data={data}
                                columns={this.tableColumns}
                                defaultPageSize={10}
                                defaultSorted={this.defaultSorted}
                                className="-highlight"
                            />
                        </Panel>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ByTopicList;
