import React from "react";
import { Redirect, Switch, useRouteMatch } from "react-router-dom";
import Profile from "../../../components/member/account/Profile";
import Settings from "../../../components/member/account/Settings";
import MemberContact from "../../../components/member/account/Contact";
import PrivateRoute from "../PrivateRoute";
import Subscription from "../../../components/member/account/Subscription";
import Invoice from "../../../components/member/account/Invoice";

const AccountRoutes = () => {
    let { path } = useRouteMatch();

    return (
        <Switch>
            <PrivateRoute exact path={`${path}/profile`} component={Profile} />
            <PrivateRoute
                exact
                path={`${path}/settings`}
                component={Settings}
            />
            <PrivateRoute
                exact
                path={`${path}/contact`}
                component={MemberContact}
            />
            <PrivateRoute
                exact
                path={`${path}/subscription`}
                component={Subscription}
            />
            <PrivateRoute
                exact
                path={`${path}/view-invoice/:Id`}
                component={Invoice}
            />
            <Redirect from="*" to={`${path}/profile`}></Redirect>
        </Switch>
    );
};

export default AccountRoutes;
