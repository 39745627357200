import React, { Component } from "react";
//import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Container from "../content/Container.jsx";
import ComUserList from "./ComUserList.jsx";
import Spinner from "../common/textfield/Spinner";
import { getCommentLists } from "../../../actions/commentActions.jsx";
import { Panel, PanelHeader, PanelBody } from "../common/panel/Panel.jsx";

class MainUserList extends Component {
    componentDidMount() {
        if (this.props.match.params.Id) {
            this.props.getCommentLists(this.props.match.params.Id);
        }
    }
    render() {
        const { commentlists, loading } = this.props.comment;
        let mainUserContent;
        if (commentlists === null || loading) {
            mainUserContent = <Spinner />;
        } else {
            //console.log(blogcats);
            if (commentlists.length > 0) {
                mainUserContent = <ComUserList commentlists={commentlists} />;
            } else {
                mainUserContent = (
                    <div className="row">
                        <div className="col-12">
                            <Panel>
                                <PanelHeader>View Comment List</PanelHeader>
                                <PanelBody>
                                    <div className="note note-secondary m-b-15">
                                        <div className="note-icon">
                                            <i className="fa fa-lightbulb"></i>
                                        </div>
                                        <div className="note-content text-right">
                                            <h4>
                                                <b>This Table Is Empty</b>
                                            </h4>
                                            <p>
                                                {" "}
                                                No data found to show here. This
                                                table is empty.{" "}
                                            </p>
                                        </div>
                                    </div>
                                </PanelBody>
                            </Panel>
                        </div>
                    </div>
                );
            }
        }
        return (
            <React.Fragment>
                <Container>
                    <div id="content" className="content ">
                        <ol className="breadcrumb float-xl-right">
                            <li className="breadcrumb-item">
                                <a href="/admin/dashboard">Dashboard</a>
                            </li>
                            <li className="breadcrumb-item active">
                                View Comment List
                            </li>
                        </ol>
                        <h1 className="page-header">View Comment List </h1>

                        {mainUserContent}
                    </div>
                </Container>
            </React.Fragment>
        );
    }
}

MainUserList.propTypes = {
    getCommentLists: PropTypes.func.isRequired,
    comment: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    comment: state.comment,
});

export default connect(mapStateToProps, { getCommentLists })(MainUserList);
