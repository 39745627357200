import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import AllLogged from "../../../components/member/manageQuestion/AllLogged";
import BookmarkedQuestion from "../../../components/member/manageQuestion/BookmarkedQuestion";
import ByTopic from "../../../components/member/manageQuestion/ByTopic";
import HiddenQuestion from "../../../components/member/manageQuestion/HiddenQuestion";
import TopicList from "../../../components/member/manageQuestion/TopicList";
import PrivateRoute from "../PrivateRoute";

const ManageQuestionRoutes = () => {
    let { path } = useRouteMatch();

    return (
        <Switch>
            <PrivateRoute
                exact
                path={`${path}/bookmarked-question`}
                component={BookmarkedQuestion}
            />
            <PrivateRoute
                exact
                path={`${path}/hidden-question`}
                component={HiddenQuestion}
            />
            <PrivateRoute
                exact
                path={`${path}/all-logged`}
                component={AllLogged}
            />
            <PrivateRoute exact path={`${path}/by-topic`} component={ByTopic} />
            <PrivateRoute
                exact
                path={`${path}/topic-list/:Id`}
                component={TopicList}
            />
        </Switch>
    );
};

export default ManageQuestionRoutes;
