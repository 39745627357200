import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import queryString from "qs";
import SweetAlert from "react-bootstrap-sweetalert";
import {
    getCommentsOnQues,
    getCommentsCount,
} from "../../../../actions/commentActions.jsx";
import CommentList from "./CommentList.jsx";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

class Comments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            comment_count: 0,
            sweetAlertInfo: false,
            collapse: false,
        };
        this.toggleSweetAlert = this.toggleSweetAlert.bind(this);
        this.routeChange = this.routeChange.bind(this);
        this.closeComment = this.closeComment.bind(this);
        this.openComment = this.openComment.bind(this);
    }

    toggleSweetAlert(name) {
        switch (name) {
            case "info":
                this.setState({ sweetAlertInfo: !this.state.sweetAlertInfo });
                break;

            default:
                break;
        }
    }

    routeChange() {
        let path = `/register`;
        this.props.history.push(path);
    }

    componentDidMount() {
        const queryStringValues = queryString.parse(
            this.props.location.search.slice(1)
        );

        this.props.getCommentsOnQues(queryStringValues.zid);
        this.props.getCommentsCount(queryStringValues.zid);
    }

    componentWillReceiveProps(nextProps) {
        const queryStringValues = queryString.parse(
            this.props.location.search.slice(1)
        );
        const queryStringValuesn = queryString.parse(
            nextProps.location.search.slice(1)
        );
        if (queryStringValues.zid !== queryStringValuesn.zid) {
            this.props.getCommentsOnQues(queryStringValuesn.zid);
            this.props.getCommentsCount(queryStringValuesn.zid);
        }

        if (nextProps.comment.count) {
            this.setState({ comment_count: nextProps.comment.count });
        }
    }

    closeComment() {
        localStorage.setItem("collapse", "1");
        this.setState({ collapse: false });
    }

    openComment() {
        localStorage.removeItem("collapse");
        this.setState({ collapse: true });
    }

    render() {
        const { comment_ques, loading } = this.props.comment;
        let CommentContent;
        if (comment_ques === null || loading) {
            CommentContent = "";
        } else {
            if (comment_ques !== "") {
                CommentContent = <CommentList comment_ques={comment_ques} />;
            }
        }

        let collapseContent;
        let buttonContent;

        if (this.state.collapse) {
            collapseContent = (
                <div id="demo" className="active collapse in">
                    <div className="col-md-12 col-sm-12 cmnt-history">
                        {CommentContent}

                        <div className="form-group adjust">
                            <div className="col-sm-12 col-xs-12">
                                <SunEditor
                                    showToolbar={true}
                                    height="200"
                                    name="comments"
                                />
                            </div>
                        </div>
                        <div className="form-group adjust">
                            <div className="col-sm-12 col-xs-12 ">
                                <button
                                    onClick={() =>
                                        this.toggleSweetAlert("info")
                                    }
                                    type="submit"
                                    className="btn btn-primary btn-sub m-r-5"
                                >
                                    Add comment
                                </button>
                                <button
                                    onClick={() =>
                                        this.toggleSweetAlert("info")
                                    }
                                    type="reset"
                                    className="btn btn-default btn-close"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            collapseContent = "";
        }

        if (localStorage.getItem("collapse") === "1") {
            collapseContent = "";

            buttonContent = (
                <button
                    onClick={this.openComment}
                    type="button"
                    className="cmnts-dts show-comments active"
                >
                    <i className="fa fa-align-justify"></i>
                </button>
            );
        } else {
            collapseContent = (
                <div id="demo" className="active collapse in">
                    <div className="col-md-12 col-sm-12 cmnt-history">
                        {CommentContent}

                        <div className="form-group adjust">
                            <div className="col-sm-12 col-xs-12">
                                <SunEditor
                                    showToolbar={true}
                                    height="200"
                                    name="comments"
                                />
                            </div>
                        </div>
                        <div className="form-group adjust">
                            <div className="col-sm-12 col-xs-12 ">
                                <button
                                    onClick={() =>
                                        this.toggleSweetAlert("info")
                                    }
                                    type="submit"
                                    className="btn btn-primary btn-sub m-r-5"
                                >
                                    Add comment
                                </button>
                                <button
                                    onClick={() =>
                                        this.toggleSweetAlert("info")
                                    }
                                    type="reset"
                                    className="btn btn-default btn-close"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            );

            buttonContent = (
                <button
                    onClick={this.closeComment}
                    type="button"
                    className="cmnts-dts show-comments active"
                >
                    <i className="fa fa-align-justify"></i>
                </button>
            );
        }
        return (
            <React.Fragment>
                {this.state.sweetAlertInfo && (
                    <SweetAlert
                        info
                        showCancel
                        confirmBtnText="Register"
                        confirmBtnBsStyle="info"
                        cancelBtnBsStyle="default"
                        title="Subscription Alerts"
                        onConfirm={this.routeChange}
                        onCancel={() => this.toggleSweetAlert("info")}
                    >
                        You have to be a premium user to comment on questions.
                    </SweetAlert>
                )}

                <div className="m-t-40">
                    <div className="q-number-wrapper for-btn">
                        <div className="col-md-6 for-only-q">
                            <span className="author-name cmt">
                                Comments <span>{this.state.comment_count}</span>{" "}
                            </span>
                        </div>
                        <div className="col-md-6 text-right for-only-q">
                            {/* <button onClick={((this.state.collapse) ? () => this.setState({ collapse: false }) : () => this.setState({ collapse: true } ))} type="button" className="cmnts-dts show-comments active" >
                  <i className="fa fa-align-justify"></i>              
                  </button>  */}
                            {buttonContent}
                        </div>
                    </div>
                </div>

                <div className="clearfix"></div>
                <div className="col-sm-12 col-xs-12">
                    {/* <div id="demo" className={"active " + ((this.state.collapse) ? "collapse " : "collapse in")}>*/}
                    {collapseContent}
                </div>
            </React.Fragment>
        );
    }
}

Comments.propTypes = {
    getCommentsOnQues: PropTypes.func.isRequired,
    getCommentsCount: PropTypes.func.isRequired,
    comment: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    comment: state.comment,
});

export default connect(mapStateToProps, {
    getCommentsOnQues,
    getCommentsCount,
})(withRouter(Comments));
