import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

const TextImageFileUpload = ({
    name,
    placeholder,
    value,
    label,
    error,
    info,
    type,
    onChange,
    upload,
    options,
    striks,
    showDefaultOption,
    src,
    multiple,
    imgextension,
}) => {
    return (
        <div className="form-group row">
            <label className="col-lg-3 col-form-label">
                {label}
                <span className="text-danger">{striks}</span>
            </label>
            <div className="col-lg-9" align="center">
                <span>
                    <img
                        alt="Your img here"
                        className="img-circle"
                        style={{ width: "50px", height: "50px" }}
                        src={src}
                    />
                </span>
                <br></br>
                <label
                    className="btn btn-primary m-r-5"
                    htmlFor="inputImage"
                    title="Your file/image here"
                >
                    {upload}
                </label>
                <input
                    id="inputImage"
                    className={classnames("hide", {
                        "is-invalid": error,
                    })}
                    type={type}
                    name={name}
                    onChange={onChange}
                    imgextension={imgextension}
                    // value={value}
                    multiple={multiple}
                />

                {error && <div className="invalid-feedback">{error}</div>}
                <small className="f-s-12 text-grey-darker">{info}</small>
            </div>
        </div>
    );
};

TextImageFileUpload.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    error: PropTypes.string,
    type: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.string,
    multiple: PropTypes.string,
};

export default TextImageFileUpload;
