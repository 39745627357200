import React, { Component } from "react";
import { Link } from "react-router-dom";

class EngineSubCatNav extends Component {
    render() {
        const Subcatlist = this.props.engine_subcats.map((subcats) => (
            <React.Fragment key={subcats.ZINSUBCATEGORY}>
                {subcats.ZINSUBCATEGORY === "0" ? (
                    ""
                ) : (
                    <li key={subcats.ZINSUBCATEGORY}>
                        <Link
                            to={`/user/watch?mode=normal&type=subcat&id=${subcats.ZINSUBCATEGORY}&num=1&zid=${subcats._id}&zinbookcat=${subcats.ZINBOOKCATEGORY}`}
                        >
                            {" "}
                            {subcats.ZSUBCATEGORYNAME}
                        </Link>
                    </li>
                )}
            </React.Fragment>
        ));
        return Subcatlist;
    }
}
export default EngineSubCatNav;
