import React, { Component } from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";

class IosTable extends Component {
    render() {
        const ioslist = this.props.ios.map((apple) => (
            <tr key={apple._id}>
                <td>
                    <i className="fa fa-calendar-alt col-blue ml-3 mr-2"></i>{" "}
                    <Moment format="D MMM YYYY" withTitle>
                        {apple.insert_date}
                    </Moment>
                </td>
                <td className="hidden-sm">
                    <Link
                        to={`/admin/subscribers/edit-subscriber/${apple._id}`}
                    >
                        {apple.user_name}
                    </Link>
                </td>
                <td className="text-nowrap">
                    <span className="label label-success m-r-3">
                        {apple.subscription_type}
                    </span>
                </td>
                <td>
                    {(
                        <Moment format="D MM YYYY" withTitle>
                            {apple.insert_date}
                        </Moment>
                    ) >=
                    (
                        <Moment format="D MM YYYY" withTitle>
                            {new Date()}
                        </Moment>
                    ) ? (
                        <span className="label label-primary m-r-3">
                            <Moment format="D MMM YYYY" withTitle>
                                {apple.unsubs_date}
                            </Moment>
                        </span>
                    ) : (
                        <span className="label label-danger m-r-3">
                            <Moment format="D MMM YYYY" withTitle>
                                {apple.unsubs_date}
                            </Moment>
                        </span>
                    )}
                </td>
                <td>{apple.question_type}</td>
                <td className="text-nowrap">{apple.platform}</td>
            </tr>
        ));
        return (
            <div>
                <table className="table">
                    <thead>
                        <tr>
                            <th className="hidden-sm">Joined date</th>
                            <th>User Name</th>
                            <th>Subscription type</th>
                            <th>Subscription Exp</th>
                            <th>Question Type</th>
                            <th>Platform</th>
                        </tr>
                    </thead>
                    <tbody>{ioslist}</tbody>
                </table>
            </div>
        );
    }
}
export default IosTable;
