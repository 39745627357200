import React, { Component } from "react";
import { PageSettings } from "../../content/page-settings.jsx";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loadUser } from "../../../../actions/userActions";
import SidebarNavList from "./SidebarNavList.jsx";
import isEmpty from "../../../../validation/is-empty";

class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            unsubs_date: "",
        };
    }

    componentDidMount() {
        this.props.loadUser();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.auth.user) {
            const profile = nextProps.auth.user;

            profile.unsubs_date = !isEmpty(profile.unsubs_date)
                ? profile.unsubs_date
                : "";
            profile.subscription_type = !isEmpty(profile.subscription_type)
                ? profile.subscription_type
                : "";

            if (profile.subscription_type === "Life Time Access") {
                localStorage.setItem("user", "Active");
            } else if (new Date() >= new Date(profile.unsubs_date)) {
                localStorage.setItem("user", "Inactive");
            } else if (new Date() <= new Date(profile.unsubs_date)) {
                localStorage.setItem("user", "Active");
            }

            // Set component fields state
            this.setState({
                unsubs_date: profile.unsubs_date,
            });
        }
    }

    render() {
        let sidebarContent;
        if (localStorage.getItem("user") === "Active") {
            sidebarContent = <SidebarNavList />;
        } else {
            sidebarContent = <SidebarNavList />;
        }

        return (
            <PageSettings.Consumer>
                {({
                    toggleSidebarMinify,
                    toggleMobileSidebar,
                    pageSidebarTransparent,
                }) => (
                    <React.Fragment>
                        <div
                            id="sidebar"
                            className={
                                "sidebar member-sidebar" +
                                (pageSidebarTransparent
                                    ? "sidebar-transparent"
                                    : "")
                            }
                        >
                            <PerfectScrollbar
                                className="height-full"
                                options={{ suppressScrollX: true }}
                            >
                                {/*  <SidebarProfile />*/}
                                {sidebarContent}
                                <Link
                                    to="/"
                                    className="sidebar-minify-btn"
                                    onClick={toggleSidebarMinify}
                                >
                                    <i className="fa fa-angle-double-left"></i>
                                </Link>
                            </PerfectScrollbar>
                        </div>
                        <div className="sidebar-bg"></div>
                        <div
                            className="sidebar-mobile-dismiss"
                            onClick={toggleMobileSidebar}
                        ></div>
                    </React.Fragment>
                )}
            </PageSettings.Consumer>
        );
    }
}

Sidebar.propTypes = {
    loadUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, { loadUser })(Sidebar);
