import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ManageTestList from "../test/ManageTestList.jsx";
import Spinner from "../common/textfield/Spinner";
import { manageTest } from "../../../actions/testActions.jsx";
import { loadUser, sendVeriEmail } from "../../../actions/userActions";
import { Panel, PanelHeader, PanelBody } from "../common/panel/Panel.jsx";
import isEmpty from "../../../validation/is-empty";

class ManageTest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            verification_status: "",
            user_id: "",
        };
    }

    componentDidMount() {
        this.props.manageTest();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.auth.user) {
            const user = nextProps.auth.user;
            user._id = !isEmpty(user._id) ? user._id : "";
            user.verification_status = !isEmpty(user.verification_status)
                ? user.verification_status
                : "";
            this.setState({
                verification_status: user.verification_status,
                user_id: user._id,
            });
        }
    }

    render() {
        const { tests, loading } = this.props.test;
        let maintestContent;
        if (tests === null || loading) {
            maintestContent = <Spinner />;
        } else {
            //console.log(blogcats);
            if (tests.length > 0) {
                maintestContent = <ManageTestList tests={tests} />;
            } else {
                maintestContent = (
                    <div className="row">
                        <div className="col-12">
                            <Panel className="member-panel">
                                <PanelHeader>List of Tests</PanelHeader>
                                <PanelBody>
                                    <div className="note note-secondary m-b-15">
                                        <div className="note-icon">
                                            <i className="fa fa-lightbulb"></i>
                                        </div>
                                        <div className="note-content text-right">
                                            <h4>
                                                <b>This Table Is Empty</b>
                                            </h4>
                                            <p>
                                                {" "}
                                                No data found to show here. This
                                                table is empty.{" "}
                                            </p>
                                        </div>
                                    </div>
                                </PanelBody>
                            </Panel>
                        </div>
                    </div>
                );
            }
        }

        return <React.Fragment>{maintestContent}</React.Fragment>;
    }
}
ManageTest.propTypes = {
    manageTest: PropTypes.func.isRequired,
    test: PropTypes.object.isRequired,
    loadUser: PropTypes.func.isRequired,
    sendVeriEmail: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    test: state.test,
    auth: state.auth,
});

export default connect(mapStateToProps, {
    manageTest,
    loadUser,
    sendVeriEmail,
})(ManageTest);
