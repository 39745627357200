import axios from "axios";
//import { setAlert } from "./alertActions";
import {
    GET_COMMENT,
    GET_COMMENTS,
    GET_COMMENT_LISTS,
    COMMENT_LOADING,
    COMMENT_COUNT,
    COMMENT_QUESTIONS,
    ADD_COMMENT,
    DELETE_COMMENT,
    GET_ERRORS,
    // CLEAR_ERRORS
} from "./types";

// Get current Pages
export const getComments = () => (dispatch) => {
    dispatch(setCommentLoading());
    axios
        .get("/api/mlp_comment/all_comment")
        .then((res) =>
            dispatch({
                type: GET_COMMENTS,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_COMMENTS,
                payload: null,
            })
        );
};

// Get current Pages
export const getCommentLists = (id) => (dispatch) => {
    dispatch(setCommentLoading());
    axios
        .get(`/api/mlp_comment/get_comment/${id}`)
        .then((res) =>
            dispatch({
                type: GET_COMMENT_LISTS,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_COMMENT_LISTS,
                payload: null,
            })
        );
};

// Get current Pages
export const getUserCommentLists = () => (dispatch) => {
    dispatch(setCommentLoading());
    axios
        .get(`/api/mlp_comment/get_user_comment`)
        .then((res) =>
            dispatch({
                type: GET_COMMENT_LISTS,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_COMMENT_LISTS,
                payload: null,
            })
        );
};

// Delete Subscriber
export const deleteComment = (id) => (dispatch) => {
    // if (window.confirm("Are you sure? This can NOT be undone!")) {
    axios
        .delete(`/api/mlp_comment/comment/${id}`)
        .then((res) =>
            dispatch({
                type: DELETE_COMMENT,
                payload: id,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            })
        );
};

// Get Comments by id
export const getCommentsbyId = (id) => (dispatch) => {
    dispatch(setCommentLoading());
    axios
        .get(`/api/mlp_comment/comments/${id}`)
        .then((res) =>
            dispatch({
                type: GET_COMMENT,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_COMMENT,
                payload: null,
            })
        );
};

// Edit Comments
export const updateComments = (comment_Id, editData) => (dispatch) => {
    axios
        .post(`/api/mlp_comment/comment_edit/${comment_Id}`, editData)
        .then((res) =>
            dispatch({
                type: GET_COMMENT,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            })
        );
};

// Edit Comments
export const updateUserComments = (comment_Id, editData) => (dispatch) => {
    axios
        .post(`/api/mlp_comment/user_comment_edit/${comment_Id}`, editData)
        .then((res) =>
            dispatch({
                type: GET_COMMENT,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            })
        );
};

// Get Comments on questions by id
export const getCommentsOnQues = (Id) => (dispatch) => {
    dispatch(setCommentLoading());
    axios
        .get(`/api/mlp_comment/comments_ques/${Id}`)
        .then((res) =>
            dispatch({
                type: COMMENT_QUESTIONS,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: COMMENT_QUESTIONS,
                payload: null,
            })
        );
};

// Get Comments on questions by id
export const getCommentsCount = (id) => (dispatch) => {
    dispatch(setCommentLoading());
    axios
        .get(`/api/mlp_comment/comments_count/${id}`)
        .then((res) =>
            dispatch({
                type: COMMENT_COUNT,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: COMMENT_COUNT,
                payload: null,
            })
        );
};

//Add Pages
export const addComments = (commentData) => (dispatch) => {
    axios
        .post("/api/mlp_comment/add_comments", commentData)
        .then((res) =>
            dispatch({
                type: ADD_COMMENT,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            })
        );
};

// Invoice loading
export const setCommentLoading = () => {
    return {
        type: COMMENT_LOADING,
    };
};
