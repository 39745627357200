import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import queryString from "qs";
import "../common/common.jsx";
import TextLoginField from "../common/textfield/TextLoginField";
import AlertBox from "../common/textfield/AlertBox";
import { adminlogin } from "../../../actions/adminActions.jsx";
import ReactNotification from "react-notifications-component";
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

class AdminLogin extends Component {
    constructor() {
        super();

        this.state = {
            email: "",
            password: "",
            visible: false,
            visible1: true,
            errors: {},
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onDismiss = this.onDismiss.bind(this);
    }

    onDismiss() {
        this.setState({ visible: false, visible1: false });
    }
    componentDidMount() {
        if (this.props.admin.isAuthenticated) {
            this.props.history.push("/admin/dashboard");
        }
        const queryStringValues = queryString.parse(
            this.props.location.search.slice(1)
        );

        if (queryStringValues.mail) {
            // User is redirected by us after registration.
            store.addNotification({
                title: "Success",
                message:
                    "Email with password reset link is sent. Please check your email",
                type: "success",
                insert: "top",
                container: "top-center",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: { duration: 2000 },
                dismissable: { click: true },
                // content: notificationContent
            });
        }
        if (queryStringValues.password) {
            // User is redirected by us after registration.
            store.addNotification({
                title: "Success",
                message:
                    "Password Changed Successfully. Please Login with new password",
                type: "success",
                insert: "top",
                container: "top-center",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: { duration: 2000 },
                dismissable: { click: true },
                // content: notificationContent
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.admin.isAuthenticated) {
            this.props.history.push("/admin/dashboard");
        }

        if (nextProps.errors) {
            this.setState({ errors: nextProps.errors });
            this.state.errors.error &&
                store.addNotification({
                    title: "Success",
                    message: this.state.errors.error,
                    type: "danger",
                    insert: "top",
                    container: "top-center",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: { duration: 3000 },
                    dismissable: { click: true },
                    // content: notificationContent
                });
        }
    }

    onSubmit(e) {
        e.preventDefault();
        const adminloginData = {
            email: this.state.email,
            password: this.state.password,
        };
        // console.log(adminloginData);
        this.props.adminlogin(adminloginData);
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    render() {
        const { errors } = this.state;
        return (
            <React.Fragment>
                <ReactNotification />
                <div className="login-cover">
                    <div className="login-cover-image news-login "></div>
                    <div className="login-cover-bg"></div>
                </div>
                <div className="login login-v2">
                    <div className="login-header">
                        <div className="brand">
                            <b>Login</b>

                            <small>login to admin panel</small>
                        </div>
                        <div className="icon">
                            <i className="fa fa-lock"></i>
                        </div>
                    </div>
                    <br />

                    <AlertBox
                        content={this.state.msg}
                        classes={"green"}
                        visible={this.state.visible}
                        bold={"Success!"}
                        tongle={this.onDismiss}
                    />

                    <div className="login-content">
                        <form
                            className="margin-bottom-0"
                            onSubmit={this.onSubmit}
                        >
                            <TextLoginField
                                placeholder="Email Address"
                                name="email"
                                type="email"
                                value={this.state.email}
                                onChange={this.onChange}
                                error={errors.email}
                            />

                            <TextLoginField
                                placeholder="Password"
                                name="password"
                                type="password"
                                value={this.state.password}
                                onChange={this.onChange}
                                error={errors.password}
                            />
                            <div className="checkbox checkbox-css m-b-20">
                                <input type="checkbox" id="remember_checkbox" />
                                <label htmlFor="remember_checkbox">
                                    Remember Me
                                </label>
                            </div>
                            <div className="login-buttons">
                                <button
                                    type="submit"
                                    className="btn btn-success btn-block btn-lg"
                                >
                                    Sign me in
                                </button>
                            </div>
                            <div className="m-t-20">
                                <Link to="/admin/forgot-password">
                                    Forgot your password?
                                </Link>
                            </div>
                        </form>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

AdminLogin.propTypes = {
    adminlogin: PropTypes.func.isRequired,
    admin: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    admin: state.admin,
    errors: state.errors,
});

export default connect(mapStateToProps, { adminlogin })(AdminLogin);
