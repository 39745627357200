import React, { Component } from "react";
import { Link } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import SidebarProfile from "./SidebarProfile.jsx";
import SidebarNav from "./SidebarNav.jsx";
import { PageSettings } from "../../content/page-settings.jsx";
class Sidebar extends Component {
    render() {
        return (
            <PageSettings.Consumer>
                {({
                    toggleSidebarMinify,
                    toggleMobileSidebar,
                    pageSidebarTransparent,
                }) => (
                    <React.Fragment>
                        <div
                            id="sidebar"
                            className={
                                "sidebar " +
                                (pageSidebarTransparent
                                    ? "sidebar-transparent"
                                    : "")
                            }
                        >
                            <PerfectScrollbar
                                className="height-full"
                                options={{ suppressScrollX: true }}
                            >
                                <SidebarProfile />
                                <SidebarNav />
                                <Link
                                    to="/"
                                    className="sidebar-minify-btn"
                                    onClick={toggleSidebarMinify}
                                >
                                    <i className="fa fa-angle-double-left"></i>
                                </Link>
                            </PerfectScrollbar>
                        </div>
                        <div className="sidebar-bg"></div>
                        <div
                            className="sidebar-mobile-dismiss"
                            onClick={toggleMobileSidebar}
                        ></div>
                    </React.Fragment>
                )}
            </PageSettings.Consumer>
        );
    }
}
export default Sidebar;
