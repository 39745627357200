import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import queryString from "qs";
import "../common/common.jsx";
import TextLoginField from "../common/textfield/TextLoginField";
//import AlertBox from "../common/textfield/AlertBox";
import ReactNotification from "react-notifications-component";
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { adminPassverify } from "../../../actions/adminActions.jsx";

class AdminPasswordVerify extends Component {
    constructor() {
        super();

        this.state = {
            admin_email: "",
            token: "",
            password: "",
            password2: "",
            errors: {},
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        if (this.props.admin.isAuthenticated) {
            this.props.history.push("/admin/dashboard");
        }
        const queryStringValues = queryString.parse(
            this.props.location.search.slice(1)
        );
        if (queryStringValues) {
            // User is redirected by us after registration.
            this.setState({
                admin_email: queryStringValues.email,
                token: queryStringValues.token,
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.admin.isAuthenticated) {
            this.props.history.push("/admin/dashboard");
        }

        if (nextProps.errors) {
            this.setState({ errors: nextProps.errors });
        }
    }

    onSubmit(e) {
        e.preventDefault();

        const forgotData = {
            admin_email: this.state.admin_email,
            token: this.state.token,
            password: this.state.password,
            password2: this.state.password2,
        };
        //console.log(adminloginData);
        this.props.adminPassverify(forgotData);
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }
    render() {
        const { errors } = this.state;
        return (
            <React.Fragment>
                <ReactNotification />
                <div className="login-cover">
                    <div className="login-cover-image news-login "></div>
                    <div className="login-cover-bg"></div>
                </div>
                <div className="login login-v2">
                    <div className="login-header">
                        <div className="brand">
                            <b>Change Password</b>
                            <small>change your password</small>
                        </div>
                        <div className="icon">
                            <i className="fa fa-lock"></i>
                        </div>
                    </div>
                    <div className={this.state.alerttype}>
                        <span className="close" data-dismiss="alert"></span>
                        <strong> {this.state.success} </strong>
                        {this.state.msg}
                    </div>
                    <div className="login-content">
                        {errors.token &&
                            store.addNotification({
                                title: "Success",
                                message: errors.token,
                                type: "danger",
                                insert: "top",
                                container: "top-center",
                                animationIn: ["animated", "fadeIn"],
                                animationOut: ["animated", "fadeOut"],
                                dismiss: { duration: 2000 },
                                dismissable: { click: true },
                                // content: notificationContent
                            })}
                        {errors.token1 &&
                            store.addNotification({
                                title: "Success",
                                message: errors.token1,
                                type: "danger",
                                insert: "top",
                                container: "top-center",
                                animationIn: ["animated", "fadeIn"],
                                animationOut: ["animated", "fadeOut"],
                                dismiss: { duration: 2000 },
                                dismissable: { click: true },
                                // content: notificationContent
                            })}
                        <form
                            className="margin-bottom-0"
                            onSubmit={this.onSubmit}
                        >
                            <TextLoginField
                                placeholder="Email Address"
                                name="email"
                                type="hidden"
                                value={this.state.email}
                                onChange={this.onChange}
                                error={errors.email}
                            />

                            <TextLoginField
                                placeholder="Token"
                                name="token"
                                type="hidden"
                                value={this.state.token}
                                onChange={this.onChange}
                            />

                            <TextLoginField
                                placeholder="Password"
                                name="password"
                                type="password"
                                value={this.state.password}
                                onChange={this.onChange}
                                error={errors.password}
                            />

                            <TextLoginField
                                placeholder="Confirm Password"
                                name="password2"
                                type="password"
                                value={this.state.password2}
                                onChange={this.onChange}
                                error={errors.password2}
                            />

                            <div className="login-buttons">
                                <button
                                    type="submit"
                                    className="btn btn-success btn-block btn-lg"
                                >
                                    Change Password
                                </button>
                            </div>
                            <div className="m-t-20">
                                <Link to="/admin/forgot-password">
                                    Forgot Password
                                </Link>
                            </div>
                        </form>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

AdminPasswordVerify.propTypes = {
    adminPassverify: PropTypes.func.isRequired,
    admin: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    admin: state.admin,
    errors: state.errors,
});

export default connect(mapStateToProps, { adminPassverify })(
    AdminPasswordVerify
);
