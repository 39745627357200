import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { GetTestList, GetTest } from "../../../../actions/testActions.jsx";
import logo from "./../../common/logo.png";
import Spinner from "./../../common/textfield/Spinner";
import Moment from "react-moment";
import isEmpty from "../../../../validation/is-empty";
import BlankTestList from "./BlankTestList.jsx";

class PrintBlankTest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            test_num: "",
            insert_date: new Date(),
            question_count: "",
        };
        this.print = () => {
            window.print();
        };
    }

    componentDidMount() {
        if (localStorage.getItem("user") === "Inactive") {
            this.props.history.push("/user/alert");
        }
        if (this.props.match.params.Id) {
            this.props.GetTestList(this.props.match.params.Id);
            this.props.GetTest(this.props.match.params.Id);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({ errors: nextProps.errors });
        }
        if (nextProps.test.test) {
            const test = nextProps.test.test;

            test.test_name = !isEmpty(test.test_name) ? test.test_name : "";
            test.insert_date = !isEmpty(test.insert_date)
                ? test.insert_date
                : "";
            test.question_count = !isEmpty(test.question_count)
                ? test.question_count
                : "";
            // console.log(test.insert_date)

            this.setState({
                test_name: test.test_name,
                insert_date: test.insert_date,
                question_count: test.question_count,
            });
        }
    }

    render() {
        const { testlists, loading } = this.props.test;
        let testBlankContent;
        if (testlists === null || loading) {
            testBlankContent = <Spinner />;
        } else {
            //console.log(blogcats);
            if (testlists.length > 0) {
                testBlankContent = <BlankTestList testlists={testlists} />;
            }
        }
        return (
            <React.Fragment>
                <div className="container">
                    <div className="invoice">
                        <div className="invoice-company text-inverse f-w-600">
                            <span className="pull-right hidden-print">
                                <button
                                    onClick={this.print}
                                    className="btn btn-sm btn-white m-b-10 p-l-5"
                                >
                                    <i className="fa fa-print t-plus-1 fa-fw fa-lg"></i>{" "}
                                    Print
                                </button>
                            </span>
                            <Link to="/" className="navbar-brand">
                                <img src={logo} alt="Logo" width="150" />
                            </Link>
                            <div className="d-flex align-items-center justify-content-center mt-4">
                                <h4>
                                    <strong>Practice Test</strong>
                                </h4>
                            </div>
                            <div className="invoice-header">
                                <div className="invoice-from">
                                    <address className="m-t-2 ">
                                        <strong className="text-inverse">
                                            Test Name: {this.state.test_name}
                                        </strong>
                                        <br />
                                        <strong className="text-inverse">
                                            Total Question:{" "}
                                            {this.state.question_count}
                                        </strong>{" "}
                                        <br />
                                    </address>
                                </div>
                                <div className="invoice-to">
                                    <small></small>
                                    <address className="m-t-5 m-b-5">
                                        <strong className="text-inverse"></strong>
                                    </address>
                                </div>
                                <div className="invoice-date">
                                    <div className="date text-inverse m-t-5">
                                        Date:{" "}
                                        <Moment
                                            format="D MMM YYYY"
                                            date={this.state.insert_date}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="invoice-content">
                                <div className="table-responsive">
                                    {testBlankContent}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

PrintBlankTest.propTypes = {
    GetTestList: PropTypes.func.isRequired,
    GetTest: PropTypes.func.isRequired,
    test: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    test: state.test,
});

export default connect(mapStateToProps, { GetTestList, GetTest })(
    withRouter(PrintBlankTest)
);
