import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "core-js/features/array/find";
import "core-js/features/array/includes";
import "core-js/features/number/is-nan";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import * as serviceWorker from "./serviceWorker";

// import firebase app from the sdk for firebase integration
import firebase from "firebase/app";

// import firebase anylytics from the sdk for integration of analytics
import "firebase/analytics";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAbr_u5TVAJTTdMpaKEPhnIqV2bIN0bcl4",
    authDomain: "sea-trials-d617e.firebaseapp.com",
    databaseURL: "https://sea-trials-d617e.firebaseio.com",
    projectId: "sea-trials-d617e",
    storageBucket: "sea-trials-d617e.appspot.com",
    messagingSenderId: "336846974418",
    appId: "1:336846974418:web:3d01ac64d97061e4246c0b",
    measurementId: "G-2MHH5G74WL",
};

// Initialize firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
