import React, { Component } from "react";
//import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Container from "../content/Container.jsx";
import CommentList from "./CommentList.jsx";
import Spinner from "../common/textfield/Spinner";
import { getComments } from "../../../actions/commentActions.jsx";
import { Panel, PanelHeader, PanelBody } from "../common/panel/Panel.jsx";

class MainComments extends Component {
    componentDidMount() {
        this.props.getComments();
    }
    render() {
        const { comments, loading } = this.props.comment;
        let mainCommentsrContent;
        if (comments === null || loading) {
            mainCommentsrContent = <Spinner />;
        } else {
            //console.log(blogcats);
            if (comments.length > 0) {
                mainCommentsrContent = <CommentList comments={comments} />;
            } else {
                mainCommentsrContent = (
                    <div className="row">
                        <div className="col-12">
                            <Panel>
                                <PanelHeader>comments Tables</PanelHeader>
                                <PanelBody>
                                    <div className="note note-secondary m-b-15">
                                        <div className="note-icon">
                                            <i className="fa fa-lightbulb"></i>
                                        </div>
                                        <div className="note-content text-right">
                                            <h4>
                                                <b>This Table Is Empty</b>
                                            </h4>
                                            <p>
                                                {" "}
                                                No data found to show here. This
                                                table is empty.{" "}
                                            </p>
                                        </div>
                                    </div>
                                </PanelBody>
                            </Panel>
                        </div>
                    </div>
                );
            }
        }
        return (
            <React.Fragment>
                <Container>
                    <div id="content" className="content ">
                        <ol className="breadcrumb float-xl-right">
                            <li className="breadcrumb-item">
                                <a href="/admin/dashboard">Dashboard</a>
                            </li>
                            <li className="breadcrumb-item active">
                                Comments Tables
                            </li>
                        </ol>
                        <h1 className="page-header">Comments Tables </h1>

                        {mainCommentsrContent}
                    </div>
                </Container>
            </React.Fragment>
        );
    }
}

MainComments.propTypes = {
    getComments: PropTypes.func.isRequired,
    comment: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    comment: state.comment,
});

export default connect(mapStateToProps, { getComments })(MainComments);
