import React from "react";
import { Link } from "react-router-dom";

class PlanTile extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="pricing-tables-item">
                <div className="main-pricing-content">
                    {this.props.isPopular && (
                        <div className="most-popular-plan">Most Popular</div>
                    )}
                    <h2>
                        <span
                            className="plan-duration"
                            data-annually="0"
                            data-monthly="0"
                        >
                            {this.props.planTitle}
                        </span>
                    </h2>
                    <h5 className="pricing-title">Month Access</h5>
                    {this.props.discount ? (
                        <h4 className="pricing-discount">
                            Save {this.props.discount}%
                        </h4>
                    ) : (
                        <h4 className="pricing-discount">&nbsp;</h4>
                    )}
                    <h2>
                        <b>$</b>
                        <span
                            className="price"
                            data-annually="0"
                            data-monthly="0"
                        >
                            {this.props.planPrice}
                        </span>
                    </h2>

                    <div
                        className={`pricing-line bg-${this.props.planId}`}
                    ></div>
                    <ul className="pricing-tables-position">
                        <li className="position-item">
                            Access to forums and comments
                        </li>
                        <li className="position-item">
                            Access to all questions
                        </li>
                        <li className="position-item">
                            Ability to log and save your answers
                        </li>
                        <li className="position-item">
                            Permanently hide questions
                        </li>
                        <li className="position-item">
                            Favourite questions for later
                        </li>
                        <li className="position-item">
                            Generate practice exams
                        </li>
                    </ul>
                </div>
                <div className={`bg-pricing-content bg-${this.props.planId}`}>
                    <Link
                        to={this.props.navigationUrl}
                        className="link-buy"
                    >
                        Buy It Now
                    </Link>
                </div>
            </div>
        );
    }
}

export default PlanTile;
