import React, { useEffect } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { loadAdmin } from "../../actions/adminActions";
import store from "../../store";
import AdminForgotpassword from "../../components/admin/auth/AdminForgotpassword";
import AdminLogin from "../../components/admin/auth/AdminLogin";
import AdminPasswordVerify from "../../components/admin/auth/AdminPasswordVerify";
import MainComments from "../../components/admin/comments/MainComments";
import Dashboard from "../../components/admin/dashboard/Dashboard";
import EditPages from "../../components/admin/pages/EditPages";
import Pricing from "../../components/admin/pricing/Pricing";
import AdminRoute from "./AdminRoute";
import AccountRoutes from "./AccountRoutes";
import CommentRoutes from "./CommentRoutes";
import CoupanCodeRoutes from "./CoupanCodeRoutes";
// import ForumRoutes from "./ForumRoutes";
import PagesRoutes from "./PagesRoutes";
import SubscribersRoutes from "./SubscribersRoutes";

const AdminRoutes = () => {
    let { path } = useRouteMatch();

    useEffect(() => {
        store.dispatch(loadAdmin());
    }, []);

    return (
        <Switch>
            <Route
                exact
                path={`${path}/`}
                title="Login"
                component={AdminLogin}
            />
            <Route
                exact
                path={`${path}/forgot-password`}
                component={AdminForgotpassword}
            />
            <Route
                exact
                path={`${path}/change-password`}
                component={AdminPasswordVerify}
            />

            <AdminRoute
                exact
                path={`${path}/comments`}
                component={MainComments}
            />
            <AdminRoute
                exact
                path={`${path}/dashboard`}
                component={Dashboard}
            />
            <AdminRoute exact path={`${path}/pricing`} component={Pricing} />
            <AdminRoute
                exact
                path={`${path}/page/edit/:Id`}
                component={EditPages}
            />

            <Route path={`${path}/account`} component={AccountRoutes} />
            <Route path={`${path}/subscribers`} component={SubscribersRoutes} />
            <Route path={`${path}/comment`} component={CommentRoutes} />
            <Route path={`${path}/pages`} component={PagesRoutes} />
            <Route path={`${path}/coupan-code`} component={CoupanCodeRoutes} />
            {/* <Route path={`${path}/forum`} component={ForumRoutes} /> */}
        </Switch>
    );
};

export default AdminRoutes;
