import React, { Component } from "react";
import ReactTable from "react-table";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Panel, PanelHeader } from "../common/panel/Panel.jsx";
import "react-table/react-table.css";
import { deleteSubscriber } from "../../../actions/subscriberActions.jsx";
import Moment from "react-moment";
import SweetAlert from "react-bootstrap-sweetalert";

class SubscriberList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sweetAlertError: false,
        };

        //this.data = makeData();
        this.defaultSorted = [
            {
                id: "_id",
                desc: true,
            },
        ];
        this.tableColumns = [
            {
                Header: "Id",
                id: "row",
                maxWidth: 50,
                filterable: false,
                Cell: (row) => {
                    return <div>{row.index + 1}</div>;
                },
                sortType: "basic",
            },
            {
                Header: "User",
                columns: [
                    {
                        Header: "Joined date",
                        accessor: "insert_date",
                        filterable: false,
                        sortable: false,
                        Cell: (row) => (
                            <div>
                                <i className="fa fa-calendar-alt col-blue mr-2"></i>
                                <Moment format="D MMM YYYY" withTitle>
                                    {row.value}
                                </Moment>
                            </div>
                        ),
                        sortType: "basic",
                    },

                    {
                        Header: "Name",
                        id: "user_name",
                        accessor: (d) => d.user_name,
                        sortType: "basic",
                    },

                    {
                        Header: "Email",
                        accessor: "email", // matters for grouping and sorting,
                        sortType: "basic",
                    },
                ],
            },
            {
                Header: "Subscription",
                columns: [
                    {
                        Header: "Type",
                        accessor: "subscription_type",
                        Cell: (row) => (
                            <span>
                                {row.value === "Trial" ? (
                                    <span className="label label-warning m-r-3">
                                        {row.value}
                                    </span>
                                ) : (
                                    <span className="label label-lime m-r-3">
                                        {row.value}
                                    </span>
                                )}
                            </span>
                        ),
                        sortType: "basic",
                    },

                    {
                        Header: "Expiry",
                        accessor: "unsubs_date",
                        filterable: false,
                        sortable: false,
                        Cell: (row) => (
                            <span>
                                {(
                                    <Moment format="D MMM YYYY" withTitle>
                                        {row.value}
                                    </Moment>
                                ) >=
                                (
                                    <Moment
                                        format="D MMM YYYY"
                                        withTitle
                                    ></Moment>
                                ) ? (
                                    <span className="label label-primary m-r-3">
                                        <Moment format="D MMM YYYY" withTitle>
                                            {row.value}
                                        </Moment>
                                    </span>
                                ) : (
                                    <span className="label label-danger m-r-3">
                                        <Moment format="D MMM YYYY" withTitle>
                                            {row.value}
                                        </Moment>
                                    </span>
                                )}
                            </span>
                        ),
                        sortType: "basic",
                    },
                ],
            },
            {
                Header: "Question",
                columns: [
                    {
                        Header: "Type",
                        accessor: "question_type",
                        Cell: (row) => (
                            <span>
                                {row.original.license_rating}-{row.value}
                            </span>
                        ),
                        sortType: "basic",
                    },

                    {
                        Header: "PlatForm",
                        accessor: "platform",
                        sortType: "basic",
                    },
                ],
            },
            {
                Header: "Action",
                columns: [
                    {
                        Header: "Actions",
                        accessor: "_id",
                        filterable: false,
                        sortable: false,
                        Cell: (row) => (
                            <div>
                                <Link
                                    to={`/admin/subscribers/edit-subscriber/${row.value}`}
                                    className="btn btn-info btn-sm m-r-5 "
                                >
                                    <i className="fas fa-sm fa-fw m-r-2 fa-edit"></i>
                                </Link>
                                <button
                                    onClick={() =>
                                        this.toggleSweetAlert(
                                            "error",
                                            row.value
                                        )
                                    }
                                    className="btn btn-danger btn-sm m-r-5 "
                                >
                                    <i className="fas fa-sm fa-fw m-r-2 fa-trash"></i>
                                </button>
                            </div>
                        ),
                        sortType: "basic",
                    },
                ],
            },
        ];
        this.toggleSweetAlert = this.toggleSweetAlert.bind(this);
    }
    toggleSweetAlert(name, id) {
        switch (name) {
            case "error":
                this.setState({
                    sweetAlertError: !this.state.sweetAlertError,
                    id: id,
                });
                break;
            default:
                break;
        }
    }

    onDeleteClick(id) {
        this.props.deleteSubscriber(id);
        this.setState({ sweetAlertError: !this.state.sweetAlertError });
    }
    render() {
        const data = this.props.subscribers;

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-12">
                        {this.state.sweetAlertError && (
                            <SweetAlert
                                danger
                                showCancel
                                confirmBtnText="Yes, delete it!"
                                confirmBtnBsStyle="danger"
                                cancelBtnBsStyle="default"
                                title="Are you sure?"
                                onConfirm={this.onDeleteClick.bind(
                                    this,
                                    this.state.id
                                )}
                                onCancel={() => this.toggleSweetAlert("error")}
                            >
                                You will not be able to recover this again!
                            </SweetAlert>
                        )}
                        <Panel>
                            <PanelHeader>Subscriber table</PanelHeader>

                            <ReactTable
                                filterable
                                //  useSortBy
                                data={data}
                                columns={this.tableColumns}
                                defaultPageSize={10}
                                defaultSorted={this.defaultSorted}
                                className="-highlight"
                            />
                        </Panel>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

SubscriberList.propTypes = {
    deleteSubscriber: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
    errors: state.errors,
});
export default connect(mapStateToProps, { deleteSubscriber })(
    withRouter(SubscriberList)
);
