import React, { Component } from "react";
import { Link } from "react-router-dom";

class DeckList extends Component {
    render() {
        const booklist = this.props.deck_books.map((books) => (
            <li key={books._id}>
                <Link
                    to={`/question/watch?mode=normal&type=book&id=${books.ZINBOOK}&num=1&zid=${books._id}`}
                >
                    {books.ZBOOKNAME}
                </Link>
            </li>
        ));
        return booklist;
    }
}
export default DeckList;
