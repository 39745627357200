import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Container from "./../content/Container.jsx";
import { Panel, PanelHeader, PanelBody } from "./../common/panel/Panel.jsx";
import TextFieldArea from "./../common/textfield/TextFieldArea";
import TextImageFileUpload from "./../common/textfield/TextImageFileUpload";
import TextAreaField from "./../common/textfield/TextAreaField";
import {
    adminprofileEdit,
    getadminCurrentProfile,
} from "../../../actions/adminActions.jsx";
import ReactNotification from "react-notifications-component";
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import isEmpty from "../../../validation/is-empty";

class EditProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            first_name: "",
            last_name: "",
            address: "",
            cont_no: "",
            mobile: "",
            city: "",
            country: "",
            state: "",
            zip: "",
            visible: false,
            file: "",
            imagePreview: "",
            imagePreviewUrl: "",
            errors: {},
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.fileChangedHandler = this.fileChangedHandler.bind(this);
        this.onDismiss = this.onDismiss.bind(this);
    }

    onDismiss() {
        this.setState({ visible: false });
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    componentDidMount() {
        this.props.getadminCurrentProfile();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({ errors: nextProps.errors });
        }

        if (nextProps.admin_profile.admin_profile) {
            const admin_profile = nextProps.admin_profile.admin_profile;
            // console.log(admin_profile);
            // If profile field doesnt exist, make empty string
            admin_profile.first_name = !isEmpty(admin_profile.first_name)
                ? admin_profile.first_name
                : "";
            admin_profile.last_name = !isEmpty(admin_profile.last_name)
                ? admin_profile.last_name
                : "";
            admin_profile.country = !isEmpty(admin_profile.country)
                ? admin_profile.country
                : "";
            admin_profile.city = !isEmpty(admin_profile.city)
                ? admin_profile.city
                : "";
            admin_profile.address = !isEmpty(admin_profile.address)
                ? admin_profile.address
                : "";
            admin_profile.state = !isEmpty(admin_profile.state)
                ? admin_profile.state
                : "";
            admin_profile.zip = !isEmpty(admin_profile.zip)
                ? admin_profile.zip
                : "";
            admin_profile.cont_no = !isEmpty(admin_profile.cont_no)
                ? admin_profile.cont_no
                : "";
            admin_profile.mobile = !isEmpty(admin_profile.mobile)
                ? admin_profile.mobile
                : "";
            admin_profile.country = !isEmpty(admin_profile.country)
                ? admin_profile.country
                : "";
            admin_profile.image = !isEmpty(admin_profile.image)
                ? admin_profile.image
                : "/noimage.jpg";

            // Set component fields state
            this.setState({
                first_name: admin_profile.first_name,
                last_name: admin_profile.last_name,
                address: admin_profile.address,
                state: admin_profile.state,
                zip: admin_profile.zip,
                city: admin_profile.city,
                cont_no: admin_profile.cont_no,
                mobile: admin_profile.mobile,
                country: admin_profile.country,
                imagePreviewUrl:
                    "https://seatrials-bucket.s3.us-east-2.amazonaws.com/admin/" +
                    admin_profile.image,
            });
        }
    }

    fileChangedHandler(e) {
        this.setState({
            file: e.target.files[0],
        });
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            this.setState({
                file: file,
                imagePreviewUrl: reader.result,
            });
        };
        reader.readAsDataURL(file);
    }

    onSubmit(e) {
        e.preventDefault();
        const formData = new FormData();
        formData.append("first_name", this.state.first_name);
        formData.append("last_name", this.state.last_name);
        formData.append("address", this.state.address);
        formData.append("state", this.state.state);
        formData.append("city", this.state.city);
        formData.append("country", this.state.country);
        formData.append("zip", this.state.zip);
        formData.append("cont_no", this.state.cont_no);
        formData.append("mobile", this.state.mobile);
        formData.append("image", this.state.file);

        this.props.adminprofileEdit(formData, this.props.history);
        if (!Object.keys(this.state.errors).length) {
            store.addNotification({
                title: "Success!",
                message: "profile updated successfully.",
                type: "success",
                insert: "top",
                container: "top-center",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: { duration: 2000 },
                dismissable: { click: true },
                // content: notificationContent
            });
        }
    }

    render() {
        const { errors } = this.state;
        return (
            <React.Fragment>
                <ReactNotification />
                <Container>
                    <div className="content">
                        <div>
                            <ol className="breadcrumb float-xl-right">
                                <li className="breadcrumb-item">
                                    <Link to="/admin/dashboard">Dashboard</Link>
                                </li>

                                <li className="breadcrumb-item active">
                                    Profile
                                </li>
                            </ol>
                            <h1 className="page-header">Profile</h1>

                            <div className="row">
                                <div className="col-xl-12">
                                    <Panel>
                                        <PanelHeader>Edit Profile</PanelHeader>
                                        <PanelBody className="panel-form">
                                            <form
                                                className="form-horizontal form-bordered"
                                                onSubmit={this.onSubmit}
                                            >
                                                <fieldset>
                                                    <TextFieldArea
                                                        label="First Name"
                                                        placeholder="First Name"
                                                        name="first_name"
                                                        type="text"
                                                        value={
                                                            this.state
                                                                .first_name
                                                        }
                                                        onChange={this.onChange}
                                                        error={
                                                            errors.first_name
                                                        }
                                                        striks="*"
                                                    />

                                                    <TextFieldArea
                                                        label="Last Name"
                                                        placeholder="Last Name"
                                                        name="last_name"
                                                        type="text"
                                                        value={
                                                            this.state.last_name
                                                        }
                                                        onChange={this.onChange}
                                                        error={errors.last_name}
                                                        striks="*"
                                                    />

                                                    <TextFieldArea
                                                        label="Phone No"
                                                        placeholder="Phone Number"
                                                        name="cont_no"
                                                        type="text"
                                                        value={
                                                            this.state.cont_no
                                                        }
                                                        onChange={this.onChange}
                                                        error={errors.cont_no}
                                                    />

                                                    <TextFieldArea
                                                        label="Mobile"
                                                        placeholder="0123456789"
                                                        name="mobile"
                                                        type="text"
                                                        value={
                                                            this.state.mobile
                                                        }
                                                        onChange={this.onChange}
                                                        error={errors.mobile}
                                                    />

                                                    <TextAreaField
                                                        label="Address"
                                                        placeholder="Address"
                                                        name="address"
                                                        type="text"
                                                        value={
                                                            this.state.address
                                                        }
                                                        onChange={this.onChange}
                                                        error={errors.address}
                                                    />

                                                    <TextFieldArea
                                                        label="City"
                                                        placeholder="City"
                                                        name="city"
                                                        type="text"
                                                        value={this.state.city}
                                                        onChange={this.onChange}
                                                        error={errors.city}
                                                    />

                                                    <TextFieldArea
                                                        label="State"
                                                        placeholder="State"
                                                        name="state"
                                                        type="text"
                                                        value={this.state.state}
                                                        onChange={this.onChange}
                                                        error={errors.state}
                                                    />

                                                    <TextFieldArea
                                                        label="Country"
                                                        placeholder="Country"
                                                        name="country"
                                                        type="text"
                                                        value={
                                                            this.state.country
                                                        }
                                                        onChange={this.onChange}
                                                        error={errors.country}
                                                    />

                                                    <TextFieldArea
                                                        label="Zip Code"
                                                        placeholder="Zip Code"
                                                        name="zip"
                                                        type="text"
                                                        value={this.state.zip}
                                                        onChange={this.onChange}
                                                        error={errors.zip}
                                                    />

                                                    <TextImageFileUpload
                                                        src={
                                                            this.state
                                                                .imagePreviewUrl
                                                        }
                                                        type="file"
                                                        name="image"
                                                        text="Upload new image"
                                                        label="upload image"
                                                        //  value={this.state.file}
                                                        onChange={
                                                            this
                                                                .fileChangedHandler
                                                        }
                                                        imgextension={[
                                                            ".jpg",
                                                            ".gif",
                                                            ".png",
                                                            ".jpeg",
                                                        ]}
                                                        upload="Upload Image"
                                                    />
                                                    <div className="form-group row">
                                                        <div className="col-md-12 offset-md-5">
                                                            <button
                                                                type="submit"
                                                                className="btn btn-sm btn-primary m-r-5"
                                                            >
                                                                Submit
                                                            </button>
                                                        </div>
                                                    </div>
                                                </fieldset>
                                            </form>
                                        </PanelBody>
                                    </Panel>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </React.Fragment>
        );
    }
}

EditProfile.propTypes = {
    admin: PropTypes.object.isRequired,
    adminprofileEdit: PropTypes.func.isRequired,
    getadminCurrentProfile: PropTypes.func.isRequired,
    admin_profile: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    admin: state.admin,
    admin_profile: state.admin_profile,
    errors: state.errors,
});

export default connect(mapStateToProps, {
    adminprofileEdit,
    getadminCurrentProfile,
})(withRouter(EditProfile));
