import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Container from "../content/Container.jsx";
import TableList from "./TableList.jsx";
import Spinner from "../common/textfield/Spinner";
import { getActivePage } from "../../../actions/pageActions.jsx";
import { Panel, PanelHeader, PanelBody } from "../common/panel/Panel.jsx";
import { ButtonGroup } from "reactstrap";

class MainPublished extends Component {
    componentDidMount() {
        this.props.getActivePage();
    }
    render() {
        const { pages, loading } = this.props.page;

        let mainPageContent;
        if (pages === null || loading) {
            mainPageContent = <Spinner />;
        } else {
            //console.log(blogcats);
            if (pages.length > 0) {
                mainPageContent = <TableList pages={pages} />;
            } else {
                mainPageContent = (
                    <div className="row">
                        <div className="col-12">
                            <Panel>
                                <PanelHeader>
                                    <ButtonGroup className="pull-right">
                                        <Link to="/admin/pages/add-pages">
                                            <button className="btn btn-sm btn-green ">
                                                <i className="fas fa-plus" />
                                                &nbsp; Add Pages
                                            </button>
                                        </Link>
                                        &nbsp; &nbsp;
                                    </ButtonGroup>
                                    Published Pages Table
                                </PanelHeader>
                                <PanelBody>
                                    <div className="note note-secondary m-b-15">
                                        <div className="note-icon">
                                            <i className="fa fa-lightbulb"></i>
                                        </div>
                                        <div className="note-content text-right">
                                            <h4>
                                                <b>This Table Is Empty</b>
                                            </h4>
                                            <p>
                                                {" "}
                                                No data found to show here. This
                                                table is empty.{" "}
                                            </p>
                                        </div>
                                    </div>
                                </PanelBody>
                            </Panel>
                        </div>
                    </div>
                );
            }
        }
        return (
            <React.Fragment>
                <Container>
                    <div id="content" className="content ">
                        <ol className="breadcrumb float-xl-right">
                            <li className="breadcrumb-item">
                                <a href="/admin/dashboard">Dashboard</a>
                            </li>
                            <li className="breadcrumb-item active">
                                Published Page Table
                            </li>
                        </ol>
                        <h1 className="page-header">Published Page Table </h1>

                        {mainPageContent}
                    </div>
                </Container>
            </React.Fragment>
        );
    }
}

MainPublished.propTypes = {
    getActivePage: PropTypes.func.isRequired,
    page: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    page: state.page,
});

export default connect(mapStateToProps, { getActivePage })(MainPublished);
