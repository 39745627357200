import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
//import  logo from "../../.././assets/user/images/logo.png";
import classnames from "classnames";
import TextFieldOption from "../common/textfield/TextFieldOption";
import TextFieldArea from "./../common/textfield/TextFieldArea";
import { ApplyCoupanCode } from "../../../actions/paymentActions.jsx";
import isEmpty from "../../../validation/is-empty";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { getCurrentPrice } from "../../../actions/authActions.jsx";
import { loadUser } from "../../../actions/userActions";
import { paymentReg } from "../../../actions/paymentActions";
import ReactNotification from "react-notifications-component";
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

class PaymentNew extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subscription_type: "",
            card_number: "",
            exp_month: "",
            exp_year: "",
            cvc: "",
            full_name: "",
            email: "",
            user_name: "",
            password: "",
            license_rating: "",
            question_type: "",
            modalDialog: false,
            coupan_code: "",
            amount: "",
            amt: "",
            errors: {},
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onSubmitCoupan = this.onSubmitCoupan.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    toggleModal(name) {
        switch (name) {
            case "modalDialog":
                this.setState({ modalDialog: !this.state.modalDialog });
                break;
            default:
                break;
        }
    }

    componentDidMount() {
        this.props.getCurrentPrice();
        this.props.loadUser();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({ errors: nextProps.errors });
        }

        // pricing
        if (nextProps.price.price) {
            const price = nextProps.price.price;

            price.one_month = !isEmpty(price.one_month) ? price.one_month : "";
            price.three_month = !isEmpty(price.three_month)
                ? price.three_month
                : "";
            price.life_time = !isEmpty(price.life_time) ? price.life_time : "";

            if (this.props.match.params.Id === "1") {
                this.setState({
                    amt: price.one_month,
                    subscription_type: "1 Month Subscription",
                });
            } else if (this.props.match.params.Id === "2") {
                this.setState({
                    amt: price.three_month,
                    subscription_type: "3 Month Subscription",
                });
            }
        }

        if (nextProps.coupan.coupan) {
            this.setState({ modalDialog: false });
            store.addNotification({
                title: "Success",
                message: "Coupon code applied successfully.",
                type: "success",
                insert: "top",
                container: "top-center",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: { duration: 2000 },
                dismissable: { click: true },
                // content: notificationContent
            });

            const coupan = nextProps.coupan.coupan;
            console.log(coupan);
            coupan.coupan_off = !isEmpty(coupan.coupan_off)
                ? coupan.coupan_off
                : "";

            if (!isEmpty(coupan.coupan_off)) {
                var new_amount;
                new_amount =
                    this.state.amt - (this.state.amt * coupan.coupan_off) / 100;
                new_amount = new_amount.toFixed(2);
                this.setState({
                    amount: new_amount,
                });
            }
        } else {
            this.setState({
                amount: this.state.amt,
            });
        }

        if (nextProps.auth.user) {
            const profile = nextProps.auth.user;
            // console.log(profile);
            // If profile field doesnt exist, make empty string
            profile.user_name = !isEmpty(profile.user_name)
                ? profile.user_name
                : "";

            // Set component fields state
            this.setState({
                user_name: profile.user_name,
                imagePreviewUrl: profile.image,
            });
        }
    }

    onSubmit(e) {
        e.preventDefault();

        const payData = {
            card_number: this.state.card_number,
            exp_month: this.state.exp_month,
            exp_year: this.state.exp_year,
            cvc: this.state.cvc,
            full_name: this.state.full_name,
            amount: this.state.amount,
            subscription_type: this.state.subscription_type,
            email: this.state.email,
            user_name: this.state.user_name,
            password: this.state.password,
            question_type: this.state.question_type,
            license_rating: this.state.license_rating,
            platform: "web",
        };

        this.props.paymentReg(payData, this.props.history);
    }

    onSubmitCoupan(e) {
        e.preventDefault();
        const subs_type = this.props.match.params.Id;
        const categoryData = {
            coupan_code: this.state.coupan_code,
            subscription_type: subs_type,
        };

        this.props.ApplyCoupanCode(categoryData, this.props.history);
        /// this.setState({ modalDialog: false });
    }

    render() {
        const { errors, modalDialog } = this.state;
        let CoupanModelContent;
        if (modalDialog === true) {
            CoupanModelContent = (
                <Modal
                    isOpen={this.state.modalDialog}
                    toggle={() => this.toggleModal("modalDialog")}
                >
                    <ModalHeader toggle={() => this.toggleModal("modalDialog")}>
                        Add Coupan Code
                    </ModalHeader>
                    <form
                        className="form-horizontal form-bordered"
                        onSubmit={this.onSubmitCoupan}
                    >
                        <ModalBody>
                            <TextFieldArea
                                label="Coupan Code"
                                placeholder="Coupan Code"
                                name="coupan_code"
                                type="text"
                                value={this.state.coupan_code}
                                onChange={this.onChange}
                                error={errors.coupan_code}
                            />
                        </ModalBody>
                        <ModalFooter>
                            <button
                                className="btn btn-white"
                                onClick={() => this.toggleModal("modalDialog")}
                            >
                                Close
                            </button>
                            <button type="submit" className="btn btn-success">
                                Add Coupan Code
                            </button>
                        </ModalFooter>
                    </form>
                </Modal>
            );
        }
        let RateContent;
        if (this.state.question_type === "Deck") {
            RateContent = (
                <div class="form-label-group">
                    <div class="row">
                        <div class="col-sm-12 col-xs-12">
                            <label for="inputEmail">
                                Deck rating:
                                <span style={{ color: "red" }}>*</span>
                            </label>

                            <select
                                className={classnames("form-control2 ", {
                                    "is-invalid": errors.license_rating,
                                })}
                                data-size="10"
                                data-live-search="true"
                                data-style="btn-white"
                                name="license_rating"
                                value={this.state.license_rating}
                                onChange={this.onChange}
                            >
                                <option value="">Select Deck Rating</option>
                                <option value="ZDL1">
                                    Master & Chief Mate (Any Gross Registered
                                    Tons (AGT)) (Oceans or Near Coastal)
                                </option>
                                <option value="ZDL2">
                                    Master (NMT 500/1600 Gross Registered Tons)
                                    (Oceans or Near Coastal)
                                </option>
                                <option value="ZDL3">
                                    2nd & 3rd Mate (Any Gross Registered Tons
                                    (AGT)) (Oceans or Near Coastal)
                                </option>
                                <option value="ZDL4">
                                    Mate (NMT 500/1600 Gross Registered Tons)
                                    (NMT 500/1600 Gross Registered Tons)
                                </option>
                                <option value="ZDL5">
                                    Master (NMT 200 Gross Registered Tons)
                                    (Oceans or Near Coastal)
                                </option>
                                <option value="ZDL6">
                                    Operator (Uninspected Passenger Vessel)
                                    (Near Coastal)
                                </option>
                                <option value="ZDL7">
                                    Operator (Uninspected Passenger Vessel)
                                    (Great Lakes and Inland or Inland)
                                </option>
                                <option value="ZDL8">
                                    Apprentice Mate Steersman (Any Appropriate
                                    Tonnage) (Oceans or Near Coastal)
                                </option>
                                <option value="ZDL9">
                                    Apprentice Mate Steersman (Any Appropriate
                                    Tonnage) (Great Lakes and Inland)
                                </option>
                                <option value="ZDL10">
                                    Master (Any Gross Registered Tons (AGT))
                                    (Great Lakes and Inland or Inland)
                                </option>
                                <option value="ZDL11">
                                    Mate (Any Gross Registered Tons (AGT))
                                    (Great Lakes and Inland)
                                </option>
                                <option value="ZDL12">
                                    Master (NMT 500/1600 Gross Registered Tons)
                                    (Great Lakes and Inland)
                                </option>
                                <option value="ZDL13">
                                    Mate (NMT 500/1600 Gross Registered Tons)
                                    (Great Lakes and Inland)
                                </option>
                                <option value="ZDL14">
                                    Master (NMT 200 Gross Registered Tons)
                                    (Great Lakes and Inland or Inland)
                                </option>
                                <option value="ZDL15">
                                    Master (AGT OR NMT 500/1600 GRT) (Rivers)
                                </option>
                                <option value="ZDL16">
                                    Master (AGT OR NMT 500/1600 GRT) (Rivers)
                                </option>
                                <option value="ZDL17">
                                    Mate (NMT 500/1600 GRT) (Rivers)
                                </option>
                                <option value="ZDL18">
                                    Master or Mate (NMT 200 Gross Registered
                                    Tons) (Rivers)
                                </option>
                                <option value="ZDL19">
                                    Master Uninspected Fishing Vessel (Any
                                    Appropriate Tonnage) (Oceans or Near
                                    Coastal)
                                </option>
                                <option value="ZDL20">
                                    Mate Uninspected Fishing Vessel (Any
                                    Appropriate Tonnage) (Oceans or Near
                                    Coastal)
                                </option>
                                <option value="ZDL21">
                                    First Class Pilot (Any Appropriate Tonnage)
                                    (Any Appropriate Waters)
                                </option>
                                <option value="ZDL22">
                                    Offshore Installation Manager (Mobile
                                    Offshore Drilling Unit) (Unrestricted)
                                </option>
                                <option value="ZDL23">
                                    Offshore Installation Manager (Mobile
                                    Offshore Drilling Unit) (Surface Units
                                    Underway)
                                </option>
                                <option value="ZDL24">
                                    Offshore Installation Manager (Mobile
                                    Offshore Drilling Unit) (Surface Units On
                                    Location)
                                </option>
                                <option value="ZDL25">
                                    Offshore Installation Manager (Mobile
                                    Offshore Drilling Unit) (Bottom Bearing
                                    Units Underway)
                                </option>
                                <option value="ZDL26">
                                    Offshore Installation Manager (Mobile
                                    Offshore Drilling Unit) (Bottom Bearing
                                    Units On Location)
                                </option>
                                <option value="ZDL27">
                                    Barge Supervisor (Mobile Offshore Drilling
                                    Unit) (N/A)
                                </option>
                                <option value="ZDL28">
                                    Ballast Control Operator (Mobile Offshore
                                    Drilling Unit) (N/A)
                                </option>
                            </select>

                            {errors.license_rating && (
                                <div
                                    className="invalid-feedback"
                                    style={{
                                        display: "block",
                                    }}
                                >
                                    {errors.license_rating}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            );
        } else if (this.state.question_type === "Engine") {
            RateContent = (
                <div class="form-label-group">
                    <div class="row">
                        <div class="col-sm-12 col-xs-12">
                            <label for="inputEmail">
                                Deck rating:
                                <span style={{ color: "red" }}>*</span>
                            </label>

                            <select
                                className={classnames("form-control2 ", {
                                    "is-invalid": errors.license_rating,
                                })}
                                data-size="10"
                                data-live-search="true"
                                data-style="btn-white"
                                name="license_rating"
                                value={this.state.license_rating}
                                onChange={this.onChange}
                            >
                                <option value="">Select Engine Rating</option>
                                <option value="ZDL1">
                                    First Assistant Engineer (Unlimited)
                                </option>
                                <option value="ZDL2">
                                    Third Assistant Engineer (Unlimited)
                                </option>
                                <option value="ZDL19">
                                    MEWB (Marine Engineering Workbook)
                                </option>
                                <option value="ZDL3">
                                    Chief Engineer (Limited)
                                </option>
                                <option value="ZDL4">
                                    Assistant Engineer (Limited)
                                </option>
                                <option value="ZDL5">
                                    Designated Duty Engineer (Unlimited)
                                </option>
                                <option value="ZDL6">
                                    Designated Duty Engineer (4,000 HP)
                                </option>
                                <option value="ZDL7">
                                    Designated Duty Engineer (1,000 HP)
                                </option>
                                <option value="ZDL8">
                                    Chief Engineer (Uninspected Fishing Industry
                                    Vessels)
                                </option>
                                <option value="ZDL9">
                                    Assistant Engineer (Uninspected Fishing
                                    Industry Vessels)
                                </option>
                                <option value="ZDL10">
                                    Chief Engineer (MODU)
                                </option>
                                <option value="ZDL11">
                                    Assistant Engineer (MODU)
                                </option>
                                <option value="ZDL12">
                                    Chief Engineer (OSV Unlimited)
                                </option>
                                <option value="ZDL13">
                                    Assistant Engineer (OSV Unlimited)
                                </option>
                                <option value="ZDL14">
                                    QMED (Junior Engineer)
                                </option>
                                <option value="ZDL15">
                                    QMED (Electrician - Refrigeration Engineer)
                                </option>
                                <option value="ZDL16">QMED (Oiler)</option>
                                <option value="ZDL17">
                                    QMED (Fireman - Watertender)
                                </option>
                                <option value="ZDL18">
                                    QMED (Machinist - Pumpman)
                                </option>
                            </select>

                            {errors.license_rating && (
                                <div
                                    className="invalid-feedback"
                                    style={{
                                        display: "block",
                                    }}
                                >
                                    {errors.license_rating}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <React.Fragment>
                <ReactNotification />
                {errors.coupanerror &&
                    store.addNotification({
                        title: "Success",
                        message: errors.coupanerror,
                        type: "danger",
                        insert: "top",
                        container: "top-center",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: { duration: 2000 },
                        dismissable: { click: true },
                        // content: notificationContent
                    })}
                {CoupanModelContent}
                <div class="registerbg">
                    <div class="container">
                        <div class="row">
                            <div class="form-signin">
                                <div class="text-center mb-4">
                                    <a href="/">
                                        <img
                                            src="https://seatrials-bucket.s3.us-east-2.amazonaws.com/main/logo.png"
                                            alt="Marinerlicenseprep"
                                            width="400"
                                        />
                                    </a>

                                    <h1 class="h3 mb-3 font-weight-bold">
                                        Checkout
                                    </h1>
                                    <p>
                                        We don’t share your financial details
                                        with the merchant.
                                    </p>
                                </div>

                                <form
                                    id="payment_form"
                                    onSubmit={this.onSubmit}
                                >
                                    <div class="col-sm-12 col-xs-12">
                                        <div class="form-label-group mt-2">
                                            <div class="row">
                                                <div class="col-sm-12 col-xs-12">
                                                    <div class="creditCardInput">
                                                        <div class="crediticon">
                                                            <img
                                                                src="./../assets/user/images/VISA.jpg"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div class="crediticon">
                                                            <img
                                                                src="./../assets/user/images/MASTERCARD.jpg"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div class="crediticon">
                                                            <img
                                                                src="./../assets/user/images/AMEX.jpg"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div class="crediticon">
                                                            <img
                                                                src="./../assets/user/images/DISCOVER.jpg"
                                                                alt=""
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-label-group mt-2">
                                            <div class="row">
                                                <div class="col-sm-12 col-xs-12">
                                                    <label for="inputEmail">
                                                        CARD NUMBER
                                                        <span
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        >
                                                            *
                                                        </span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="card_number"
                                                        name="card_number"
                                                        className={classnames(
                                                            "form-control",
                                                            {
                                                                "is-invalid":
                                                                    errors.card_number,
                                                            }
                                                        )}
                                                        placeholder="Card Number"
                                                        required=""
                                                        autofocus=""
                                                        onChange={this.onChange}
                                                        value={
                                                            this.state
                                                                .card_number
                                                        }
                                                    />
                                                    {errors.card_number && (
                                                        <div
                                                            className="invalid-feedback"
                                                            style={{
                                                                display:
                                                                    "block",
                                                            }}
                                                        >
                                                            {errors.card_number}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-label-group mt-2">
                                            <div class="row">
                                                <div class="col-sm-6 col-xs-12">
                                                    <label for="inputEmail">
                                                        EXPIRY MONTH
                                                        <span
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        >
                                                            *
                                                        </span>
                                                    </label>
                                                    <select
                                                        type="text"
                                                        id="expires"
                                                        name="exp_month"
                                                        className={classnames(
                                                            "form-control",
                                                            {
                                                                "is-invalid":
                                                                    errors.exp_month,
                                                            }
                                                        )}
                                                        placeholder="Expiry Month"
                                                        onChange={this.onChange}
                                                        value={
                                                            this.state.exp_month
                                                        }
                                                    >
                                                        <option value="">
                                                            Select Expiry Month
                                                        </option>
                                                        <option value="01">
                                                            01
                                                        </option>
                                                        <option value="02">
                                                            02
                                                        </option>
                                                        <option value="03">
                                                            03
                                                        </option>
                                                        <option value="04">
                                                            04
                                                        </option>
                                                        <option value="05">
                                                            05
                                                        </option>
                                                        <option value="06">
                                                            06
                                                        </option>
                                                        <option value="07">
                                                            07
                                                        </option>
                                                        <option value="08">
                                                            08
                                                        </option>
                                                        <option value="09">
                                                            09
                                                        </option>
                                                        <option value="10">
                                                            10
                                                        </option>
                                                        <option value="11">
                                                            11
                                                        </option>
                                                        <option value="12">
                                                            12
                                                        </option>
                                                    </select>
                                                    {errors.exp_month && (
                                                        <div
                                                            className="invalid-feedback"
                                                            style={{
                                                                display:
                                                                    "block",
                                                            }}
                                                        >
                                                            {errors.exp_month}
                                                        </div>
                                                    )}
                                                </div>

                                                <div class="col-sm-6 col-xs-12">
                                                    <label for="inputEmail">
                                                        EXPIRY YEAR
                                                        <span
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        >
                                                            *
                                                        </span>
                                                    </label>
                                                    <select
                                                        type="text"
                                                        id="Csc"
                                                        name="exp_year"
                                                        className={classnames(
                                                            "form-control",
                                                            {
                                                                "is-invalid":
                                                                    errors.exp_year,
                                                            }
                                                        )}
                                                        onChange={this.onChange}
                                                        value={
                                                            this.state.exp_year
                                                        }
                                                    >
                                                        <option value="">
                                                            Select Expiry Year
                                                        </option>
                                                        <option value="2022">
                                                            2022
                                                        </option>
                                                        <option value="2023">
                                                            2023
                                                        </option>
                                                        <option value="2024">
                                                            2024
                                                        </option>
                                                        <option value="2025">
                                                            2025
                                                        </option>
                                                        <option value="2026">
                                                            2026
                                                        </option>
                                                        <option value="2027">
                                                            2027
                                                        </option>
                                                        <option value="2028">
                                                            2028
                                                        </option>
                                                        <option value="2029">
                                                            2029
                                                        </option>
                                                        <option value="2030">
                                                            2030
                                                        </option>
                                                        <option value="2031">
                                                            2031
                                                        </option>
                                                        <option value="2032">
                                                            2032
                                                        </option>
                                                        <option value="2033">
                                                            2033
                                                        </option>
                                                        <option value="2034">
                                                            2034
                                                        </option>
                                                        <option value="2035">
                                                            2035
                                                        </option>
                                                        <option value="2036">
                                                            2036
                                                        </option>
                                                        <option value="2037">
                                                            2037
                                                        </option>
                                                        <option value="2037">
                                                            2038
                                                        </option>
                                                        <option value="2037">
                                                            2039
                                                        </option>
                                                        <option value="2037">
                                                            2040
                                                        </option>
                                                    </select>
                                                    {errors.exp_year && (
                                                        <div
                                                            className="invalid-feedback"
                                                            style={{
                                                                display:
                                                                    "block",
                                                            }}
                                                        >
                                                            {errors.exp_year}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-label-group mt-2">
                                            <div class="row">
                                                <div class="col-sm-6 col-xs-12">
                                                    <label for="inputEmail">
                                                        CVC
                                                        <span
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        >
                                                            *
                                                        </span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="cvc"
                                                        name="cvc"
                                                        className={classnames(
                                                            "form-control",
                                                            {
                                                                "is-invalid":
                                                                    errors.cvc,
                                                            }
                                                        )}
                                                        placeholder="CVC"
                                                        onChange={this.onChange}
                                                        value={this.state.cvc}
                                                    />
                                                    {errors.cvc && (
                                                        <div
                                                            className="invalid-feedback"
                                                            style={{
                                                                display:
                                                                    "block",
                                                            }}
                                                        >
                                                            {errors.cvc}
                                                        </div>
                                                    )}
                                                </div>

                                                <div class="col-sm-6 col-xs-12">
                                                    <label for="inputEmail">
                                                        NAME ON CARD
                                                        <span
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        >
                                                            *
                                                        </span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="full_name"
                                                        name="full_name"
                                                        className={classnames(
                                                            "form-control",
                                                            {
                                                                "is-invalid":
                                                                    errors.full_name,
                                                            }
                                                        )}
                                                        placeholder="Name on card"
                                                        onChange={this.onChange}
                                                        value={
                                                            this.state.full_name
                                                        }
                                                    />
                                                    {errors.full_name && (
                                                        <div
                                                            className="invalid-feedback"
                                                            style={{
                                                                display:
                                                                    "block",
                                                            }}
                                                        >
                                                            {errors.full_name}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        {/**Registration starts here */}
                                        <div class="form-label-group  mt-5">
                                            <div class="row">
                                                <div class="col-sm-12 col-xs-12">
                                                    <label>
                                                        {" "}
                                                        REGISTER INFORMATION
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-label-group">
                                            <div class="row">
                                                <div class="col-sm-12 col-xs-12">
                                                    <label for="inputEmail">
                                                        EMAIL
                                                        <span
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        >
                                                            *
                                                        </span>
                                                    </label>
                                                    <input
                                                        type="email"
                                                        id="email"
                                                        name="email"
                                                        className={classnames(
                                                            "form-control",
                                                            {
                                                                "is-invalid":
                                                                    errors.email,
                                                            }
                                                        )}
                                                        placeholder="Email"
                                                        onChange={this.onChange}
                                                        value={this.state.email}
                                                    />
                                                    {errors.email && (
                                                        <div
                                                            className="invalid-feedback"
                                                            style={{
                                                                display:
                                                                    "block",
                                                            }}
                                                        >
                                                            {errors.email}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-label-group">
                                            <div class="row">
                                                <div class="col-sm-6 col-xs-12">
                                                    <label for="inputEmail">
                                                        USERNAME
                                                        <span
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        >
                                                            *
                                                        </span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="user_name"
                                                        name="user_name"
                                                        className={classnames(
                                                            "form-control",
                                                            {
                                                                "is-invalid":
                                                                    errors.user_name,
                                                            }
                                                        )}
                                                        placeholder="User Name"
                                                        onChange={this.onChange}
                                                        value={
                                                            this.state.user_name
                                                        }
                                                    />
                                                    {errors.user_name && (
                                                        <div
                                                            className="invalid-feedback"
                                                            style={{
                                                                display:
                                                                    "block",
                                                            }}
                                                        >
                                                            {errors.user_name}
                                                        </div>
                                                    )}
                                                </div>

                                                <div class="col-sm-6 col-xs-12">
                                                    <label for="inputEmail">
                                                        PASSWORD
                                                        <span
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        >
                                                            *
                                                        </span>
                                                    </label>
                                                    <input
                                                        type="password"
                                                        id="Password"
                                                        className={classnames(
                                                            "form-control",
                                                            {
                                                                "is-invalid":
                                                                    errors.password,
                                                            }
                                                        )}
                                                        name="password"
                                                        placeholder="Password"
                                                        onChange={this.onChange}
                                                        value={
                                                            this.state.password
                                                        }
                                                    />
                                                    {errors.password && (
                                                        <div
                                                            className="invalid-feedback"
                                                            style={{
                                                                display:
                                                                    "block",
                                                            }}
                                                        >
                                                            {errors.password}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        <TextFieldOption
                                            label="Which questions do you plan on studying?"
                                            type="radio"
                                            name="question_type"
                                            checked1={
                                                this.state.question_type ===
                                                "Deck"
                                            }
                                            checked2={
                                                this.state.question_type ===
                                                "Engine"
                                            }
                                            value1="Deck"
                                            value2="Engine"
                                            option1="Deck"
                                            option2="Engine"
                                            onChange={this.onChange}
                                            error={errors.question_type}
                                            striks="*"
                                        />

                                        {RateContent}
                                        {/**Registration ends here */}
                                        <div class="form-label-group">
                                            <div class="row">
                                                <div class="col-sm-12 col-xs-12">
                                                    <button
                                                        class="btn btn-lg btn-primary btn-block mt-3"
                                                        type="submit"
                                                    >
                                                        PAY NOW $
                                                        {this.state.amount}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <p class="mt-4 mb-3 text-muted text-center">
                                    <button
                                        onClick={() =>
                                            this.toggleModal("modalDialog")
                                        }
                                        class=" btn-lg btn-link mt-3 demo4"
                                    >
                                        Have A Promo Code?
                                    </button>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

PaymentNew.propTypes = {
    ApplyCoupanCode: PropTypes.func.isRequired,
    getCurrentPrice: PropTypes.func.isRequired,
    paymentReg: PropTypes.func.isRequired,
    coupan: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    price: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
    auth: state.auth,
    coupan: state.coupan,
    price: state.price,
    errors: state.errors,
});
export default connect(mapStateToProps, {
    ApplyCoupanCode,
    getCurrentPrice,
    loadUser,
    paymentReg,
})(withRouter(PaymentNew));
