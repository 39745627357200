import React, { Component } from "react";
//import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Container from "../content/Container.jsx";
import SubscriberList from "./SubscriberList.jsx";
import Spinner from "../common/textfield/Spinner";
import { getSubscribers } from "../../../actions/subscriberActions.jsx";
import { Panel, PanelHeader, PanelBody } from "../common/panel/Panel.jsx";

class MainSubscriber extends Component {
    componentDidMount() {
        this.props.getSubscribers();
    }
    render() {
        const { subscribers, loading } = this.props.subscriber;

        let mainSubscriberContent;
        if (subscribers === null || loading) {
            mainSubscriberContent = <Spinner />;
        } else {
            //console.log(blogcats);
            if (subscribers.length > 0) {
                mainSubscriberContent = (
                    <SubscriberList subscribers={subscribers} />
                );
            } else {
                mainSubscriberContent = (
                    <div className="row">
                        <div className="col-12">
                            <Panel>
                                <PanelHeader>Subscribers Tables</PanelHeader>
                                <PanelBody>
                                    <div className="note note-secondary m-b-15">
                                        <div className="note-icon">
                                            <i className="fa fa-lightbulb"></i>
                                        </div>
                                        <div className="note-content text-right">
                                            <h4>
                                                <b>This Table Is Empty</b>
                                            </h4>
                                            <p>
                                                {" "}
                                                No data found to show here. This
                                                table is empty.{" "}
                                            </p>
                                        </div>
                                    </div>
                                </PanelBody>
                            </Panel>
                        </div>
                    </div>
                );
            }
        }
        return (
            <React.Fragment>
                <Container>
                    <div id="content" className="content ">
                        <ol className="breadcrumb float-xl-right">
                            <li className="breadcrumb-item">
                                <a href="/admin/dashboard">Dashboard</a>
                            </li>
                            <li className="breadcrumb-item active">
                                Subscribers Tables
                            </li>
                        </ol>
                        <h1 className="page-header">Subscribers Tables </h1>

                        {mainSubscriberContent}
                    </div>
                </Container>
            </React.Fragment>
        );
    }
}

MainSubscriber.propTypes = {
    getSubscribers: PropTypes.func.isRequired,
    subscriber: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    subscriber: state.subscriber,
});

export default connect(mapStateToProps, { getSubscribers })(MainSubscriber);
