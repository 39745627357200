import React, { Component } from "react";
import PropTypes from "prop-types";
import SweetAlert from "react-bootstrap-sweetalert";
import CommentList from "./CommentList.jsx";
import "suneditor/dist/css/suneditor.min.css";
import ReactQuill from "react-quill";

class Comments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapse: localStorage.getItem("collapse") === "1",
            sweetAlertInfo: false,
        };
        this.toggleSweetAlert = this.toggleSweetAlert.bind(this);
        this.routeChange = this.routeChange.bind(this);
        this.closeComment = this.closeComment.bind(this);
        this.openComment = this.openComment.bind(this);
    }

    toggleSweetAlert(name) {
        switch (name) {
            case "info":
                this.setState({ sweetAlertInfo: !this.state.sweetAlertInfo });
                break;

            default:
                break;
        }
    }

    routeChange() {
        let path = `/register`;
        this.props.history.push(path);
    }

    closeComment() {
        localStorage.setItem("collapse", "1");
        this.setState({ collapse: true });
    }

    openComment() {
        localStorage.removeItem("collapse");
        this.setState({ collapse: false });
    }

    render() {
        let CommentContent = <CommentList comments={this.props.comments} />;

        let collapseContent;
        let buttonContent;

        if (this.state.collapse) {
            collapseContent = "";

            buttonContent = (
                <button
                    onClick={this.openComment}
                    type="button"
                    className="cmnts-dts show-comments active"
                >
                    <i className="fa fa-align-justify"></i>
                </button>
            );
        } else {
            collapseContent = (
                <div id="demo" className="active collapse in">
                    <div className="col-md-12 col-sm-12 cmnt-history">
                        {CommentContent}

                        <div className="form-group adjust">
                            <div className="col-sm-12 col-xs-12">
                                <ReactQuill
                                    name="comment"
                                    style={{
                                        height: "200px",
                                        marginBottom: "20px",
                                    }}
                                />
                            </div>
                        </div>
                        <div className="form-group adjust">
                            <div className="col-sm-12 col-xs-12 ">
                                <button
                                    onClick={() =>
                                        this.toggleSweetAlert("info")
                                    }
                                    type="submit"
                                    className="btn btn-primary btn-sub m-r-5"
                                >
                                    Add comment
                                </button>
                                <button
                                    onClick={() =>
                                        this.toggleSweetAlert("info")
                                    }
                                    type="reset"
                                    className="btn btn-default btn-close"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            );

            buttonContent = (
                <button
                    onClick={this.closeComment}
                    type="button"
                    className="cmnts-dts show-comments active"
                >
                    <i className="fa fa-align-justify"></i>
                </button>
            );
        }
        return (
            <React.Fragment>
                {this.state.sweetAlertInfo && (
                    <SweetAlert
                        info
                        showCancel
                        confirmBtnText="Register"
                        confirmBtnBsStyle="info"
                        cancelBtnBsStyle="default"
                        title="Subscription Alerts"
                        onConfirm={this.routeChange}
                        onCancel={() => this.toggleSweetAlert("info")}
                    >
                        You have to be a premium user to comment on questions.
                    </SweetAlert>
                )}

                <div className="m-t-40">
                    <div className="q-number-wrapper for-btn">
                        <div className="col-md-6 for-only-q">
                            <span className="author-name cmt">
                                Comments{" "}
                                <span>{this.props.comments.length}</span>{" "}
                            </span>
                        </div>
                        <div className="col-md-6 text-right for-only-q">
                            {buttonContent}
                        </div>
                    </div>
                </div>

                <div className="clearfix"></div>
                <div className="col-sm-12 col-xs-12">{collapseContent}</div>
            </React.Fragment>
        );
    }
}

Comments.propTypes = {
    comments: PropTypes.array.isRequired,
};

export default Comments;
