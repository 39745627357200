import { GET_INVOICE, GET_INVOICES, INVOICE_LOADING } from "../actions/types";

const initialState = {
    invoices: [],
    invoice: null,
    loading: true,
    error: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case INVOICE_LOADING:
            return {
                ...state,
                loading: true,
            };

        case GET_INVOICES:
            return {
                ...state,
                invoices: action.payload,
                loading: false,
            };

        case GET_INVOICE:
            return {
                ...state,
                invoice: action.payload,
                loading: false,
            };

        default:
            return state;
    }
}
