import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "../../common/common.jsx";
import queryString from "qs";
import {
    getQuestion,
    submitQuestion,
    HideQuestion,
    BookmarkQuestion,
    getbook,
    getHide,
} from "../../../../actions/questionActions.jsx";
import {
    searchtotalQues,
    searchPreviousLink,
    searchNextLink,
} from "../../../../actions/searchActions.jsx";
import { loadUser, sendVeriEmail } from "../../../../actions/userActions";
import Button from "./Button";
import isEmpty from "../../../../validation/is-empty";
import Container from "../../content/Container.jsx";
import Comments from "./Comments.jsx";
import ModalImage from "react-modal-image";
//import Spinner from "../../common/textfield/Spinner";
import { Alert } from "reactstrap";
import ReactNotification from "react-notifications-component";
import { store } from "react-notifications-component";

class SearchQuestion extends Component {
    constructor() {
        super();
        this.state = {
            keyword: "",
            topic: "",
            num: "",
            area: "",
            mode: "",
            ZSUBCATEGORYNAME: "",
            ZNUMBER: "",
            ZQUESTION: "",
            ZANSWERONE: "",
            ZANSWERTWO: "",
            ZANSWERTHREE: "",
            ZANSWERFOUR: "",
            ZSLUG: "",
            image_type: "",
            total_ques: "",
            ZINBOOKCATEGORY: "",
            ZANSWER: "",
            bgcolora: "",
            bgcolorb: "",
            bgcolorc: "",
            bgcolord: "",
            peventa: "",
            peventb: "",
            peventc: "",
            peventd: "",
            correctanswer: 0,
            wronganswer: 0,
            percentage: 0,
            addnumber: 1,
            answeronly: false,
            shuffle: false,
            ZIMAGE: "",
            ZIMAGENAME: "",
        };
        this.onShuffle = this.onShuffle.bind(this);
        this.onUnShuffle = this.onUnShuffle.bind(this);
    }

    componentDidMount() {
        const queryStringValues = queryString.parse(
            this.props.location.search.slice(1)
        );

        // console.log(queryStringValues.type)
        this.props.searchNextLink(
            queryStringValues.keyword,
            queryStringValues.topic,
            queryStringValues.area,
            queryStringValues.num,
            queryStringValues.mode
        );
        this.props.searchPreviousLink(
            queryStringValues.keyword,
            queryStringValues.topic,
            queryStringValues.area,
            queryStringValues.num,
            queryStringValues.mode
        );

        this.props.getQuestion(queryStringValues.zid);
        this.props.searchtotalQues(
            queryStringValues.keyword,
            queryStringValues.topic,
            queryStringValues.area
        );

        this.setState({
            num: queryStringValues.num,
            mode: queryStringValues.mode,
            keyword: queryStringValues.keyword,
            topic: queryStringValues.topic,
            area: queryStringValues.area,
        });
    }

    componentWillReceiveProps(nextProps) {
        const queryStringValues = queryString.parse(
            this.props.location.search.slice(1)
        );
        const queryStringValuesn = queryString.parse(
            nextProps.location.search.slice(1)
        );
        if (queryStringValues.mode !== queryStringValuesn.mode) {
            this.setState({ mode: queryStringValuesn.mode });
            this.props.searchNextLink(
                queryStringValuesn.keyword,
                queryStringValuesn.topic,
                queryStringValuesn.area,
                queryStringValuesn.num,
                queryStringValuesn.mode
            );
            this.props.searchPreviousLink(
                queryStringValuesn.keyword,
                queryStringValuesn.topic,
                queryStringValuesn.area,
                queryStringValuesn.num,
                queryStringValuesn.mode
            );
        }
        // console.log(queryStringValuesn);
        if (queryStringValues.num !== queryStringValuesn.num) {
            // console.log(queryStringValuesn)
            this.props.getQuestion(queryStringValuesn.zid);
            this.props.searchNextLink(
                queryStringValuesn.keyword,
                queryStringValuesn.topic,
                queryStringValuesn.area,
                queryStringValuesn.num,
                queryStringValuesn.mode
            );
            this.props.searchPreviousLink(
                queryStringValuesn.keyword,
                queryStringValuesn.topic,
                queryStringValuesn.area,
                queryStringValuesn.num,
                queryStringValuesn.mode
            );
            this.props.searchtotalQues(
                queryStringValues.keyword,
                queryStringValues.topic,
                queryStringValues.area
            );

            this.setState({
                num: queryStringValuesn.num,
                mode: queryStringValuesn.mode,
                keyword: queryStringValuesn.keyword,
                topic: queryStringValuesn.topic,
                area: queryStringValuesn.area,
                bgcolora: "",
                bgcolorb: "",
                bgcolorc: "",
                bgcolord: "",
                peventa: "",
                peventb: "",
                peventc: "",
                peventd: "",
            });
        }

        if (nextProps.question.total) {
            this.setState({ total_ques: nextProps.question.total });
        }

        if (nextProps.question.bookmark) {
            const bookmark = nextProps.question.bookmark;
            bookmark.zmcq_id = !isEmpty(bookmark.zmcq_id)
                ? bookmark.zmcq_id
                : "";
            this.setState({
                book: bookmark.zmcq_id,
            });
        }

        if (nextProps.question.hide) {
            const hide = nextProps.question.hide;
            hide.zmcq_id = !isEmpty(hide.zmcq_id) ? hide.zmcq_id : "";
            this.setState({
                hide: hide.zmcq_id,
            });
        }

        if (nextProps.question.question) {
            const question = nextProps.question.question;

            question.ZSUBCATEGORYNAME = !isEmpty(question.ZSUBCATEGORYNAME)
                ? question.ZSUBCATEGORYNAME
                : "";
            question.ZNUMBER = !isEmpty(question.ZNUMBER)
                ? question.ZNUMBER
                : "";
            question.ZQUESTION = !isEmpty(question.ZQUESTION)
                ? question.ZQUESTION
                : "";
            question.ZANSWERONE = !isEmpty(question.ZANSWERONE)
                ? question.ZANSWERONE
                : "";
            question.ZANSWERTWO = !isEmpty(question.ZANSWERTWO)
                ? question.ZANSWERTWO
                : "";
            question.ZANSWERTHREE = !isEmpty(question.ZANSWERTHREE)
                ? question.ZANSWERTHREE
                : "";
            question.ZANSWERFOUR = !isEmpty(question.ZANSWERFOUR)
                ? question.ZANSWERFOUR
                : "";
            question.ZINBOOKCATEGORY = !isEmpty(question.ZINBOOKCATEGORY)
                ? question.ZINBOOKCATEGORY
                : "";
            question.ZANSWER = !isEmpty(question.ZANSWER)
                ? question.ZANSWER
                : "";
            question.ZSLUG = !isEmpty(question.ZSLUG) ? question.ZSLUG : "";
            question.ZIMAGE = !isEmpty(question.ZIMAGE) ? question.ZIMAGE : "";
            question.ZIMAGENAME = !isEmpty(question.ZIMAGENAME)
                ? question.ZIMAGENAME
                : "";
            question._id = !isEmpty(question._id) ? question._id : "";

            // Set component fields state for blog
            this.setState({
                ZSUBCATEGORYNAME: question.ZSUBCATEGORYNAME,
                ZNUMBER: question.ZNUMBER,
                ZQUESTION: question.ZQUESTION,
                ZANSWERONE: question.ZANSWERONE,
                ZANSWERTWO: question.ZANSWERTWO,
                ZANSWERTHREE: question.ZANSWERTHREE,
                ZANSWERFOUR: question.ZANSWERFOUR,
                ZINBOOKCATEGORY: question.ZINBOOKCATEGORY,
                ZANSWER: question.ZANSWER,
                ZSLUG: question.ZSLUG,
                ZIMAGE: question.ZIMAGE,
                ZIMAGENAME: question.ZIMAGENAME,
                _id: question._id,
            });
        }

        if (nextProps.auth.user) {
            const user = nextProps.auth.user;
            user._id = !isEmpty(user._id) ? user._id : "";
            user.verification_status = !isEmpty(user.verification_status)
                ? user.verification_status
                : "";
            this.setState({
                verification_status: user.verification_status,
                user_id: user._id,
            });
        }
    }

    onEmailClick(id) {
        this.props.sendVeriEmail(id);
        store.addNotification({
            title: "Success!",
            message:
                " An email is sent to your registered email id. Click on the link to verify.",
            type: "success",
            insert: "top",
            container: "top-center",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: { duration: 3000 },
            dismissable: { click: true },
            // content: notificationContent
        });
    }

    onHideClick(id) {
        this.props.HideQuestion(id);
        let path = `/user/search/watch?mode=${this.state.mode}&keyword=${this.state.keyword}&topic=${this.state.topic}&area=${this.state.area}&num=${this.state.num}&zid=${this.state._id}`;
        this.props.history.push(path);
    }

    onBookClick(id) {
        //  console.log(id)
        this.props.BookmarkQuestion(id);
        let path = `/user/search/watch?mode=${this.state.mode}&keyword=${this.state.keyword}&topic=${this.state.topic}&area=${this.state.area}&num=${this.state.num}&zid=${this.state._id}`;
        this.props.history.push(path);
    }

    onAnswerClick(id) {
        const quesdata = {
            answer_type: id,
            _id: this.state._id,
        };
        this.props.submitQuestion(quesdata, this.props.history);
        let correct = this.state.correctanswer + this.state.addnumber;
        let wrong = this.state.wronganswer + this.state.addnumber;
        let adddata =
            this.state.wronganswer +
            this.state.correctanswer +
            this.state.addnumber;
        if (id === "a") {
            if (this.state.ZANSWER === "a") {
                let percent = Math.round((correct / adddata) * 100);
                this.setState({
                    bgcolora: "Chartreuse",
                    correctanswer: correct,
                    percentage: percent,
                });
            } else if (this.state.ZANSWER === "b") {
                let percent = Math.round(
                    (this.state.correctanswer / adddata) * 100
                );
                this.setState({
                    bgcolora: "red",
                    bgcolorb: "Chartreuse",
                    wronganswer: wrong,
                    percentage: percent,
                });
            } else if (this.state.ZANSWER === "c") {
                let percent = Math.round(
                    (this.state.correctanswer / adddata) * 100
                );
                this.setState({
                    bgcolora: "red",
                    bgcolorc: "Chartreuse",
                    wronganswer: wrong,
                    percentage: percent,
                });
            } else if (this.state.ZANSWER === "d") {
                let percent = Math.round(
                    (this.state.correctanswer / adddata) * 100
                );
                this.setState({
                    bgcolora: "red",
                    bgcolord: "Chartreuse",
                    wronganswer: wrong,
                    percentage: percent,
                });
            }
        } else if (id === "b") {
            if (this.state.ZANSWER === "a") {
                let percent = Math.round(
                    (this.state.correctanswer / adddata) * 100
                );
                this.setState({
                    wronganswer: wrong,
                    bgcolora: "Chartreuse",
                    bgcolorb: "red",
                    percentage: percent,
                });
            } else if (this.state.ZANSWER === "b") {
                let percent = Math.round((correct / adddata) * 100);
                this.setState({
                    bgcolorb: "Chartreuse",
                    correctanswer: correct,
                    percentage: percent,
                });
            } else if (this.state.ZANSWER === "c") {
                let percent = Math.round(
                    (this.state.correctanswer / adddata) * 100
                );
                this.setState({
                    wronganswer: wrong,
                    bgcolorb: "red",
                    bgcolorc: "Chartreuse",
                    percentage: percent,
                });
            } else if (this.state.ZANSWER === "d") {
                let percent = Math.round(
                    (this.state.correctanswer / adddata) * 100
                );
                this.setState({
                    wronganswer: wrong,
                    bgcolorb: "red",
                    bgcolord: "Chartreuse",
                    percentage: percent,
                });
            }
        } else if (id === "c") {
            if (this.state.ZANSWER === "a") {
                let percent = Math.round(
                    (this.state.correctanswer / adddata) * 100
                );
                this.setState({
                    wronganswer: wrong,
                    bgcolora: "Chartreuse",
                    bgcolorc: "red",
                    percentage: percent,
                });
            } else if (this.state.ZANSWER === "b") {
                let percent = Math.round(
                    (this.state.correctanswer / adddata) * 100
                );
                this.setState({
                    wronganswer: wrong,
                    bgcolorc: "red",
                    bgcolorb: "Chartreuse",
                    percentage: percent,
                });
            } else if (this.state.ZANSWER === "c") {
                let percent = Math.round((correct / adddata) * 100);
                this.setState({
                    correctanswer: correct,
                    bgcolorc: "Chartreuse",
                    percentage: percent,
                });
            } else if (this.state.ZANSWER === "d") {
                let percent = Math.round(
                    (this.state.correctanswer / adddata) * 100
                );
                this.setState({
                    wronganswer: wrong,
                    bgcolorc: "red",
                    bgcolord: "Chartreuse",
                    percentage: percent,
                });
            }
        } else if (id === "d") {
            if (this.state.ZANSWER === "a") {
                let percent = Math.round(
                    (this.state.correctanswer / adddata) * 100
                );
                this.setState({
                    wronganswer: wrong,
                    bgcolord: "red",
                    bgcolora: "Chartreuse",
                    percentage: percent,
                });
            } else if (this.state.ZANSWER === "b") {
                let percent = Math.round(
                    (this.state.correctanswer / adddata) * 100
                );
                this.setState({
                    wronganswer: wrong,
                    bgcolord: "red",
                    bgcolorb: "Chartreuse",
                    percentage: percent,
                });
            } else if (this.state.ZANSWER === "c") {
                let percent = Math.round(
                    (this.state.correctanswer / adddata) * 100
                );
                this.setState({
                    wronganswer: wrong,
                    bgcolord: "red",
                    bgcolorc: "Chartreuse",
                    percentage: percent,
                });
            } else if (this.state.ZANSWER === "d") {
                let percent = Math.round((correct / adddata) * 100);
                this.setState({
                    correctanswer: correct,
                    bgcolord: "Chartreuse",
                    percentage: percent,
                });
            }
        }
        this.setState({
            peventa: "none",
            peventb: "none",
            peventc: "none",
            peventd: "none",
        });
        localStorage.setItem("correctanswer", this.state.correctanswer);
        localStorage.setItem("wronganswer", this.state.wronganswer);
        localStorage.setItem("percentage", this.state.percentage);
        if (localStorage.getItem("user") === "Inactive") {
            if (localStorage.getItem("time") === null) {
                localStorage.setItem("time", "1");
            } else if (localStorage.getItem("time") === "1") {
                localStorage.setItem("time", "2");
            } else if (localStorage.getItem("time") === "2") {
                localStorage.setItem("time", "3");
            } else if (localStorage.getItem("time") === "3") {
                localStorage.setItem("time", "4");
            } else if (localStorage.getItem("time") === "4") {
                localStorage.setItem("time", "5");
            } else if (localStorage.getItem("time") === "5") {
                localStorage.setItem("time", "6");
            } else if (localStorage.getItem("time") === "6") {
                localStorage.setItem("time", "7");
            } else if (localStorage.getItem("time") === "7") {
                localStorage.setItem("time", "8");
            } else if (localStorage.getItem("time") === "8") {
                localStorage.setItem("time", "9");
            } else if (localStorage.getItem("time") === "9") {
                localStorage.setItem("time", "10");
            } else if (localStorage.getItem("time") === "10") {
                localStorage.setItem("time", "11");
            } else if (localStorage.getItem("time") === "11") {
                localStorage.setItem("time", "12");
            } else if (localStorage.getItem("time") === "12") {
                localStorage.setItem("time", "13");
            } else if (localStorage.getItem("time") === "13") {
                localStorage.setItem("time", "14");
            } else if (localStorage.getItem("time") === "14") {
                localStorage.setItem("time", "15");
            } else if (localStorage.getItem("time") === "15") {
                localStorage.setItem("time", "16");
            } else if (localStorage.getItem("time") === "16") {
                localStorage.setItem("time", "17");
            } else if (localStorage.getItem("time") === "17") {
                localStorage.setItem("time", "18");
            } else if (localStorage.getItem("time") === "18") {
                localStorage.setItem("time", "19");
            } else if (localStorage.getItem("time") === "19") {
                localStorage.setItem("time", "20");
            } else if (localStorage.getItem("time") === "20") {
                localStorage.setItem("time", "21");
            } else if (localStorage.getItem("time") === "21") {
                localStorage.setItem("time", "22");
            } else if (localStorage.getItem("time") === "22") {
                localStorage.setItem("time", "23");
            } else if (localStorage.getItem("time") === "23") {
                localStorage.setItem("time", "24");
            } else if (localStorage.getItem("time") === "24") {
                localStorage.setItem("time", "25");
            } else if (localStorage.getItem("time") === "25") {
                localStorage.setItem("time", "26");
            } else if (localStorage.getItem("time") === "26") {
                localStorage.setItem("time", "27");
            } else if (localStorage.getItem("time") === "27") {
                localStorage.setItem("time", "28");
            } else if (localStorage.getItem("time") === "28") {
                localStorage.setItem("time", "29");
            } else if (localStorage.getItem("time") === "29") {
                localStorage.setItem("time", "30");
            } else if (localStorage.getItem("time") === "30") {
                localStorage.setItem("time", "31");
            } else if (localStorage.getItem("time") === "31") {
                localStorage.setItem("time", "32");
            } else if (localStorage.getItem("time") === "32") {
                localStorage.setItem("time", "33");
            } else if (localStorage.getItem("time") === "33") {
                localStorage.setItem("time", "34");
            } else if (localStorage.getItem("time") === "34") {
                localStorage.setItem("time", "35");
            } else if (localStorage.getItem("time") === "35") {
                localStorage.setItem("time", "36");
            } else if (localStorage.getItem("time") === "36") {
                localStorage.setItem("time", "37");
            } else if (localStorage.getItem("time") === "37") {
                localStorage.setItem("time", "38");
            } else if (localStorage.getItem("time") === "38") {
                localStorage.setItem("time", "39");
            } else if (localStorage.getItem("time") === "39") {
                localStorage.setItem("time", "40");
            } else if (localStorage.getItem("time") === "40") {
                localStorage.setItem("time", "41");
            } else if (localStorage.getItem("time") === "41") {
                localStorage.setItem("time", "42");
            } else if (localStorage.getItem("time") === "42") {
                localStorage.setItem("time", "43");
            } else if (localStorage.getItem("time") === "43") {
                localStorage.setItem("time", "44");
            } else if (localStorage.getItem("time") === "44") {
                localStorage.setItem("time", "45");
            } else if (localStorage.getItem("time") === "45") {
                localStorage.setItem("time", "46");
            } else if (localStorage.getItem("time") === "46") {
                localStorage.setItem("time", "47");
            } else if (localStorage.getItem("time") === "47") {
                localStorage.setItem("time", "48");
            } else if (localStorage.getItem("time") === "48") {
                localStorage.setItem("time", "49");
            } else if (localStorage.getItem("time") === "49") {
                localStorage.setItem("time", "50");
            }
            console.log(localStorage.getItem("time"));
        }
    }

    onShuffle() {
        this.setState({ shuffle: true });
        let path = `/user/search/watch?mode=shuffle&keyword=${this.state.keyword}&topic=${this.state.topic}&area=${this.state.area}&num=${this.state.num}&zid=${this.state._id}`;
        this.props.history.push(path);
        //window.location.reload(false);
    }

    onUnShuffle() {
        this.setState({ shuffle: false });
        let path = `/user/search/watch?mode=normal&keyword=${this.state.keyword}&topic=${this.state.topic}&area=${this.state.area}&num=${this.state.num}&zid=${this.state._id}`;
        this.props.history.push(path);
        // window.location.reload(false);
    }

    render() {
        const { next, previous, loading } = this.props.question;
        let mainButton;

        //console.log(blogcats);
        if (previous === null) {
            mainButton = "";
        } else {
            mainButton = (
                <Button
                    next={next}
                    previous={previous}
                    keyword={this.state.keyword}
                    topic={this.state.topic}
                    num={this.state.num}
                    mode={this.state.mode}
                    area={this.state.area}
                    total_ques={this.state.total_ques}
                />
            );
        }

        let bookCategoryName;
        if (this.state.ZINBOOKCATEGORY === "1") {
            bookCategoryName = "Engine";
        } else {
            bookCategoryName = "Deck";
        }

        //Answer content start here
        let answeronlyContent;
        let answeronlyMainContent;
        if (this.state.answeronly) {
            answeronlyContent = (
                <button
                    onClick={() => {
                        this.setState((prevState) => ({ answeronly: false }));
                    }}
                    className="btn btn-link"
                >
                    <i className="fa fa-angle-double-right"></i> Normal
                </button>
            );

            answeronlyMainContent = (
                <div id="main" className="row">
                    <div className="col-sm-12">
                        <div className="form-group radio_input q-hover">
                            <label
                                className="text-s"
                                id="a"
                                style={{
                                    backgroundColor:
                                        this.state.ZANSWER === "a"
                                            ? "Chartreuse"
                                            : "",
                                    pointerEvents: "none",
                                }}
                            >
                                <b>A) </b>{" "}
                                <span className="f-c">
                                    {this.state.ZANSWERONE}
                                </span>
                            </label>
                        </div>
                        <div className="form-group radio_input q-hover">
                            <label
                                className="text-s"
                                id="b"
                                style={{
                                    backgroundColor:
                                        this.state.ZANSWER === "b"
                                            ? "Chartreuse"
                                            : "",
                                    pointerEvents: "none",
                                }}
                            >
                                <b>B) </b>{" "}
                                <span className="f-c">
                                    {this.state.ZANSWERTWO}
                                </span>
                            </label>
                        </div>
                        <div className="form-group radio_input q-hover">
                            <label
                                className="text-s"
                                id="c"
                                style={{
                                    backgroundColor:
                                        this.state.ZANSWER === "c"
                                            ? "Chartreuse"
                                            : "",
                                    pointerEvents: "none",
                                }}
                            >
                                <b>C) </b>{" "}
                                <span className="f-c">
                                    {this.state.ZANSWERTHREE}
                                </span>
                            </label>
                        </div>
                        <div className="form-group radio_input q-hover">
                            <label
                                className="text-s"
                                id="d"
                                style={{
                                    backgroundColor:
                                        this.state.ZANSWER === "d"
                                            ? "Chartreuse"
                                            : "",
                                    pointerEvents: "none",
                                }}
                            >
                                <b>D) </b>{" "}
                                <span className="f-c">
                                    {this.state.ZANSWERFOUR}
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
            );
        } else {
            answeronlyContent = (
                <button
                    onClick={() => {
                        this.setState((prevState) => ({ answeronly: true }));
                    }}
                    className="btn btn-link"
                >
                    <i className="fa fa-angle-double-right"></i> Answers Only
                </button>
            );

            answeronlyMainContent = (
                <div id="main" className="row">
                    <div className="col-sm-12">
                        <div className="form-group radio_input q-hover">
                            <label
                                className="text-s"
                                id="a"
                                style={{
                                    backgroundColor: this.state.bgcolora,
                                    pointerEvents: this.state.peventa,
                                }}
                                onClick={this.onAnswerClick.bind(this, "a")}
                            >
                                <b>A) </b>{" "}
                                <span className="f-c">
                                    {this.state.ZANSWERONE}
                                </span>
                            </label>
                        </div>
                        <div className="form-group radio_input q-hover">
                            <label
                                className="text-s"
                                id="b"
                                style={{
                                    backgroundColor: this.state.bgcolorb,
                                    pointerEvents: this.state.peventb,
                                }}
                                onClick={this.onAnswerClick.bind(this, "b")}
                            >
                                <b>B) </b>{" "}
                                <span className="f-c">
                                    {this.state.ZANSWERTWO}
                                </span>
                            </label>
                        </div>
                        <div className="form-group radio_input q-hover">
                            <label
                                className="text-s"
                                id="c"
                                style={{
                                    backgroundColor: this.state.bgcolorc,
                                    pointerEvents: this.state.peventc,
                                }}
                                onClick={this.onAnswerClick.bind(
                                    this,
                                    "c",
                                    this.state.correctanswer,
                                    this.state.wronganswer,
                                    this.state.percentage
                                )}
                            >
                                <b>C) </b>{" "}
                                <span className="f-c">
                                    {this.state.ZANSWERTHREE}
                                </span>
                            </label>
                        </div>
                        <div className="form-group radio_input q-hover">
                            <label
                                className="text-s"
                                id="d"
                                style={{
                                    backgroundColor: this.state.bgcolord,
                                    pointerEvents: this.state.peventd,
                                }}
                                onClick={this.onAnswerClick.bind(this, "d")}
                            >
                                <b>D) </b>{" "}
                                <span className="f-c">
                                    {this.state.ZANSWERFOUR}
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
            );
        }

        //Shuffle content start here
        let shuffleContent;
        if (this.state.shuffle) {
            shuffleContent = (
                <button onClick={this.onUnShuffle} className="btn btn-link">
                    <i className="fa fa-angle-double-right"></i> Un-Shuffle
                </button>
            );
        } else {
            shuffleContent = (
                <button onClick={this.onShuffle} className="btn btn-link">
                    <i className="fa fa-angle-double-right"></i> Shuffle
                </button>
            );
        }

        //Email Verification
        let EmailVerificationContent;
        if (this.state.verification_status === "No") {
            EmailVerificationContent = (
                <Alert
                    color="danger"
                    isOpen={this.state.visible}
                    toggle={this.onDismiss}
                >
                    Your Email verification is pending!
                    <Link
                        to="#"
                        onClick={this.onEmailClick.bind(
                            this,
                            this.state.user_id
                        )}
                        className="alert-link"
                    >
                        {" "}
                        resend verification link
                    </Link>
                    .
                </Alert>
            );
        }

        let commentContent;
        commentContent = <Comments id={this.state._id} />;

        let mainContent;
        if (loading) {
            mainContent = "";
            // mainContent = <Spinner/>;
        } else {
            mainContent = (
                <div className="dashboard">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 col-xs-12"></div>
                        </div>
                        <div className="post-list" id="postList">
                            <div className="row">
                                <div className="col-sm-12 col-xs-12">
                                    <div className="dashright dass-wraper">
                                        <div className="row">
                                            <div className="col-md-8 padd">
                                                <div className="bomb-wrapper">
                                                    <div className="col-md-6 for-logo">
                                                        {/*<!--	<figure><img src="" alt="" width="250"></figure> -->*/}
                                                    </div>
                                                    <div className="col-md-6 text-right for-bomb-icon">
                                                        {this.state.hide ===
                                                        this.state._id ? (
                                                            <span>
                                                                <img
                                                                    src="https://seatrials-bucket.s3.us-east-2.amazonaws.com/main/icons8-explosive-filled-100.png"
                                                                    alt="un-book"
                                                                ></img>
                                                            </span>
                                                        ) : (
                                                            <span
                                                                onClick={this.onHideClick.bind(
                                                                    this,
                                                                    this.state
                                                                        ._id
                                                                )}
                                                            >
                                                                <img
                                                                    src="https://seatrials-bucket.s3.us-east-2.amazonaws.com/main/icons8-explosive-100.png"
                                                                    alt="explosive"
                                                                />
                                                            </span>
                                                        )}

                                                        {this.state.book ===
                                                        this.state._id ? (
                                                            <span>
                                                                <img
                                                                    src="https://seatrials-bucket.s3.us-east-2.amazonaws.com/main/icons8-bookmark-filled-100.png"
                                                                    alt="un-book"
                                                                ></img>
                                                            </span>
                                                        ) : (
                                                            <span
                                                                onClick={this.onBookClick.bind(
                                                                    this,
                                                                    this.state
                                                                        ._id
                                                                )}
                                                            >
                                                                <img
                                                                    src="https://seatrials-bucket.s3.us-east-2.amazonaws.com/main/icons8-bookmark-100.png"
                                                                    alt="bookmark"
                                                                />{" "}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div
                                                    id="wizard_container"
                                                    className="wizard"
                                                    noValidate="novalidate"
                                                >
                                                    <div className="q-number-wrapper">
                                                        <div className="col-md-6 for-only-q">
                                                            <span
                                                                title=""
                                                                className="user-badge"
                                                                style={{
                                                                    backgroundcolor:
                                                                        "#04aad4",
                                                                }}
                                                            >
                                                                {
                                                                    bookCategoryName
                                                                }
                                                            </span>
                                                            <span className="author-name">
                                                                {
                                                                    this.state
                                                                        .ZSUBCATEGORYNAME
                                                                }{" "}
                                                            </span>
                                                        </div>

                                                        <div className="col-md-6 text-right for-only-q">
                                                            <span
                                                                className="num-hov"
                                                                onClick={() =>
                                                                    this.toggleModal(
                                                                        "modalDialog"
                                                                    )
                                                                }
                                                            >
                                                                {" "}
                                                                <strong>
                                                                    NUMBER: #
                                                                    {
                                                                        this
                                                                            .state
                                                                            .num
                                                                    }
                                                                    /
                                                                    {
                                                                        this
                                                                            .state
                                                                            .total_ques
                                                                    }
                                                                </strong>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {/*<!-- /top-wizard -->*/}
                                                    <form
                                                        name="example-1"
                                                        id="wrapped"
                                                        method="POST"
                                                        action="questionare_send.php"
                                                        className="wizard-form"
                                                    >
                                                        <div
                                                            id="middle-wizard"
                                                            className="wizard-branch wizard-wrapper for-qs"
                                                        >
                                                            <div
                                                                className="step wizard-step current"
                                                                style={{
                                                                    display:
                                                                        "block",
                                                                }}
                                                            >
                                                                <p className="main_question wizard-header">
                                                                    <strong>
                                                                        {
                                                                            this
                                                                                .state
                                                                                .ZNUMBER
                                                                        }
                                                                    </strong>
                                                                </p>
                                                                <h3 className="main_question wizard-header q-text">
                                                                    <strong>
                                                                        {
                                                                            this
                                                                                .state
                                                                                .ZQUESTION
                                                                        }
                                                                    </strong>
                                                                </h3>

                                                                {
                                                                    answeronlyMainContent
                                                                }

                                                                {/*<!-- /row -->*/}
                                                            </div>
                                                            {/*<!-- /step -->*/}
                                                        </div>
                                                    </form>
                                                    <div className="q-number-wrapper for-btn for-top-btn">
                                                        <div
                                                            id="main-score"
                                                            className="col-md-7 text-left for-only-q"
                                                        >
                                                            <ul
                                                                className="question-statistic and-dsc"
                                                                id="question"
                                                            >
                                                                <li className="s-text">
                                                                    <p className="question-views clr">
                                                                        Correct:{" "}
                                                                        {
                                                                            this
                                                                                .state
                                                                                .correctanswer
                                                                        }
                                                                    </p>
                                                                </li>

                                                                <li className="s-text s-text-1">
                                                                    <p
                                                                        itemProp="answerCount"
                                                                        className="question-answers question-views clr"
                                                                    >
                                                                        Wrong:{" "}
                                                                        {
                                                                            this
                                                                                .state
                                                                                .wronganswer
                                                                        }{" "}
                                                                    </p>
                                                                </li>

                                                                <li className="s-text s-text-2">
                                                                    <p
                                                                        itemProp="upvoteCount"
                                                                        className="question-votes question-views clr"
                                                                    >
                                                                        Percentage:{" "}
                                                                        {
                                                                            this
                                                                                .state
                                                                                .percentage
                                                                        }
                                                                        %
                                                                    </p>
                                                                </li>
                                                            </ul>
                                                        </div>

                                                        {mainButton}
                                                    </div>
                                                </div>
                                                {commentContent}
                                            </div>
                                            {/*<!-- left col-end-->*/}

                                            <div className="col-md-4">
                                                <div className="dashleft">
                                                    <h3>STUDY MODE</h3>
                                                    <ul>
                                                        <li>
                                                            {answeronlyContent}
                                                        </li>

                                                        <li>
                                                            {shuffleContent}
                                                        </li>
                                                    </ul>
                                                    <h3>OPTIONS</h3>
                                                    <ul>
                                                        <li>
                                                            <button
                                                                onClick={() => {
                                                                    this.setState(
                                                                        (
                                                                            prevState
                                                                        ) => ({
                                                                            correctanswer: 0,
                                                                            wronganswer: 0,
                                                                            percentage: 0,
                                                                        })
                                                                    );
                                                                }}
                                                                className="btn btn-link"
                                                            >
                                                                <i className="fa fa-angle-double-right"></i>{" "}
                                                                Clear Score
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div>
                                                {/*{ImageContent}*/}
                                                <div id="right-form">
                                                    <figure>
                                                        {this.state.ZIMAGE ? (
                                                            <ModalImage
                                                                small={
                                                                    this.state
                                                                        .ZIMAGE
                                                                }
                                                                large={
                                                                    this.state
                                                                        .ZIMAGE
                                                                }
                                                                alt={
                                                                    this.state
                                                                        .ZIMAGENAME
                                                                }
                                                            />
                                                        ) : (
                                                            <img
                                                                id="myImg"
                                                                src="https://seatrials-bucket.s3.us-east-2.amazonaws.com/main/q-logo.png"
                                                                alt="logo-q"
                                                            />
                                                        )}
                                                    </figure>
                                                </div>
                                                {/*<!-- right col-end-->*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <React.Fragment>
                <ReactNotification />
                <Container>
                    <div className="content">
                        {EmailVerificationContent}

                        {mainContent}
                    </div>
                </Container>
            </React.Fragment>
        );
    }
}

SearchQuestion.propTypes = {
    submitQuestion: PropTypes.func.isRequired,
    searchNextLink: PropTypes.func.isRequired,
    searchPreviousLink: PropTypes.func.isRequired,
    getQuestion: PropTypes.func.isRequired,
    loadUser: PropTypes.func.isRequired,
    HideQuestion: PropTypes.func.isRequired,
    BookmarkQuestion: PropTypes.func.isRequired,
    searchtotalQues: PropTypes.func.isRequired,
    getbook: PropTypes.func.isRequired,
    getHide: PropTypes.func.isRequired,
    sendVeriEmail: PropTypes.func.isRequired,
    question: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    question: state.question,
    auth: state.auth,
});

export default connect(mapStateToProps, {
    searchtotalQues,
    searchPreviousLink,
    searchNextLink,
    getQuestion,
    submitQuestion,
    loadUser,
    sendVeriEmail,
    HideQuestion,
    BookmarkQuestion,
    getbook,
    getHide,
})(withRouter(SearchQuestion));
