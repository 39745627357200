import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import EditComment from "../../components/admin/comments/EditComment";
import MainUserList from "../../components/admin/comments/MainUserList";
import AdminRoute from "./AdminRoute";

const CommentRoutes = () => {
    let { path } = useRouteMatch();

    return (
        <Switch>
            <AdminRoute
                exact
                path={`${path}/comment-list/:Id`}
                component={MainUserList}
            />
            <AdminRoute
                exact
                path={`${path}/edit-comment/:Id`}
                component={EditComment}
            />
        </Switch>
    );
};

export default CommentRoutes;
