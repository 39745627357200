import React, { Component } from "react";
//import TopicSubList from "./TopicSubList.jsx";

class TopicList extends Component {
    render() {
        const mainList = this.props.topics.map((topi) => (
            <optgroup key={topi._id.ZBOOKNAME} label={topi._id.ZBOOKNAME}>
                <option
                    className="optgroupp"
                    key={topi._id.ZBOOKNAME}
                    value={`ZINBOOK-${topi._id.ZINBOOK}`}
                >
                    All {topi._id.ZBOOKNAME}
                </option>

                {topi.cat.map((cat) => (
                    <React.Fragment key={cat.ZINCATEGORY}>
                        {cat.ZINCATEGORY === "0" ? (
                            ""
                        ) : (
                            <option
                                className="optgroup"
                                key={cat.ZICATEGORY}
                                value={`ZINCATEGORY-${topi._id.ZINBOOK}-${cat.ZINCATEGORY}`}
                            >
                                All {cat.ZCATEGORYNAME}
                            </option>
                        )}

                        {cat.subcat.map((sub) => (
                            <React.Fragment key={sub.ZINSUBCATEGORY}>
                                {sub.ZINSUBCATEGORY === "0" ? (
                                    ""
                                ) : (
                                    <option
                                        key={sub.ZINSUBCATEGORY}
                                        value={`ZINSUBCATEGORY-${topi._id.ZINBOOK}-${cat.ZINCATEGORY}-${sub.ZINSUBCATEGORY}`}
                                    >
                                        {sub.ZSUBCATEGORYNAME}
                                    </option>
                                )}
                            </React.Fragment>
                        ))}
                    </React.Fragment>
                ))}
            </optgroup>
        ));
        return mainList;
    }
}
export default TopicList;
