import axios from "axios";

const setAuthToken1 = (token1) => {
    if (token1) {
        axios.defaults.headers.common["x-auth-token"] = token1;
    } else {
        delete axios.defaults.headers.common["x-auth-token"];
    }
};

export default setAuthToken1;
