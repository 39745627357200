import {
    V1_GET_QUESTION,
    V1_QUESTION_LOADING,
    V1_GET_BOOKMARK,
    V1_GET_HIDE,
    V1_APPEND_COMMENT,
    V1_DELETE_COMMENT,
} from "../../actions/v1/types";

const initialState = {
    hide: null,
    bookmark: null,
    question: null,
    meta: null,
    previous: [],
    next: [],
    deck: null,
    num: null,
    deck_books: [],
    deck_cats: [],
    deck_subcats: [],
    engine: null,
    engine_books: [],
    engine_cats: [],
    engine_subcats: [],
    deck_navs: [],
    engine_navs: [],
    total: null,
    loading: true,
    error: {},
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case V1_QUESTION_LOADING:
            return {
                ...state,
                loading: true,
            };

        case V1_GET_QUESTION:
            return {
                ...state,
                question: payload.data,
                meta: payload.meta,
                loading: false,
            };

        case V1_GET_BOOKMARK: {
            return {
                ...state,
                question: {
                    ...state.question,
                    bookmark: payload,
                },
                loading: false,
            };
        }

        case V1_GET_HIDE: {
            return {
                ...state,
                hide: payload,
                loading: false,
            };
        }

        case V1_APPEND_COMMENT:
            return {
                ...state,
                question: {
                    ...state.question,
                    comments: [...state.question.comments, action.payload],
                },
            };

        case V1_DELETE_COMMENT:
            return {
                ...state,
                question: {
                    ...state.question,
                    comments: state.question.comments.filter(
                        (comment) => comment._id !== action.payload
                    ),
                },
            };
        default:
            return state;
    }
}
