import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "../common/common.jsx";
import classnames from "classnames";
import Header from "../common/Header";
import Footer from "../common/Footer";
import ReactNotification from "react-notifications-component";
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { contactPost } from "../../../actions/authActions.jsx";

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            subject: "",
            message: "",
            errors: {},
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({ errors: nextProps.errors });
        }
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    onSubmit(e) {
        e.preventDefault();
        const contactData = {
            name: this.state.name,
            email: this.state.email,
            subject: this.state.subject,
            message: this.state.message,
        };
        this.props.contactPost(contactData, this.props.history);
        this.setState({ name: "", email: "", subject: "", message: "" });
        store.addNotification({
            title: "Success",
            message: " Contact Form Submitted successfully",
            type: "success",
            insert: "top",
            container: "top-center",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: { duration: 3000 },
            dismissable: { click: true },
            // content: notificationContent
        });
    }
    render() {
        const { errors } = this.state;
        return (
            <React.Fragment>
                <Header />
                <ReactNotification />
                {/*<!-- Breadcrumb -->*/}
                <nav className="breadcrumb">
                    <div className="container">
                        <Link className="breadcrumb-item" to="/">
                            Home
                        </Link>
                        <span className="breadcrumb-item active">Contact</span>
                    </div>
                </nav>
                {/*<!-- Breadcrumb End -->*/}

                {/*<!-- Contac Section -->*/}
                <div className="contactbg">
                    <div className="container">
                        <div className="row">
                            <form
                                className="form-contact"
                                data-effect="slide-top"
                                onSubmit={this.onSubmit}
                            >
                                <div className="text-center mb-4">
                                    <h3>
                                        Feel free to contact SeaTrials.net with
                                        any questions, comments, suggestions, or
                                        concerns. We look forward to hearing
                                        from you!{" "}
                                        <span>
                                            <Link to="mailto:support@seatrials.net">
                                                support@seatrials.net
                                            </Link>
                                        </span>{" "}
                                    </h3>
                                </div>

                                <center>
                                    <div id="result"></div>
                                </center>
                                <span
                                    id="success_message"
                                    className="text-success"
                                ></span>
                                <div className="form-label-group mt-5 mb-5">
                                    <div className="row">
                                        <div className="col-sm-6 col-xs-12">
                                            <input
                                                type="text"
                                                className={classnames(
                                                    "form-control",
                                                    {
                                                        "is-invalid":
                                                            errors.name,
                                                    }
                                                )}
                                                placeholder="Full Name"
                                                name="name"
                                                id="name"
                                                value={this.state.name}
                                                onChange={this.onChange}
                                                autofocus=""
                                            />
                                            {errors.name && (
                                                <div
                                                    className="invalid-feedback"
                                                    style={{
                                                        display: "block",
                                                    }}
                                                >
                                                    {errors.name}
                                                </div>
                                            )}
                                        </div>

                                        <div className="col-sm-6 col-xs-12">
                                            <input
                                                type="email"
                                                className={classnames(
                                                    "form-control",
                                                    {
                                                        "is-invalid":
                                                            errors.email,
                                                    }
                                                )}
                                                placeholder="Email"
                                                id="email"
                                                name="email"
                                                value={this.state.email}
                                                onChange={this.onChange}
                                            />
                                            {errors.email && (
                                                <div
                                                    className="invalid-feedback"
                                                    style={{
                                                        display: "block",
                                                    }}
                                                >
                                                    {errors.email}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="form-label-group mb-5">
                                    <div className="row">
                                        <div className="col-sm-12 col-xs-12">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Subject"
                                                id="subject"
                                                name="subject"
                                                value={this.state.subject}
                                                onChange={this.onChange}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="form-label-group mb-5">
                                    <div className="row">
                                        <div className="col-sm-12 col-xs-12">
                                            <textarea
                                                name="message"
                                                cols="40"
                                                rows="5"
                                                className={classnames(
                                                    "form-control",
                                                    {
                                                        "is-invalid":
                                                            errors.message,
                                                    }
                                                )}
                                                placeholder="Message"
                                                id="message"
                                                value={this.state.message}
                                                onChange={this.onChange}
                                            ></textarea>
                                            {errors.message && (
                                                <div
                                                    className="invalid-feedback"
                                                    style={{
                                                        display: "block",
                                                    }}
                                                >
                                                    {errors.message}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="form-label-group">
                                    <div className="row">
                                        <div className="col-sm-4 col-xs-12">
                                            <button
                                                type="submit"
                                                className="btn btn-lg btn-primary btn-block mt-4"
                                            >
                                                Send
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {/*<!-- Contac Section End -->	*/}
                <Footer />
            </React.Fragment>
        );
    }
}

Contact.propTypes = {
    contactPost: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    errors: state.errors,
});

export default connect(mapStateToProps, { contactPost })(withRouter(Contact));
