import React, { Component } from "react";
import "../common/common.jsx";
import Header from "../common/Header";
import Footer from "../common/Footer";

class About extends Component {
    render() {
        return (
            <React.Fragment>
                <Header />

                {/*<!-- Breadcrumb -->*/}
                <nav className="breadcrumb">
                    <div className="container">
                        <a className="breadcrumb-item" href="/">
                            Home
                        </a>
                        <span className="breadcrumb-item active">About Us</span>
                    </div>
                </nav>
                {/*<!-- Breadcrumb End -->*/}

                {/*<!-- Breadcrumb -->*/}
                <nav className="breadcrumb">
                    <div className="container">
                        <a className="breadcrumb-item" href="/">
                            Home
                        </a>
                        <span className="breadcrumb-item active">About Us</span>
                    </div>
                </nav>
                {/*<!-- Breadcrumb End -->*/}

                {/*<!-- About -->*/}
                <div className="weare-section">
                    <div className="container">
                        <div className="abt-wrapper">
                            <h1>Mariner License Prep</h1>
                            <p>
                                Leading U.S. Coast Guard License Examination
                                Self-Study Website &amp; iOS App
                            </p>
                            <div className="clearfix bottom"></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-7 col-xs-12">
                                <div className="wearetext-right">
                                    <h3>Our Mission</h3>

                                    <p>
                                        Mariner License Prep was created by
                                        passionate mariners who feel studying
                                        for license is harder and more
                                        convoluted than it needs to be. This
                                        site has EVERYTHING mariners need to
                                        study and PASS license examinations on
                                        their FIRST TRY. With up-to-date
                                        questions directly released from the
                                        U.S. Coast Guard, diagrams, question
                                        tracking, search functionality, and an
                                        iOS App which is optimized for mobile
                                        platforms and fully functional offline,
                                        Mariner License Prep is a staple for any
                                        mariner studying for the Coast Guard
                                        Exams. Both DECK and ENGINE. All Ratings
                                        from limited to unlimited. Questions are
                                        relevant to what YOU are studying AND
                                        the database is divided into
                                        subcategories to help you focus on the
                                        areas you are weak in.{" "}
                                    </p>
                                </div>
                            </div>

                            <div className="col-sm-5 col-xs-12">
                                <div className="weareimg2 pull-right">
                                    <img
                                        src="./assets/user/images/license.jpg"
                                        alt="about"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*<!-- About End -->*/}

                {/*<!-- Contac Section -->*/}
                <div className="contac-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-4 col-xs-12 text-center">
                                <div className="contac-service">
                                    <div className="icon-box">
                                        <i className="fa fa-lightbulb"></i>
                                    </div>
                                    <h1>Up-To-Date Questions</h1>
                                    <p>
                                        All Questions are DIRECTLY released from
                                        the U.S. Coast Guard and up-to-date.
                                    </p>
                                </div>
                            </div>

                            <div className="col-sm-4 col-xs-12 text-center">
                                <div className="contac-service">
                                    <div className="icon-box">
                                        <i className="fa fa-life-ring"></i>
                                    </div>
                                    <h1>Generate Practice Tests</h1>
                                    <p>
                                        Generate practice exams and get an idea
                                        of where you are.
                                    </p>
                                </div>
                            </div>

                            <div className="col-sm-4 col-xs-12 text-center">
                                <div className="contac-service">
                                    <div className="icon-box">
                                        <i className="fa fa-search"></i>
                                    </div>
                                    <h1>Search and Divide Questions</h1>
                                    <p>
                                        Search questions for keywords or
                                        specific topics.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<!-- Contac Section End -->*/}

                <Footer />
            </React.Fragment>
        );
    }
}
export default About;
