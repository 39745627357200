import React, { Component } from "react";
import ReactTable from "react-table";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Panel, PanelHeader } from "../common/panel/Panel.jsx";
import "react-table/react-table.css";
import Moment from "react-moment";
import { changeStatus } from "../../../actions/coupanActions.jsx";
import SweetAlert from "react-bootstrap-sweetalert";

class CoupanList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alertblog: false,
        };

        //this.data = makeData();
        this.defaultSorted = [
            {
                id: "_id",
                desc: false,
            },
        ];
        this.tableColumns = [
            {
                Header: "Id",
                id: "row",
                maxWidth: 50,
                filterable: false,
                Cell: (row) => {
                    return <div>{row.index + 1}</div>;
                },
            },
            {
                Header: "Generated",
                columns: [
                    {
                        Header: " Code",
                        accessor: "coupan_code",
                        Cell: (row) => (
                            <span>
                                {row.original.coupan_type === "Expiry" ? (
                                    <span className="label label-warning m-r-3">
                                        {row.value}
                                    </span>
                                ) : row.original.coupan_type ===
                                  "Non-Expiry" ? (
                                    <span className="label label-primary  m-r-3">
                                        {row.value}
                                    </span>
                                ) : (
                                    <span className="label label-warning m-r-3">
                                        None
                                    </span>
                                )}
                            </span>
                        ),
                    },

                    {
                        Header: "Offers(%)",
                        accessor: "coupan_off",
                        Cell: (row) => (
                            <span>
                                <span className="label label-green m-r-3">
                                    {row.value}
                                </span>
                            </span>
                        ),
                    },
                    {
                        Header: "Date",
                        accessor: "coupan_gen_date",
                        filterable: false,
                        sortable: false,
                        Cell: (row) => (
                            <span>
                                <i className="fa fa-calendar-alt col-blue mr-2"></i>
                                <Moment format="D MMM YYYY" withTitle>
                                    {row.value}
                                </Moment>
                            </span>
                        ),
                    },
                ],
            },
            {
                Header: "Code",
                columns: [
                    {
                        Header: "Type",
                        accessor: "coupan_type",
                        Cell: (row) => (
                            <span>
                                {row.value === "Expiry" ? (
                                    <span className="label label-warning m-r-3">
                                        Expiry
                                    </span>
                                ) : row.value === "Non-Expiry" ? (
                                    <span className="label label-primary  m-r-3">
                                        Non Expiry
                                    </span>
                                ) : (
                                    <span className="label label-warning m-r-3">
                                        None
                                    </span>
                                )}
                            </span>
                        ),
                    },

                    {
                        Header: "Status",
                        accessor: "coupan_status",
                        Cell: (row) => (
                            <span>
                                {row.value === "Active" ? (
                                    <span>
                                        <span className="label label-green m-r-3">
                                            Active
                                        </span>
                                        <br />
                                        <Link
                                            to="#"
                                            onClick={() =>
                                                this.toggleSweetAlert(
                                                    "error",
                                                    row.original._id
                                                )
                                            }
                                            style={{ color: "#CC0000" }}
                                        >
                                            change status
                                        </Link>
                                    </span>
                                ) : row.value === "Inactive" ? (
                                    <span>
                                        <span className="label label-danger  m-r-3">
                                            Inactive
                                        </span>
                                        <br />
                                        <Link
                                            to="#"
                                            onClick={() =>
                                                this.toggleSweetAlert(
                                                    "error",
                                                    row.original._id
                                                )
                                            }
                                            style={{ color: "#00CC00" }}
                                        >
                                            change status
                                        </Link>
                                    </span>
                                ) : (
                                    <span className="label label-warning m-r-3">
                                        None
                                    </span>
                                )}
                            </span>
                        ),
                    },
                ],
            },
            {
                Header: "Subscription",
                columns: [
                    {
                        Header: "Type",
                        accessor: "subscription_type",
                        Cell: (row) => (
                            <span>
                                {row.value === "all" ? (
                                    <span>Applicable to All</span>
                                ) : row.value === "one_month" ? (
                                    <span>One Month Subscription</span>
                                ) : row.value === "three_months" ? (
                                    <span>Three Month Subscription</span>
                                ) : row.value === "life_time" ? (
                                    <span>Life Time Subscription</span>
                                ) : (
                                    <span>None</span>
                                )}
                            </span>
                        ),
                    },
                ],
            },

            {
                Header: "Actions",
                columns: [
                    {
                        Header: "Actions",
                        accessor: "_id",
                        filterable: false,
                        sortable: false,
                        Cell: (row) => (
                            <div>
                                <Link
                                    to={`/admin/coupan-code/send-promo/${row.value}`}
                                    className="btn btn-info btn-sm m-r-5 "
                                >
                                    Send Promo
                                </Link>
                            </div>
                        ),
                    },
                ],
            },
        ];

        this.toggleSweetAlert = this.toggleSweetAlert.bind(this);
    }
    toggleSweetAlert(name, id) {
        switch (name) {
            case "error":
                this.setState({
                    sweetAlertError: !this.state.sweetAlertError,
                    id: id,
                });
                break;
            default:
                break;
        }
    }

    onDeleteClick(id) {
        this.props.changeStatus(id);
        this.setState({ sweetAlertError: !this.state.sweetAlertError });
    }

    render() {
        const data = this.props.coupans;

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-12">
                        {this.state.sweetAlertError && (
                            <SweetAlert
                                warning
                                showCancel
                                confirmBtnText="Yes, change it!"
                                confirmBtnBsStyle="warning"
                                cancelBtnBsStyle="default"
                                title="Are you sure?"
                                onConfirm={this.onDeleteClick.bind(
                                    this,
                                    this.state.id
                                )}
                                onCancel={() => this.toggleSweetAlert("error")}
                            >
                                You are going to change coupans status!
                            </SweetAlert>
                        )}
                        <Panel>
                            <PanelHeader>Promo Code List</PanelHeader>

                            <ReactTable
                                filterable
                                data={data}
                                columns={this.tableColumns}
                                defaultPageSize={10}
                                defaultSorted={this.defaultSorted}
                                className="-highlight"
                            />
                        </Panel>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

CoupanList.propTypes = {
    changeStatus: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
    errors: state.errors,
});
export default connect(mapStateToProps, { changeStatus })(
    withRouter(CoupanList)
);
