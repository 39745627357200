import {
    GET_BOOKMARKEDS,
    GET_HIDDENS,
    GET_LOGGEDS,
    GET_LOGGED_TOPICS,
    LOGGED_LOADING,
    UN_BOOKMARKED,
    GET_BOOK_LISTS,
    UN_HIDE,
    DASH_BOOK,
    DASH_HIDDEN,
    DASH_LOGGED,
    DELETE_ALL_LOGGED,
    DELETE_ALL_BOOKMARKED,
    UNHIDE_ALL_QUESTIONS,
} from "../actions/types";

const initialState = {
    bookmarks: [],
    hiddens: [],
    loggeds: null,
    logged_topics: [],
    book_lists: [],
    dashbook: null,
    dashhidden: null,
    dashlogged: null,

    loading: true,
    error: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case LOGGED_LOADING:
            return {
                ...state,
                loading: true,
            };

        case DASH_BOOK:
            return {
                ...state,
                dashbook: action.payload,
                loading: false,
            };

        case DASH_HIDDEN:
            return {
                ...state,
                dashhidden: action.payload,
                loading: false,
            };

        case DASH_LOGGED:
            return {
                ...state,
                dashlogged: action.payload,
                loading: false,
            };

        case GET_BOOK_LISTS:
            return {
                ...state,
                book_lists: action.payload,
                loading: false,
            };

        case GET_BOOKMARKEDS:
            return {
                ...state,
                bookmarks: action.payload,
                loading: false,
            };

        case GET_HIDDENS:
            return {
                ...state,
                hiddens: action.payload,
                loading: false,
            };

        case GET_LOGGEDS:
            return {
                ...state,
                loggeds: action.payload,
                loading: false,
            };

        case GET_LOGGED_TOPICS:
            return {
                ...state,
                logged_topics: action.payload,
                loading: false,
            };

        case UN_HIDE:
            return {
                ...state,
                hiddens: state.hiddens.filter(
                    (hiddens) => hiddens._id !== action.payload
                ),
            };

        case UN_BOOKMARKED:
            return {
                ...state,
                bookmarks: state.bookmarks.filter(
                    (bookmarks) => bookmarks._id !== action.payload
                ),
            };

        case DELETE_ALL_LOGGED:
            return {
                ...state,
                loggeds: action.payload ? [] : state.loggeds,
            };

        case DELETE_ALL_BOOKMARKED:
            return {
                ...state,
                bookmarks: action.payload ? [] : state.bookmarks,
            };

        case UNHIDE_ALL_QUESTIONS:
            return {
                ...state,
                hiddens: action.payload ? [] : state.hiddens,
            };

        default:
            return state;
    }
}
