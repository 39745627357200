import axios from "axios";
//import { setAlert } from "./alertActions";
import {
    ADMIN_LOADED,
    ADMIN_AUTH_ERROR,
    ADMIN_LOGIN_SUCCESS,
    GET_ERRORS,
    ADMIN_CLEAR_PROFILE,
    ADMIN_LOGOUT,
    GET_ADMIN_PROFILE,
    ADMIN_PROFILE_LOADING,
    CLEAR_ERRORS,
    GET_BILLING,
    GET_PRICE,
} from "./types";
import setAuthToken from "../utils/setAuthToken";

// Load Admin
export const loadAdmin = () => async (dispatch) => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }
    try {
        const res = await axios.get("/api/mlp_admin_auth");
        dispatch({
            type: ADMIN_LOADED,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: ADMIN_AUTH_ERROR,
        });
    }
};

// Login User
export const adminlogin = (adminloginData) => (dispatch) => {
    axios
        .post("/api/mlp_admin_auth", adminloginData)
        .then((res) => {
            dispatch({
                type: ADMIN_LOGIN_SUCCESS,
                payload: res.data,
            });
            dispatch(loadAdmin());
        })
        .catch((err) =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            })
        );
};

// Forgot password
export const forgotAdmin = (forgotData, history) => (dispatch) => {
    axios
        .post("/api/mlp_email/forgotadminmail", forgotData)
        .then((res) => history.push("/admin/?mail=send"))
        .catch((err) =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            })
        );
};

// Forgotpassword verify
export const adminPassverify = (forgotData1, history) => (dispatch) => {
    axios
        .post("/api/mlp_admin_auth/changepassword", forgotData1)
        .then((res) => history.push("/admin/?password=change"))
        .catch((err) =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            })
        );
};

// Logout / Clear Profile
export const adminlogout = () => (dispatch) => {
    dispatch({ type: ADMIN_CLEAR_PROFILE });
    dispatch({ type: ADMIN_LOGOUT });
};

// Get current profile
export const getadminCurrentProfile = () => (dispatch) => {
    dispatch(setadminProfileLoading());
    axios
        .get("/api/mlp_admin/get_profile")
        .then((res) =>
            dispatch({
                type: GET_ADMIN_PROFILE,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_ADMIN_PROFILE,
                payload: {},
            })
        );
};

// Profile loading
export const setadminProfileLoading = () => {
    return {
        type: ADMIN_PROFILE_LOADING,
    };
};

//  Edit Profile
export const adminprofileEdit = (profileData) => (dispatch) => {
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
    };
    dispatch(clearadminErrors());
    axios
        .post("/api/mlp_admin/profile_update", profileData, config)
        .then((res) =>
            dispatch({
                type: GET_ADMIN_PROFILE,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            })
        );
};

//  Edit Admin Email
export const adminEmailEdit = (emailData) => (dispatch) => {
    dispatch(clearadminErrors());
    axios
        .post("/api/mlp_admin/email_update", emailData)
        .then((res) =>
            dispatch({
                type: GET_ADMIN_PROFILE,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            })
        );
};

//  Edit Admin Password
export const adminPasswordEdit = (passwordData) => (dispatch) => {
    dispatch(clearadminErrors());
    axios
        .post("/api/mlp_admin/password_update", passwordData)
        .then((res) =>
            dispatch({
                type: GET_ADMIN_PROFILE,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            })
        );
};

//Update Billing
export const updatebilling = (categoryData) => (dispatch) => {
    axios
        .post("/api/mlp_admin/add_billing", categoryData)
        .then((res) =>
            dispatch({
                type: GET_BILLING,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            })
        );
};

// Get current Billing Information
export const getCurrentBilling = () => (dispatch) => {
    dispatch(clearadminErrors());
    axios
        .get("/api/mlp_admin/get_billing")
        .then((res) =>
            dispatch({
                type: GET_BILLING,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_BILLING,
                payload: null,
            })
        );
};

//Update Pricing
export const updatePrice = (categoryData) => (dispatch) => {
    axios
        .post("/api/mlp_admin/add_price", categoryData)
        .then((res) =>
            dispatch({
                type: GET_PRICE,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            })
        );
};

// Get current Pricing Information
export const getCurrentPrice = () => (dispatch) => {
    dispatch(clearadminErrors());
    axios
        .get("/api/mlp_admin/get_price")
        .then((res) =>
            dispatch({
                type: GET_PRICE,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_PRICE,
                payload: null,
            })
        );
};

// Clear errors
export const clearadminErrors = () => {
    return {
        type: CLEAR_ERRORS,
    };
};
