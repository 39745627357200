import React, { Component } from "react";
import { Link } from "react-router-dom";
import { GetTest } from "../../../../actions/testActions.jsx";
import queryString from "qs";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import isEmpty from "../../../../validation/is-empty";
//import SweetAlert from 'react-bootstrap-sweetalert';
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
class Button extends Component {
    constructor(props) {
        super(props);
        this.state = {
            test_num: "",
            insert_date: new Date(),
            question_count: "",
            sweetAlertInfo: false,
            modalAlert: false,
        };
        this.toggleModal = this.toggleModal.bind(this);
    }

    toggleModal(name) {
        switch (name) {
            case "info":
                this.setState({ modalAlert: !this.state.modalAlert });

                break;
            default:
                break;
        }
    }

    componentDidMount() {
        const queryStringValues = queryString.parse(
            this.props.location.search.slice(1)
        );
        // console.log(queryStringValues.type)
        this.props.GetTest(queryStringValues.test);
        this.setState({
            test: queryStringValues.test,
            num: queryStringValues.num,
            view: queryStringValues.view,
        });
    }

    componentWillReceiveProps(nextProps) {
        const queryStringValues = queryString.parse(
            this.props.location.search.slice(1)
        );
        const queryStringValuesn = queryString.parse(
            nextProps.location.search.slice(1)
        );
        if (queryStringValues.num !== queryStringValuesn.num) {
            this.setState({
                test: queryStringValuesn.test,
                num: queryStringValuesn.num,
                view: queryStringValuesn.view,
            });
        }

        if (nextProps.test.test) {
            const test = nextProps.test.test;

            test.test_name = !isEmpty(test.test_name) ? test.test_name : "";
            test.insert_date = !isEmpty(test.insert_date)
                ? test.insert_date
                : "";
            test.question_count = !isEmpty(test.question_count)
                ? test.question_count
                : "";
            // console.log(test.insert_date)

            this.setState({
                test_name: test.test_name,
                insert_date: test.insert_date,
                question_count: test.question_count,
            });
        }
    }

    render() {
        const num = this.props.num;
        var prevnum = num - 1;
        var adding = 1;
        var nextnum = num - 0 + (adding - 0);
        let prevbutton;
        if (this.props.previous) {
            prevbutton = this.props.previous.map((pques) => (
                <span key={pques._id}>
                    {prevnum === 0 ? (
                        <span>
                            {" "}
                            <button
                                type="button"
                                className="backward demo4 disabled"
                                disabled
                            >
                                <i className="fa fa-angle-double-left"></i>{" "}
                                Previous
                            </button>{" "}
                        </span>
                    ) : (
                        <Link
                            to={`/user/test/watch?test=${this.state.test}&num=${prevnum}&id=${pques._id}&zid=${pques.zmcq_id}`}
                            type="button"
                            className="backward demo4"
                        >
                            <i className="fa fa-angle-double-left"></i> Previous{" "}
                        </Link>
                    )}
                </span>
            ));
        }
        let nextbutton;
        if (this.state.question_count === num) {
            nextbutton = (
                <button
                    onClick={() => this.toggleModal("info")}
                    type="button"
                    className="forward adjust-btn demo4"
                >
                    Submit Test <i className="fa fa-angle-double-right"></i>
                </button>
            );
        } else {
            nextbutton = this.props.next.map((nques) => (
                <React.Fragment key={nques._id}>
                    <Link
                        to={`/user/test/watch?test=${this.state.test}&num=${nextnum}&id=${nques._id}&zid=${nques.zmcq_id}`}
                        type="button"
                        className="forward adjust-btn demo4"
                    >
                        Next <i className="fa fa-angle-double-right"></i>
                    </Link>
                </React.Fragment>
            ));
        }

        return (
            <div className="col-md-5 text-right for-only-q">
                <Modal
                    isOpen={this.state.modalAlert}
                    toggle={() => this.toggleModal("info")}
                >
                    <ModalHeader toggle={() => this.toggleModal("info")}>
                        Test Completed
                    </ModalHeader>
                    <ModalBody>
                        <div className="alert alert-info m-b-0">
                            <h5>
                                <i className="fa fa-info-circle"></i>You have
                                completed Your test?
                            </h5>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button
                            className="btn btn-default"
                            onClick={() => this.toggleModal("info")}
                        >
                            Close
                        </button>
                        <Link
                            to={`/user/test/result/${this.state.test}`}
                            className="btn btn-success"
                        >
                            Print Test
                        </Link>
                        <Link
                            to={`/user/test/print-result/${this.state.test}`}
                            className="btn btn-info"
                        >
                            View Result
                        </Link>
                    </ModalFooter>
                </Modal>
                {prevbutton}
                {nextbutton}
            </div>
        );
    }
}

Button.propTypes = {
    GetTest: PropTypes.func.isRequired,
    test: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    test: state.test,
});

export default connect(mapStateToProps, { GetTest })(withRouter(Button));
