import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

const TextFieldOption = ({
    name,
    placeholder,
    value,
    label,
    error,
    striks,
    //type,
    onChange,
    disabled,
    option1,
    option2,
    checked2,
    checked1,
    value1,
    value2,
}) => {
    return (
        <div className="form-group row">
            <label className="col-lg-3 col-form-label">
                {label}
                <span className="text-danger">{striks}</span>
            </label>
            <div className="col-lg-9">
                <div className="form-check form-check-inline">
                    <input
                        className={classnames("form-check-input", {
                            "is-invalid": error,
                        })}
                        type="radio"
                        name={name}
                        id="inlineCssRadio1"
                        value={value1}
                        checked={checked1}
                        onChange={onChange}
                    />
                    <label
                        className="form-check-label"
                        htmlFor="inlineCssRadio1"
                    >
                        {option1}{" "}
                    </label>
                </div>
                <div className="form-check form-check-inline">
                    <input
                        className={classnames("form-check-input", {
                            "is-invalid": error,
                        })}
                        type="radio"
                        name={name}
                        id="inlineCssRadio2"
                        value={value2}
                        checked={checked2}
                        onChange={onChange}
                    />
                    <label
                        className="form-check-label"
                        htmlFor="inlineCssRadio2"
                    >
                        {option2}{" "}
                    </label>
                </div>
                {error && (
                    <div
                        className="invalid-feedback"
                        style={{
                            display: "block",
                        }}
                    >
                        {error}
                    </div>
                )}
            </div>
        </div>
    );
};

TextFieldOption.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    checked: PropTypes.string,
    info: PropTypes.string,
    error: PropTypes.string,
    //type: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.string,
};

export default TextFieldOption;
