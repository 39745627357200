import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import EditSubscriber from "../../components/admin/subscribers/EditSubscriber";
import Invoice from "../../components/admin/subscribers/Invoice";
import MainInvoice from "../../components/admin/subscribers/MainInvoice";
import MainSubscriber from "../../components/admin/subscribers/MainSubscriber";
import AdminRoute from "./AdminRoute";

const SubscribersRoutes = () => {
    let { path } = useRouteMatch();

    return (
        <Switch>
            <AdminRoute
                exact
                path={`${path}/details`}
                component={MainSubscriber}
            />
            <AdminRoute
                exact
                path={`${path}/invoice`}
                component={MainInvoice}
            />
            <AdminRoute
                exact
                path={`${path}/edit-subscriber/:Id`}
                component={EditSubscriber}
            />
            <AdminRoute
                exact
                path={`${path}/view-invoice/:Id`}
                component={Invoice}
            />
        </Switch>
    );
};

export default SubscribersRoutes;
