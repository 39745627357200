import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addComment } from "../../../../actions/v1/commentActions.jsx";
import CommentList from "./CommentList.jsx";
import ReactNotification from "react-notifications-component";
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import ReactQuill from "react-quill";

class Comments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            _id: "",
            data: "",
            comment: "",
            collapse: localStorage.getItem("collapse") === "1",
            editor: {
                height: 500,
            },
            errors: {},
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.closeComment = this.closeComment.bind(this);
        this.openComment = this.openComment.bind(this);
    }

    handleChange(evt) {
        this.setState({
            comment: evt,
        });
    }

    handleCancel(e) {
        this.setState({
            comment: "",
        });
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    closeComment() {
        localStorage.setItem("collapse", "1");
        this.setState({ collapse: true });
    }

    openComment() {
        localStorage.removeItem("collapse");
        this.setState({ collapse: false });
    }

    onSubmit(e) {
        e.preventDefault();
        const commentData = {
            comments: this.state.comment,
            zmcq_id: this.props.zmcq_id,
        };
        this.props.addComment(commentData, this.props.history);

        this.setState({
            comment: "",
        });
        console.log(this.state.comment);
        store.addNotification({
            title: "Success",
            message: "Comment Save Successfully",
            type: "success",
            insert: "top",
            container: "top-center",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: { duration: 3000 },
            dismissable: { click: true },
        });
    }

    render() {
        const { errors } = this.state;
        let CommentContent = <CommentList comments={this.props.comments} />;

        let collapseContent;
        let buttonContent;

        if (!this.state.collapse) {
            collapseContent = (
                <div id="demo" className="active collapse in">
                    <div className="col-md-12 col-sm-12 cmnt-history">
                        {CommentContent}
                        <form onSubmit={this.onSubmit}>
                            <div className="form-group adjust">
                                <div className="col-sm-12 col-xs-12">
                                    <ReactQuill
                                        value={this.state.comment}
                                        name="comment"
                                        onChange={this.handleChange}
                                        style={{
                                            height: "250px",
                                            marginBottom: "20px",
                                        }}
                                    />

                                    {errors.comment && (
                                        <div
                                            className="invalid-feedback"
                                            style={{
                                                display: "block",
                                            }}
                                        >
                                            {errors.comment}
                                        </div>
                                    )}
                                    <br />
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-md-12 ">
                                    <button
                                        type="submit"
                                        className="btn btn-primary  btn-sub m-r-5"
                                    >
                                        Add comment
                                    </button>
                                    <button
                                        type="button"
                                        onClick={this.handleCancel}
                                        className="btn btn-default btn-sub"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            );
            buttonContent = (
                <button
                    onClick={this.closeComment}
                    type="button"
                    className="cmnts-dts show-comments active"
                >
                    <i className="fa fa-align-justify"></i>
                </button>
            );
        } else {
            collapseContent = "";

            buttonContent = (
                <button
                    onClick={this.openComment}
                    type="button"
                    className="cmnts-dts show-comments active"
                >
                    <i className="fa fa-align-justify"></i>
                </button>
            );
        }

        return (
            <React.Fragment>
                <ReactNotification />
                <div className="m-t-40">
                    <div className="q-number-wrapper for-btn for-top-btn">
                        <div className="col-md-6 for-only-q">
                            <span className="author-name cmt">
                                Comments{" "}
                                <span>{this.props.comments.length}</span>{" "}
                            </span>
                        </div>
                        <div className="col-md-6 text-right for-only-q">
                            {buttonContent}
                        </div>
                    </div>

                    <div className="col-sm-12 col-xs-12">{collapseContent}</div>
                </div>
            </React.Fragment>
        );
    }
}

Comments.propTypes = {
    addComment: PropTypes.func.isRequired,
    comments: PropTypes.array.isRequired,
    zmcq_id: PropTypes.string.isRequired,
    errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    errors: state.errors,
});

export default connect(mapStateToProps, {
    addComment,
})(withRouter(Comments));
