import React, { Component } from "react";
import Container from "../content/Container.jsx";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import { Panel, PanelHeader, PanelBody } from "../common/panel/Panel.jsx";
import classnames from "classnames";
import PerfectScrollbar from "react-perfect-scrollbar";
import AndroidTable from "./AndroidTable.jsx";
import IosTable from "./IosTable.jsx";
import WebTable from "./WebTable.jsx";
import CommentsTable from "./CommentsTable.jsx";
import {
    getDUsers,
    getSubscriber,
    getTRevenue,
    getNRevenue,
    getWebsite,
    getAndroid,
    getIos,
    getComments,
} from "../../../actions/dashboardActions.jsx";
import Spinner from "../common/textfield/Spinner";

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: 0,
            subscriber: 0,
            trevenue: 0,
            netrevenue: 0,
        };
        this.toggle = this.toggle.bind(this);

        this.state = {
            activeTab: "1",
        };
    }

    componentDidMount() {
        this.props.getDUsers();
        this.props.getSubscriber();
        this.props.getTRevenue();
        this.props.getNRevenue();
        this.props.getWebsite();
        this.props.getAndroid();
        this.props.getIos();
        this.props.getComments();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.dashboard.user) {
            this.setState({ user: nextProps.dashboard.user });
        }
        if (nextProps.dashboard.subscriber) {
            this.setState({ subscriber: nextProps.dashboard.subscriber });
        }
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    }
    render() {
        const {
            websites,
            ios,
            comments,
            androids,
            loading,
            trevenue,
            nrevenue,
        } = this.props.dashboard;

        let trevenueCont;
        if (trevenue === null) {
            trevenueCont = 0;
        } else {
            trevenueCont = trevenue.map((trenv) => (
                <span key={trenv._id}>{trenv.totalCost}</span>
            ));
        }
        let nrevenueCont;
        if (nrevenue === null) {
            nrevenueCont = 0;
        } else {
            nrevenueCont = nrevenue.map((nrevne) => (
                <span key={nrevne._id}>{nrevne.TotalAmount}</span>
            ));
        }

        let mainWebContent;
        if (websites === null || loading) {
            mainWebContent = <Spinner />;
        } else {
            //console.log(blogcats);
            if (websites.length > 0) {
                mainWebContent = <WebTable websites={websites} />;
            } else {
                mainWebContent = (
                    <ul className="media-list media-list-with-divider">
                        <li className="media media-lg">
                            <div className="media-body">
                                <h4>
                                    <b>This Table Is Empty</b>
                                </h4>
                                <p>
                                    {" "}
                                    No data found to show here. This table is
                                    empty.{" "}
                                </p>
                            </div>
                        </li>
                    </ul>
                );
            }
        }

        let mainAndroidContent;
        if (androids === null || loading) {
            mainAndroidContent = <Spinner />;
        } else {
            //console.log(blogcats);
            if (androids.length > 0) {
                mainAndroidContent = <AndroidTable androids={androids} />;
            } else {
                mainAndroidContent = (
                    <ul className="media-list media-list-with-divider">
                        <li className="media media-lg">
                            <div className="media-body">
                                <h4>
                                    <b>This Table Is Empty</b>
                                </h4>
                                <p>
                                    {" "}
                                    No data found to show here. This table is
                                    empty.{" "}
                                </p>
                            </div>
                        </li>
                    </ul>
                );
            }
        }

        let mainIosContent;
        if (ios === null || loading) {
            mainIosContent = <Spinner />;
        } else {
            //console.log(blogcats);
            if (ios.length > 0) {
                mainIosContent = <IosTable ios={ios} />;
            } else {
                mainIosContent = (
                    <ul className="media-list media-list-with-divider">
                        <li className="media media-lg">
                            <div className="media-body">
                                <h4>
                                    <b>This Table Is Empty</b>
                                </h4>
                                <p>
                                    {" "}
                                    No data found to show here. This table is
                                    empty.{" "}
                                </p>
                            </div>
                        </li>
                    </ul>
                );
            }
        }

        let mainCommentContent;
        if (comments === null || loading) {
            mainCommentContent = <Spinner />;
        } else {
            //console.log(blogcats);
            if (comments.length > 0) {
                mainCommentContent = <CommentsTable comments={comments} />;
            } else {
                mainCommentContent = (
                    <ul className="media-list media-list-with-divider">
                        <li className="media media-lg">
                            <div className="media-body">
                                <h4>
                                    <b>This Table Is Empty</b>
                                </h4>
                                <p>
                                    {" "}
                                    No data found to show here. This table is
                                    empty.{" "}
                                </p>
                            </div>
                        </li>
                    </ul>
                );
            }
        }

        let MainContent;
        if (websites === null || loading) {
            MainContent = <Spinner />;
        } else {
            MainContent = (
                <React.Fragment>
                    <div className="row">
                        <div className="col-xl-3 col-md-6">
                            <div className="widget widget-stats bg-red">
                                <div className="stats-icon">
                                    <i className="fa fa-desktop"></i>
                                </div>
                                <div className="stats-info">
                                    <h4>NEW USERS (30 DAYS)</h4>
                                    <p>{this.state.user}</p>
                                </div>
                                <div className="stats-link">
                                    <Link to="/admin/subscribers/details">
                                        View Detail{" "}
                                        <i className="fa fa-arrow-alt-circle-right"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6">
                            <div className="widget widget-stats bg-orange">
                                <div className="stats-icon">
                                    <i className="fa fa-link"></i>
                                </div>
                                <div className="stats-info">
                                    <h4>NEW SUBSCRIBERS (30 DAYS)</h4>
                                    <p>{this.state.subscriber}</p>
                                </div>
                                <div className="stats-link">
                                    <Link to="/admin/subscribers/details">
                                        View Detail{" "}
                                        <i className="fa fa-arrow-alt-circle-right"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6">
                            <div className="widget widget-stats bg-grey-darker">
                                <div className="stats-icon">
                                    <i className="fa fa-money-bill-wave"></i>
                                </div>
                                <div className="stats-info">
                                    <h4>TOTAL REVENUE (30 DAYS) </h4>
                                    <p>$ {trevenueCont}</p>
                                </div>
                                <div className="stats-link">
                                    <Link to="/admin/subscribers/details">
                                        View Detail{" "}
                                        <i className="fa fa-arrow-alt-circle-right"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6">
                            <div className="widget widget-stats bg-black-lighter">
                                <div className="stats-icon">
                                    <i className="fa fa-clock"></i>
                                </div>
                                <div className="stats-info">
                                    <h4>NET REVENUE (30 DAYS)</h4>
                                    <p>$ {nrevenueCont}</p>
                                </div>
                                <div className="stats-link">
                                    <Link to="/admin/subscribers/details">
                                        View Detail{" "}
                                        <i className="fa fa-arrow-alt-circle-right"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <Nav
                                tabs
                                className="nav-tabs-inverse nav-justified nav-justified-mobile"
                            >
                                <NavItem>
                                    <NavLink
                                        className={classnames({
                                            active:
                                                this.state.activeTab === "1",
                                        })}
                                        onClick={() => {
                                            this.toggle("1");
                                        }}
                                    >
                                        <i className="fa fa-desktop fa-lg m-r-5"></i>{" "}
                                        <span className="d-none d-md-inline">
                                            Website
                                        </span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({
                                            active:
                                                this.state.activeTab === "2",
                                        })}
                                        onClick={() => {
                                            this.toggle("2");
                                        }}
                                    >
                                        <i className="fa fa-mobile fa-lg m-r-5"></i>{" "}
                                        <span className="d-none d-md-inline">
                                            Android
                                        </span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({
                                            active:
                                                this.state.activeTab === "3",
                                        })}
                                        onClick={() => {
                                            this.toggle("3");
                                        }}
                                    >
                                        <i className="fa fa-mobile fa-lg m-r-5"></i>{" "}
                                        <span className="d-none d-md-inline">
                                            IOS
                                        </span>
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={this.state.activeTab}>
                                <TabPane tabId="1">
                                    <PerfectScrollbar
                                        className="height-sm"
                                        options={{ suppressScrollX: true }}
                                    >
                                        {mainWebContent}
                                    </PerfectScrollbar>
                                </TabPane>
                                <TabPane tabId="2">
                                    <PerfectScrollbar
                                        className="height-sm"
                                        options={{ suppressScrollX: true }}
                                    >
                                        {mainAndroidContent}
                                    </PerfectScrollbar>
                                </TabPane>
                                <TabPane tabId="3">
                                    <PerfectScrollbar
                                        className="height-sm"
                                        options={{ suppressScrollX: true }}
                                    >
                                        {mainIosContent}
                                    </PerfectScrollbar>
                                </TabPane>
                            </TabContent>

                            <Panel>
                                <PanelHeader>Comments</PanelHeader>
                                <PanelBody>
                                    <PerfectScrollbar
                                        className="height-sm"
                                        options={{ suppressScrollX: true }}
                                    >
                                        {mainCommentContent}
                                    </PerfectScrollbar>
                                </PanelBody>
                            </Panel>
                        </div>
                    </div>
                </React.Fragment>
            );
        }
        return (
            <Container>
                <div id="content" className="content">
                    <ol className="breadcrumb float-xl-right">
                        <li className="breadcrumb-item">
                            <Link to="/dashboard/v1">Home</Link>
                        </li>
                        <li className="breadcrumb-item active">Dashboard</li>
                    </ol>
                    <h1 className="page-header">Dashboard </h1>

                    {MainContent}
                </div>
            </Container>
        );
    }
}

Dashboard.propTypes = {
    getDUsers: PropTypes.func.isRequired,
    getSubscriber: PropTypes.func.isRequired,
    getTRevenue: PropTypes.func.isRequired,
    getNRevenue: PropTypes.func.isRequired,
    getWebsite: PropTypes.func.isRequired,
    getAndroid: PropTypes.func.isRequired,
    getIos: PropTypes.func.isRequired,
    getComments: PropTypes.func.isRequired,
    dashboard: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    dashboard: state.dashboard,
});

export default connect(mapStateToProps, {
    getDUsers,
    getSubscriber,
    getTRevenue,
    getNRevenue,
    getWebsite,
    getAndroid,
    getIos,
    getComments,
})(Dashboard);
