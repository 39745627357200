import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Container from "../content/Container.jsx";
import { Panel, PanelHeader, PanelBody } from "../common/panel/Panel.jsx";
import ReactNotification from "react-notifications-component";
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import {
    updateUserComments,
    getCommentsbyId,
} from "../../../actions/commentActions.jsx";
import { loadUser, sendVeriEmail } from "../../../actions/userActions";
import isEmpty from "../../../validation/is-empty";
import AlertBox from "./../common/textfield/AlertBox";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

class EditComment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            comments: "",
            status: "",
            alert: false,
            AlertBox: false,
            visible: false,
            verification_status: "",
            user_id: "",
            errors: {},
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onDismiss = this.onDismiss.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(evt) {
        this.setState({
            data: evt,
        });
    }

    onDismiss() {
        this.setState({ visible: false, visible1: false, visible2: false });
    }

    componentDidMount() {
        if (this.props.match.params.Id) {
            this.props.getCommentsbyId(this.props.match.params.Id);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({ errors: nextProps.errors });
        }
        if (nextProps.comment.comment) {
            const comment = nextProps.comment.comment;

            comment._id = !isEmpty(comment._id) ? comment._id : "";
            comment.comments = !isEmpty(comment.comments)
                ? comment.comments
                : "";
            comment.status = !isEmpty(comment.status) ? comment.status : "";
            comment.zmcq_id.ZQUESTION = !isEmpty(comment.zmcq_id.ZQUESTION)
                ? comment.zmcq_id.ZQUESTION
                : "";
            comment.user_id.user_name = !isEmpty(comment.user_id.user_name)
                ? comment.user_id.user_name
                : "";
            comment.user_id.email = !isEmpty(comment.user_id.email)
                ? comment.user_id.email
                : "";

            // Set component fields state for blog
            this.setState({
                id: comment._id,
                question: comment.zmcq_id.ZQUESTION,
                user_name: comment.user_id.user_name,
                email: comment.user_id.email,
                data: comment.comments,
                status: comment.status,
            });
        }

        if (nextProps.auth.user) {
            const user = nextProps.auth.user;
            user._id = !isEmpty(user._id) ? user._id : "";
            user.verification_status = !isEmpty(user.verification_status)
                ? user.verification_status
                : "";
            this.setState({
                verification_status: user.verification_status,
                user_id: user._id,
            });
        }
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    onSubmit(e) {
        e.preventDefault();
        const page_id = this.props.match.params.Id;

        const formData = {
            comments: this.state.data,
        };
        console.log(formData);
        this.props.updateUserComments(page_id, formData, this.props.history);
        this.setState({ alert: true, visible: true });
        store.addNotification({
            title: "Success",
            message: "Comment Saved successfully",
            type: "success",
            insert: "top",
            container: "top-center",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: { duration: 2000 },
            dismissable: { click: true },
            // content: notificationContent
        });
    }

    onEmailClick(id) {
        this.props.sendVeriEmail(id);
        store.addNotification({
            title: "Success!",
            message:
                " An email is sent to your registered email id. Click on the link to verify.",
            type: "success",
            insert: "top",
            container: "top-center",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: { duration: 3000 },
            dismissable: { click: true },
            // content: notificationContent
        });
    }

    render() {
        const { errors } = this.state;
        let EmailVerificationContent;
        if (this.state.verification_status === "No") {
            EmailVerificationContent = (
                <AlertBox
                    classes="danger"
                    isOpen={this.state.visible}
                    toggle={this.onDismiss}
                    content="Your Email verification is pending!"
                    linkContent="resend verification link"
                    onClick={this.onEmailClick.bind(this, this.state.user_id)}
                />
            );
        }
        return (
            <React.Fragment>
                <ReactNotification />
                <Container>
                    <div className="content">
                        {EmailVerificationContent}
                        <ol className="breadcrumb float-xl-right">
                            <li className="breadcrumb-item">
                                <Link to="/dashboard">Dashboard</Link>
                            </li>
                            <li className="breadcrumb-item active">
                                Edit Comments{" "}
                            </li>
                        </ol>
                        <h1 className="page-header">Edit Comment </h1>
                        <div className="row">
                            <div className="col-xl-12">
                                <Panel className="member-panel">
                                    <PanelHeader>Edit Comment </PanelHeader>
                                    <PanelBody className="panel-form">
                                        <div className="panel-form">
                                            <form
                                                className="form-horizontal form-bordered"
                                                onSubmit={this.onSubmit}
                                            >
                                                <div className="form-group row">
                                                    <label className="col-lg-3 col-form-label">
                                                        Question
                                                    </label>
                                                    <div className="col-lg-9">
                                                        {this.state.question}
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <label className="col-lg-3 col-form-label">
                                                        Comments
                                                        <span className="text-danger">
                                                            *
                                                        </span>
                                                    </label>
                                                    <div className="col-lg-9">
                                                        <SunEditor
                                                            name="data"
                                                            type="text"
                                                            value={
                                                                this.state.data
                                                            }
                                                            setContents={
                                                                this.state.data
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                            height="200"
                                                        />
                                                        {errors.Comments && (
                                                            <div
                                                                className="invalid-feedback"
                                                                style={{
                                                                    display:
                                                                        "block",
                                                                }}
                                                            >
                                                                {
                                                                    errors.Comments
                                                                }
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <div className="col-md-12 offset-md-5">
                                                        <button
                                                            type="submit"
                                                            className="btn btn-sm btn-primary m-r-5"
                                                        >
                                                            Submit
                                                        </button>

                                                        <button
                                                            type="button"
                                                            onClick={
                                                                this
                                                                    .handleCancel
                                                            }
                                                            className="btn btn-sm btn-default"
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </PanelBody>
                                </Panel>
                            </div>
                        </div>
                    </div>
                </Container>
            </React.Fragment>
        );
    }
}

EditComment.propTypes = {
    updateUserComments: PropTypes.func.isRequired,
    getCommentsbyId: PropTypes.func.isRequired,
    loadUser: PropTypes.func.isRequired,
    sendVeriEmail: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    comment: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    comment: state.comment,
    errors: state.errors,
});

export default connect(mapStateToProps, {
    updateUserComments,
    getCommentsbyId,
    loadUser,
    sendVeriEmail,
})(withRouter(EditComment));
