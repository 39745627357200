import React, { Component } from "react";
import { Link } from "react-router-dom";

class DeckList extends Component {
    render() {
        const deckContent = this.props.decks.map((deck) => (
            <Link
                to={`/question/${deck.ZSLUG}`}
                key={deck._id}
                className="directory-list"
            >
                {deck.Z_PK}
            </Link>
        ));
        return deckContent;
    }
}
export default DeckList;
