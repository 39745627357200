import React, { Component } from "react";
import { Link } from "react-router-dom";

class CommentsTable extends Component {
    render() {
        const Commentlist = this.props.comments.map((comm) => (
            <li className="media media-sm" key={comm._id._id}>
                <Link to="/dashboard/v1" className="pull-left">
                    <img
                        src="/assets/img/user/user-5.jpg"
                        alt=""
                        className="media-object rounded-corner"
                    />
                </Link>
                <div className="media-body">
                    <h5 className="media-heading">User Name</h5>
                    <p>{comm._id ? comm._id.user_name : <span>Null</span>}</p>
                </div>
                <div className="media-body">
                    <h5 className="media-heading">Email</h5>
                    <p>{comm._id ? comm._id.email : <span>NUll</span>}</p>
                </div>
                <div className="media-body">
                    <h5 className="media-heading">Total Coments</h5>
                    <p>{comm.tags}.</p>
                </div>
                <div className="media-body">
                    <h5 className="media-heading">Actions</h5>
                    <p>
                        <Link
                            to={`/admin/comment/comment-list/${
                                comm._id ? comm._id._id : <span></span>
                            }`}
                            className="btn btn-primary btn-sm"
                        >
                            <i className="fa fa-paste"></i> View Comment List
                        </Link>
                    </p>
                </div>
            </li>
        ));
        return (
            <div>
                <ul className="media-list media-list-with-divider media-messaging">
                    {Commentlist}
                </ul>
            </div>
        );
    }
}
export default CommentsTable;
