import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Container from "./../content/Container.jsx";
import { Panel, PanelHeader, PanelBody } from "./../common/panel/Panel.jsx";
import TextFieldArea from "./../common/textfield/TextFieldArea";
import {
    getCurrentBilling,
    updatebilling,
} from "../../../actions/adminActions.jsx";
//import AlertBox from "../common/textfield/AlertBox";
import ReactNotification from "react-notifications-component";
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import isEmpty from "../../../validation/is-empty";

class BillingInformation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            company_name: "",
            address: "",
            phone_no: "",
            email: "",
            website: "",
            errors: {},
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onDismiss = this.onDismiss.bind(this);
    }

    onDismiss() {
        this.setState({ visible: false, visible1: false });
    }

    componentDidMount() {
        this.props.getCurrentBilling();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors,
                visible: true,
                visible1: true,
            });
        }

        if (nextProps.billing.billing) {
            const billing = nextProps.billing.billing;
            // console.log(billing);
            // If profile field doesnt exist, make empty string

            billing.company_name = !isEmpty(billing.company_name)
                ? billing.company_name
                : "";

            billing.address = !isEmpty(billing.address) ? billing.address : "";

            billing.phone_no = !isEmpty(billing.phone_no)
                ? billing.phone_no
                : "";

            billing.email = !isEmpty(billing.email) ? billing.email : "";
            billing.website = !isEmpty(billing.website) ? billing.website : "";

            // Set component fields state
            this.setState({
                company_name: billing.company_name,
                address: billing.address,
                phone_no: billing.phone_no,
                email: billing.email,
                website: billing.website,
            });
        }
    }

    onSubmit(e) {
        e.preventDefault();
        const adminemail = {
            company_name: this.state.company_name,
            address: this.state.address,
            phone_no: this.state.phone_no,
            email: this.state.email,
            website: this.state.website,
        };
        this.props.updatebilling(adminemail, this.props.history);
        if (!Object.keys(this.state.errors).length) {
            store.addNotification({
                title: "Success",
                message: "Billing Information Updated Successfully.",
                type: "success",
                insert: "top",
                container: "top-center",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: { duration: 2000 },
                dismissable: { click: true },
                // content: notificationContent
            });
        }
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }
    render() {
        const { errors } = this.state;
        return (
            <React.Fragment>
                <ReactNotification />
                <Container>
                    <div className="content">
                        <div>
                            <ol className="breadcrumb float-xl-right">
                                <li className="breadcrumb-item">
                                    <Link to="/admin/dashboard">Dashboard</Link>
                                </li>

                                <li className="breadcrumb-item active">
                                    Billing Information
                                </li>
                            </ol>
                            <h1 className="page-header">
                                Invoice Billing Information
                            </h1>

                            <div className="row">
                                <div className="col-xl-12">
                                    <Panel>
                                        <PanelHeader>
                                            Invoice Information
                                        </PanelHeader>
                                        <PanelBody className="panel-form">
                                            {errors.billingchange &&
                                                store.addNotification({
                                                    title: "Success",
                                                    message:
                                                        errors.billingchange,
                                                    type: "success",
                                                    insert: "top",
                                                    container: "top-center",
                                                    animationIn: [
                                                        "animated",
                                                        "fadeIn",
                                                    ],
                                                    animationOut: [
                                                        "animated",
                                                        "fadeOut",
                                                    ],
                                                    dismiss: { duration: 2000 },
                                                    dismissable: {
                                                        click: true,
                                                    },
                                                    // content: notificationContent
                                                })}
                                            <form
                                                className="form-horizontal form-bordered"
                                                onSubmit={this.onSubmit}
                                            >
                                                <fieldset>
                                                    <TextFieldArea
                                                        label="Company name"
                                                        placeholder="Company name"
                                                        name="company_name"
                                                        type="text"
                                                        value={
                                                            this.state
                                                                .company_name
                                                        }
                                                        onChange={this.onChange}
                                                        error={
                                                            errors.company_name
                                                        }
                                                        striks="*"
                                                    />

                                                    <TextFieldArea
                                                        label="Street Address"
                                                        placeholder="Street Address"
                                                        name="address"
                                                        type="text"
                                                        value={
                                                            this.state.address
                                                        }
                                                        onChange={this.onChange}
                                                        error={errors.address}
                                                    />

                                                    <TextFieldArea
                                                        label="Phone Number"
                                                        placeholder="Phone Number"
                                                        name="phone_no"
                                                        type="text"
                                                        value={
                                                            this.state.phone_no
                                                        }
                                                        onChange={this.onChange}
                                                        error={errors.phone_no}
                                                    />

                                                    <TextFieldArea
                                                        label="Email"
                                                        placeholder="example@example.com"
                                                        name="email"
                                                        type="email"
                                                        value={this.state.email}
                                                        onChange={this.onChange}
                                                        error={errors.email}
                                                        striks="*"
                                                    />

                                                    <TextFieldArea
                                                        label="Website"
                                                        placeholder="Website"
                                                        name="website"
                                                        type="url"
                                                        value={
                                                            this.state.website
                                                        }
                                                        onChange={this.onChange}
                                                        error={errors.website}
                                                        striks="*"
                                                    />

                                                    <div className="form-group row">
                                                        <div className="col-md-12 offset-md-5">
                                                            <button
                                                                type="submit"
                                                                className="btn btn-sm btn-primary m-r-5"
                                                            >
                                                                Submit
                                                            </button>
                                                        </div>
                                                    </div>
                                                </fieldset>
                                            </form>
                                        </PanelBody>
                                    </Panel>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </React.Fragment>
        );
    }
}

BillingInformation.propTypes = {
    billing: PropTypes.object.isRequired,
    getCurrentBilling: PropTypes.func.isRequired,
    updatebilling: PropTypes.func.isRequired,

    errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    admin: state.admin,
    billing: state.billing,
    errors: state.errors,
});

export default connect(mapStateToProps, {
    getCurrentBilling,
    updatebilling,
})(withRouter(BillingInformation));
