import axios from "axios";
//import { setAlert } from "./alertActions";
import {
    ADD_COUPAN,
    GET_COUPAN,
    GET_COUPANS,
    USED_COUPANS,
    COUPAN_LOADING,
    COUPAN_EMAILS,
    DELETE_EMAILS,
    DELETE_COUPAN,
    GET_PRO_EMAIL,
    GET_ERRORS,
    CLEAR_ERRORS,
} from "./types";

// Clear errors
export const clearErrors = () => {
    return {
        type: CLEAR_ERRORS,
    };
};

// Delete Subscriber
export const deleteEmail = (id) => (dispatch) => {
    // if (window.confirm("Are you sure? This can NOT be undone!")) {
    axios
        .delete(`/api/mlp_coupan/delete_coupan/${id}`)
        .then((res) =>
            dispatch({
                type: DELETE_EMAILS,
                payload: id,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            })
        );
};

// Delete Subscriber
export const changeStatus = (id) => (dispatch) => {
    // if (window.confirm("Are you sure? This can NOT be undone!")) {
    axios
        .delete(`/api/mlp_coupan/change_coupan_status/${id}`)
        .then((res) =>
            dispatch({
                type: DELETE_COUPAN,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            })
        );
};

//Add Coupans
export const addCoupans = (coupanData) => (dispatch) => {
    axios
        .post("/api/mlp_coupan/add_coupan", coupanData)
        .then((res) =>
            dispatch({
                type: ADD_COUPAN,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            })
        );
};

// Get Page by id
export const getCoupanbyId = (id) => (dispatch) => {
    dispatch(setCoupanLoading());
    axios
        .get(`/api/mlp_coupan/getcoupan/${id}`)
        .then((res) =>
            dispatch({
                type: GET_COUPAN,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_COUPAN,
                payload: null,
            })
        );
};

// Get Page by id
export const getMessagebyId = (id) => (dispatch) => {
    dispatch(setCoupanLoading());
    axios
        .get(`/api/mlp_coupan/get_coupan/${id}`)
        .then((res) =>
            dispatch({
                type: GET_PRO_EMAIL,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_PRO_EMAIL,
                payload: null,
            })
        );
};

// Send coupan Email
export const SendCoupans = (coupan_Id, editData) => (dispatch) => {
    axios
        .post(`/api/mlp_email/send_mail/${coupan_Id}`, editData)
        .then((res) =>
            dispatch({
                type: GET_COUPAN,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            })
        );
};

// Get current Pages
export const getCoupans = () => (dispatch) => {
    dispatch(setCoupanLoading());
    axios
        .get("/api/mlp_coupan/all_coupan")
        .then((res) =>
            dispatch({
                type: GET_COUPANS,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_COUPANS,
                payload: null,
            })
        );
};

// Get current Pages
export const usedCoupans = () => (dispatch) => {
    dispatch(setCoupanLoading());
    axios
        .get("/api/mlp_coupan/used_coupan")
        .then((res) =>
            dispatch({
                type: USED_COUPANS,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: USED_COUPANS,
                payload: null,
            })
        );
};

// Get current Pages
export const sentCoupans = () => (dispatch) => {
    dispatch(setCoupanLoading());
    axios
        .get("/api/mlp_coupan/sentMail")
        .then((res) =>
            dispatch({
                type: COUPAN_EMAILS,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: COUPAN_EMAILS,
                payload: null,
            })
        );
};

// Coupan loading
export const setCoupanLoading = () => {
    return {
        type: COUPAN_LOADING,
    };
};
