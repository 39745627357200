import React, { Component } from "react";
import { Link } from "react-router-dom";
import EngineSubCatNav from "./EngineSubCatNav";
import { getuserEngineSubCat } from "../../../../../actions/questionActions.jsx";
import PropTypes from "prop-types";
import { connect } from "react-redux";

class studyEngine extends Component {
    constructor(props) {
        super(props);
        this.state = {
            book1active: "",
        };
    }

    onChangeBookClick(id) {
        this.props.getuserEngineSubCat(id);
        //console.log(id)
        this.setState({ book1active: id });
    }
    render() {
        const { engine_subcats } = this.props.question;
        let engineCatContent;
        if (engine_subcats.length > 0) {
            engineCatContent = (
                <EngineSubCatNav engine_subcats={engine_subcats} />
            );
        }
        const booklist = this.props.engine_books.map((books) => (
            <li
                className={
                    (this.state.book1active === books.ZINBOOK
                        ? "active expand "
                        : "closed") + "has-sub"
                }
                key={books.ZINBOOK}
            >
                <span
                    className="nav-study"
                    onClick={
                        this.state.book1active === books.ZINBOOK
                            ? () => this.setState({ book1active: "" })
                            : this.onChangeBookClick.bind(this, books.ZINBOOK)
                    }
                >
                    <b className="caret"></b>
                    {books.ZBOOKNAME}
                </span>

                <ul
                    className={
                        "sub-menu " +
                        (this.state.book1active === books.ZINBOOK
                            ? "d-block "
                            : "d-none")
                    }
                >
                    <li>
                        <Link
                            to={`/user/watch?mode=normal&type=book&id=${books.ZINBOOK}&num=1&zid=${books._id}&zinbookcat=${books.ZINBOOKCATEGORY}`}
                        >
                            All {books.ZBOOKNAME}
                        </Link>
                    </li>
                    {engineCatContent}
                </ul>
            </li>
        ));
        return booklist;
    }
}

studyEngine.propTypes = {
    getuserEngineSubCat: PropTypes.func.isRequired,
    question: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    question: state.question,
});

export default connect(mapStateToProps, { getuserEngineSubCat })(studyEngine);
