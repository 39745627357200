import React from "react";
import "./index.css";

const PageNotFound = () => {
    return (
        <div id="notfound">
            <div className="notfound">
                <div className="notfound-404">
                    <h1>
                        <span>4</span>
                        <span>0</span>
                        <span>4</span>
                    </h1>
                </div>
                <h2>sorry! the requested page was not found.</h2>
                <a class="btn btn-primary btn-block text-uppercase" href="/">
                    homepage
                </a>
            </div>
        </div>
    );
};

export default PageNotFound;
