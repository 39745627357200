import axios from "axios";
//import { setAlert } from "./alertActions";
import {
    GET_INVOICE,
    GET_INVOICES,
    INVOICE_LOADING,
    GET_ERRORS,
    //CLEAR_ERRORS
} from "./types";

// Get current Pages
export const getInvoices = () => (dispatch) => {
    dispatch(setInvoiceLoading());
    axios
        .get("/api/mlp_invoice/all_invoice")
        .then((res) =>
            dispatch({
                type: GET_INVOICES,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_INVOICES,
                payload: null,
            })
        );
};

// Edit Comments
export const getInvoicebyDate = (editData) => (dispatch) => {
    axios
        .post(`/api/mlp_invoice/get_inv_date`, editData)
        .then((res) =>
            dispatch({
                type: GET_INVOICES,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            })
        );
};

// Get current Invoice
export const getInvoiceNew = (id) => (dispatch) => {
    dispatch(setInvoiceLoading());
    axios
        .get(`/api/mlp_invoice/invoicenew/${id}`)
        .then((res) =>
            dispatch({
                type: GET_INVOICE,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_INVOICE,
                payload: null,
            })
        );
};

// Invoice loading
export const setInvoiceLoading = () => {
    return {
        type: INVOICE_LOADING,
    };
};

// Get current Pages
export const getUserInvoices = () => (dispatch) => {
    dispatch(setInvoiceLoading());
    axios
        .get("/api/mlp_invoice/user_invoice")
        .then((res) =>
            dispatch({
                type: GET_INVOICES,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_INVOICES,
                payload: null,
            })
        );
};
