import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Container from "../content/Container.jsx";
import { Panel, PanelHeader, PanelBody } from "../common/panel/Panel.jsx";
import TextFieldArea from "../common/textfield/TextFieldArea";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
//import AlertBox from "../common/textfield/AlertBox";
import ReactNotification from "react-notifications-component";
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { SendCoupans, getCoupanbyId } from "../../../actions/coupanActions.jsx";
import isEmpty from "../../../validation/is-empty";

class SendPromo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customer_email: "",
            promo_subject: "",
            data: "",
            coupan_id: "",
            coupan_code: "",
            coupan_off: "",
            subscription_type: "",
            alertpromo: false,
            AlertBox: false,
            visible: false,
            errors: {},
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onDismiss = this.onDismiss.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }
    onDismiss() {
        this.setState({ visible: false });
    }

    componentDidMount() {
        if (this.props.match.params.Id) {
            this.props.getCoupanbyId(this.props.match.params.Id);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({ errors: nextProps.errors });
        }

        if (nextProps.coupan.coupan) {
            const coupan = nextProps.coupan.coupan;

            coupan._id = !isEmpty(coupan._id) ? coupan._id : "";
            coupan.coupan_code = !isEmpty(coupan.coupan_code)
                ? coupan.coupan_code
                : "";
            coupan.subscription_type = !isEmpty(coupan.subscription_type)
                ? coupan.subscription_type
                : "";
            coupan.coupan_off = !isEmpty(coupan.coupan_off)
                ? coupan.coupan_off
                : "";

            // Set component fields state for blog
            this.setState({
                coupan_id: coupan._id,
                coupan_code: coupan.coupan_code,
                subscription_type: coupan.subscription_type,
                coupan_off: coupan.coupan_off,
            });
        }
    }

    handleChange(evt) {
        this.setState({
            data: evt,
        });
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleCancel(e) {
        this.setState({
            customer_email: "",
            promo_subject: "",
            data: "",
        });
    }

    onSubmit(e) {
        e.preventDefault();
        const coupan_id = this.props.match.params.Id;
        const formData = {
            customer_email: this.state.customer_email,
            promo_subject: this.state.promo_subject,
            promo_message: this.state.data,
            coupan_id: this.state.coupan_id,
            coupan_code: this.state.coupan_code,
            coupan_off: this.state.coupan_off,
            subscription_type: this.state.subscription_type,
        };

        this.props.SendCoupans(coupan_id, formData, this.props.history);

        this.setState({
            alertpromo: true,
            customer_email: "",
            promo_subject: "",
            data: "",
        });
    }
    render() {
        const { errors } = this.state;
        return (
            <React.Fragment>
                <ReactNotification />
                <Container>
                    <div className="content">
                        <div>
                            <ol className="breadcrumb float-xl-right">
                                <li className="breadcrumb-item">
                                    <Link to="/admin/dashboard">Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to="/admin/coupan-code/generate-codes">
                                        generated Code
                                    </Link>
                                </li>
                                <li className="breadcrumb-item active">
                                    Send Promo Code
                                </li>
                            </ol>
                            <h1 className="page-header">Send Promo Code </h1>
                            <div className="row">
                                <div className="col-xl-12">
                                    <Panel>
                                        <PanelHeader>
                                            Compose Message
                                        </PanelHeader>
                                        <PanelBody>
                                            <div className="alert alert-secondary m-b-15 text-center">
                                                <h4>
                                                    <b>
                                                        Promo-Code :{" "}
                                                        {this.state.coupan_code}{" "}
                                                        ({" "}
                                                        {this.state
                                                            .subscription_type ===
                                                        "all" ? (
                                                            <span>
                                                                Applicable to
                                                                All
                                                            </span>
                                                        ) : this.state
                                                              .subscription_type ===
                                                          "one_month" ? (
                                                            <span>
                                                                One Month
                                                                Subscription
                                                            </span>
                                                        ) : this.state
                                                              .subscription_type ===
                                                          "three_months" ? (
                                                            <span>
                                                                Three Month
                                                                Subscription
                                                            </span>
                                                        ) : this.state
                                                              .subscription_type ===
                                                          "life_time" ? (
                                                            <span>
                                                                Life Time
                                                                Subscription
                                                            </span>
                                                        ) : (
                                                            <span>None</span>
                                                        )}
                                                        )
                                                    </b>
                                                </h4>
                                            </div>
                                            <div className="panel-form">
                                                <span className="hide-noti ">
                                                    {errors.promoSuccess &&
                                                        store.addNotification({
                                                            title: "Success!",
                                                            message:
                                                                "Email Send successfully.",
                                                            type: "success",
                                                            insert: "top",
                                                            container:
                                                                "top-center",
                                                            animationIn: [
                                                                "animated",
                                                                "fadeIn",
                                                            ],
                                                            animationOut: [
                                                                "animated",
                                                                "fadeOut",
                                                            ],
                                                            dismiss: {
                                                                duration: 2000,
                                                            },
                                                            dismissable: {
                                                                click: true,
                                                            },
                                                            // content: notificationContent
                                                        })}
                                                </span>

                                                <form
                                                    className="form-horizontal form-bordered"
                                                    onSubmit={this.onSubmit}
                                                >
                                                    <TextFieldArea
                                                        label="To"
                                                        placeholder="example@example.com"
                                                        name="customer_email"
                                                        type="email"
                                                        value={
                                                            this.state
                                                                .customer_email
                                                        }
                                                        onChange={this.onChange}
                                                        error={
                                                            errors.customer_email
                                                        }
                                                        striks="*"
                                                    />

                                                    <TextFieldArea
                                                        label="Subject"
                                                        placeholder="Subject"
                                                        name="promo_subject"
                                                        type="text"
                                                        value={
                                                            this.state
                                                                .promo_subject
                                                        }
                                                        onChange={this.onChange}
                                                        error={
                                                            errors.promo_subject
                                                        }
                                                        striks="*"
                                                    />

                                                    <div className="form-group row">
                                                        <label className="col-lg-3 col-form-label">
                                                            Description
                                                        </label>
                                                        <div className="col-lg-9">
                                                            <SunEditor
                                                                name="data"
                                                                type="text"
                                                                value={
                                                                    this.state
                                                                        .data
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleChange
                                                                }
                                                                height="200"
                                                            />
                                                            {errors.description && (
                                                                <div
                                                                    className="invalid-feedback"
                                                                    style={{
                                                                        display:
                                                                            "block",
                                                                    }}
                                                                >
                                                                    {
                                                                        errors.description
                                                                    }
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <div className="col-md-12 offset-md-5">
                                                            <button
                                                                type="submit"
                                                                className="btn btn-sm btn-primary m-r-5"
                                                            >
                                                                Submit
                                                            </button>

                                                            <button
                                                                type="button"
                                                                onClick={
                                                                    this
                                                                        .handleCancel
                                                                }
                                                                className="btn btn-sm btn-default"
                                                            >
                                                                Cancel
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </PanelBody>
                                    </Panel>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </React.Fragment>
        );
    }
}

SendPromo.propTypes = {
    SendCoupans: PropTypes.func.isRequired,
    getCoupanbyId: PropTypes.func.isRequired,
    admin: PropTypes.object.isRequired,
    coupan: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    admin: state.admin,
    coupan: state.coupan,
    errors: state.errors,
});

export default connect(mapStateToProps, { SendCoupans, getCoupanbyId })(
    withRouter(SendPromo)
);
