import React, { Component } from "react";
import { Link } from "react-router-dom";
import DeckCatNav from "./DeckCatNav";
import { getUserDeckCat } from "../../../../../actions/questionActions.jsx";
import PropTypes from "prop-types";
import { connect } from "react-redux";

class StudyDeck extends Component {
    constructor(props) {
        super(props);
        this.state = {
            book2active: "",
        };
    }

    onChangeBookClick(id) {
        this.props.getUserDeckCat(id);

        this.setState({ book2active: id });
    }

    render() {
        const { deck_cats } = this.props.question;
        let deckCatContent;
        if (deck_cats.length > 0) {
            deckCatContent = <DeckCatNav deck_cats={deck_cats} />;
        }
        const DeckList = this.props.deck_books.map((deck_book) => (
            <li
                className={
                    (this.state.book2active === deck_book.ZINBOOK
                        ? "active expand "
                        : "closed") + "has-sub"
                }
                key={deck_book.ZINBOOK}
            >
                <span
                    className="nav-study"
                    onClick={
                        this.state.book2active === deck_book.ZINBOOK
                            ? () => this.setState({ book2active: "" })
                            : this.onChangeBookClick.bind(
                                  this,
                                  deck_book.ZINBOOK
                              )
                    }
                >
                    <b className="caret"></b>
                    {deck_book.ZBOOKNAME}
                </span>
                <ul
                    className={
                        "sub-menu " +
                        (this.state.book2active === deck_book.ZINBOOK
                            ? "d-block "
                            : "d-none")
                    }
                >
                    <li>
                        <Link
                            to={`/user/watch?mode=normal&type=book&id=${deck_book.ZINBOOK}&num=1&zid=${deck_book._id}&zinbookcat=${deck_book.ZINBOOKCATEGORY}`}
                        >
                            All {deck_book.ZBOOKNAME}
                        </Link>
                    </li>
                    {deckCatContent}
                </ul>
            </li>
        ));
        return DeckList;
    }
}

StudyDeck.propTypes = {
    getUserDeckCat: PropTypes.func.isRequired,
    question: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    question: state.question,
});

export default connect(mapStateToProps, { getUserDeckCat })(StudyDeck);
