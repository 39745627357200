import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "../../common/common.jsx";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import { getQuestionbySlug } from "../../../../actions/questionActions.jsx";

//import Spinner from "./../common/textfield/Spinner";
import isEmpty from "../../../../validation/is-empty";
import ModalImage from "react-modal-image";
import SweetAlert from "react-bootstrap-sweetalert";
import Comments from "./Comments.jsx";

class SiteQuestion extends Component {
    constructor() {
        super();
        this.state = {
            type: "",
            id: "",
            num: "",
            cat: "",
            ZSUBCATEGORYNAME: "",
            ZNUMBER: "",
            ZQUESTION: "",
            ZANSWERONE: "",
            ZANSWERTWO: "",
            ZANSWERTHREE: "",
            ZANSWERFOUR: "",
            ZSLUG: "",
            total_ques: "",
            ZINBOOKCATEGORY: "",
            ZANSWER: "",
            bgcolora: "",
            bgcolorb: "",
            bgcolorc: "",
            bgcolord: "",
            peventa: "",
            peventb: "",
            peventc: "",
            peventd: "",
            mode: "",
            correctanswer: 0,
            wronganswer: 0,
            percentage: 0,
            addnumber: 1,
            answeronly: false,
            shuffle: false,
            sweetAlertInfo: false,
            ZIMAGE: "",
            ZIMAGENAME: "",
        };
        this.onShuffle = this.onShuffle.bind(this);
        this.onUnShuffle = this.onUnShuffle.bind(this);
        this.toggleSweetAlert = this.toggleSweetAlert.bind(this);
        this.routeChange = this.routeChange.bind(this);
    }

    toggleSweetAlert(name) {
        switch (name) {
            case "info":
                this.setState({ sweetAlertInfo: !this.state.sweetAlertInfo });
                break;

            default:
                break;
        }
    }
    componentDidMount() {
        if (this.props.match.params.Id) {
            this.props.getQuestionbySlug(this.props.match.params.Id);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.question.total) {
            this.setState({ total_ques: nextProps.question.total });
        }

        if (nextProps.question.question) {
            const question = nextProps.question.question;

            question.ZSUBCATEGORYNAME = !isEmpty(question.ZSUBCATEGORYNAME)
                ? question.ZSUBCATEGORYNAME
                : "";
            question.ZNUMBER = !isEmpty(question.ZNUMBER)
                ? question.ZNUMBER
                : "";
            question.ZQUESTION = !isEmpty(question.ZQUESTION)
                ? question.ZQUESTION
                : "";
            question.ZANSWERONE = !isEmpty(question.ZANSWERONE)
                ? question.ZANSWERONE
                : "";
            question.ZANSWERTWO = !isEmpty(question.ZANSWERTWO)
                ? question.ZANSWERTWO
                : "";
            question.ZANSWERTHREE = !isEmpty(question.ZANSWERTHREE)
                ? question.ZANSWERTHREE
                : "";
            question.ZANSWERFOUR = !isEmpty(question.ZANSWERFOUR)
                ? question.ZANSWERFOUR
                : "";
            question.ZINBOOKCATEGORY = !isEmpty(question.ZINBOOKCATEGORY)
                ? question.ZINBOOKCATEGORY
                : "";
            question.ZANSWER = !isEmpty(question.ZANSWER)
                ? question.ZANSWER
                : "";
            question.ZSLUG = !isEmpty(question.ZSLUG) ? question.ZSLUG : "";
            question.ZIMAGE = !isEmpty(question.ZIMAGE) ? question.ZIMAGE : "";
            question.ZIMAGENAME = !isEmpty(question.ZIMAGENAME)
                ? question.ZIMAGENAME
                : "";

            // Set component fields state for blog
            this.setState({
                ZSUBCATEGORYNAME: question.ZSUBCATEGORYNAME,
                ZNUMBER: question.ZNUMBER,
                ZQUESTION: question.ZQUESTION,
                ZANSWERONE: question.ZANSWERONE,
                ZANSWERTWO: question.ZANSWERTWO,
                ZANSWERTHREE: question.ZANSWERTHREE,
                ZANSWERFOUR: question.ZANSWERFOUR,
                ZINBOOKCATEGORY: question.ZINBOOKCATEGORY,
                ZANSWER: question.ZANSWER,
                ZSLUG: question.ZSLUG,
                ZIMAGE: question.ZIMAGE,
                ZIMAGENAME: question.ZIMAGENAME,
            });
            if (this.state.answeronly === true) {
                if (this.state.ZANSWER === "a") {
                    this.setState({
                        bgcolora: "Chartreuse",
                        bgcolorb: "",
                        bgcolorc: "",
                        bgcolord: "",
                        peventa: "none",
                        peventb: "none",
                        peventc: "none",
                        peventd: "none",
                    });
                } else if (this.state.ZANSWER === "b") {
                    this.setState({
                        bgcolorb: "Chartreuse",
                        bgcolora: "",
                        bgcolorc: "",
                        bgcolord: "",
                        peventa: "none",
                        peventb: "none",
                        peventc: "none",
                        peventd: "none",
                    });
                } else if (this.state.ZANSWER === "c") {
                    this.setState({
                        bgcolorc: "Chartreuse",
                        bgcolora: "",
                        bgcolorb: "",
                        bgcolord: "",
                        peventa: "none",
                        peventb: "none",
                        peventc: "none",
                        peventd: "none",
                    });
                } else if (this.state.ZANSWER === "d") {
                    this.setState({
                        bgcolord: "Chartreuse",
                        bgcolora: "",
                        bgcolorb: "",
                        bgcolorc: "",
                        peventa: "none",
                        peventb: "none",
                        peventc: "none",
                        peventd: "none",
                    });
                }
            }
        }
    }

    onAnswerClick(id) {
        let correct = this.state.correctanswer + this.state.addnumber;
        let wrong = this.state.wronganswer + this.state.addnumber;
        let adddata =
            this.state.wronganswer +
            this.state.correctanswer +
            this.state.addnumber;
        if (id === "a") {
            if (this.state.ZANSWER === "a") {
                let percent = Math.round((correct / adddata) * 100);
                this.setState({
                    bgcolora: "Chartreuse",
                    correctanswer: correct,
                    percentage: percent,
                });
            } else if (this.state.ZANSWER === "b") {
                let percent = Math.round(
                    (this.state.correctanswer / adddata) * 100
                );
                this.setState({
                    bgcolora: "red",
                    bgcolorb: "Chartreuse",
                    wronganswer: wrong,
                    percentage: percent,
                });
            } else if (this.state.ZANSWER === "c") {
                let percent = Math.round(
                    (this.state.correctanswer / adddata) * 100
                );
                this.setState({
                    bgcolora: "red",
                    bgcolorc: "Chartreuse",
                    wronganswer: wrong,
                    percentage: percent,
                });
            } else if (this.state.ZANSWER === "d") {
                let percent = Math.round(
                    (this.state.correctanswer / adddata) * 100
                );
                this.setState({
                    bgcolora: "red",
                    bgcolord: "Chartreuse",
                    wronganswer: wrong,
                    percentage: percent,
                });
            }
        } else if (id === "b") {
            if (this.state.ZANSWER === "a") {
                let percent = Math.round(
                    (this.state.correctanswer / adddata) * 100
                );
                this.setState({
                    wronganswer: wrong,
                    bgcolora: "Chartreuse",
                    bgcolorb: "red",
                    percentage: percent,
                });
            } else if (this.state.ZANSWER === "b") {
                let percent = Math.round((correct / adddata) * 100);
                this.setState({
                    bgcolorb: "Chartreuse",
                    correctanswer: correct,
                    percentage: percent,
                });
            } else if (this.state.ZANSWER === "c") {
                let percent = Math.round(
                    (this.state.correctanswer / adddata) * 100
                );
                this.setState({
                    wronganswer: wrong,
                    bgcolorb: "red",
                    bgcolorc: "Chartreuse",
                    percentage: percent,
                });
            } else if (this.state.ZANSWER === "d") {
                let percent = Math.round(
                    (this.state.correctanswer / adddata) * 100
                );
                this.setState({
                    wronganswer: wrong,
                    bgcolorb: "red",
                    bgcolord: "Chartreuse",
                    percentage: percent,
                });
            }
        } else if (id === "c") {
            if (this.state.ZANSWER === "a") {
                let percent = Math.round(
                    (this.state.correctanswer / adddata) * 100
                );
                this.setState({
                    wronganswer: wrong,
                    bgcolora: "Chartreuse",
                    bgcolorc: "red",
                    percentage: percent,
                });
            } else if (this.state.ZANSWER === "b") {
                let percent = Math.round(
                    (this.state.correctanswer / adddata) * 100
                );
                this.setState({
                    wronganswer: wrong,
                    bgcolorc: "red",
                    bgcolorb: "Chartreuse",
                    percentage: percent,
                });
            } else if (this.state.ZANSWER === "c") {
                let percent = Math.round((correct / adddata) * 100);
                this.setState({
                    correctanswer: correct,
                    bgcolorc: "Chartreuse",
                    percentage: percent,
                });
            } else if (this.state.ZANSWER === "d") {
                let percent = Math.round(
                    (this.state.correctanswer / adddata) * 100
                );
                this.setState({
                    wronganswer: wrong,
                    bgcolorc: "red",
                    bgcolord: "Chartreuse",
                    percentage: percent,
                });
            }
        } else if (id === "d") {
            if (this.state.ZANSWER === "a") {
                let percent = Math.round(
                    (this.state.correctanswer / adddata) * 100
                );
                this.setState({
                    wronganswer: wrong,
                    bgcolord: "red",
                    bgcolora: "Chartreuse",
                    percentage: percent,
                });
            } else if (this.state.ZANSWER === "b") {
                let percent = Math.round(
                    (this.state.correctanswer / adddata) * 100
                );
                this.setState({
                    wronganswer: wrong,
                    bgcolord: "red",
                    bgcolorb: "Chartreuse",
                    percentage: percent,
                });
            } else if (this.state.ZANSWER === "c") {
                let percent = Math.round(
                    (this.state.correctanswer / adddata) * 100
                );
                this.setState({
                    wronganswer: wrong,
                    bgcolord: "red",
                    bgcolorc: "Chartreuse",
                    percentage: percent,
                });
            } else if (this.state.ZANSWER === "d") {
                let percent = Math.round((correct / adddata) * 100);
                this.setState({
                    correctanswer: correct,
                    bgcolord: "Chartreuse",
                    percentage: percent,
                });
            }
        }
        this.setState({
            peventa: "none",
            peventb: "none",
            peventc: "none",
            peventd: "none",
        });
    }

    onShuffle() {
        this.setState({ shuffle: true });
        let path = `/question/${this.state._id}`;
        this.props.history.push(path);
    }

    onUnShuffle() {
        this.setState({ shuffle: false });
        let path = `/question/${this.state._id}`;
        this.props.history.push(path);
    }

    routeChange() {
        let path = `/register`;
        this.props.history.push(path);
    }

    render() {
        let mainButton;

        if (this.state.ZINBOOKCATEGORY === "1") {
            mainButton = (
                <Link
                    to={`/question/watch?mode=normal&type=bookcat&id=1&num=1&zid=5eccef92bacbc34ba4d4260e`}
                    type="button"
                    className="forward adjust-btn demo4"
                >
                    Next <i className="fa fa-angle-double-right"></i>
                </Link>
            );
        } else {
            mainButton = (
                <Link
                    to={`/question/watch?mode=normal&type=bookcat&id=2&num=1&zid=5eccee74bacbc34ba4d3ff0f`}
                    type="button"
                    className="forward adjust-btn demo4"
                >
                    Next <i className="fa fa-angle-double-right"></i>
                </Link>
            );
        }

        let maincount;
        if (this.state.ZINBOOKCATEGORY === "1") {
            maincount = 21426;
        } else {
            maincount = 13821;
        }

        let bookCategoryName;
        if (this.state.ZINBOOKCATEGORY === "1") {
            bookCategoryName = "Engine";
        } else {
            bookCategoryName = "Deck";
        }

        //Answer content start here
        let answeronlyContent;
        if (this.state.answeronly) {
            answeronlyContent = (
                <button
                    onClick={() => {
                        this.setState((prevState) => ({ answeronly: false }));
                    }}
                    className="btn btn-link"
                >
                    <i className="fa fa-angle-double-right"></i> Normal
                </button>
            );
        } else {
            answeronlyContent = (
                <button
                    onClick={() => {
                        this.setState((prevState) => ({ answeronly: true }));
                    }}
                    className="btn btn-link"
                >
                    <i className="fa fa-angle-double-right"></i> Answers Only
                </button>
            );
        }

        //Shuffle content start here
        let shuffleContent;
        if (this.state.shuffle) {
            shuffleContent = (
                <button onClick={this.onUnShuffle} className="btn btn-link">
                    <i className="fa fa-angle-double-right"></i> Un-Shuffle
                </button>
            );
        } else {
            shuffleContent = (
                <button onClick={this.onShuffle} className="btn btn-link">
                    <i className="fa fa-angle-double-right"></i> Shuffle
                </button>
            );
        }

        return (
            <div>
                <Header />

                <div className="dashboard">
                    {this.state.sweetAlertInfo && (
                        <SweetAlert
                            info
                            showCancel
                            confirmBtnText="Register"
                            confirmBtnBsStyle="info"
                            cancelBtnBsStyle="default"
                            title="Subscription Alerts"
                            onConfirm={this.routeChange}
                            onCancel={() => this.toggleSweetAlert("info")}
                        >
                            You have to be a premium user to favorite or hide
                            questions.
                        </SweetAlert>
                    )}
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 col-xs-12"></div>
                        </div>
                        <div className="post-list" id="postList">
                            <div className="row">
                                <div className="col-sm-12 col-xs-12">
                                    <div className="dashright dass-wraper">
                                        <div className="row">
                                            <div className="col-md-8 padd">
                                                <div className="bomb-wrapper">
                                                    <div className="col-md-6 for-logo">
                                                        {/*<!--	<figure><img src="" alt="" width="250"></figure> -->*/}
                                                    </div>
                                                    <div className="col-md-6 text-right for-bomb-icon">
                                                        <span
                                                            onClick={() =>
                                                                this.toggleSweetAlert(
                                                                    "info"
                                                                )
                                                            }
                                                        >
                                                            <img
                                                                src="https://seatrials-bucket.s3.us-east-2.amazonaws.com/main/icons8-explosive-100.png"
                                                                alt="explosive"
                                                            />
                                                        </span>

                                                        <span
                                                            onClick={() =>
                                                                this.toggleSweetAlert(
                                                                    "info"
                                                                )
                                                            }
                                                        >
                                                            <img
                                                                src="https://seatrials-bucket.s3.us-east-2.amazonaws.com/main/icons8-bookmark-100.png"
                                                                alt="bookmark"
                                                            />
                                                        </span>
                                                    </div>
                                                </div>

                                                <div
                                                    id="wizard_container"
                                                    className="wizard"
                                                    noValidate="novalidate"
                                                >
                                                    <div className="q-number-wrapper">
                                                        <div className="col-md-6 for-only-q">
                                                            <span
                                                                title=""
                                                                className="user-badge"
                                                                style={{
                                                                    backgroundcolor:
                                                                        "#04aad4",
                                                                }}
                                                            >
                                                                {
                                                                    bookCategoryName
                                                                }
                                                            </span>
                                                            <span className="author-name">
                                                                {
                                                                    this.state
                                                                        .ZSUBCATEGORYNAME
                                                                }{" "}
                                                            </span>
                                                        </div>

                                                        <div className="col-md-6 text-right for-only-q">
                                                            <strong>
                                                                NUMBER: #1/
                                                                {maincount}
                                                            </strong>
                                                        </div>
                                                    </div>
                                                    {/*<!-- /top-wizard -->*/}
                                                    <form
                                                        name="example-1"
                                                        id="wrapped"
                                                        method="POST"
                                                        action="questionare_send.php"
                                                        className="wizard-form"
                                                    >
                                                        <div
                                                            id="middle-wizard"
                                                            className="wizard-branch wizard-wrapper for-qs"
                                                        >
                                                            <div
                                                                className="step wizard-step current"
                                                                style={{
                                                                    display:
                                                                        "block",
                                                                }}
                                                            >
                                                                <p className="main_question wizard-header">
                                                                    <strong>
                                                                        {
                                                                            this
                                                                                .state
                                                                                .ZNUMBER
                                                                        }
                                                                    </strong>
                                                                </p>
                                                                <h3 className="main_question wizard-header q-text">
                                                                    <strong>
                                                                        {
                                                                            this
                                                                                .state
                                                                                .ZQUESTION
                                                                        }
                                                                    </strong>
                                                                </h3>

                                                                <div
                                                                    id="main"
                                                                    className="row"
                                                                >
                                                                    <div className="col-sm-12">
                                                                        <div className="form-group radio_input q-hover">
                                                                            <label
                                                                                className="text-s"
                                                                                id="a"
                                                                                style={{
                                                                                    backgroundColor:
                                                                                        this
                                                                                            .state
                                                                                            .bgcolora,
                                                                                    pointerEvents:
                                                                                        this
                                                                                            .state
                                                                                            .peventa,
                                                                                }}
                                                                                onClick={this.onAnswerClick.bind(
                                                                                    this,
                                                                                    "a"
                                                                                )}
                                                                            >
                                                                                <b>
                                                                                    A){" "}
                                                                                </b>{" "}
                                                                                <span className="f-c">
                                                                                    {
                                                                                        this
                                                                                            .state
                                                                                            .ZANSWERONE
                                                                                    }
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-group radio_input q-hover">
                                                                            <label
                                                                                className="text-s"
                                                                                id="b"
                                                                                style={{
                                                                                    backgroundColor:
                                                                                        this
                                                                                            .state
                                                                                            .bgcolorb,
                                                                                    pointerEvents:
                                                                                        this
                                                                                            .state
                                                                                            .peventb,
                                                                                }}
                                                                                onClick={this.onAnswerClick.bind(
                                                                                    this,
                                                                                    "b"
                                                                                )}
                                                                            >
                                                                                <b>
                                                                                    B){" "}
                                                                                </b>{" "}
                                                                                <span className="f-c">
                                                                                    {
                                                                                        this
                                                                                            .state
                                                                                            .ZANSWERTWO
                                                                                    }
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-group radio_input q-hover">
                                                                            <label
                                                                                className="text-s"
                                                                                id="c"
                                                                                style={{
                                                                                    backgroundColor:
                                                                                        this
                                                                                            .state
                                                                                            .bgcolorc,
                                                                                    pointerEvents:
                                                                                        this
                                                                                            .state
                                                                                            .peventc,
                                                                                }}
                                                                                onClick={this.onAnswerClick.bind(
                                                                                    this,
                                                                                    "c",
                                                                                    this
                                                                                        .state
                                                                                        .correctanswer,
                                                                                    this
                                                                                        .state
                                                                                        .wronganswer,
                                                                                    this
                                                                                        .state
                                                                                        .percentage
                                                                                )}
                                                                            >
                                                                                <b>
                                                                                    C){" "}
                                                                                </b>{" "}
                                                                                <span className="f-c">
                                                                                    {
                                                                                        this
                                                                                            .state
                                                                                            .ZANSWERTHREE
                                                                                    }
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-group radio_input q-hover">
                                                                            <label
                                                                                className="text-s"
                                                                                id="d"
                                                                                style={{
                                                                                    backgroundColor:
                                                                                        this
                                                                                            .state
                                                                                            .bgcolord,
                                                                                    pointerEvents:
                                                                                        this
                                                                                            .state
                                                                                            .peventd,
                                                                                }}
                                                                                onClick={this.onAnswerClick.bind(
                                                                                    this,
                                                                                    "d"
                                                                                )}
                                                                            >
                                                                                <b>
                                                                                    D){" "}
                                                                                </b>{" "}
                                                                                <span className="f-c">
                                                                                    {
                                                                                        this
                                                                                            .state
                                                                                            .ZANSWERFOUR
                                                                                    }
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {/*<!-- /row -->*/}
                                                            </div>
                                                            {/*<!-- /step -->*/}
                                                        </div>
                                                    </form>
                                                    <div className="q-number-wrapper for-btn for-top-btn">
                                                        <div
                                                            id="main-score"
                                                            className="col-md-7 text-left for-only-q"
                                                        >
                                                            <ul
                                                                className="question-statistic and-dsc"
                                                                id="question"
                                                            >
                                                                <li className="s-text">
                                                                    <p className="question-views clr">
                                                                        Correct:{" "}
                                                                        {
                                                                            this
                                                                                .state
                                                                                .correctanswer
                                                                        }
                                                                    </p>
                                                                </li>

                                                                <li className="s-text s-text-1">
                                                                    <p
                                                                        itemProp="answerCount"
                                                                        className="question-answers question-views clr"
                                                                    >
                                                                        Wrong:{" "}
                                                                        {
                                                                            this
                                                                                .state
                                                                                .wronganswer
                                                                        }{" "}
                                                                    </p>
                                                                </li>

                                                                <li className="s-text s-text-2">
                                                                    <p
                                                                        itemProp="upvoteCount"
                                                                        className="question-votes question-views clr"
                                                                    >
                                                                        Percentage:{" "}
                                                                        {
                                                                            this
                                                                                .state
                                                                                .percentage
                                                                        }
                                                                        %
                                                                    </p>
                                                                </li>
                                                            </ul>
                                                        </div>

                                                        <div className="col-md-5 text-right for-only-q">
                                                            {mainButton}
                                                        </div>
                                                    </div>
                                                </div>
                                                <Comments />
                                            </div>
                                            {/*<!-- left col-end-->*/}

                                            <div className="col-md-4">
                                                <div className="dashleft">
                                                    <h3>STUDY MODE</h3>
                                                    <ul>
                                                        <li>
                                                            {answeronlyContent}
                                                        </li>

                                                        <li>
                                                            {shuffleContent}
                                                        </li>
                                                    </ul>
                                                    <h3>OPTIONS</h3>
                                                    <ul>
                                                        <li>
                                                            <button
                                                                onClick={() => {
                                                                    this.setState(
                                                                        (
                                                                            prevState
                                                                        ) => ({
                                                                            correctanswer: 0,
                                                                            wronganswer: 0,
                                                                            percentage: 0,
                                                                        })
                                                                    );
                                                                }}
                                                                className="btn btn-link"
                                                            >
                                                                <i className="fa fa-angle-double-right"></i>{" "}
                                                                Clear Score
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div>

                                                <div id="right-form">
                                                    <figure>
                                                        {this.state.ZIMAGE ? (
                                                            <ModalImage
                                                                small={
                                                                    this.state
                                                                        .ZIMAGE
                                                                }
                                                                large={
                                                                    this.state
                                                                        .ZIMAGE
                                                                }
                                                                alt={
                                                                    this.state
                                                                        .ZIMAGENAME
                                                                }
                                                            />
                                                        ) : (
                                                            <img
                                                                id="myImg"
                                                                src="https://seatrials-bucket.s3.us-east-2.amazonaws.com/main/q-logo.png"
                                                                alt="logo-q"
                                                            />
                                                        )}
                                                    </figure>
                                                </div>
                                                {/*<!-- right col-end-->*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

SiteQuestion.propTypes = {
    getQuestionbySlug: PropTypes.func.isRequired,
    question: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    question: state.question,
});

export default connect(mapStateToProps, { getQuestionbySlug })(
    withRouter(SiteQuestion)
);
