import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

const TextFieldArea = ({
    name,
    placeholder,
    value,
    label,
    error,
    info,
    striks,
    type,
    onChange,
    disabled,
}) => {
    return (
        <div className="col-sm-12 col-xs-12 marg-bt">
            {" "}
            {/* added class by yashi */}
            <label htmlFor="inputEmail">{label}</label>
            <input
                type={type}
                className={classnames("form-control", {
                    "is-invalid": error,
                })}
                placeholder={placeholder}
                name={name}
                onChange={onChange}
                disabled={disabled}
                value={value}
            />
            {error && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

TextFieldArea.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.string.isRequired,
    info: PropTypes.string,
    error: PropTypes.string,
    type: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.string,
};

export default TextFieldArea;
