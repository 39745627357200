import React, { Component } from "react";
import ReactTable from "react-table";
import { Link } from "react-router-dom";
import { Panel, PanelHeader } from "../common/panel/Panel.jsx";
import "react-table/react-table.css";

class CommentList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alertblog: false,
        };

        //this.data = makeData();
        this.defaultSorted = [
            {
                id: "_id",
                desc: true,
            },
        ];
        this.tableColumns = [
            {
                Header: "Id",
                id: "row",
                maxWidth: 50,
                filterable: false,
                Cell: (row) => {
                    return <div>{row.index + 1}</div>;
                },
            },

            {
                Header: "User Name",
                accessor: "user_name",
                Cell: (row) => (
                    <span>
                        {row.original._id ? (
                            row.original._id.user_name
                        ) : (
                            <span className="label label-default m-r-3">
                                Undefine
                            </span>
                        )}
                    </span>
                ),
            },

            {
                Header: "Email",
                accessor: "email",
                Cell: (row) => (
                    <span>
                        {row.original._id ? (
                            row.original._id.email
                        ) : (
                            <span className="label label-default m-r-3">
                                Undefine
                            </span>
                        )}
                    </span>
                ),
            },

            {
                Header: "Total Count",
                accessor: "tags",
                Cell: (row) => (
                    <span>
                        <span className="label label-default m-r-3">
                            {row.value}
                        </span>
                    </span>
                ),
            },

            {
                Header: "Actions",
                accessor: "_id",
                filterable: false,
                sortable: false,
                Cell: (row) => (
                    <div>
                        <Link
                            to={`/admin/comment/comment-list/${row.original._id._id}`}
                            className="btn btn-primary btn-sm m-r-5 "
                        >
                            <i className="fas fa-sm fa-fw m-r-2 fa-paste"> </i>{" "}
                            View Comment List
                        </Link>
                    </div>
                ),
            },
        ];
    }

    render() {
        const data = this.props.comments;

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-12">
                        <Panel>
                            <PanelHeader>Subscriber table</PanelHeader>

                            <ReactTable
                                filterable
                                useSortBy
                                data={data}
                                columns={this.tableColumns}
                                defaultPageSize={10}
                                defaultSorted={this.defaultSorted}
                                className="-highlight"
                            />
                        </Panel>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default CommentList;
