import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Container from "../content/Container.jsx";
import { Panel, PanelHeader, PanelBody } from "../common/panel/Panel.jsx";
import TextFieldArea from "../common/textfield/TextFieldArea";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { getMessagebyId } from "../../../actions/coupanActions.jsx";
import isEmpty from "../../../validation/is-empty";

class ViewMessage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customer_email: "",
            promo_subject: "",
            data: "",
            coupan_id: "",
            coupan_code: "",
            coupan_off: "",
            subscription_type: "",
            alert: false,
            AlertBox: false,
            visible: false,
            errors: {},
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        if (this.props.match.params.Id) {
            this.props.getMessagebyId(this.props.match.params.Id);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.coupan.promo) {
            const promo = nextProps.coupan.promo;

            promo.customer_email = !isEmpty(promo.customer_email)
                ? promo.customer_email
                : "";
            promo.promo_subject = !isEmpty(promo.promo_subject)
                ? promo.promo_subject
                : "";
            promo.promo_message = !isEmpty(promo.promo_message)
                ? promo.promo_message
                : "";
            promo.coupan_id = !isEmpty(promo.coupan_id) ? promo.coupan_id : {};
            promo.coupan_code = !isEmpty(promo.coupan_id.coupan_code)
                ? promo.coupan_id.coupan_code
                : "";
            promo.subscription_type = !isEmpty(
                promo.coupan_id.subscription_type
            )
                ? promo.coupan_id.subscription_type
                : "";

            // Set component fields state for blog
            this.setState({
                customer_email: promo.customer_email,
                promo_subject: promo.promo_subject,
                data: promo.promo_message,
                coupan_code: promo.coupan_code,
                subscription_type: promo.subscription_type,
            });
        }
    }
    handleChange(evt) {
        this.setState({
            data: evt,
        });
    }

    render() {
        return (
            <React.Fragment>
                <Container>
                    <div className="content">
                        <div>
                            <ol className="breadcrumb float-xl-right">
                                <li className="breadcrumb-item">
                                    <Link to="/admin/dashboard">Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to="/admin/coupan-code/sent-mail">
                                        Sent Mail
                                    </Link>
                                </li>
                                <li className="breadcrumb-item active">
                                    Send Promo Code
                                </li>
                            </ol>
                            <h1 className="page-header">Send Promo Code </h1>
                            <div className="row">
                                <div className="col-xl-12">
                                    <Panel>
                                        <PanelHeader>
                                            Compose Message
                                        </PanelHeader>
                                        <PanelBody>
                                            <div className="alert alert-secondary m-b-15 text-center">
                                                <h4>
                                                    <b>
                                                        Promo-Code :{" "}
                                                        {this.state.coupan_code}{" "}
                                                        ({" "}
                                                        {this.state
                                                            .subscription_type ===
                                                        "all" ? (
                                                            <span>
                                                                Applicable to
                                                                All
                                                            </span>
                                                        ) : this.state
                                                              .subscription_type ===
                                                          "one_month" ? (
                                                            <span>
                                                                One Month
                                                                Subscription
                                                            </span>
                                                        ) : this.state
                                                              .subscription_type ===
                                                          "three_months" ? (
                                                            <span>
                                                                Three Month
                                                                Subscription
                                                            </span>
                                                        ) : this.state
                                                              .subscription_type ===
                                                          "life_time" ? (
                                                            <span>
                                                                Life Time
                                                                Subscription
                                                            </span>
                                                        ) : (
                                                            <span>None</span>
                                                        )}
                                                        )
                                                    </b>
                                                </h4>
                                            </div>
                                            <div className="panel-form">
                                                <form
                                                    className="form-horizontal form-bordered"
                                                    onSubmit={this.onSubmit}
                                                >
                                                    <TextFieldArea
                                                        label="To"
                                                        placeholder="example@example.com"
                                                        name="customer_email"
                                                        type="email"
                                                        value={
                                                            this.state
                                                                .customer_email
                                                        }
                                                        striks="*"
                                                    />

                                                    <TextFieldArea
                                                        label="Subject"
                                                        placeholder="Subject"
                                                        name="promo_subject"
                                                        type="text"
                                                        value={
                                                            this.state
                                                                .promo_subject
                                                        }
                                                        striks="*"
                                                    />

                                                    <div className="form-group row">
                                                        <label className="col-lg-3 col-form-label">
                                                            Description
                                                        </label>
                                                        <div className="col-lg-9">
                                                            <SunEditor
                                                                name="data"
                                                                type="text"
                                                                data={
                                                                    this.state
                                                                        .data
                                                                }
                                                                setContents={
                                                                    this.state
                                                                        .data
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleChange
                                                                }
                                                                height="200"
                                                            />
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </PanelBody>
                                    </Panel>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </React.Fragment>
        );
    }
}

ViewMessage.propTypes = {
    getMessagebyId: PropTypes.func.isRequired,
    coupan: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    coupan: state.coupan,
    errors: state.errors,
});

export default connect(mapStateToProps, { getMessagebyId })(
    withRouter(ViewMessage)
);
