import axios from "axios";
//import { setAlert } from "./alertActions";
import { V1_APPEND_COMMENT, V1_DELETE_COMMENT, V1_GET_ERRORS } from "./types";

//Add Pages
export const addComment = (commentData) => (dispatch) => {
    axios
        .post("/api/mlp_comment/add_comments", commentData)
        .then((res) =>
            dispatch({
                type: V1_APPEND_COMMENT,
                payload: {
                    _id: res.data._id,
                    commentId: "",
                    mcqId: "",
                    clientId: "",
                    comment: res.data.comments,
                    insertDate: res.data.insert_date,
                    updateDate: "",
                    status: res.data.status,
                    zmcqId: res.data.zmcq_id,
                    userId: res.data.user_id._id,
                    user: {
                        _id: res.data.user_id._id,
                        image: res.data.user_id.image,
                        user_name: res.data.user_id.user_name,
                    },
                },
            })
        )
        .catch((err) =>
            dispatch({
                type: V1_GET_ERRORS,
                payload: err.response.data,
            })
        );
};

// Delete Subscriber
export const deleteComment = (id) => (dispatch) => {
    axios
        .delete(`/api/v1/private/comment/${id}`)
        .then((res) =>
            dispatch({
                type: V1_DELETE_COMMENT,
                payload: id,
            })
        )
        .catch((err) =>
            dispatch({
                type: V1_GET_ERRORS,
                payload: err.response.data,
            })
        );
};
