import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import EditComment from "../../../components/member/comments/EditComment";
import MainComments from "../../../components/member/comments/MainComments";
import BookQuestion from "../../../components/member/question/bookmark/BookQuestion";
import UserQuestion from "../../../components/member/question/main/UserQuestion";
import SweetAlertReg from "../../../components/member/sweetalert/SweetAlertReg";
import PageNotFound from "../../../components/PageNotFound";
import PrivateRoute from "../PrivateRoute";
import AccountRoutes from "./AccountRoutes";
// import ForumRoutes from "./ForumRoutes";
import ManageQuestionRoutes from "./ManageQuestionRoutes";
import SearchRoutes from "./SearchRoutes";
import TestRoutes from "./TestRoutes";

const UserRoutes = () => {
    let { path } = useRouteMatch();

    return (
        <Switch>
            <PrivateRoute
                exact
                path={`${path}/manage-comments`}
                component={MainComments}
            />
            <PrivateRoute
                exact
                path={`${path}/manage-comment/edit-comment/:Id`}
                component={EditComment}
            />
            <PrivateRoute
                exact
                path={`${path}/watch`}
                component={UserQuestion}
            />
            <PrivateRoute
                exact
                path={`${path}/bookmarked-question/watch`}
                component={BookQuestion}
            />
            <PrivateRoute
                exact
                path={`${path}/alert`}
                component={SweetAlertReg}
            />

            <Route path={`${path}/account`} component={AccountRoutes} />
            <Route path={`${path}/test`} component={TestRoutes} />
            <Route
                path={`${path}/manage-question`}
                component={ManageQuestionRoutes}
            />
            <Route path={`${path}/search`} component={SearchRoutes} />
            <Route path="*" component={PageNotFound} />
        </Switch>
    );
};

export default UserRoutes;
