import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "../common/common.jsx";
import Header from "../common/Header";
import Footer from "../common/Footer";
import queryString from "qs";
import SearchTable from "./SearchTable.jsx";
import { getSearch } from "../../../actions/searchActions.jsx";
import Spinner from "../common/textfield/Spinner";
import classnames from "classnames";
import { Panel, PanelHeader, PanelBody } from "../common/panel/Panel.jsx";

class Search extends Component {
    constructor(props) {
        super(props);
        this.state = {
            keyword: "",
            topic: "",
            area: "",
            search: false,
            errors: {},
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    componentDidMount() {
        const queryStringValues = queryString.parse(
            this.props.location.search.slice(1)
        );

        if (queryStringValues.keyword === undefined) {
            // console.log("hii3")
            this.props.getSearch(1);
        } else if (queryStringValues.keyword === "") {
            // console.log("hii1")
            this.props.getSearch(
                1,
                queryStringValues.topic,
                queryStringValues.area
            );
        } else {
            // console.log("hii")
            this.props.getSearch(
                queryStringValues.keyword,
                queryStringValues.topic,
                queryStringValues.area
            );
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            keyword: localStorage.getItem("keyword"),
            topic: localStorage.getItem("topic"),
            area: localStorage.getItem("area"),
        });
    }

    handleValidation() {
        let fields = this.state;
        let errors = {};
        let formIsValid = true;

        //Email validation
        if (!fields["keyword"]) {
            formIsValid = false;
            errors["keyword"] = "Keyword cannot be empty";
        }
        if (!fields["topic"]) {
            formIsValid = false;
            errors["topic"] = "Topic cannot be empty";
        }
        if (!fields["area"]) {
            formIsValid = false;
            errors["area"] = "Area cannot be empty";
        }

        this.setState({ errors: errors });

        if (formIsValid === false) {
            return formIsValid;
        }
    }

    onSubmit(e) {
        e.preventDefault();
        this.handleValidation();

        if (
            this.state.keyword !== "" &&
            this.state.topic !== "" &&
            this.state.area !== ""
        ) {
            localStorage.setItem("keyword", this.state.keyword);
            localStorage.setItem("topic", this.state.topic);
            localStorage.setItem("area", this.state.area);
            this.props.getSearch(
                this.state.keyword,
                this.state.topic,
                this.state.area
            );
        }
    }
    render() {
        const { searchs, loading } = this.props.search;
        let searchContent;
        if (searchs === null || loading) {
            searchContent = <Spinner />;
        } else {
            if (searchs.length > 0) {
                searchContent = <SearchTable searchs={searchs} />;
            } else {
                searchContent = (
                    <div className="row">
                        <div className="col-12">
                            <Panel className="member-panel">
                                <PanelHeader>Search Result</PanelHeader>
                                <PanelBody>
                                    <div className="note note-secondary m-b-15">
                                        <div className="note-icon">
                                            <i className="fa fa-lightbulb"></i>
                                        </div>
                                        <div className="note-content text-right">
                                            <h4>
                                                <b>This Table Is Empty</b>
                                            </h4>
                                            <p>
                                                {" "}
                                                No data found to show here. This
                                                table is empty.{" "}
                                            </p>
                                        </div>
                                    </div>
                                </PanelBody>
                            </Panel>
                        </div>
                    </div>
                );
            }
        }

        return (
            <div>
                <Header />
                {/*-- Breadcrumb --*/}
                <nav className="breadcrumb">
                    <div className="container">
                        <a className="breadcrumb-item" href="/">
                            Home
                        </a>
                        <span className="breadcrumb-item active">Search</span>
                    </div>
                </nav>
                {/*-- Breadcrumb End --*/}
                {/*-- Search --*/}
                <div className="searchbg">
                    <div className="container">
                        <div className="row">
                            <form
                                id="search-form"
                                className="form-signin"
                                onSubmit={this.onSubmit}
                            >
                                <div className="text-center mb-4">
                                    <img
                                        className="mb-2"
                                        src="./assets/user/images/search-icon.png"
                                        alt="search-icon"
                                    />
                                    <h1 className="h3 mb-3 font-weight-bold with-bg">
                                        Search
                                    </h1>
                                </div>

                                <div className="form-label-group mt-5 bt-margin">
                                    <label htmlFor="inputEmail">
                                        Keyword(s) :
                                    </label>
                                    <input
                                        type="text"
                                        name="keyword"
                                        className={classnames("form-control", {
                                            "is-invalid":
                                                this.state.errors["keyword"],
                                        })}
                                        value={this.state.keyword}
                                        onChange={this.onChange}
                                        placeholder="Enter Keyword"
                                        required=""
                                        autoFocus=""
                                    />
                                    {this.state.errors["keyword"] && (
                                        <div
                                            className="invalid-feedback"
                                            style={{
                                                display: "block",
                                            }}
                                        >
                                            {this.state.errors["keyword"]}
                                        </div>
                                    )}
                                </div>

                                <div className="form-label-group nw-mdf">
                                    <label htmlFor="inputPassword">
                                        Topic :
                                    </label>
                                    <select
                                        className="form-control2"
                                        value={this.state.topic}
                                        onChange={this.onChange}
                                        name="topic"
                                        tabIndex="8"
                                    >
                                        <option value="">Select Topic</option>
                                        <option value="all">All Topics</option>
                                        <optgroup label="Deck">
                                            <option value="deck-all">
                                                All Deck
                                            </option>
                                            <option value="ZINBOOK-2">
                                                Navigation Rules
                                            </option>
                                            <option value="ZINBOOK-3">
                                                Deck General
                                            </option>
                                            <option value="ZINBOOK-4">
                                                Deck Safety & EP
                                            </option>
                                            <option value="ZINBOOK-5">
                                                Navigation General
                                            </option>
                                            <option value="ZINBOOK-6">
                                                Nav Problems - Chart Plot
                                            </option>
                                            <option value="ZINBOOK-7">
                                                Navigation Problem
                                            </option>
                                        </optgroup>
                                        <optgroup label="Engine">
                                            <option value="engine-all">
                                                All Engine
                                            </option>
                                            <option value="ZINBOOK-9">
                                                Electical
                                            </option>
                                            <option value="ZINBOOK-10">
                                                General Subject
                                            </option>
                                            <option value="ZINBOOK-11">
                                                Motor Plants
                                            </option>
                                            <option value="ZINBOOK-12">
                                                Steam Plants
                                            </option>
                                            <option value="ZINBOOK-13">
                                                Safety
                                            </option>
                                            <option value="ZINBOOK-14">
                                                Gas Turbines
                                            </option>
                                        </optgroup>
                                    </select>
                                    {this.state.errors["topic"] && (
                                        <div
                                            className="invalid-feedback"
                                            style={{
                                                display: "block",
                                            }}
                                        >
                                            {this.state.errors["topic"]}
                                        </div>
                                    )}
                                </div>

                                <div className="form-label-group">
                                    <label htmlFor="inputPassword">
                                        Search Area :
                                    </label>
                                    <select
                                        className="form-control2"
                                        name="area"
                                        value={this.state.area}
                                        onChange={this.onChange}
                                        tabIndex="9"
                                    >
                                        <option value="">
                                            Select Search Area
                                        </option>
                                        <option value="all">All</option>
                                        <option value="question">
                                            Questions
                                        </option>
                                        <option value="question_refrence">
                                            Question Reference
                                        </option>
                                        <option value="question_number">
                                            Question Number
                                        </option>
                                        <option value="answer">Answers</option>
                                    </select>
                                    {this.state.errors["area"] && (
                                        <div
                                            className="invalid-feedback"
                                            style={{
                                                display: "block",
                                            }}
                                        >
                                            {this.state.errors["area"]}
                                        </div>
                                    )}
                                </div>
                                <div className="nw-mdf-btn">
                                    <button
                                        className="btn btn-lg btn-primary btn-block mt-4 sbtn"
                                        type="submit"
                                    >
                                        Search
                                    </button>
                                </div>
                            </form>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-md-12">{searchContent}</div>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        );
    }
}
Search.propTypes = {
    getSearch: PropTypes.func.isRequired,
    search: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    search: state.search,
});

export default connect(mapStateToProps, { getSearch })(Search);
