import React, { Component } from "react";
import ReactTable from "react-table";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Panel, PanelHeader } from "../common/panel/Panel.jsx";
import "react-table/react-table.css";
import AlertBox from "../common/textfield/AlertBox";
import { deleteComment } from "../../../actions/commentActions.jsx";
import SweetAlert from "react-bootstrap-sweetalert";

class ComUserList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sweetAlertError: false,
        };

        //this.data = makeData();
        this.defaultSorted = [
            {
                id: "_id",
                desc: true,
            },
        ];
        this.tableColumns = [
            {
                Header: "Id",
                id: "row",
                maxWidth: 50,
                filterable: false,
                Cell: (row) => {
                    return <div>{row.index + 1}</div>;
                },
            },

            {
                Header: "Question",
                accessor: "zmcq_id",
                Cell: (row) => (
                    <span>
                        {row.original.zmcq_id ? (
                            row.original.zmcq_id.ZQUESTION
                        ) : (
                            <span className="label label-default m-r-3">
                                Undefine
                            </span>
                        )}
                    </span>
                ),
            },

            {
                Header: "Message",
                accessor: "comments",
                Cell: (row) => (
                    <span dangerouslySetInnerHTML={{ __html: row.value }} />
                ),
            },

            {
                Header: "Status",
                accessor: "status",
                Cell: (row) => (
                    <span>
                        <span className="label label-primary m-r-3">
                            {row.value}
                        </span>
                    </span>
                ),
            },

            {
                Header: "Actions",
                accessor: "_id",
                filterable: false,
                sortable: false,
                Cell: (row) => (
                    <div>
                        <Link
                            to={`/admin/comment/edit-comment/${row.value}`}
                            className="btn btn-primary btn-sm m-r-5 "
                        >
                            <i className="fas fa-sm fa-fw m-r-2 fa-paste"> </i>
                            Edit
                        </Link>
                        <button
                            onClick={() =>
                                this.toggleSweetAlert("error", row.value)
                            }
                            className="btn btn-danger btn-sm m-r-5 "
                        >
                            <i className="fas fa-sm fa-fw m-r-2 fa-trash"></i>
                            Delete
                        </button>
                    </div>
                ),
            },
        ];
        this.toggleSweetAlert = this.toggleSweetAlert.bind(this);
    }
    toggleSweetAlert(name, id) {
        switch (name) {
            case "error":
                this.setState({
                    sweetAlertError: !this.state.sweetAlertError,
                    id: id,
                });
                break;
            default:
                break;
        }
    }
    onDeleteClick(id) {
        this.props.deleteComment(id);
        this.setState({ sweetAlertError: !this.state.sweetAlertError });
    }

    render() {
        const data = this.props.commentlists;
        const { alertpage } = this.state;
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-12">
                        {this.state.sweetAlertError && (
                            <SweetAlert
                                danger
                                showCancel
                                confirmBtnText="Yes, delete it!"
                                confirmBtnBsStyle="danger"
                                cancelBtnBsStyle="default"
                                title="Are you sure?"
                                onConfirm={this.onDeleteClick.bind(
                                    this,
                                    this.state.id
                                )}
                                onCancel={() => this.toggleSweetAlert("error")}
                            >
                                You will not be able to recover this again!
                            </SweetAlert>
                        )}
                        <Panel>
                            <PanelHeader>Comment List Table</PanelHeader>
                            {alertpage && (
                                <AlertBox
                                    content="Pages deleted successfully"
                                    classes={"green"}
                                    bold="Success!"
                                    visible={this.state.visible}
                                    tongle={this.onDismiss}
                                />
                            )}
                            <ReactTable
                                filterable
                                useSortBy
                                data={data}
                                columns={this.tableColumns}
                                defaultPageSize={10}
                                defaultSorted={this.defaultSorted}
                                className="-highlight"
                            />
                        </Panel>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

ComUserList.propTypes = {
    deleteComment: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
    errors: state.errors,
});
export default connect(mapStateToProps, { deleteComment })(
    withRouter(ComUserList)
);
