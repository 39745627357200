import React, { Component } from "react";
import ReactTable from "react-table";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Panel, PanelHeader } from "../common/panel/Panel.jsx";
import "react-table/react-table.css";
import Moment from "react-moment";
import SweetAlert from "react-bootstrap-sweetalert";
import { deleteEmail } from "../../../actions/coupanActions.jsx";

class SentMailList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sweetAlertError: false,
        };

        //this.data = makeData();
        this.defaultSorted = [
            {
                id: "_id",
                desc: false,
            },
        ];
        this.tableColumns = [
            {
                Header: "Id",
                id: "row",
                maxWidth: 50,
                filterable: false,
                Cell: (row) => {
                    return <div>{row.index + 1}</div>;
                },
            },

            {
                Header: "Subscriber Email",
                id: "customer_email",
                accessor: (d) => d.customer_email,
            },

            {
                Header: "Send Date",
                accessor: "promo_send_date",
                filterable: false,
                sortable: false,
                Cell: (row) => (
                    <div>
                        <i className="fa fa-calendar-alt col-blue ml-3 mr-2"></i>
                        <Moment format="D MMM YYYY" withTitle>
                            {row.value}
                        </Moment>
                    </div>
                ),
            },

            {
                Header: "Action",
                accessor: "_id",
                filterable: false,
                sortable: false,
                Cell: (row) => (
                    <div>
                        <Link
                            to={`/admin/coupan-code/view-message/${row.value}`}
                            className="btn btn-info btn-sm m-r-5"
                        >
                            <i className="fas fa-sm fa-fw m-r-2 fa-eye"></i>{" "}
                            View
                        </Link>
                        <button
                            onClick={() =>
                                this.toggleSweetAlert("error", row.value)
                            }
                            className="btn btn-danger btn-sm m-r-5"
                        >
                            <i className="fas fa-sm fa-fw m-r-2 fa-trash"></i>{" "}
                            Delete
                        </button>
                    </div>
                ),
            },
        ];
        this.toggleSweetAlert = this.toggleSweetAlert.bind(this);
    }
    toggleSweetAlert(name, id) {
        switch (name) {
            case "error":
                this.setState({
                    sweetAlertError: !this.state.sweetAlertError,
                    id: id,
                });
                break;
            default:
                break;
        }
    }

    onDeleteClick(id) {
        this.props.deleteEmail(id);
        this.setState({ sweetAlertError: !this.state.sweetAlertError });
    }
    render() {
        const data = this.props.coupan_emails;

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-12">
                        {this.state.sweetAlertError && (
                            <SweetAlert
                                danger
                                showCancel
                                confirmBtnText="Yes, delete it!"
                                confirmBtnBsStyle="danger"
                                cancelBtnBsStyle="default"
                                title="Are you sure?"
                                onConfirm={this.onDeleteClick.bind(
                                    this,
                                    this.state.id
                                )}
                                onCancel={() => this.toggleSweetAlert("error")}
                            >
                                You will not be able to recover this again!
                            </SweetAlert>
                        )}
                        <Panel>
                            <PanelHeader>Sent Promo Code List</PanelHeader>

                            <ReactTable
                                filterable
                                data={data}
                                columns={this.tableColumns}
                                defaultPageSize={10}
                                defaultSorted={this.defaultSorted}
                                className="-highlight"
                            />
                        </Panel>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

SentMailList.propTypes = {
    deleteEmail: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
    errors: state.errors,
});
export default connect(mapStateToProps, { deleteEmail })(
    withRouter(SentMailList)
);
