import React, { Component } from "react";
import { Alert } from "reactstrap";
class AlertBox extends Component {
    constructor(props) {
        super(props);

        this.codeMirrorOptions = {
            mode: "application/xml",
            theme: "material",
            lineNumbers: true,
            indentWithTabs: true,
            tabSize: 2,
            autoScroll: false,
        };
        this.state = {
            visible: true,
        };

        this.onDismiss = this.onDismiss.bind(this);
    }

    onDismiss() {
        this.setState({ visible: false });
    }
    render() {
        let { content, classes, bold, tongle, visible } = this.props;

        return (
            <Alert
                color={classes}
                className="mb-0"
                isOpen={visible}
                toggle={tongle}
            >
                <strong>{bold}</strong>
                &nbsp;{content}
            </Alert>
        );
    }
}

export default AlertBox;
