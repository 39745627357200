import React, { Component } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import Container from "../content/Container.jsx";
class SweetAlertReg extends Component {
    constructor() {
        super();

        this.routeChange = this.routeChange.bind(this);
    }

    routeChange() {
        let path = `/user/account/subscription`;
        this.props.history.push(path);
    }

    render() {
        return (
            <React.Fragment>
                <Container>
                    <SweetAlert
                        info
                        showCancel
                        confirmBtnText="Upgrade Account"
                        confirmBtnBsStyle="info"
                        cancelBtnBsStyle="default"
                        title="Subscription Alerts"
                        onConfirm={this.routeChange}
                        onCancel={this.routeChange}
                    >
                        You have to be a premium user to use all Funtionality.
                    </SweetAlert>
                </Container>
            </React.Fragment>
        );
    }
}
export default SweetAlertReg;
