import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";

class DeckSubCatNav extends Component {
    render() {
        const bookSubCatlist = this.props.deck_subcats.map((subs) => (
            <span key={subs._id}>
                <Dropdown.Item
                    href={`/question/watch?mode=normal&type=subcat&id=${subs.ZINSUBCATEGORY}&num=1&zid=${subs._id}&zinbookcat=${subs.ZINBOOKCATEGORY}`}
                >
                    {" "}
                    {subs.ZSUBCATEGORYNAME}
                </Dropdown.Item>
            </span>
        ));
        return <div>{bookSubCatlist}</div>;
    }
}
export default DeckSubCatNav;
