import React, { Component } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import DeckCatNav from "./DeckCatNav";
import { getDeckCat } from "../../../../actions/questionActions.jsx";
import PropTypes from "prop-types";
import { connect } from "react-redux";

class DeckBookNav extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bookId: "1",
        };
    }
    onChangeBookClick(id) {
        this.props.getDeckCat(id);
        //console.log(id)
        // this.setState({ bookId: id });
    }
    render() {
        const { deck_cats } = this.props.question;
        let deckCatContent;
        if (deck_cats.length > 0) {
            deckCatContent = <DeckCatNav deck_cats={deck_cats} />;
        }
        const booklist = this.props.deck_books.map((books) => (
            <DropdownButton
                //href="/deck"
                key={`right ${books._id}`}
                onClick={this.onChangeBookClick.bind(this, books.ZINBOOK)}
                id="dropdown-button-drop-right"
                drop="right"
                variant="secondary"
                title={books.ZBOOKNAME}
            >
                <Dropdown.Item
                    href={`/question/watch?mode=normal&type=book&id=${books.ZINBOOK}&num=1&zid=${books._id}`}
                >{`All ${books.ZBOOKNAME}`}</Dropdown.Item>
                {deckCatContent}
            </DropdownButton>
        ));
        return <div>{booklist}</div>;
    }
}

DeckBookNav.propTypes = {
    getDeckCat: PropTypes.func.isRequired,
    question: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    question: state.question,
});

export default connect(mapStateToProps, { getDeckCat })(DeckBookNav);
