import React, { Component } from "react";
import ReactTable from "react-table";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Moment from "react-moment";
import { Panel, PanelHeader } from "../common/panel/Panel.jsx";
import "react-table/react-table.css";
import { unBookmarkedQues } from "../../../actions/loggedActions.jsx";
//import SweetAlert from 'react-bootstrap-sweetalert';

class BookmarkList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sweetAlertError: false,
        };

        //this.data = makeData();
        this.defaultSorted = [
            {
                id: "_id",
                desc: false,
            },
        ];
        this.tableColumns = [
            {
                Header: "#Q.Id",
                accessor: "zmcq_id",
                maxWidth: 100,
                Cell: (row) => <span>{row.original.zmcq_id.ZNUMBER}</span>,
            },
            {
                Header: "Added",
                accessor: "insert_date",
                filterable: false,
                maxWidth: 150,
                Cell: (row) => (
                    <div>
                        <i className="fa fa-calendar-alt col-blue ml-3 mr-2"></i>
                        <Moment format="D MMM YYYY" withTitle>
                            {row.value}
                        </Moment>
                    </div>
                ),
            },
            {
                Header: "Book Name",
                accessor: "zmcq_id",
                maxWidth: 150,
                style: { whiteSpace: "unset" },
                Cell: (row) => <span>{row.original.zmcq_id.ZBOOKNAME}</span>,
            },

            {
                Header: "Questions",
                id: "row",
                accessor: "zmcq_id",
                style: { whiteSpace: "unset" },
                Cell: (row) => (
                    <Link
                        to={`/user/bookmarked-question/watch?mode=normal&num=${
                            row.index + 1
                        }&zid=${row.original.zmcq_id._id}`}
                    >
                        {row.original.zmcq_id.ZQUESTION}
                        <br />
                        <span>
                            <span> A) {row.original.zmcq_id.ZANSWERONE}</span>{" "}
                            <br />
                            <span>
                                {" "}
                                B) {row.original.zmcq_id.ZANSWERTWO}
                            </span>{" "}
                            <br />
                            <span>
                                {" "}
                                C) {row.original.zmcq_id.ZANSWERTHREE}
                            </span>{" "}
                            <br />
                            <span> D) {row.original.zmcq_id.ZANSWERFOUR}</span>
                        </span>
                    </Link>
                ),
            },

            {
                Header: "Actions",
                accessor: "_id",
                filterable: false,
                sortable: false,
                maxWidth: 175,
                Cell: (row) => (
                    <div>
                        <button
                            onClick={this.onUnBookmarkedClick.bind(
                                this,
                                row.value
                            )}
                            className="btn btn-outline btn-info btn-sm m-r-5 "
                        >
                            <i className="fas fa-sm m-r-2 fa-redo-alt"></i>{" "}
                            Un-Bookmark
                        </button>
                    </div>
                ),
            },
        ];
    }

    onUnBookmarkedClick(id) {
        this.props.unBookmarkedQues(id);
        this.setState({ sweetAlertError: !this.state.sweetAlertError });
    }

    render() {
        const data = this.props.bookmarks;

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-12">
                        <Panel className="member-panel">
                            <PanelHeader>Bookmarked List Table</PanelHeader>

                            <ReactTable
                                filterable
                                useSortBy
                                data={data}
                                columns={this.tableColumns}
                                defaultPageSize={10}
                                defaultSorted={this.defaultSorted}
                                className="-highlight"
                            />
                        </Panel>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

BookmarkList.propTypes = {
    unBookmarkedQues: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
    errors: state.errors,
});

export default connect(mapStateToProps, { unBookmarkedQues })(
    withRouter(BookmarkList)
);
