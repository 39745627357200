import React, { Component } from "react";
import { Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { withRouter } from "react-router-dom";

class Button extends Component {
    constructor() {
        super();
        this.state = {
            sweetAlertInfo: false,
        };
        this.toggleSweetAlert = this.toggleSweetAlert.bind(this);
        this.routeChange = this.routeChange.bind(this);
        this.goBackChange = this.goBackChange.bind(this);
    }

    toggleSweetAlert(name) {
        switch (name) {
            case "info":
                this.setState({ sweetAlertInfo: !this.state.sweetAlertInfo });
                break;

            default:
                break;
        }
    }

    routeChange() {
        let path = `/register`;
        this.props.history.push(path);
    }

    goBackChange() {
        this.props.history.goBack();
    }

    render() {
        const keyword = this.props.keyword;
        const mode = this.props.mode;
        const topic = this.props.topic;
        const area = this.props.area;
        const num = this.props.num;
        var adding = 1;
        var nextnum = num - 0 + (adding - 0);
        var prevnum = num - 1;
        let prevbutton;
        let nextbutton;
        if (localStorage.getItem("time") === "20") {
            prevbutton = (
                <button
                    type="button"
                    className="backward demo4"
                    onClick={() => this.toggleSweetAlert("info")}
                >
                    <i className="fa fa-angle-double-left"></i> Previous
                </button>
            );

            nextbutton = (
                <button
                    type="button"
                    className="forward adjust-btn demo4"
                    onClick={() => this.toggleSweetAlert("info")}
                >
                    <i className="fa fa-angle-double-right"></i> Next
                </button>
            );
        } else {
            if (mode === "shuffle") {
                if (this.props.previous) {
                    prevbutton = prevbutton = (
                        <button
                            onClick={this.goBackChange}
                            type="button"
                            className="backward demo4"
                        >
                            <i className="fa fa-angle-double-left"></i> Previous{" "}
                        </button>
                    );
                }

                if (this.props.next) {
                    nextbutton = this.props.next.map((nques) => (
                        <span key={nques._id}>
                            <Link
                                to={`/search/view?mode=${mode}&keyword=${keyword}&topic=${topic}&area=${area}&num=${nextnum}&zid=${nques._id}`}
                                type="button"
                                className="forward adjust-btn demo4"
                            >
                                Next{" "}
                                <i className="fa fa-angle-double-right"></i>{" "}
                            </Link>
                        </span>
                    ));
                }
            } else {
                prevbutton = this.props.previous.map((pques) => (
                    <span key={pques._id}>
                        {prevnum === 0 ? (
                            <span>
                                {" "}
                                <button
                                    type="button"
                                    className="backward demo4 disabled"
                                    disabled
                                >
                                    <i className="fa fa-angle-double-left"></i>{" "}
                                    Previous
                                </button>{" "}
                            </span>
                        ) : (
                            <Link
                                to={`/search/view?mode=${mode}&keyword=${keyword}&topic=${topic}&area=${area}&num=${prevnum}&zid=${pques._id}`}
                                type="button"
                                className="backward demo4"
                            >
                                <i className="fa fa-angle-double-left"></i>{" "}
                                Previous{" "}
                            </Link>
                        )}
                    </span>
                ));

                nextbutton = this.props.next.map((nques) => (
                    <span key={nques._id}>
                        <Link
                            to={`/search/view?mode=${mode}&keyword=${keyword}&topic=${topic}&area=${area}&num=${nextnum}&zid=${nques._id}`}
                            type="button"
                            className="forward adjust-btn demo4"
                        >
                            Next <i className="fa fa-angle-double-right"></i>{" "}
                        </Link>
                    </span>
                ));
            }
        }
        return (
            <React.Fragment>
                {this.state.sweetAlertInfo && (
                    <SweetAlert
                        info
                        showCancel
                        confirmBtnText="Register"
                        confirmBtnBsStyle="info"
                        cancelBtnBsStyle="default"
                        title="Subscription Alerts"
                        //  onConfirm={this.routeChange}
                        onCancel={() => this.toggleSweetAlert("info")}
                    >
                        To go through more questions today, you must upgrade to
                        premium account.
                    </SweetAlert>
                )}
                <div className="col-md-5 text-right for-only-q">
                    {prevbutton}
                    {nextbutton}
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(Button);
