import {
    GET_FORUM,
    ADD_FORUM,
    GET_FORUMS,
    GET_REPLY,
    GET_REPLYS,
    DELETE_FORUM,
    GET_NEW_GENERAL,
    GET_NEW_DECK,
    GET_NEW_ENGINE,
    GET_NEW_HELP,
    DELETE_REPLY,
    COUNT_GENERAL,
    COUNT_DECK,
    COUNT_ENGINE,
    COUNT_HELP,
    FORUM_COMMENTS,
    FORUM_COMMENT,
    FORUM_LOADING,
} from "../actions/types";

const initialState = {
    forums: [],
    replys: [],
    forum: null,
    reply: null,
    general: null,
    deck: null,
    engine: null,
    help: null,
    new_general: null,
    new_deck: null,
    new_engine: null,
    new_help: null,
    forum_comment: null,
    forum_comments: [],
    loading: true,
    error: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case FORUM_LOADING:
            return {
                ...state,
                loading: true,
            };

        case GET_NEW_GENERAL:
            return {
                ...state,
                new_general: action.payload,
                loading: false,
            };

        case GET_NEW_DECK:
            return {
                ...state,
                new_deck: action.payload,
                loading: false,
            };

        case GET_NEW_ENGINE:
            return {
                ...state,
                new_engine: action.payload,
                loading: false,
            };

        case GET_NEW_HELP:
            return {
                ...state,
                new_help: action.payload,
                loading: false,
            };

        case COUNT_GENERAL:
            return {
                ...state,
                general: action.payload,
                loading: false,
            };

        case COUNT_DECK:
            return {
                ...state,
                deck: action.payload,
                loading: false,
            };

        case COUNT_ENGINE:
            return {
                ...state,
                engine: action.payload,
                loading: false,
            };

        case COUNT_HELP:
            return {
                ...state,
                help: action.payload,
                loading: false,
            };

        case ADD_FORUM:
            return {
                ...state,
                forum: [action.payload, ...state.forum],
            };

        case GET_FORUM:
            return {
                ...state,
                forum: action.payload,
                loading: false,
            };

        case GET_FORUMS:
            return {
                ...state,
                forums: action.payload,
                loading: false,
            };

        case GET_REPLY:
            return {
                ...state,
                reply: action.payload,
                loading: false,
            };

        case GET_REPLYS:
            return {
                ...state,
                replys: action.payload,
                loading: false,
            };

        case DELETE_FORUM:
            return {
                ...state,
                forums: state.forums.filter(
                    (forum) => forum._id !== action.payload
                ),
            };

        case DELETE_REPLY:
            return {
                ...state,
                forum: state.forum.filter(
                    (forum) => forum._id !== action.payload
                ),
            };

        case FORUM_COMMENTS:
            return {
                ...state,
                forum_comments: action.payload,
                loading: false,
            };

        case FORUM_COMMENT:
            return {
                ...state,
                forum_comment: action.payload,
                loading: false,
            };

        default:
            return state;
    }
}
