import React, { Component } from "react";
import { Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { withRouter } from "react-router-dom";

class Button extends Component {
    constructor() {
        super();
        this.state = {
            sweetAlertInfo: false,
        };
        this.toggleSweetAlert = this.toggleSweetAlert.bind(this);
        this.routeChange = this.routeChange.bind(this);
        this.goBackChange = this.goBackChange.bind(this);
    }

    toggleSweetAlert(name) {
        switch (name) {
            case "info":
                this.setState({ sweetAlertInfo: !this.state.sweetAlertInfo });
                break;

            default:
                break;
        }
    }

    routeChange() {
        let path = `/user/account/subscription`;
        this.props.history.push(path);
    }

    goBackChange() {
        this.props.history.goBack();
    }

    render() {
        const type = this.props.type;
        const mode = this.props.mode;
        const id = this.props.id;
        const num = this.props.num;
        const total_ques = this.props.total_ques;
        var adding = 1;
        var nextnum = num - 0 + (adding - 0);
        var prevnum = num - 0 - (adding - 0);
        let prevbutton;
        let nextbutton;
        if (localStorage.getItem("user") === "Inactive") {
            if (localStorage.getItem("time") === "50") {
                prevbutton = (
                    <button
                        type="button"
                        className="backward demo4"
                        onClick={() => this.toggleSweetAlert("info")}
                    >
                        <i className="fa fa-angle-double-left"></i> Previous
                    </button>
                );

                nextbutton = (
                    <button
                        type="button"
                        className="forward adjust-btn demo4"
                        onClick={() => this.toggleSweetAlert("info")}
                    >
                        {" "}
                        Next <i className="fa fa-angle-double-right"></i>
                    </button>
                );
            } else {
                if (this.props.prevId) {
                    prevbutton = (
                        <span key={this.props.prevId}>
                            <Link
                                to={`/user/watch?mode=${mode}&type=${type}&id=${id}&num=${prevnum}&zid=${this.props.prevId}&zinbookcat=${this.props.ZINBOOKCATEGORY}`}
                                type="button"
                                className="backward adjust-btn demo4"
                            >
                                Previous{" "}
                                <i className="fa fa-angle-double-left"></i>{" "}
                            </Link>
                        </span>
                    );
                }

                if (total_ques > num) {
                    if (this.props.nextId) {
                        nextbutton = (
                            <span key={this.props.nextId}>
                                <Link
                                    to={`/user/watch?mode=${mode}&type=${type}&id=${id}&num=${nextnum}&zid=${this.props.nextId}&zinbookcat=${this.props.ZINBOOKCATEGORY}`}
                                    type="button"
                                    className="forward adjust-btn demo4"
                                >
                                    Next{" "}
                                    <i className="fa fa-angle-double-right"></i>{" "}
                                </Link>
                            </span>
                        );
                    }
                }
            }
        } else if (localStorage.getItem("user") === "Active") {
            if (this.props.prevId) {
                prevbutton = (
                    <span key={this.props.prevId}>
                        <Link
                            to={`/user/watch?mode=${mode}&type=${type}&id=${id}&num=${prevnum}&zid=${this.props.prevId}&zinbookcat=${this.props.ZINBOOKCATEGORY}`}
                            type="button"
                            className="backward adjust-btn demo4"
                        >
                            Previous <i className="fa fa-angle-double-left"></i>{" "}
                        </Link>
                    </span>
                );
            }
            if (total_ques > num) {
                if (this.props.nextId) {
                    nextbutton = (
                        <span key={this.props.nextId}>
                            <Link
                                to={`/user/watch?mode=${mode}&type=${type}&id=${id}&num=${nextnum}&zid=${this.props.nextId}&zinbookcat=${this.props.ZINBOOKCATEGORY}`}
                                type="button"
                                className="forward adjust-btn demo4"
                            >
                                Next{" "}
                                <i className="fa fa-angle-double-right"></i>{" "}
                            </Link>
                        </span>
                    );
                }
            }
        }

        return (
            <React.Fragment>
                {this.state.sweetAlertInfo && (
                    <SweetAlert
                        info
                        showCancel
                        confirmBtnText="Upgrade Account"
                        confirmBtnBsStyle="info"
                        cancelBtnBsStyle="default"
                        title="Subscription Alerts"
                        onConfirm={this.routeChange}
                        onCancel={() => this.toggleSweetAlert("info")}
                    >
                        To go through more questions today, you must upgrade to
                        premium account.
                    </SweetAlert>
                )}
                <div className="col-md-5 text-right for-only-q">
                    {prevbutton}
                    {nextbutton}
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(Button);
