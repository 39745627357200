import { combineReducers } from "redux";

import admin from "./adminReducer";
import errors from "./errorReducer";
import admin_profile from "./adminprofileReducer";
import billing from "./billingReducer";
import price from "./priceReducer";
import page from "./pageReducer";
import coupan from "./coupanReducer";
import subscriber from "./subscriberReducer";
import invoice from "./invoiceReducer";
import comment from "./commentReducer";
import dashboard from "./dashboardReducer";
import question from "./questionReducer";
import alert from "./alertReducer";
import auth from "./authReducer";
import profile from "./profileReducer";
import test from "./testReducer";
import logged from "./loggedReducer";
import search from "./searchReducer";
import directory from "./directoryReducer";
import forum from "./forumReducer";
import payment from "./paymentReducer";
import v1Question from "./v1/questionReducer";
import v1Profile from "./v1/profileReducer"

export default combineReducers({
    alert,
    auth,
    admin,
    admin_profile,
    profile,
    billing,
    price,
    page,
    dashboard,
    coupan,
    payment,
    question,
    subscriber,
    invoice,
    comment,
    test,
    logged,
    search,
    directory,
    forum,
    errors,
    v1Question,
    v1Profile
});
