import React, { Component } from "react";
import { Link } from "react-router-dom";
import DeckSubCatNav from "./DeckSubCatNav";
import { getuserDeckSubCat } from "../../../../../actions/questionActions.jsx";
import PropTypes from "prop-types";
import { connect } from "react-redux";

class DeckCatNav extends Component {
    constructor(props) {
        super(props);
        this.state = {
            category2active: "",
        };
    }

    onChangecategoryClick(id) {
        this.props.getuserDeckSubCat(id);
        //console.log(id)
        this.setState({ category2active: id });
    }

    render() {
        const { deck_subcats } = this.props.question;
        let deckSubCatContent;
        if (deck_subcats.length > 0) {
            deckSubCatContent = <DeckSubCatNav deck_subcats={deck_subcats} />;
        }
        const bookCatlist = this.props.deck_cats.map((cats) => (
            <React.Fragment>
                {cats.ZINCATEGORY === "0" ? (
                    ""
                ) : (
                    <li
                        className={
                            (this.state.category2active === cats.ZINCATEGORY
                                ? "active expand "
                                : "closed") + "has-sub"
                        }
                        key={cats.ZINCATEGORY}
                    >
                        <span
                            className="nav-study"
                            onClick={
                                this.state.category2active === cats.ZINCATEGORY
                                    ? () =>
                                          this.setState({ category2active: "" })
                                    : this.onChangecategoryClick.bind(
                                          this,
                                          cats.ZINCATEGORY
                                      )
                            }
                        >
                            <b className="caret"></b>
                            {cats.ZCATEGORYNAME}
                        </span>
                        <ul
                            className={
                                "sub-menu " +
                                (this.state.category2active === cats.ZINCATEGORY
                                    ? "d-block "
                                    : "d-none")
                            }
                        >
                            <li>
                                <Link
                                    to={`/user/watch?mode=normal&type=cat&id=${cats.ZINCATEGORY}&num=1&zid=${cats._id}`}
                                >
                                    All {cats.ZCATEGORYNAME}
                                </Link>
                            </li>
                            {deckSubCatContent}
                        </ul>
                    </li>
                )}
            </React.Fragment>
        ));
        return bookCatlist;
    }
}

DeckCatNav.propTypes = {
    getuserDeckSubCat: PropTypes.func.isRequired,
    question: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    question: state.question,
});

export default connect(mapStateToProps, { getuserDeckSubCat })(DeckCatNav);
