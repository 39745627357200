import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Container from "./../content/Container.jsx";
import { Panel, PanelHeader, PanelBody } from "./../common/panel/Panel.jsx";
import TextFieldArea from "./../common/textfield/TextFieldArea";
import {
    getCurrentProfile,
    emailEdit,
    passwordEdit,
    sendVeriEmail,
} from "../../../actions/userActions.jsx";
//import AlertBox from "../common/textfield/AlertBox";
import { Alert } from "reactstrap";
import ReactNotification from "react-notifications-component";
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import isEmpty from "../../../validation/is-empty";

class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            old_password: "",
            new_password: "",
            conf_password: "",
            user_id: "",
            verification_status: "",

            errors: {},
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onSubmitp = this.onSubmitp.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleEmailCancel = this.handleEmailCancel.bind(this);
    }

    handleEmailCancel(e) {
        this.setState({
            email: "",
        });
    }
    handleCancel(e) {
        this.setState({
            old_password: "",
            new_password: "",
            conf_password: "",
        });
    }

    componentDidMount() {
        this.props.getCurrentProfile();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors,
                visible: true,
                visible1: true,
            });
        }

        if (nextProps.profile.profile) {
            const profile = nextProps.profile.profile;
            // If profile field doesnt exist, make empty string

            profile.email = !isEmpty(profile.email) ? profile.email : "";
            profile._id = !isEmpty(profile._id) ? profile._id : "";
            profile.verification_status = !isEmpty(profile.verification_status)
                ? profile.verification_status
                : "";

            // Set component fields state
            this.setState({
                email: profile.email,
                user_id: profile._id,
                verification_status: profile.verification_status,
            });
        }
    }

    onSubmit(e) {
        e.preventDefault();
        const adminemail = {
            email: this.state.email,
        };
        this.props.emailEdit(adminemail, this.props.history);
        if (!Object.keys(this.state.errors).length) {
            store.addNotification({
                title: "Success",
                message: "Email updated successfully.",
                type: "success",
                insert: "top",
                container: "top-center",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: { duration: 2000 },
                dismissable: { click: true },
                // content: notificationContent
            });
        }
    }

    onSubmitp(e) {
        e.preventDefault();
        const addminpass = {
            old_password: this.state.old_password,
            new_password: this.state.new_password,
            conf_password: this.state.conf_password,
        };
        this.props.passwordEdit(addminpass, this.props.history);
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }
    onEmailClick(id) {
        this.props.sendVeriEmail(id);
        store.addNotification({
            title: "Success!",
            message:
                " An email is sent to your registered email id. Click on the link to verify.",
            type: "success",
            insert: "top",
            container: "top-center",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: { duration: 3000 },
            dismissable: { click: true },
            // content: notificationContent
        });
    }

    render() {
        const { errors } = this.state;
        let EmailVerificationContent;
        if (this.state.verification_status === "No") {
            EmailVerificationContent = (
                <Alert
                    color="danger"
                    isOpen={this.state.visible}
                    toggle={this.onDismiss}
                >
                    Your Email verification is pending!
                    <Link
                        to="#"
                        onClick={this.onEmailClick.bind(
                            this,
                            this.state.user_id
                        )}
                        className="alert-link"
                    >
                        {" "}
                        resend verification link
                    </Link>
                    .
                </Alert>
            );
        }
        return (
            <React.Fragment>
                <ReactNotification />
                <Container>
                    <div className="content">
                        {EmailVerificationContent}
                        <ol className="breadcrumb float-xl-right">
                            <li className="breadcrumb-item">
                                <Link to="/dashboard">Dashboard</Link>
                            </li>

                            <li className="breadcrumb-item active">Settings</li>
                        </ol>
                        <h1 className="page-header">Settings</h1>

                        <div className="row">
                            <div className="col-xl-12">
                                <Panel className="member-panel">
                                    <PanelHeader>Change Email</PanelHeader>
                                    <PanelBody className="panel-form">
                                        <form
                                            className="form-horizontal form-bordered"
                                            onSubmit={this.onSubmit}
                                        >
                                            <fieldset>
                                                <TextFieldArea
                                                    label="Email"
                                                    placeholder="email"
                                                    name="email"
                                                    type="email"
                                                    value={this.state.email}
                                                    onChange={this.onChange}
                                                    error={errors.email}
                                                    striks="*"
                                                    //info="We'll never share your Email with anyone else."
                                                    // disabled="disabled"
                                                />

                                                <div className="form-group row">
                                                    <div className="col-md-12 offset-md-5">
                                                        <button
                                                            type="submit"
                                                            className="btn btn-sm btn-primary m-r-5"
                                                        >
                                                            Submit
                                                        </button>
                                                        <button
                                                            type="button"
                                                            onClick={
                                                                this
                                                                    .handleEmailCancel
                                                            }
                                                            className="btn btn-sm btn-default"
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </form>
                                    </PanelBody>
                                </Panel>
                            </div>
                        </div>
                        <span className="hide-noti">
                            {" "}
                            {errors.passwordchange &&
                                store.addNotification({
                                    title: "Success",
                                    message: errors.passwordchange,
                                    type: "success",
                                    insert: "top",
                                    container: "top-center",
                                    animationIn: ["animated", "fadeIn"],
                                    animationOut: ["animated", "fadeOut"],
                                    dismiss: { duration: 2000 },
                                    dismissable: { click: true },
                                    // content: notificationContent
                                })}
                        </span>
                        <div className="row">
                            <div className="col-xl-12">
                                <Panel className="member-panel">
                                    <PanelHeader>Change Password </PanelHeader>
                                    <PanelBody className="panel-form">
                                        <form
                                            className="form-horizontal form-bordered"
                                            onSubmit={this.onSubmitp}
                                        >
                                            <fieldset>
                                                <TextFieldArea
                                                    label="Current Password"
                                                    placeholder="Current Password"
                                                    name="old_password"
                                                    type="password"
                                                    value={
                                                        this.state.old_password
                                                    }
                                                    onChange={this.onChange}
                                                    error={errors.old_password}
                                                    striks="*"
                                                />

                                                <TextFieldArea
                                                    label="New Password"
                                                    placeholder="New Password"
                                                    name="new_password"
                                                    type="password"
                                                    value={
                                                        this.state.new_password
                                                    }
                                                    onChange={this.onChange}
                                                    error={errors.new_password}
                                                    striks="*"
                                                />

                                                <TextFieldArea
                                                    label="Confirm Password"
                                                    placeholder="Confirm Password"
                                                    name="conf_password"
                                                    type="password"
                                                    value={
                                                        this.state.conf_password
                                                    }
                                                    onChange={this.onChange}
                                                    error={errors.conf_password}
                                                    striks="*"
                                                />

                                                <div className="form-group row">
                                                    <div className="col-md-12 offset-md-5">
                                                        <button
                                                            type="submit"
                                                            className="btn btn-sm btn-primary m-r-5"
                                                        >
                                                            Submit
                                                        </button>
                                                        <button
                                                            type="button"
                                                            onClick={
                                                                this
                                                                    .handleCancel
                                                            }
                                                            className="btn btn-sm btn-default"
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </form>
                                    </PanelBody>
                                </Panel>
                            </div>
                        </div>
                    </div>
                </Container>
            </React.Fragment>
        );
    }
}

Settings.propTypes = {
    auth: PropTypes.object.isRequired,
    getCurrentProfile: PropTypes.func.isRequired,
    sendVeriEmail: PropTypes.func.isRequired,
    passwordEdit: PropTypes.func.isRequired,
    emailEdit: PropTypes.func.isRequired,
    profile: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    profile: state.profile,
    errors: state.errors,
});

export default connect(mapStateToProps, {
    getCurrentProfile,
    passwordEdit,
    emailEdit,
    sendVeriEmail,
})(withRouter(Settings));
