import React, { Component } from "react";
import Moment from "react-moment";
import SweetAlert from "react-bootstrap-sweetalert";

class CommentList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sweetAlertError: false,
        };
        this.toggleSweetAlert = this.toggleSweetAlert.bind(this);
    }

    toggleSweetAlert(name, id) {
        switch (name) {
            case "error":
                this.setState({
                    sweetAlertError: !this.state.sweetAlertError,
                    id: id,
                });
                break;
            default:
                break;
        }
    }

    onDeleteClick(id) {
        this.props.deleteComment(id);
        this.setState({ sweetAlertError: !this.state.sweetAlertError });
    }

    render() {
        const comList = this.props.comments.map((comm) => (
            <div className="d-flex">
                <div className="feed-item flex-grow-1" key={comm._id}>
                    {comm.user ? (
                        <img
                            src={`https://seatrials-bucket.s3.us-east-2.amazonaws.com/client/${comm.user.image}`}
                            alt="user"
                            className="img-responsive profile-photo-sm"
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src =
                                    "https://seatrials-bucket.s3.us-east-2.amazonaws.com/main/noimage.jpg";
                            }}
                        />
                    ) : (
                        <img
                            src="../assets/admin/img/user/user-12.jpg"
                            alt="user"
                            className="img-responsive profile-photo-sm"
                        />
                    )}

                    <div className="live-activity">
                        <h4 className="profile-link">
                            {comm.user ? comm.user.user_name : ""}{" "}
                        </h4>
                        <p
                            className="text-muted-sub"
                            dangerouslySetInnerHTML={{ __html: comm.comment }}
                        />

                        <p className="text-muted">
                            <Moment format="lll">{comm.insertDate}</Moment>{" "}
                        </p>
                    </div>
                </div>
                {this.props.currentUser._id === comm.userId && (
                    <div>
                        <button
                            onClick={() =>
                                this.toggleSweetAlert("error", comm._id)
                            }
                            type="button"
                            className="comment-delete"
                        >
                            <i className="fa fa-trash"></i>
                        </button>
                    </div>
                )}
            </div>
        ));
        return (
            <>
                {this.state.sweetAlertError && (
                    <SweetAlert
                        danger
                        showCancel
                        confirmBtnText="Yes"
                        confirmBtnBsStyle="danger"
                        cancelBtnBsStyle="default"
                        title="Delete Comment?"
                        onConfirm={this.onDeleteClick.bind(this, this.state.id)}
                        onCancel={() => this.toggleSweetAlert("error")}
                    ></SweetAlert>
                )}
                <div className="comment-wrapper">
                    <ul className="media-list underline ">{comList}</ul>
                </div>
            </>
        );
    }
}
export default CommentList;
