import axios from "axios";
import {
    GET_BOOKMARKEDS,
    GET_HIDDENS,
    GET_LOGGEDS,
    GET_LOGGED_TOPICS,
    LOGGED_LOADING,
    UN_HIDE,
    UN_BOOKMARKED,
    GET_PRIVIOUS,
    GET_NEXT,
    TOTAL_QUESTION,
    QUESTION_LOADING,
    GET_ERRORS,
    GET_BOOK_LISTS,
    DASH_BOOK,
    DASH_HIDDEN,
    DASH_LOGGED,
    DELETE_ALL_LOGGED,
    DELETE_ALL_BOOKMARKED,
    UNHIDE_ALL_QUESTIONS,
} from "./types";

// Get Dashboard Hidden Count
export const getDashLogged = () => (dispatch) => {
    axios
        .get("/api/mlp_logged/total_logged_ques")
        .then((res) =>
            dispatch({
                type: DASH_LOGGED,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: DASH_LOGGED,
                payload: null,
            })
        );
};

// Get Dashboard Hidden Count
export const getDashHidden = () => (dispatch) => {
    axios
        .get("/api/mlp_logged/get_hiddendash")
        .then((res) =>
            dispatch({
                type: DASH_HIDDEN,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: DASH_HIDDEN,
                payload: null,
            })
        );
};

// Get Dashboard bookmarked Count
export const getDashBook = () => (dispatch) => {
    axios
        .get("/api/mlp_logged/get_bookdash")
        .then((res) =>
            dispatch({
                type: DASH_BOOK,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: DASH_BOOK,
                payload: null,
            })
        );
};

// Get bookmarked Question
export const getBookmarkedQues = () => (dispatch) => {
    dispatch(setLoggedLoading());
    axios
        .get("/api/mlp_logged/get_bookmarked")
        .then((res) =>
            dispatch({
                type: GET_BOOKMARKEDS,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_BOOKMARKEDS,
                payload: null,
            })
        );
};

export const deleteAllBookmarkedQues = () => (dispatch) => {
    axios
        .delete("/api/v1/private/all_questions/favorite")
        .then((_res) =>
            dispatch({
                type: DELETE_ALL_BOOKMARKED,
                payload: true,
            })
        )
        .catch((_err) =>
            dispatch({
                type: DELETE_ALL_BOOKMARKED,
                payload: false,
            })
        );
};

// Get Hidden Question
export const getHiddenQues = () => (dispatch) => {
    dispatch(setLoggedLoading());
    axios
        .get("/api/mlp_logged/get_hidden")
        .then((res) =>
            dispatch({
                type: GET_HIDDENS,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_HIDDENS,
                payload: null,
            })
        );
};

export const unhideAllQues = () => (dispatch) => {
    axios
        .delete("/api/v1/private/all_questions/hide")
        .then((_res) =>
            dispatch({
                type: UNHIDE_ALL_QUESTIONS,
                payload: true,
            })
        )
        .catch((_err) =>
            dispatch({
                type: UNHIDE_ALL_QUESTIONS,
                payload: false,
            })
        );
};

// Get bookmarked Question
export const getLoggedQues =
    ({ page, limit, sortBy, sortType }) =>
        (dispatch) => {
            dispatch(setLoggedLoading());
            const baseUrl = "/api/mlp_logged/get_logged_questions";
            const queryParams = [];

            if (Number.isInteger(page) && page >= 0) {
                queryParams.push(`page=${page}`)
            };

            if (Number.isInteger(limit) && limit >= 0) {
                queryParams.push(`limit=${limit}`);
            }

            if (sortBy) {
                queryParams.push(`sortBy=${sortBy}`);
            }

            if (sortType === "desc" || sortType === "asc") {
                queryParams.push(`sortType=${sortType}`);
            }

            const reqUrl = queryParams.length > 0 ? `${baseUrl}?${queryParams.join('&')}` : baseUrl;

            axios
                .get(reqUrl)
                .then((res) =>
                    dispatch({
                        type: GET_LOGGEDS,
                        payload: res.data,
                    })
                )
                .catch((err) =>
                    dispatch({
                        type: GET_LOGGEDS,
                        payload: null,
                    })
                );
        };

export const deleteAllLoggedQues = () => (dispatch) => {
    axios
        .delete("/api/v1/private/all_questions/logged%20question")
        .then((_res) =>
            dispatch({
                type: DELETE_ALL_LOGGED,
                payload: true,
            })
        )
        .catch((_err) =>
            dispatch({
                type: DELETE_ALL_LOGGED,
                payload: false,
            })
        );
};

// Get bookmarked Question
export const getloggedTopicQues = () => (dispatch) => {
    dispatch(setLoggedLoading());
    axios
        .get("/api/mlp_logged/getLogedTopic")
        .then((res) =>
            dispatch({
                type: GET_LOGGED_TOPICS,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_LOGGED_TOPICS,
                payload: null,
            })
        );
};

// Get bookmarked Question
export const getTopicQues = (id) => (dispatch) => {
    dispatch(setLoggedLoading());
    axios
        .get(`/api/mlp_logged/getTopic/${id}`)
        .then((res) =>
            dispatch({
                type: GET_BOOK_LISTS,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_BOOK_LISTS,
                payload: null,
            })
        );
};

// Delete blog
export const unHideQues = (id) => (dispatch) => {
    // if (window.confirm("Are you sure? This can NOT be undone!")) {
    axios
        .delete(`/api/v1/private/unhide_question/${id}`)
        .then((res) =>
            dispatch({
                type: UN_HIDE,
                payload: id,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            })
        );
    // }
};

// Delete blog
export const unBookmarkedQues = (id) => (dispatch) => {
    //  if (window.confirm("Are you sure? This can NOT be undone!")) {
    axios
        .delete(`/api/mlp_logged/delete/${id}`)
        .then((res) =>
            dispatch({
                type: UN_BOOKMARKED,
                payload: id,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            })
        );
    //  }
};

// Get Total Question by id
export const booktotalQues = () => (dispatch) => {
    dispatch(setQuestionLoading());
    axios
        .get(`/api/mlp_logged/get_bookdash`)
        .then((res) =>
            dispatch({
                type: TOTAL_QUESTION,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: TOTAL_QUESTION,
                payload: null,
            })
        );
};

// Get Next link by id
export const bookPreviousLink = (num, mode) => (dispatch) => {
    dispatch(setQuestionLoading());
    axios
        .get(`/api/mlp_logged/book_prev_link/${num}/${mode}`)
        .then((res) =>
            dispatch({
                type: GET_PRIVIOUS,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_PRIVIOUS,
                payload: null,
            })
        );
};

// Get Next link by id
export const bookNextLinkbook = (num, mode) => (dispatch) => {
    dispatch(setQuestionLoading());
    axios
        .get(`/api/mlp_logged/book_next_link/${num}/${mode}`)
        .then((res) =>
            dispatch({
                type: GET_NEXT,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_NEXT,
                payload: null,
            })
        );
};

// Contact loading
export const setLoggedLoading = () => {
    return {
        type: LOGGED_LOADING,
    };
};

// Question loading
export const setQuestionLoading = () => {
    return {
        type: QUESTION_LOADING,
    };
};
