import {
    GET_COMMENT,
    GET_COMMENTS,
    GET_COMMENT_LISTS,
    DELETE_COMMENT,
    COMMENT_QUESTIONS,
    ADD_COMMENT,
    COMMENT_COUNT,
    COMMENT_LOADING,
} from "../actions/types";

const initialState = {
    comments: [],
    commentlists: [],
    comment_ques: [],
    count: null,
    comment: null,
    loading: true,
    error: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case COMMENT_LOADING:
            return {
                ...state,
                loading: true,
            };

        case ADD_COMMENT:
            return {
                ...state,
                comment_ques: [action.payload, ...state.comment_ques],
            };

        case COMMENT_COUNT:
            return {
                ...state,
                count: action.payload,
                loading: false,
            };

        case COMMENT_QUESTIONS:
            return {
                ...state,
                comment_ques: action.payload,
                loading: false,
            };

        case GET_COMMENTS:
            return {
                ...state,
                comments: action.payload,
                loading: false,
            };

        case GET_COMMENT_LISTS:
            return {
                ...state,
                commentlists: action.payload,
                loading: false,
            };

        case GET_COMMENT:
            return {
                ...state,
                comment: action.payload,
                loading: false,
            };

        case DELETE_COMMENT:
            return {
                ...state,
                commentlists: state.commentlists.filter(
                    (comment) => comment._id !== action.payload
                ),
                comments: state.comments.filter(
                    (comment) => comment._id !== action.payload
                ),
            };

        default:
            return state;
    }
}
