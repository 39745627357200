import React, { Component } from "react";
import ReactTable from "react-table";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Moment from "react-moment";
import { Panel, PanelHeader } from "../common/panel/Panel.jsx";
import "react-table/react-table.css";
import { unHideQues } from "../../../actions/loggedActions.jsx";
import SweetAlert from "react-bootstrap-sweetalert";

class HiddenList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sweetAlertError: false,
        };

        //this.data = makeData();
        this.defaultSorted = [
            {
                id: "_id",
                desc: false,
            },
        ];
        this.tableColumns = [
            {
                Header: "#Q.Id",
                accessor: "zmcq_id.ZNUMBER",
                maxWidth: 100,
                // Cell: (row) => <span>{row.original.zmcq_id.ZNUMBER}</span>,
            },
            {
                Header: "Logged On",
                accessor: "insert_date",
                filterable: false,
                maxWidth: 150,
                Cell: (row) => (
                    <div>
                        <i className="fa fa-calendar col-blue ml-3 mr-2"></i>
                        <Moment format="D MMM YYYY" withTitle>
                            {row.value}
                        </Moment>
                    </div>
                ),
            },
            {
                Header: "Book Name",
                accessor: "zmcq_id.ZBOOKNAME",
                maxWidth: 150,
                style: { whiteSpace: "unset" },
                // Cell: (row) => <span>{row.original.zmcq_id.ZBOOKNAME}</span>,
            },

            {
                Header: "Question",
                accessor: "zmcq_id",
                style: { whiteSpace: "unset" },
                Cell: (row) => (
                    <span>
                        {row.original.zmcq_id ? (
                            <span>
                                {row.original.zmcq_id.ZQUESTION}
                                <br />
                                <span>
                                    <span>
                                        {" "}
                                        A) {row.original.zmcq_id.ZANSWERONE}
                                    </span>{" "}
                                    <br />
                                    <span>
                                        {" "}
                                        B) {row.original.zmcq_id.ZANSWERTWO}
                                    </span>{" "}
                                    <br />
                                    <span>
                                        {" "}
                                        C) {row.original.zmcq_id.ZANSWERTHREE}
                                    </span>{" "}
                                    <br />
                                    <span>
                                        {" "}
                                        D) {row.original.zmcq_id.ZANSWERFOUR}
                                    </span>
                                </span>
                            </span>
                        ) : (
                            <span className="label label-default m-r-3">
                                Undefine
                            </span>
                        )}
                    </span>
                ),
            },

            {
                Header: "Actions",
                accessor: "_id",
                filterable: false,
                sortable: false,
                maxWidth: 175,
                Cell: (row) => (
                    <div>
                        <button
                            onClick={() =>
                                this.toggleSweetAlert("error", row.value)
                            }
                            className="btn btn-outline btn-info btn-sm m-r-5 "
                        >
                            <i className="fas fa-sm  m-r-2 fa-eye"></i> Un-Hide
                        </button>
                    </div>
                ),
            },
        ];
        this.toggleSweetAlert = this.toggleSweetAlert.bind(this);
    }
    toggleSweetAlert(name, id) {
        switch (name) {
            case "error":
                this.setState({
                    sweetAlertError: !this.state.sweetAlertError,
                    id: id,
                });
                break;
            default:
                break;
        }
    }

    onUnhideClick(id) {
        this.props.unHideQues(id);
        this.setState({ sweetAlertError: !this.state.sweetAlertError });
    }

    render() {
        const data = this.props.hiddens;

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-12">
                        {this.state.sweetAlertError && (
                            <SweetAlert
                                danger
                                showCancel
                                confirmBtnText="Yes, Un-Hide it!"
                                confirmBtnBsStyle="danger"
                                cancelBtnBsStyle="default"
                                title="Are you sure?"
                                onConfirm={this.onUnhideClick.bind(
                                    this,
                                    this.state.id
                                )}
                                onCancel={() => this.toggleSweetAlert("error")}
                            >
                                You will not be able to recover this again!
                            </SweetAlert>
                        )}
                        <Panel className="member-panel">
                            <PanelHeader>Hidden List Table</PanelHeader>

                            <ReactTable
                                filterable
                                useSortBy
                                data={data}
                                columns={this.tableColumns}
                                defaultPageSize={10}
                                defaultSorted={this.defaultSorted}
                                className="-highlight"
                            />
                        </Panel>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

HiddenList.propTypes = {
    unHideQues: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
    errors: state.errors,
});

export default connect(mapStateToProps, { unHideQues })(withRouter(HiddenList));
