import React, { Fragment } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// Redux
import { Provider } from "react-redux";
import store from "./store";
import setAuthToken from "./utils/setAuthToken";
import setAuthToken1 from "./utils/setAuthToken";
import { setBaseUrl } from "./utils/setBaseUrl";
import AdminRoutes from "./routes/Admin";
import PublicRoutes from "./routes/Public";

if (localStorage.token) {
    setAuthToken(localStorage.token);
}
if (localStorage.token1) {
    setAuthToken1(localStorage.token1);
}

setBaseUrl();

const App = () => {
    return (
        <Provider store={store}>
            <Router>
                <Fragment>
                    <Switch>
                        <Route path="/admin" component={AdminRoutes} />
                        <Route path="/" component={PublicRoutes} />
                    </Switch>
                </Fragment>
            </Router>
        </Provider>
    );
};

export default App;
