import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

const TextAreaVideo = ({
    name,
    placeholder,
    value,
    label,
    error,
    info,
    type,
    onChange,
    disabled,
    options,
    labelType,
    showDefaultOption,
    src,
    text,
    imgextension,
}) => {
    return (
        <div className="form-group row">
            <label className="col-lg-3 col-form-label">{label}</label>

            <div className="col-lg-9" align="center">
                <label
                    className="btn btn-primary m-r-5"
                    htmlFor="inputImage"
                    title="Upload  file"
                >
                    Upload
                </label>
                <input
                    id="inputImage"
                    className={classnames("hide", {
                        "is-invalid": error,
                    })}
                    type={type}
                    name={name}
                    onChange={onChange}
                    imgextension={imgextension}
                    // value={value}
                />
                {text}

                {error && <div className="invalid-feedback">{error}</div>}
                <small className="f-s-12 text-grey-darker">{info}</small>
            </div>
        </div>
    );
};

TextAreaVideo.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    info: PropTypes.string,
    error: PropTypes.string,
    type: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.string,
};

export default TextAreaVideo;
