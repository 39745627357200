import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Container from "./../content/Container.jsx";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { Panel, PanelHeader, PanelBody } from "./../common/panel/Panel.jsx";
import { getCurrentPrice } from "../../../actions/authActions.jsx";
import { getUserInvoices } from "../../../actions/invoiceActions.jsx";
import { getCurrentProfile } from "../../../actions/userActions.jsx";
import isEmpty from "../../../validation/is-empty";
import Spinner from "../common/textfield/Spinner";
import InvoiceTable from "./InvoiceTable.jsx";
import Moment from "react-moment";
import AlertBox from "./../common/textfield/AlertBox";
import ReactNotification from "react-notifications-component";
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { loadUser, sendVeriEmail } from "../../../actions/userActions";
import PlanTile from "./PlanTile.jsx";

class Subscription extends Component {
    constructor(props) {
        super(props);
        this.state = {
            one_month: "",
            six_month: "",
            twelve_month: "",
            three_month: "",
            life_time: "",
            activeTab: "1",
            activePill: "1",
            subscription_type: "",
            unsubs_date: "",
            collapse: [
                { id: 1, collapse: true },
                { id: 2, collapse: false },
                { id: 3, collapse: false },
                { id: 4, collapse: false },
                { id: 5, collapse: false },
                { id: 6, collapse: false },
                { id: 7, collapse: false },
            ],
            verification_status: "",
            user_id: "",
        };
        this.toggleCollapse = this.toggleCollapse.bind(this);
    }

    togglePill(pill) {
        if (this.state.activePill !== pill) {
            this.setState({
                activePill: pill,
            });
        }
    }

    toggleCollapse(index) {
        var newArray = [];
        for (let collapseObj of this.state.collapse) {
            if (collapseObj.id === index) {
                collapseObj.collapse = !collapseObj.collapse;
            } else {
                collapseObj.collapse = false;
            }
            newArray.push(collapseObj);
        }

        this.setState({
            collapse: newArray,
        });
    }
    componentDidMount() {
        this.props.getCurrentPrice();
        this.props.getUserInvoices();
        this.props.getCurrentProfile();
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.price.price) {
            const price = nextProps.price.price;
            // console.log(price);
            // If profile field doesnt exist, make empty string

            price.one_month = !isEmpty(price.one_month) ? price.one_month : "";
            price.six_month = !isEmpty(price.six_month) ? price.six_month : "";
            price.twelve_month = !isEmpty(price.twelve_month)
                ? price.twelve_month
                : "";
            price.three_month = !isEmpty(price.three_month)
                ? price.three_month
                : "";
            price.life_time = !isEmpty(price.life_time) ? price.life_time : "";

            // Set component fields state
            this.setState({
                one_month: price.one_month,
                six_month: price.six_month,
                twelve_month: price.twelve_month,
                three_month: price.three_month,
                life_time: price.life_time,
            });
        }
        if (nextProps.profile.profile) {
            const profile = nextProps.profile.profile;
            // console.log(profile);
            // If profile field doesnt exist, make empty string
            profile.subscription_type = !isEmpty(profile.subscription_type)
                ? profile.subscription_type
                : "";
            profile.unsubs_date = !isEmpty(profile.unsubs_date)
                ? profile.unsubs_date
                : "";
            // Set component fields state
            this.setState({
                subscription_type: profile.subscription_type,
                unsubs_date: profile.unsubs_date,
            });
        }
        if (nextProps.auth.user) {
            const user = nextProps.auth.user;
            user._id = !isEmpty(user._id) ? user._id : "";
            user.verification_status = !isEmpty(user.verification_status)
                ? user.verification_status
                : "";
            this.setState({
                verification_status: user.verification_status,
                user_id: user._id,
            });
        }
    }

    onEmailClick(id) {
        this.props.sendVeriEmail(id);
        store.addNotification({
            title: "Success!",
            message:
                " An email is sent to your registered email id. Click on the link to verify.",
            type: "success",
            insert: "top",
            container: "top-center",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: { duration: 3000 },
            dismissable: { click: true },
            // content: notificationContent
        });
    }
    render() {
        const { invoices, loading } = this.props.invoice;
        let mainInvoiceContent;
        if (invoices === null || loading) {
            mainInvoiceContent = <Spinner />;
        } else {
            //console.log(blogcats);
            if (invoices.length > 0) {
                mainInvoiceContent = <InvoiceTable invoices={invoices} />;
            } else {
                mainInvoiceContent = (
                    <div className="row">
                        <div className="col-12">
                            <Panel className="member-panel">
                                <PanelHeader>Promo Code List</PanelHeader>
                                <PanelBody>
                                    <div className="note note-secondary m-b-15">
                                        <div className="note-icon">
                                            <i className="fa fa-lightbulb"></i>
                                        </div>
                                        <div className="note-content text-right">
                                            <h4>
                                                <b>This Table Is Empty</b>
                                            </h4>
                                            <p>
                                                {" "}
                                                No data found to show here. This
                                                table is empty.{" "}
                                            </p>
                                        </div>
                                    </div>
                                </PanelBody>
                            </Panel>
                        </div>
                    </div>
                );
            }
        }

        let EmailVerificationContent;
        if (this.state.verification_status === "No") {
            EmailVerificationContent = (
                <AlertBox
                    classes="danger"
                    isOpen={this.state.visible}
                    toggle={this.onDismiss}
                    content="Your Email verification is pending!"
                    linkContent="resend verification link"
                    onClick={this.onEmailClick.bind(this, this.state.user_id)}
                />
            );
        }
        return (
            <React.Fragment>
                <ReactNotification />
                <Container>
                    <div className="content">
                        {EmailVerificationContent}
                        <ol className="breadcrumb float-xl-right">
                            <li className="breadcrumb-item">
                                <Link to="/dashboard">Dashboard</Link>
                            </li>

                            <li className="breadcrumb-item active">
                                Access Plans
                            </li>
                        </ol>
                        <h1 className="page-header">Access Plans</h1>
                        <Panel className="member-panel">
                            <PanelHeader>Access Plan</PanelHeader>
                            <PanelBody>
                                <div className="note note-secondary m-b-15">
                                    <div className="note-content">
                                        <p>Access Plan Details</p>
                                        <h4>
                                            <b>
                                                Current Plan :{" "}
                                                {this.state.subscription_type} (
                                                Validity :{" "}
                                                <Moment
                                                    format="D MMM YYYY"
                                                    withTitle
                                                >
                                                    {this.state.unsubs_date}
                                                </Moment>
                                                )
                                            </b>
                                        </h4>
                                    </div>
                                </div>
                                <Nav className="mb-3" pills>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({
                                                active:
                                                    this.state.activePill ===
                                                    "1",
                                            })}
                                            onClick={() => {
                                                this.togglePill("1");
                                            }}
                                        >
                                            <span className="d-sm-none">
                                                Membership Details
                                            </span>
                                            <span className="d-sm-block d-none">
                                                Membership Details
                                            </span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({
                                                active:
                                                    this.state.activePill ===
                                                    "2",
                                            })}
                                            onClick={() => {
                                                this.togglePill("2");
                                            }}
                                        >
                                            <span className="d-sm-none">
                                                Invoices
                                            </span>
                                            <span className="d-sm-block d-none">
                                                Invoices
                                            </span>
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent
                                    className="p-15 rounded bg-white mb-4"
                                    activeTab={this.state.activePill}
                                >
                                    <TabPane tabId="1">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="dashright">
                                                    <div className="overviewbox box-1">
                                                        <div className="membership pt-20">
                                                            <div className="row ">
                                                                <div className="col-sm-4 col-xs-12">
                                                                    <PlanTile
                                                                        planPrice={
                                                                            this
                                                                                .state
                                                                                .twelve_month
                                                                        }
                                                                        planTitle={
                                                                            "+12"
                                                                        }
                                                                        planId={
                                                                            3
                                                                        }
                                                                        discount={
                                                                            54
                                                                        }
                                                                        navigationUrl={
                                                                            "/payment-subscribe/3"
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="col-sm-4 col-xs-12">
                                                                    <PlanTile
                                                                        planPrice={
                                                                            this
                                                                                .state
                                                                                .six_month
                                                                        }
                                                                        planTitle={
                                                                            "+6"
                                                                        }
                                                                        planId={
                                                                            2
                                                                        }
                                                                        isPopular={
                                                                            true
                                                                        }
                                                                        discount={
                                                                            42
                                                                        }
                                                                        navigationUrl={
                                                                            "/payment-subscribe/2"
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="col-sm-4 col-xs-12">
                                                                    <PlanTile
                                                                        planPrice={
                                                                            this
                                                                                .state
                                                                                .one_month
                                                                        }
                                                                        planTitle={
                                                                            "+1"
                                                                        }
                                                                        planId={
                                                                            1
                                                                        }
                                                                        navigationUrl={
                                                                            "/payment-subscribe/1"
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>
                                    <TabPane tabId="2">
                                        {mainInvoiceContent}
                                    </TabPane>
                                </TabContent>
                            </PanelBody>
                        </Panel>
                    </div>
                </Container>
            </React.Fragment>
        );
    }
}

Subscription.propTypes = {
    auth: PropTypes.object.isRequired,
    price: PropTypes.object.isRequired,
    getCurrentPrice: PropTypes.func.isRequired,
    getCurrentProfile: PropTypes.func.isRequired,
    getUserInvoices: PropTypes.func.isRequired,
    loadUser: PropTypes.func.isRequired,
    sendVeriEmail: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
    invoice: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    price: state.price,
    auth: state.auth,
    profile: state.profile,
    invoice: state.invoice,
    errors: state.errors,
});

export default connect(mapStateToProps, {
    getCurrentPrice,
    getUserInvoices,
    getCurrentProfile,
    loadUser,
    sendVeriEmail,
})(withRouter(Subscription));
