import React, { Component } from "react";
import ReactTable from "react-table";
//import { Link } from "react-router-dom";
import { Panel, PanelHeader } from "../common/panel/Panel.jsx";
import "react-table/react-table.css";

class UsedCodeList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alertblog: false,
        };

        //this.data = makeData();
        this.defaultSorted = [
            {
                id: "_id",
                desc: false,
            },
        ];
        this.tableColumns = [
            {
                Header: "Id",
                id: "row",
                maxWidth: 50,
                filterable: false,
                Cell: (row) => {
                    return <div>{row.index + 1}</div>;
                },
            },

            {
                Header: "Coupan Code",
                accessor: "coupan_code",
                Cell: (row) => (
                    <span>
                        <span className="label label-primary  m-r-3">
                            {row.value}
                        </span>
                    </span>
                ),
            },

            {
                Header: "Offers(%)",
                accessor: "coupan_off",
                Cell: (row) => (
                    <span>
                        <span className="label label-info m-r-3">
                            {row.value}
                        </span>
                    </span>
                ),
            },

            {
                Header: "Code Type",
                accessor: "coupan_type",
                Cell: (row) => (
                    <span>
                        {row.value === "Expiry" ? (
                            <span className="label label-warning m-r-3">
                                Expiry
                            </span>
                        ) : row.value === "Non-Expiry" ? (
                            <span className="label label-primary  m-r-3">
                                Non Expiry
                            </span>
                        ) : (
                            <span className="label label-warning m-r-3">
                                None
                            </span>
                        )}
                    </span>
                ),
            },

            {
                Header: "Code Status",
                accessor: "coupan_status",
                Cell: (row) => (
                    <span>
                        {row.value === "Active" ? (
                            <span className="label label-green m-r-3">
                                Active
                            </span>
                        ) : row.value === "Inactive" ? (
                            <span className="label label-danger  m-r-3">
                                Inactive
                            </span>
                        ) : (
                            <span className="label label-warning m-r-3">
                                None
                            </span>
                        )}
                    </span>
                ),
            },
            {
                Header: "Times Used",
                accessor: "coupan_used",
                Cell: (row) => (
                    <span>
                        <span className="label label-green m-r-3">
                            {row.value}
                        </span>
                    </span>
                ),
            },
        ];
    }

    render() {
        const data = this.props.used_coupans;

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-12">
                        <Panel>
                            <PanelHeader>Promo Code List</PanelHeader>

                            <ReactTable
                                filterable
                                data={data}
                                columns={this.tableColumns}
                                defaultPageSize={10}
                                defaultSorted={this.defaultSorted}
                                className="-highlight"
                            />
                        </Panel>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default UsedCodeList;
