import {
    DASHBOARD_USER,
    DASHBOARD_SUBSCRIBER,
    DASHBOARD_TREVENUE,
    DASHBOARD_NREVENUE,
    DASHBOARD_WEBSITES,
    DASHBOARD_ANDROIDS,
    DASHBOARD_IOS,
    DASHBOARD_COMMENTS,
    DASHBOARD_LOADING,
} from "../actions/types";

const initialState = {
    websites: [],
    androids: [],
    ios: [],
    comments: [],
    user: null,
    subscriber: null,
    trevenue: null,
    nrevenue: null,
    loading: true,
    error: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case DASHBOARD_LOADING:
            return {
                ...state,
                loading: true,
            };

        case DASHBOARD_USER:
            return {
                ...state,
                user: action.payload,
                loading: false,
            };

        case DASHBOARD_SUBSCRIBER:
            return {
                ...state,
                subscriber: action.payload,
                loading: false,
            };

        case DASHBOARD_TREVENUE:
            return {
                ...state,
                trevenue: action.payload,
                loading: false,
            };

        case DASHBOARD_NREVENUE:
            return {
                ...state,
                nrevenue: action.payload,
                loading: false,
            };

        case DASHBOARD_WEBSITES:
            return {
                ...state,
                websites: action.payload,
                loading: false,
            };

        case DASHBOARD_ANDROIDS:
            return {
                ...state,
                androids: action.payload,
                loading: false,
            };

        case DASHBOARD_IOS:
            return {
                ...state,
                ios: action.payload,
                loading: false,
            };

        case DASHBOARD_COMMENTS:
            return {
                ...state,
                comments: action.payload,
                loading: false,
            };

        default:
            return state;
    }
}
