import React, { Component } from "react";
import DropdownProfile from "./Profile.jsx";
//import SearchForm from "./form.jsx";
//import DropdownNotification from "./notification.jsx";
import { Link } from "react-router-dom";
import { PageSettings } from "../../content/page-settings.jsx";

class Header extends Component {
    componentDidMount() {
        document.body.classList.add("member-body");
    }

    componentWillUnmount() {
        document.body.classList.remove("member-body");
    }
    render() {
        return (
            <PageSettings.Consumer>
                {({
                    toggleMobileSidebar,
                    toggleRightSidebar,
                    toggleMobileRightSidebar,
                    toggleMobileTopMenu,
                    pageHeaderLanguageBar,
                    pageHeaderMegaMenu,
                    pageTwoSidebar,
                    pageTopMenu,
                    pageSidebar,
                }) => (
                    <div id="header" className="header navbar-default">
                        <div className="navbar-header">
                            {pageTwoSidebar && (
                                <button
                                    type="button"
                                    className="navbar-toggle pull-left"
                                    onClick={toggleMobileRightSidebar}
                                >
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                </button>
                            )}
                            <Link to="/" className="navbar-brand">
                                <img
                                    src="https://seatrials-bucket.s3.us-east-2.amazonaws.com/main/logo.png"
                                    alt="Logo"
                                />
                            </Link>

                            {pageHeaderMegaMenu && (
                                <button
                                    type="button"
                                    className="navbar-toggle pt-0 pb-0 mr-0"
                                    onClick={this.toggleMegaMenu}
                                >
                                    <span className="fa-stack fa-lg text-inverse">
                                        <i className="far fa-square fa-stack-2x"></i>
                                        <i className="fa fa-cog fa-stack-1x"></i>
                                    </span>
                                </button>
                            )}
                            {pageTopMenu && pageSidebar && (
                                <button
                                    type="button"
                                    className="navbar-toggle pt-0 pb-0 mr-0 collapsed"
                                    onClick={toggleMobileTopMenu}
                                >
                                    <span className="fa-stack fa-lg text-inverse">
                                        <i className="far fa-square fa-stack-2x"></i>
                                        <i className="fa fa-cog fa-stack-1x"></i>
                                    </span>
                                </button>
                            )}
                            {!pageSidebar && pageTopMenu && (
                                <button
                                    type="button"
                                    className="navbar-toggle"
                                    onClick={toggleMobileTopMenu}
                                >
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                </button>
                            )}
                            {pageSidebar && (
                                <button
                                    type="button"
                                    className="navbar-toggle"
                                    onClick={toggleMobileSidebar}
                                >
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                </button>
                            )}
                        </div>

                        <ul className="navbar-nav navbar-right">
                            {/*<SearchForm />
              <DropdownNotification />*/}

                            <DropdownProfile />

                            {pageTwoSidebar && (
                                <li className="divider d-none d-md-block"></li>
                            )}

                            {pageTwoSidebar && (
                                <li className="d-none d-md-block">
                                    <Link
                                        to="/"
                                        onClick={toggleRightSidebar}
                                        className="f-s-14"
                                    >
                                        <i className="fa fa-th"></i>
                                    </Link>
                                </li>
                            )}
                        </ul>
                    </div>
                )}
            </PageSettings.Consumer>
        );
    }
}

export default Header;
