import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "../../common/common.jsx";
import queryString from "qs";
import {
    getQuestion,
    bookmarkQuestion,
    getResume,
    submitQuestion,
    unBookmarkQuestion,
} from "../../../../actions/v1/questionActions.jsx";
import { sendVeriEmail } from "../../../../actions/userActions";
import Button from "./Button";
import isEmpty from "../../../../validation/is-empty";
import Container from "../../content/Container.jsx";
import Comments from "./Comments.jsx";
import ChangeModal from "./ChangeModal.jsx";
import Hide from "./Hide.jsx";
import { Alert, Spinner } from "reactstrap";
import ReactNotification from "react-notifications-component";
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import ModalImage from "react-modal-image";
import moment from "moment";

class UserQuestion extends Component {
    constructor() {
        super();
        this.state = {
            type: "",
            id: "",
            num: "",
            cat: "",
            ZSUBCATEGORYNAME: "",
            ZNUMBER: "",
            ZQUESTION: "",
            ZANSWERONE: "",
            ZANSWERTWO: "",
            ZANSWERTHREE: "",
            ZANSWERFOUR: "",
            ZSLUG: "",
            ZIMAGENAME: "",
            total_ques: "",
            ZINBOOKCATEGORY: "",
            ZANSWER: "",
            bgcolora: "",
            bgcolorb: "",
            bgcolorc: "",
            bgcolord: "",
            peventa: "",
            peventb: "",
            peventc: "",
            peventd: "",
            mode: "",
            image_type: "",
            correctanswer: 0,
            wronganswer: 0,
            percentage: 0,
            addnumber: 1,
            _id: "",
            answeronly: false,
            shuffle: false,
            bookmark: false,
            hide: "",
            modalDialog: false,
            verification_status: "",
            user_id: "",
            zid: "",
            zinbookcat: "",
        };
        this.onShuffle = this.onShuffle.bind(this);
        this.onUnShuffle = this.onUnShuffle.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.clearScore = this.clearScore.bind(this);
    }

    toggleModal(name) {
        switch (name) {
            case "modalDialog":
                this.setState({ modalDialog: !this.state.modalDialog });
                break;
            default:
                break;
        }
    }

    componentDidMount() {
        const queryStringValues = queryString.parse(
            this.props.location.search.slice(1)
        );

        this.props.getQuestion(
            queryStringValues.zid,
            queryStringValues.type,
            queryStringValues.id,
            queryStringValues.num,
            queryStringValues.mode,
            queryStringValues.zinbookcat,
            true
        );

        this.props.getResume(
            queryStringValues.mode,
            queryStringValues.type,
            queryStringValues.id,
            queryStringValues.num,
            queryStringValues.zid,
            queryStringValues.zinbookcat,
        );

        this.setState({
            type: queryStringValues.type,
            id: queryStringValues.id,
            num: queryStringValues.num,
            mode: queryStringValues.mode,
            zid: queryStringValues.zid,
            zinbookcat: queryStringValues.zinbookcat,
        });
    }

    componentWillReceiveProps(nextProps) {
        const queryStringValues = queryString.parse(
            this.props.location.search.slice(1)
        );
        const queryStringValuesn = queryString.parse(
            nextProps.location.search.slice(1)
        );
        if (
            queryStringValues.mode !== queryStringValuesn.mode ||
            queryStringValues.zid !== queryStringValuesn.zid ||
            queryStringValues.num !== queryStringValuesn.num
        ) {
            this.props.getQuestion(
                queryStringValuesn.zid,
                queryStringValuesn.type,
                queryStringValuesn.id,
                queryStringValuesn.num,
                queryStringValuesn.mode,
                queryStringValuesn.zinbookcat,
                true
            );

            this.props.getResume(
                queryStringValuesn.mode,
                queryStringValuesn.type,
                queryStringValuesn.id,
                queryStringValuesn.num,
                queryStringValuesn.zid,
                queryStringValuesn.zinbookcat
            );

            this.setState({
                type: queryStringValuesn.type,
                id: queryStringValuesn.id,
                num: queryStringValuesn.num,
                mode: queryStringValuesn.mode,
                zid: queryStringValuesn.zid,
                zinbookcat: queryStringValuesn.zinbookcat,
                bgcolora: "",
                bgcolorb: "",
                bgcolorc: "",
                bgcolord: "",
                peventa: "",
                peventb: "",
                peventc: "",
                peventd: "",
            });
        }

        if (nextProps.question.hide) {
            const hide = nextProps.question.hide;
            hide.zmcq_id = !isEmpty(hide.zmcq_id) ? hide.zmcq_id : "";
            this.setState({
                hide: hide.zmcq_id,
            });
        }

        if (nextProps.question.meta) {
            this.setState({ total_ques: nextProps.question.meta.total });
        }

        if (nextProps.question.question) {
            const question = nextProps.question.question;

            question.ZSUBCATEGORYNAME = !isEmpty(question.ZSUBCATEGORYNAME)
                ? question.ZSUBCATEGORYNAME
                : "";
            question.ZNUMBER = !isEmpty(question.ZNUMBER)
                ? question.ZNUMBER
                : "";
            question.ZQUESTION = !isEmpty(question.ZQUESTION)
                ? question.ZQUESTION
                : "";
            question.ZANSWERONE = !isEmpty(question.ZANSWERONE)
                ? question.ZANSWERONE
                : "";
            question.ZANSWERTWO = !isEmpty(question.ZANSWERTWO)
                ? question.ZANSWERTWO
                : "";
            question.ZANSWERTHREE = !isEmpty(question.ZANSWERTHREE)
                ? question.ZANSWERTHREE
                : "";
            question.ZANSWERFOUR = !isEmpty(question.ZANSWERFOUR)
                ? question.ZANSWERFOUR
                : "";
            question.ZINBOOKCATEGORY = !isEmpty(question.ZINBOOKCATEGORY)
                ? question.ZINBOOKCATEGORY
                : "";
            question.ZANSWER = !isEmpty(question.ZANSWER)
                ? question.ZANSWER
                : "";
            question.ZSLUG = !isEmpty(question.ZSLUG) ? question.ZSLUG : "";
            question.ZIMAGE = !isEmpty(question.ZIMAGE) ? question.ZIMAGE : "";
            question.ZIMAGENAME = !isEmpty(question.ZIMAGENAME)
                ? question.ZIMAGENAME
                : "";
            question._id = !isEmpty(question._id) ? question._id : "";

            // Set component fields state for blog
            this.setState({
                ZSUBCATEGORYNAME: question.ZSUBCATEGORYNAME,
                ZNUMBER: question.ZNUMBER,
                ZQUESTION: question.ZQUESTION,
                ZANSWERONE: question.ZANSWERONE,
                ZANSWERTWO: question.ZANSWERTWO,
                ZANSWERTHREE: question.ZANSWERTHREE,
                ZANSWERFOUR: question.ZANSWERFOUR,
                ZINBOOKCATEGORY: question.ZINBOOKCATEGORY,
                ZANSWER: question.ZANSWER,
                ZSLUG: question.ZSLUG,
                ZIMAGE: question.ZIMAGE,
                ZIMAGENAME: question.ZIMAGENAME,
                _id: question._id,
                bookmark: question.bookmark,
            });
        }

        if (nextProps.auth.user) {
            const user = nextProps.auth.user;
            user._id = !isEmpty(user._id) ? user._id : "";
            user.verification_status = !isEmpty(user.verification_status)
                ? user.verification_status
                : "";
            this.setState({
                verification_status: user.verification_status,
                user_id: user._id,
            });
        }
    }

    onEmailClick(id) {
        this.props.sendVeriEmail(id);
        store.addNotification({
            title: "Success!",
            message:
                " An email is sent to your registered email id. Click on the link to verify.",
            type: "success",
            insert: "top",
            container: "top-center",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: { duration: 3000 },
            dismissable: { click: true },
        });
    }

    onBookClick(id) {
        this.props.bookmarkQuestion(id, () => {
            this.setState({
                bookmark: true,
            });
        });
    }

    onUnBookClick(id) {
        this.props.unBookmarkQuestion(id, () => {
            this.setState({
                bookmark: false,
            });
        });
    }

    clearScore() {
        this.setState((prevState) => ({
            correctanswer: 0,
            wronganswer: 0,
            percentage: 0,
            bgcolora: "",
            bgcolorb: "",
            bgcolorc: "",
            bgcolord: "",
            peventa: "",
            peventb: "",
            peventc: "",
            peventd: "",
        }));
    }

    onAnswerClick(id) {
        const quesdata = {
            answer_type: id,
            _id: this.state._id,
        };
        this.props.submitQuestion(quesdata, this.props.history);
        let correct = this.state.correctanswer + this.state.addnumber;
        let wrong = this.state.wronganswer + this.state.addnumber;
        let adddata =
            this.state.wronganswer +
            this.state.correctanswer +
            this.state.addnumber;

        if (id === this.state.ZANSWER) {
            let percent = Math.round((correct / adddata) * 100);
            this.setState({
                [`bgcolor${id}`]: "Chartreuse",
                correctanswer: correct,
                percentage: percent,
            });
        } else {
            let percent = Math.round(
                (this.state.correctanswer / adddata) * 100
            );
            this.setState({
                [`bgcolor${id}`]: "red",
                [`bgcolor${this.state.ZANSWER}`]: "Chartreuse",
                wronganswer: wrong,
                percentage: percent,
            });
        }

        this.setState({
            peventa: "none",
            peventb: "none",
            peventc: "none",
            peventd: "none",
        });

        if (localStorage.getItem("user") === "Inactive") {
            const lastVisitedDateStr = localStorage.getItem("lastVisitedDate");
            let diff = 0;

            if (lastVisitedDateStr) {
                const lastVisitedDate = moment(lastVisitedDateStr);
                const now = moment.now();
                console.log(lastVisitedDate.diff(now, "days"));
                diff = lastVisitedDate.diff(now, "days");
            }else{
                localStorage.setItem("lastVisitedDate", new Date());
            }

            if (diff === 0) {
                const timeValue = localStorage.getItem("time");
                if (timeValue == null) {
                    localStorage.setItem("time", "1");
                } else {
                    localStorage.setItem("time", `${+timeValue + 1}`);
                }
            } else {
                localStorage.setItem("lastVisitedDate", new Date());
                localStorage.removeItem("time");
            }
        }
    }
    onShuffle() {
        this.setState({ shuffle: true });
        let path = `/user/watch?mode=shuffle&type=${this.state.type}&id=${this.state.id}&num=${this.state.num}&zid=${this.state._id}&zinbookcat=${this.state.ZINBOOKCATEGORY}`;
        this.props.history.push(path);
    }

    onUnShuffle() {
        this.setState({ shuffle: false });
        let path = `/user/watch?mode=normal&type=${this.state.type}&id=${this.state.id}&num=${this.state.num}&zid=${this.state._id}&zinbookcat=${this.state.ZINBOOKCATEGORY}`;
        this.props.history.push(path);
    }

    render() {
        const { next, previous, loading, meta, question } = this.props.question;
        let mainButton;

        mainButton = (
            <Button
                nextId={meta?.next}
                prevId={meta?.prev}
                ZINBOOKCATEGORY={this.state.ZINBOOKCATEGORY}
                type={this.state.type}
                id={this.state.id}
                num={this.state.num}
                mode={this.state.mode}
                total_ques={this.state.total_ques}
            />
        );

        let HideButton;

        HideButton = (
            <Hide
                next={next}
                nextId={meta?.next}
                prevId={meta?.prev}
                previous={previous}
                type={this.state.type}
                ZINBOOKCATEGORY={this.state.ZINBOOKCATEGORY}
                id={this.state.id}
                num={this.state.num}
                mode={this.state.mode}
                zid={this.state.zid}
            />
        );

        let bookCategoryName;
        if (this.state.ZINBOOKCATEGORY === "1") {
            bookCategoryName = "Engine";
        } else {
            bookCategoryName = "Deck";
        }

        //Answer content start here
        let answeronlyContent;
        let answeronlyMainContent;
        const zAnswers = [
            this.state.ZANSWERONE,
            this.state.ZANSWERTWO,
            this.state.ZANSWERTHREE,
            this.state.ZANSWERFOUR,
        ];

        answeronlyContent = (
            <button
                onClick={() => {
                    this.setState((prevState) => ({
                        answeronly: !this.state.answeronly,
                    }));
                }}
                className="btn btn-link"
            >
                <i className="fa fa-angle-double-right"></i>{" "}
                {this.state.answeronly ? "Normal" : "Answers Only"}
            </button>
        );

        answeronlyMainContent = (
            <div id="main" className="row">
                <div className="col-sm-12">
                    {["a", "b", "c", "d"].map((option, index) => {
                        const backgroundColor = this.state.answeronly
                            ? this.state.ZANSWER === option
                                ? "Chartreuse"
                                : ""
                            : this.state[`bgcolor${option}`];
                        const pointerEvents = this.state.answeronly
                            ? "none"
                            : this.state[`pevent${option}`];

                        return (
                            <div
                                className="form-group radio_input q-hover"
                                key={`${option}${index}`}
                            >
                                <label
                                    className="text-s"
                                    id={option}
                                    style={{
                                        backgroundColor,
                                        pointerEvents,
                                    }}
                                    onClick={
                                        this.state.answeronly
                                            ? undefined
                                            : this.onAnswerClick.bind(
                                                  this,
                                                  option
                                              )
                                    }
                                >
                                    <b>{option.toUpperCase()}) </b>{" "}
                                    <span className="f-c">
                                        {zAnswers[index]}
                                    </span>
                                </label>
                            </div>
                        );
                    })}
                </div>
            </div>
        );

        //Shuffle content start here
        const shuffleContent = (
            <button
                onClick={this.state.shuffle ? this.onUnShuffle : this.onShuffle}
                className="btn btn-link"
            >
                <i className="fa fa-angle-double-right"></i>{" "}
                {this.state.shuffle ? "Un-Shuffle" : "Shuffle"}
            </button>
        );
        const { modalDialog } = this.state;
        let ChangeCatModals;
        if (modalDialog === true) {
            ChangeCatModals = (
                <ChangeModal
                    modalDialog={this.state.modalDialog}
                    type={this.state.type}
                    id={this.state.id}
                    mode={this.state.mode}
                    zinbookcat={this.state.zinbookcat}
                />
            );
        }

        //Email Verification
        let EmailVerificationContent;
        if (this.state.verification_status === "No") {
            EmailVerificationContent = (
                <Alert
                    color="danger"
                    isOpen={this.state.visible}
                    toggle={this.onDismiss}
                >
                    Your Email verification is pending!
                    <Link
                        to="#"
                        onClick={this.onEmailClick.bind(
                            this,
                            this.state.user_id
                        )}
                        className="alert-link"
                    >
                        {" "}
                        resend verification link
                    </Link>
                    .
                </Alert>
            );
        }
        let commentContent;
        if (question && question.comments) {
            commentContent = (
                <Comments
                    zmcq_id={this.state.zid}
                    comments={question.comments}
                />
            );
        }

        let mainContent;
        if (loading) {
            mainContent = "";
        } else {
            mainContent = (
                <div className="dashboard">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 col-xs-12"></div>
                        </div>
                        <div className="post-list" id="postList">
                            <div className="row">
                                <div className="col-sm-12 col-xs-12">
                                    <div className="dashright dass-wraper">
                                        <div className="row">
                                            <div className="col-md-8 padd">
                                                <div className="bomb-wrapper">
                                                    <div className="col-md-6 for-logo">
                                                        {/*<!--	<figure><img src="" alt="" width="250"></figure> -->*/}
                                                    </div>

                                                    <div className="col-md-6 text-right for-bomb-icon">
                                                        {HideButton}

                                                        {this.state.bookmark ? (
                                                            <span
                                                                onClick={this.onUnBookClick.bind(
                                                                    this,
                                                                    this.state
                                                                        ._id
                                                                )}
                                                            >
                                                                <img
                                                                    src="https://seatrials-bucket.s3.us-east-2.amazonaws.com/main/icons8-bookmark-filled-100.png"
                                                                    alt="un-book"
                                                                ></img>
                                                            </span>
                                                        ) : (
                                                            <span
                                                                onClick={this.onBookClick.bind(
                                                                    this,
                                                                    this.state
                                                                        ._id
                                                                )}
                                                            >
                                                                <img
                                                                    src="https://seatrials-bucket.s3.us-east-2.amazonaws.com/main/icons8-bookmark-100.png"
                                                                    alt="bookmark"
                                                                />{" "}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div
                                                    id="wizard_container"
                                                    className="wizard"
                                                    noValidate="novalidate"
                                                >
                                                    <div className="q-number-wrapper">
                                                        <div className="col-md-6 for-only-q">
                                                            <span
                                                                title=""
                                                                className="user-badge"
                                                                style={{
                                                                    backgroundcolor:
                                                                        "#04aad4",
                                                                }}
                                                            >
                                                                {
                                                                    bookCategoryName
                                                                }
                                                            </span>
                                                            <span className="author-name">
                                                                {
                                                                    this.state
                                                                        .ZSUBCATEGORYNAME
                                                                }{" "}
                                                            </span>
                                                        </div>

                                                        <div className="col-md-6 text-right for-only-q">
                                                            <span
                                                                className="num-hov"
                                                                onClick={() =>
                                                                    this.toggleModal(
                                                                        "modalDialog"
                                                                    )
                                                                }
                                                            >
                                                                {" "}
                                                                <strong>
                                                                    NUMBER: #
                                                                    {
                                                                        this
                                                                            .state
                                                                            .num
                                                                    }
                                                                    /
                                                                    {
                                                                        this
                                                                            .state
                                                                            .total_ques
                                                                    }
                                                                </strong>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {/*<!-- /top-wizard -->*/}
                                                    <form
                                                        name="example-1"
                                                        id="wrapped"
                                                        method="POST"
                                                        action="questionare_send.php"
                                                        className="wizard-form"
                                                    >
                                                        <div
                                                            id="middle-wizard"
                                                            className="wizard-branch wizard-wrapper for-qs"
                                                        >
                                                            <div
                                                                className="step wizard-step current"
                                                                style={{
                                                                    display:
                                                                        "block",
                                                                }}
                                                            >
                                                                <p className="main_question wizard-header">
                                                                    <strong>
                                                                        {
                                                                            this
                                                                                .state
                                                                                .ZNUMBER
                                                                        }
                                                                    </strong>
                                                                </p>
                                                                <h3 className="main_question wizard-header q-text">
                                                                    <strong>
                                                                        {
                                                                            this
                                                                                .state
                                                                                .ZQUESTION
                                                                        }
                                                                    </strong>
                                                                </h3>

                                                                {
                                                                    answeronlyMainContent
                                                                }

                                                                {/*<!-- /row -->*/}
                                                            </div>
                                                            {/*<!-- /step -->*/}
                                                        </div>
                                                    </form>
                                                    <div className="q-number-wrapper for-btn for-top-btn">
                                                        <div
                                                            id="main-score"
                                                            className="col-md-7 text-left for-only-q"
                                                        >
                                                            <ul
                                                                className="question-statistic and-dsc"
                                                                id="question"
                                                            >
                                                                <li className="s-text">
                                                                    <p className="question-views clr">
                                                                        Correct:{" "}
                                                                        {
                                                                            this
                                                                                .state
                                                                                .correctanswer
                                                                        }
                                                                    </p>
                                                                </li>

                                                                <li className="s-text s-text-1">
                                                                    <p
                                                                        itemProp="answerCount"
                                                                        className="question-answers question-views clr"
                                                                    >
                                                                        Wrong:{" "}
                                                                        {
                                                                            this
                                                                                .state
                                                                                .wronganswer
                                                                        }{" "}
                                                                    </p>
                                                                </li>

                                                                <li className="s-text s-text-2">
                                                                    <p
                                                                        itemProp="upvoteCount"
                                                                        className="question-votes question-views clr"
                                                                    >
                                                                        Percentage:{" "}
                                                                        {
                                                                            this
                                                                                .state
                                                                                .percentage
                                                                        }
                                                                        %
                                                                    </p>
                                                                </li>
                                                            </ul>
                                                        </div>

                                                        {mainButton}
                                                    </div>
                                                </div>
                                                {commentContent}
                                            </div>
                                            {/*<!-- left col-end-->*/}

                                            <div className="col-md-4">
                                                <div className="dashleft">
                                                    <h3>STUDY MODE</h3>
                                                    <ul>
                                                        <li>
                                                            {answeronlyContent}
                                                        </li>

                                                        <li>
                                                            {shuffleContent}
                                                        </li>
                                                    </ul>
                                                    <h3>OPTIONS</h3>
                                                    <ul>
                                                        <li>
                                                            <button
                                                                onClick={() => {
                                                                    this.clearScore();
                                                                }}
                                                                className="btn btn-link"
                                                            >
                                                                <i className="fa fa-angle-double-right"></i>{" "}
                                                                Clear Score
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div>
                                                {/*{ImageContent}*/}
                                                <div id="right-form">
                                                    <figure>
                                                        {this.state.ZIMAGE ? (
                                                            <ModalImage
                                                                small={
                                                                    this.state
                                                                        .ZIMAGE
                                                                }
                                                                large={
                                                                    this.state
                                                                        .ZIMAGE
                                                                }
                                                                alt={
                                                                    this.state
                                                                        .ZIMAGENAME
                                                                }
                                                            />
                                                        ) : (
                                                            <img
                                                                id="myImg"
                                                                src="https://seatrials-bucket.s3.us-east-2.amazonaws.com/main/q-logo.png"
                                                                alt="logo-q"
                                                            />
                                                        )}
                                                    </figure>
                                                </div>
                                                {/*<!-- right col-end-->*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <React.Fragment>
                <ReactNotification />
                <Container>
                    {ChangeCatModals}
                    {loading ? (
                        <Spinner
                            color="primary"
                            className="position-absolute"
                            style={{ top: "50%", left: "50%" }}
                        />
                    ) : (
                        <div className="content">
                            {EmailVerificationContent}

                            {mainContent}
                        </div>
                    )}
                </Container>
            </React.Fragment>
        );
    }
}

UserQuestion.propTypes = {
    sendVeriEmail: PropTypes.func.isRequired,
    getQuestion: PropTypes.func.isRequired,
    submitQuestion: PropTypes.func.isRequired,
    getResume: PropTypes.func.isRequired,
    bookmarkQuestion: PropTypes.func.isRequired,
    unBookmarkQuestion: PropTypes.func.isRequired,
    question: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    question: state.v1Question,
    auth: state.auth,
});

export default connect(mapStateToProps, {
    getQuestion,
    bookmarkQuestion,
    getResume,
    submitQuestion,
    sendVeriEmail,
    unBookmarkQuestion,
})(withRouter(UserQuestion));
