import React, { Component } from "react";
import { Panel, PanelHeader, PanelBody } from "./../common/panel/Panel.jsx";
import Container from "../content/Container.jsx";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
    getTestQuestion,
    getTestQuestionCount,
    totalTestQues,
} from "../../../actions/testActions.jsx";
import isEmpty from "../../../validation/is-empty";

class TestModel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: "",
            ZSLUG: "",
            test_id: "",
            write_count: "",
            total_ques: "",
        };
    }

    componentDidMount() {
        if (localStorage.getItem("user") === "Inactive") {
            this.props.history.push("/user/alert");
        }

        if (this.props.match.params.Id) {
            // console.log(this.props.match.params.Id)
            this.setState({ id: this.props.match.params.Id });
            this.props.getTestQuestion(this.props.match.params.Id);
            this.props.getTestQuestionCount(this.props.match.params.Id);
            this.props.totalTestQues(this.props.match.params.Id);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.test.write_tests) {
            const write_tests = nextProps.test.write_tests;
            write_tests.zmcq_id.ZSLUG = !isEmpty(write_tests.zmcq_id.ZSLUG)
                ? write_tests.zmcq_id.ZSLUG
                : "";
            write_tests._id = !isEmpty(write_tests._id) ? write_tests._id : "";
            write_tests.zmcq_id._id = !isEmpty(write_tests.zmcq_id._id)
                ? write_tests.zmcq_id._id
                : "";
            this.setState({
                ZSLUG: write_tests.zmcq_id.ZSLUG,
                test_id: write_tests._id,
                zmcq_id: write_tests.zmcq_id._id,
            });
        }

        if (nextProps.test.write_count) {
            this.setState({ write_count: nextProps.test.write_count });
        } else {
            this.setState({ write_count: "1" });
        }

        if (nextProps.question.total) {
            this.setState({ total_ques: nextProps.question.total });
        }
    }
    render() {
        return (
            <React.Fragment>
                <Container>
                    <div className="content">
                        <ol className="breadcrumb float-xl-right">
                            <li className="breadcrumb-item">
                                <Link to="/dashboard">Dashboard</Link>
                            </li>
                            <li className="breadcrumb-item">
                                <Link to="/user/test/manage-test">
                                    Manage Test
                                </Link>
                            </li>
                            <li className="breadcrumb-item active">Result</li>
                        </ol>
                        <h1 className="page-header">Result </h1>
                        <div className="row">
                            <div className="col-xl-12">
                                <Panel className="member-panel">
                                    <PanelHeader>Result</PanelHeader>
                                    <PanelBody className="panel-form">
                                        <form className="form-horizontal form-bordered">
                                            <fieldset>
                                                <div className="form-group row">
                                                    <div className="col-md-4 offset-md-2">
                                                        <button
                                                            onClick={() =>
                                                                window.open(
                                                                    `/user/test/result/${this.state.id}`,
                                                                    "_blank"
                                                                )
                                                            }
                                                            type="button"
                                                            className="btn btn-outline btn-warning"
                                                        >
                                                            <i className="fa fa-print"></i>{" "}
                                                            Result
                                                        </button>
                                                    </div>
                                                    {this.state.zmcq_id ? (
                                                        <div className="col-md-4 offset-md-2">
                                                            {this.state.id ? (
                                                                <Link
                                                                    to={`/user/test/watch?test=${this.state.id}&num=${this.state.write_count}&id=${this.state.test_id}&zid=${this.state.zmcq_id}`}
                                                                    target="_blank"
                                                                    type="button"
                                                                    className="btn btn-outline btn-success"
                                                                >
                                                                    <i className="fa fa-check"></i>{" "}
                                                                    Resume Test(
                                                                    {
                                                                        this
                                                                            .state
                                                                            .write_count
                                                                    }
                                                                    /
                                                                    {
                                                                        this
                                                                            .state
                                                                            .total_ques
                                                                    }
                                                                    )
                                                                </Link>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                            </fieldset>
                                        </form>
                                    </PanelBody>
                                </Panel>
                            </div>
                        </div>
                    </div>
                </Container>
            </React.Fragment>
        );
    }
}
TestModel.propTypes = {
    getTestQuestion: PropTypes.func.isRequired,
    getTestQuestionCount: PropTypes.func.isRequired,
    totalTestQues: PropTypes.func.isRequired,
    test: PropTypes.object.isRequired,
    question: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    test: state.test,
    question: state.question,
});

export default connect(mapStateToProps, {
    getTestQuestion,
    getTestQuestionCount,
    totalTestQues,
})(withRouter(TestModel));
