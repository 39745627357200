import React, { Component } from "react";
import Container from "./../content/Container.jsx";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ManageTest from "./ManageTest.jsx";
import {
    getloggedTopicQues,
    getDashLogged,
    getDashHidden,
    getDashBook,
} from "../../../actions/loggedActions.jsx";
import { loadUser, sendVeriEmail } from "../../../actions/userActions";
import { Alert } from "reactstrap";
import isEmpty from "../../../validation/is-empty";
import ReactNotification from "react-notifications-component";
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import queryString from "qs";
import SweetAlert from "react-bootstrap-sweetalert";
import { getTopic, generateNewTest } from "../../../actions/testActions.jsx";
import { Panel, PanelHeader, PanelBody } from "./../common/panel/Panel.jsx";
import TopicList from "./TopicList.jsx";
import TopicListEngine from "./TopicListEngine.jsx";
import TextFieldArea from "./../common/textfield/TextFieldArea";
import classnames from "classnames";

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            logged_topics: "",
            dashbook: "0",
            dashhidden: "0",
            dashlogged: "0",
            verification_status: "",
            user_id: "",

            test_name: "",
            test_subject: "",
            question_count: "",
            test_num: "",
            visible: false,
            sweetAlertInfo: false,
            collapse: false,
            question_type: "",
            errors: {},
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.toggleSweetAlert = this.toggleSweetAlert.bind(this);
        this.routeChange = this.routeChange.bind(this);
    }

    routeChange() {
        let path = `/user/account/subscription`;
        this.props.history.push(path);
    }

    handleCancel(e) {
        this.setState({
            test_name: "",
            test_subject: "",
            question_count: "",
            test_num: "",
        });
    }

    toggleSweetAlert(name) {
        switch (name) {
            case "info":
                this.setState({ sweetAlertInfo: !this.state.sweetAlertInfo });
                break;

            default:
                break;
        }
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    componentDidMount() {
        this.props.getloggedTopicQues();
        this.props.getDashLogged();
        this.props.getDashHidden();
        this.props.getDashBook();
        this.props.getTopic();
        const queryStringValues = queryString.parse(
            this.props.location.search.slice(1)
        );
        if (queryStringValues.payment) {
            // User is redirected by us after registration.
            store.addNotification({
                title: "Success!",
                message: " Payment successfull ",
                type: "success",
                insert: "top",
                container: "top-center",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: { duration: 3000 },
                dismissable: { click: true },
                // content: notificationContent
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors,
            });
        }
        if (nextProps.logged.logged_topics) {
            this.setState({ logged_topics: nextProps.logged.logged_topics });
        }

        if (nextProps.logged.dashbook) {
            this.setState({ dashbook: nextProps.logged.dashbook });
        }

        if (nextProps.logged.dashhidden) {
            this.setState({ dashhidden: nextProps.logged.dashhidden });
        }

        if (nextProps.logged.dashlogged) {
            this.setState({ dashlogged: nextProps.logged.dashlogged });
        }

        if (nextProps.auth.user) {
            const user = nextProps.auth.user;
            user._id = !isEmpty(user._id) ? user._id : "";
            user.verification_status = !isEmpty(user.verification_status)
                ? user.verification_status
                : "";
            user.question_type = !isEmpty(user.question_type)
                ? user.question_type
                : "";
            this.setState({
                verification_status: user.verification_status,
                user_id: user._id,
                question_type: user.question_type,
            });
        }
        if (nextProps.test.test) {
            const test = nextProps.test.test;
            test.test_num = !isEmpty(test.test_num) ? test.test_num : "";
            if (!isEmpty(test.test_num)) {
                this.props.history.push(`/user/test/print/${test.test_num}`);
            }
        }
    }

    onSubmit(e) {
        e.preventDefault();

        if (this.state.sweetAlertInfo === false) {
            const testData = {
                test_name: this.state.test_name,
                test_subject: this.state.test_subject,
                question_count: this.state.question_count,
            };
            this.props.generateNewTest(testData, this.props.history);
        }
    }

    onEmailClick(id) {
        this.props.sendVeriEmail(id);
        store.addNotification({
            title: "Success!",
            message:
                " An email is sent to your registered email id. Click on the link to verify.",
            type: "success",
            insert: "top",
            container: "top-center",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: { duration: 3000 },
            dismissable: { click: true },
            // content: notificationContent
        });
    }
    render() {
        const { errors } = this.state;
        const { topics, loading } = this.props.test;
        let EmailVerificationContent;
        if (this.state.verification_status === "No") {
            EmailVerificationContent = (
                <Alert
                    color="danger"
                    isOpen={this.state.visible}
                    toggle={this.onDismiss}
                >
                    Your Email verification is pending!
                    <Link
                        to="#"
                        onClick={this.onEmailClick.bind(
                            this,
                            this.state.user_id
                        )}
                        className="alert-link"
                    >
                        {" "}
                        resend verification link
                    </Link>
                    .
                </Alert>
            );
        }

        let topicContent;

        if (topics === null || loading) {
            topicContent = <option>No customer</option>;
        } else {
            if (topics.length > 0) {
                if (this.state.question_type === "Deck") {
                    topicContent = <TopicList topics={topics} />;
                } else {
                    topicContent = <TopicListEngine topics={topics} />;
                }
            }
        }

        let buttonContent;
        if (localStorage.getItem("user") === "Inactive") {
            buttonContent = (
                <div className="form-group row">
                    <div className="col-md-12 offset-md-5">
                        <button
                            onClick={() => this.toggleSweetAlert("info")}
                            className="btn btn-sm btn-primary m-r-5"
                        >
                            Submit
                        </button>
                        <button
                            type="button"
                            onClick={() => this.toggleSweetAlert("info")}
                            className="btn btn-sm btn-default"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            );
        } else {
            buttonContent = (
                <div className="form-group row">
                    <div className="col-md-12 offset-md-5">
                        <button
                            type="submit"
                            className="btn btn-sm btn-primary m-r-5"
                        >
                            Submit
                        </button>
                        <button
                            type="button"
                            onClick={this.handleCancel}
                            className="btn btn-sm btn-default"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            );
        }

        return (
            <React.Fragment>
                <Container>
                    <ReactNotification />
                    <div id="content" className="content">
                        {EmailVerificationContent}
                        <ol className="breadcrumb float-xl-right">
                            <li className="breadcrumb-item">
                                <Link to="/">Home</Link>
                            </li>
                            <li className="breadcrumb-item active">
                                Dashboard
                            </li>
                        </ol>
                        <h1 className="page-header">Dashboard </h1>

                        <div className="row">
                            <div className="col-xl-3 col-md-6">
                                <div className="widget widget-stats bg-red">
                                    <div className="stats-icon">
                                        <i className="far fa-bookmark"></i>
                                    </div>
                                    <div className="stats-info">
                                        <h4>Total Bookmark Question</h4>
                                        <p>{this.state.dashbook}</p>
                                    </div>
                                    <div className="stats-link">
                                        <Link to="/user/manage-question/bookmarked-question">
                                            View List{" "}
                                            <i className="fa fa-arrow-alt-circle-right"></i>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6">
                                <div className="widget widget-stats bg-orange">
                                    <div className="stats-icon">
                                        <i className="fas fa-bomb"></i>
                                    </div>
                                    <div className="stats-info">
                                        <h4>Total Hidden Question</h4>
                                        <p>{this.state.dashhidden}</p>
                                    </div>
                                    <div className="stats-link">
                                        <Link to="/user/manage-question/hidden-question">
                                            View List{" "}
                                            <i className="fa fa-arrow-alt-circle-right"></i>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6">
                                <div className="widget widget-stats bg-grey-darker">
                                    <div className="stats-icon">
                                        <i className="far fa-list-alt"></i>
                                    </div>
                                    <div className="stats-info">
                                        <h4>Total Logged Questions </h4>
                                        <p>{this.state.dashlogged}</p>
                                    </div>
                                    <div className="stats-link">
                                        <Link to="/user/manage-question/all-logged">
                                            View List{" "}
                                            <i className="fa fa-arrow-alt-circle-right"></i>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6">
                                <div className="widget widget-stats bg-black-lighter">
                                    <div className="stats-icon">
                                        <i className="fas fa-book-open"></i>
                                    </div>
                                    <div className="stats-info">
                                        <h4>Total Question By Topic</h4>
                                        <p>{this.state.logged_topics.length}</p>
                                    </div>
                                    <div className="stats-link">
                                        <Link to="/user/manage-question/by-topic">
                                            View List{" "}
                                            <i className="fa fa-arrow-alt-circle-right"></i>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12">
                                {this.state.sweetAlertInfo && (
                                    <SweetAlert
                                        info
                                        showCancel
                                        confirmBtnText="Upgrade Account"
                                        confirmBtnBsStyle="info"
                                        cancelBtnBsStyle="default"
                                        title="Subscription Alerts"
                                        onConfirm={this.routeChange}
                                        onCancel={() =>
                                            this.toggleSweetAlert("info")
                                        }
                                    >
                                        You have to be a premium user to use all
                                        Funtionality.
                                    </SweetAlert>
                                )}
                                <Panel className="member-panel">
                                    <PanelHeader>Generate A Test</PanelHeader>
                                    <PanelBody className="panel-form">
                                        <form
                                            className="form-horizontal form-bordered"
                                            onSubmit={this.onSubmit}
                                        >
                                            <fieldset>
                                                <TextFieldArea
                                                    label="Test Name"
                                                    placeholder="Test Name"
                                                    name="test_name"
                                                    type="text"
                                                    value={this.state.test_name}
                                                    onChange={this.onChange}
                                                    error={errors.test_name}
                                                />

                                                <div className="form-group row ">
                                                    <label className="col-lg-3 col-form-label">
                                                        Select A Topic
                                                        <span className="text-danger">
                                                            *
                                                        </span>
                                                    </label>
                                                    <div className="col-lg-9">
                                                        <select
                                                            className={classnames(
                                                                "form-control ",
                                                                {
                                                                    "is-invalid":
                                                                        errors.test_subject,
                                                                }
                                                            )}
                                                            data-size="10"
                                                            data-live-search="true"
                                                            data-style="btn-white"
                                                            name="test_subject"
                                                            value={
                                                                this.state
                                                                    .test_subject
                                                            }
                                                            onChange={
                                                                this.onChange
                                                            }
                                                        >
                                                            <option value="">
                                                                Select A Topic
                                                            </option>
                                                            {topicContent}
                                                        </select>

                                                        {errors.test_subject && (
                                                            <div
                                                                className="invalid-feedback"
                                                                style={{
                                                                    display:
                                                                        "block",
                                                                }}
                                                            >
                                                                {
                                                                    errors.test_subject
                                                                }
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="form-group row ">
                                                    <label className="col-lg-3 col-form-label">
                                                        Number of Questions To
                                                        Generate
                                                        <span className="text-danger">
                                                            *
                                                        </span>
                                                    </label>
                                                    <div className="col-lg-9">
                                                        <select
                                                            className={classnames(
                                                                "form-control ",
                                                                {
                                                                    "is-invalid":
                                                                        errors.question_count,
                                                                }
                                                            )}
                                                            data-size="10"
                                                            data-live-search="true"
                                                            data-style="btn-white"
                                                            name="question_count"
                                                            value={
                                                                this.state
                                                                    .question_count
                                                            }
                                                            onChange={
                                                                this.onChange
                                                            }
                                                        >
                                                            <option value="">
                                                                Select Number of
                                                                Question to
                                                                Generate{" "}
                                                            </option>
                                                            <option value="10">
                                                                10
                                                            </option>
                                                            <option value="15">
                                                                15
                                                            </option>
                                                            <option value="50">
                                                                50
                                                            </option>
                                                            <option value="70">
                                                                70
                                                            </option>
                                                        </select>

                                                        {errors.question_count && (
                                                            <div
                                                                className="invalid-feedback"
                                                                style={{
                                                                    display:
                                                                        "block",
                                                                }}
                                                            >
                                                                {
                                                                    errors.question_count
                                                                }
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                {buttonContent}
                                            </fieldset>
                                        </form>
                                    </PanelBody>
                                </Panel>
                            </div>

                            <div className="col-xl-12">
                                <ManageTest />
                            </div>
                        </div>
                    </div>
                </Container>
            </React.Fragment>
        );
    }
}

Dashboard.propTypes = {
    getloggedTopicQues: PropTypes.func.isRequired,
    loadUser: PropTypes.func.isRequired,
    sendVeriEmail: PropTypes.func.isRequired,
    getDashLogged: PropTypes.func.isRequired,
    getDashHidden: PropTypes.func.isRequired,
    getDashBook: PropTypes.func.isRequired,
    getTopic: PropTypes.func.isRequired,
    generateNewTest: PropTypes.func.isRequired,
    logged: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    test: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    logged: state.logged,
    auth: state.auth,
    test: state.test,
    errors: state.errors,
});

export default connect(mapStateToProps, {
    getloggedTopicQues,
    getDashLogged,
    getDashHidden,
    getDashBook,
    loadUser,
    sendVeriEmail,
    getTopic,
    generateNewTest,
})(Dashboard);
