import axios from "axios";

import {
    GET_TOPICS,
    TEST_LOADING,
    GET_TESTS,
    GET_NUM,
    GET_TEST_LISTS,
    GET_TEST_LIST,
    GET_TEST,
    GET_WRITE_TESTS,
    GET_WRITE_COUNT,
    TOTAL_QUESTION,
    GET_ERRORS,
    DELETE_TEST,
    GET_PRIVIOUS,
    GET_NEXT,
    QUESTION_LOADING,
} from "./types";

// Generate Test
export const postTest = (testData) => (dispatch) => {
    axios.post("/api/mlp_test/post_test", testData).then((res) => {
        dispatch({
            type: GET_TEST_LIST,
            payload: res.data,
        });
        //  dispatch(get_Test_New());
    });
};

// Generate Test
export const get_Test_New = (test, zid) => (dispatch) => {
    axios.get(`/api/mlp_test/get_test/${test}/${zid}`).then((res) =>
        dispatch({
            type: GET_TEST_LIST,
            payload: res.data,
        })
    );
};

// Edit Page
export const postNum = (editData) => (dispatch) => {
    axios
        .post(`/api/mlp_test/page_num`, editData)
        .then((res) =>
            dispatch({
                type: GET_NUM,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            })
        );
};

// Get Next link by id
export const getNextLink = (test, num) => (dispatch) => {
    dispatch(setQuestionLoading());
    axios
        .get(`/api/mlp_test/next_link/${test}/${num}`)
        .then((res) =>
            dispatch({
                type: GET_NEXT,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_NEXT,
                payload: null,
            })
        );
};

// Get Next link by id
export const getPreviousLink = (test, num) => (dispatch) => {
    dispatch(setQuestionLoading());
    axios
        .get(`/api/mlp_test/prev_link/${test}/${num}`)
        .then((res) =>
            dispatch({
                type: GET_PRIVIOUS,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_PRIVIOUS,
                payload: null,
            })
        );
};

// Question loading
export const setQuestionLoading = () => {
    return {
        type: QUESTION_LOADING,
    };
};

// Get test result
export const totalTestQues = (test) => (dispatch) => {
    axios
        .get(`/api/mlp_test/test_count/${test}`)
        .then((res) =>
            dispatch({
                type: TOTAL_QUESTION,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: TOTAL_QUESTION,
                payload: null,
            })
        );
};

// Get test result
export const getTestQuestion = (test) => (dispatch) => {
    dispatch(setTestLoading());
    axios
        .get(`/api/mlp_test/test_question/${test}`)
        .then((res) =>
            dispatch({
                type: GET_WRITE_TESTS,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_WRITE_TESTS,
                payload: null,
            })
        );
};

// Get test result
export const getTestQuestionCount = (test) => (dispatch) => {
    //  dispatch(setTestLoading());
    axios
        .get(`/api/mlp_test/test_question_count/${test}`)
        .then((res) =>
            dispatch({
                type: GET_WRITE_COUNT,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_WRITE_COUNT,
                payload: null,
            })
        );
};

// Get current Pages
export const getTopic = () => (dispatch) => {
    dispatch(setTestLoading());
    axios
        .get("/api/mlp_test/getTopic")
        .then((res) =>
            dispatch({
                type: GET_TOPICS,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_TOPICS,
                payload: null,
            })
        );
};

// Get current Pages
export const manageTest = () => (dispatch) => {
    dispatch(setTestLoading());
    axios
        .get("/api/mlp_test/get_test_details")
        .then((res) =>
            dispatch({
                type: GET_TESTS,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_TESTS,
                payload: null,
            })
        );
};

// Get current Pages
export const GetTest = (id) => (dispatch) => {
    dispatch(setTestLoading());
    axios
        .get(`/api/mlp_test/test_details/${id}`)
        .then((res) =>
            dispatch({
                type: GET_TEST,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_TEST,
                payload: null,
            })
        );
};

// Get current Pages
export const GetTestList = (id) => (dispatch) => {
    dispatch(setTestLoading());
    axios
        .get(`/api/mlp_test/get_test_list/${id}`)
        .then((res) =>
            dispatch({
                type: GET_TEST_LISTS,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_TEST_LISTS,
                payload: null,
            })
        );
};

// Delete Subscriber
export const deleteTest = (id) => (dispatch) => {
    // if (window.confirm("Are you sure? This can NOT be undone!")) {
    axios
        .delete(`/api/mlp_test/test/${id}`)
        .then((res) =>
            dispatch({
                type: DELETE_TEST,
                payload: id,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            })
        );
    // }
};

// Generate Test
export const generateNewTest = (testData) => (dispatch) => {
    axios
        .post("/api/mlp_test/generate_test", testData)
        .then((res) =>
            dispatch({
                type: GET_TEST,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            })
        );
};

// Contact loading
export const setTestLoading = () => {
    return {
        type: TEST_LOADING,
    };
};
