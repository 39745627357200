import React, { Component } from "react";
import ReactTable from "react-table";
import Moment from "react-moment";
import { Panel, PanelHeader } from "../common/panel/Panel.jsx";
import "react-table/react-table.css";

class ListByTopic extends Component {
    constructor(props) {
        super(props);

        //this.data = makeData();
        this.defaultSorted = [
            {
                id: "_id",
                desc: false,
            },
        ];
        this.tableColumns = [
            {
                Header: "#Q.Id",
                accessor: "zmcq_id",
                maxWidth: 100,
                Cell: (row) => (
                    <span>
                        {row.original.zmcq_id ? (
                            <span>{row.original.zmcq_id.ZNUMBER}</span>
                        ) : (
                            <span className="label label-default m-r-3">
                                Undefine
                            </span>
                        )}
                    </span>
                ),
            },
            {
                Header: "Added",
                accessor: "insert_date",
                filterable: false,
                maxWidth: 150,
                Cell: (row) => (
                    <div>
                        <i className="fa fa-calendar col-blue ml-3 mr-2"></i>
                        <Moment format="D MMM YYYY" withTitle>
                            {row.value}
                        </Moment>
                    </div>
                ),
            },
            {
                Header: "Book Name",
                accessor: "zmcq_id",
                maxWidth: 150,
                style: { whiteSpace: "unset" },
                Cell: (row) => (
                    <span>
                        {row.original.zmcq_id ? (
                            <span>{row.original.zmcq_id.ZBOOKNAME}</span>
                        ) : (
                            <span className="label label-default m-r-3">
                                Undefine
                            </span>
                        )}
                    </span>
                ),
            },

            {
                Header: "Question",
                accessor: "zmcq_id",
                style: { whiteSpace: "unset" },
                Cell: (row) => (
                    <span>
                        {row.original.zmcq_id ? (
                            <span>
                                {row.original.zmcq_id.ZQUESTION}
                                <br />
                                <span>
                                    <span
                                        style={{
                                            background:
                                                row.original.zmcq_id.ZANSWER ===
                                                "a"
                                                    ? "Chartreuse"
                                                    : row.original
                                                          .answer_type === "a"
                                                    ? "lightcoral"
                                                    : null,
                                        }}
                                    >
                                        {" "}
                                        A) {row.original.zmcq_id.ZANSWERONE}
                                    </span>{" "}
                                    <br />
                                    <span
                                        style={{
                                            background:
                                                row.original.zmcq_id.ZANSWER ===
                                                "b"
                                                    ? "Chartreuse"
                                                    : row.original
                                                          .answer_type === "b"
                                                    ? "lightcoral"
                                                    : null,
                                        }}
                                    >
                                        {" "}
                                        B) {row.original.zmcq_id.ZANSWERTWO}
                                    </span>{" "}
                                    <br />
                                    <span
                                        style={{
                                            background:
                                                row.original.zmcq_id.ZANSWER ===
                                                "c"
                                                    ? "Chartreuse"
                                                    : row.original
                                                          .answer_type === "c"
                                                    ? "lightcoral"
                                                    : null,
                                        }}
                                    >
                                        {" "}
                                        C) {row.original.zmcq_id.ZANSWERTHREE}
                                    </span>{" "}
                                    <br />
                                    <span
                                        style={{
                                            background:
                                                row.original.zmcq_id.ZANSWER ===
                                                "d"
                                                    ? "Chartreuse"
                                                    : row.original
                                                          .answer_type === "d"
                                                    ? "lightcoral"
                                                    : null,
                                        }}
                                    >
                                        {" "}
                                        D) {row.original.zmcq_id.ZANSWERFOUR}
                                    </span>
                                </span>
                            </span>
                        ) : (
                            <span className="label label-default m-r-3">
                                Undefine
                            </span>
                        )}
                    </span>
                ),
            },
        ];
        this.onDismiss = this.onDismiss.bind(this);
    }

    onDismiss() {
        this.setState({ visible: false });
    }

    onDeleteClick(id) {
        this.props.deleteComment(id);
        this.setState({ visible: true, alertblog: true });
    }

    render() {
        const data = this.props.book_lists;

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-12">
                        <Panel className="member-panel">
                            <PanelHeader>
                                All Logged Question By Topic
                            </PanelHeader>

                            <ReactTable
                                filterable
                                useSortBy
                                data={data}
                                columns={this.tableColumns}
                                defaultPageSize={10}
                                defaultSorted={this.defaultSorted}
                                className="-highlight"
                            />
                        </Panel>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default ListByTopic;
