import React from "react";
import { Link } from "react-router-dom";
class Footer extends React.Component {
    constructor(props) {
        super(props);

        var date = new Date();
        this.state = {
            year: date.getFullYear(),
        };
    }
    render() {
        return (
            <div className="member-footer">
                <div id="footer" className="footer">
                    Copyright © {this.state.year}{" "}
                    <Link to="#" onClick={() => window.open("/", "_blank")}>
                        SeaTrials.net
                    </Link>{" "}
                    All Rights Reserved.
                </div>
            </div>
        );
    }
}

export default Footer;
