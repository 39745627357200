import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "../../common/common.jsx";
import queryString from "qs";
import { getQuestion } from "../../../../actions/questionActions.jsx";
import {
    getNextLink,
    getPreviousLink,
    totalTestQues,
    get_Test_New,
    postTest,
} from "../../../../actions/testActions.jsx";
import Button from "./Button";
import ChangeModal from "./ChangeModal";
import isEmpty from "../../../../validation/is-empty";
import Container from "../../content/Container.jsx";
import ModalImage from "react-modal-image";
import SweetAlert from "react-bootstrap-sweetalert";
import Spinner from "../../common/textfield/Spinner";
class UserQuestion extends Component {
    constructor() {
        super();
        this.state = {
            type: "",
            id: "",
            num: "",
            cat: "",
            ZSUBCATEGORYNAME: "",
            ZNUMBER: "",
            ZQUESTION: "",
            ZANSWERONE: "",
            ZANSWERTWO: "",
            ZANSWERTHREE: "",
            ZANSWERFOUR: "",
            ZSLUG: "",
            image_type: "",
            total_ques: "",
            ZINBOOKCATEGORY: "",
            ZANSWER: "",
            bgcolora: "",
            bgcolorb: "",
            bgcolorc: "",
            bgcolord: "",
            peventa: "",
            peventb: "",
            peventc: "",
            peventd: "",
            mode: "",
            modalDialog: false,
            correctanswer: 0,
            wronganswer: 0,
            percentage: 0,
            addnumber: 1,
            _id: "",
            answeronly: false,
            shuffle: false,
            book: "",
            hide: "",
            status: "",
            answer_type: "",
            test_id: "",
            ZIMAGENAME: "",
            sweetAlertInfo: false,
        };
        this.toggleSweetAlert = this.toggleSweetAlert.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
    }

    toggleSweetAlert(name) {
        switch (name) {
            case "info":
                this.setState({ sweetAlertInfo: !this.state.sweetAlertInfo });
                break;
            default:
                break;
        }
    }

    toggleModal(name) {
        switch (name) {
            case "modalDialog":
                this.setState({ modalDialog: !this.state.modalDialog });
                break;
            default:
                break;
        }
    }

    componentDidMount() {
        const queryStringValues = queryString.parse(
            this.props.location.search.slice(1)
        );
        // console.log(queryStringValues.zid)
        this.props.getNextLink(queryStringValues.test, queryStringValues.num);
        this.props.getPreviousLink(
            queryStringValues.test,
            queryStringValues.num
        );
        this.props.getQuestion(queryStringValues.zid);
        this.props.totalTestQues(queryStringValues.test);
        this.props.get_Test_New(queryStringValues.test, queryStringValues.zid);

        this.setState({
            test: queryStringValues.test,
            num: queryStringValues.num,
            view: queryStringValues.view,
            test_id: queryStringValues.id,
        });
    }

    componentWillReceiveProps(nextProps) {
        const queryStringValues = queryString.parse(
            this.props.location.search.slice(1)
        );
        const queryStringValuesn = queryString.parse(
            nextProps.location.search.slice(1)
        );

        // console.log(queryStringValuesn);
        if (queryStringValues.zid !== queryStringValuesn.zid) {
            this.props.getQuestion(queryStringValuesn.zid);
            this.props.getNextLink(
                queryStringValuesn.test,
                queryStringValuesn.num
            );
            this.props.getPreviousLink(
                queryStringValuesn.test,
                queryStringValuesn.num
            );
            this.props.get_Test_New(
                queryStringValuesn.test,
                queryStringValuesn.zid
            );

            this.setState({
                test: queryStringValuesn.test,
                num: queryStringValuesn.num,
                view: queryStringValuesn.view,
                test_id: queryStringValuesn.id,
                bgcolora: "",
                bgcolorb: "",
                bgcolorc: "",
                bgcolord: "",
                peventa: "",
                peventb: "",
                peventc: "",
                peventd: "",
            });
        }

        /// if(queryStringValues.num !== queryStringValuesn.num)
        //  {
        //     this.props.getQuestion(queryStringValuesn.zid);
        //    this.props.getNextLink(queryStringValuesn.test,queryStringValuesn.num);
        //    this.props.getPreviousLink(queryStringValuesn.test,queryStringValuesn.num);
        //    this.props.get_Test_New(queryStringValuesn.test,queryStringValuesn.zid);

        //    this.setState({
        //     test: queryStringValuesn.test,
        //     num: queryStringValuesn.num,
        //     view: queryStringValuesn.view,
        //     test_id: queryStringValuesn.id,
        //     bgcolora: "",
        ///     bgcolorb: "",
        //     bgcolorc: "",
        //      bgcolord: "",
        //      peventa : "",
        //      peventb : "",
        //      peventc : "",
        //      peventd : "",
        //   })
        // }

        if (queryStringValues.test !== queryStringValuesn.test) {
            this.props.getQuestion(queryStringValuesn.zid);
            this.props.getNextLink(
                queryStringValuesn.test,
                queryStringValuesn.num
            );
            this.props.getPreviousLink(
                queryStringValuesn.test,
                queryStringValuesn.num
            );
            this.props.get_Test_New(
                queryStringValuesn.test,
                queryStringValuesn.zid
            );

            this.setState({
                test: queryStringValuesn.test,
                num: queryStringValuesn.num,
                view: queryStringValuesn.view,
                test_id: queryStringValuesn.id,
                bgcolora: "",
                bgcolorb: "",
                bgcolorc: "",
                bgcolord: "",
                peventa: "",
                peventb: "",
                peventc: "",
                peventd: "",
            });
        }

        //console.log(queryStringValuesn);

        if (nextProps.question.total) {
            this.setState({ total_ques: nextProps.question.total });
        }

        if (nextProps.question.question) {
            const question = nextProps.question.question;
            question.ZSUBCATEGORYNAME = !isEmpty(question.ZSUBCATEGORYNAME)
                ? question.ZSUBCATEGORYNAME
                : "";
            question.ZNUMBER = !isEmpty(question.ZNUMBER)
                ? question.ZNUMBER
                : "";
            question.ZQUESTION = !isEmpty(question.ZQUESTION)
                ? question.ZQUESTION
                : "";
            question.ZANSWERONE = !isEmpty(question.ZANSWERONE)
                ? question.ZANSWERONE
                : "";
            question.ZANSWERTWO = !isEmpty(question.ZANSWERTWO)
                ? question.ZANSWERTWO
                : "";
            question.ZANSWERTHREE = !isEmpty(question.ZANSWERTHREE)
                ? question.ZANSWERTHREE
                : "";
            question.ZANSWERFOUR = !isEmpty(question.ZANSWERFOUR)
                ? question.ZANSWERFOUR
                : "";
            question.ZINBOOKCATEGORY = !isEmpty(question.ZINBOOKCATEGORY)
                ? question.ZINBOOKCATEGORY
                : "";
            question.ZANSWER = !isEmpty(question.ZANSWER)
                ? question.ZANSWER
                : "";
            question.ZSLUG = !isEmpty(question.ZSLUG) ? question.ZSLUG : "";
            question.ZIMAGE = !isEmpty(question.ZIMAGE) ? question.ZIMAGE : "";
            question.ZIMAGENAME = !isEmpty(question.ZIMAGENAME)
                ? question.ZIMAGENAME
                : "";
            question._id = !isEmpty(question._id) ? question._id : "";

            // Set component fields state for blog
            this.setState({
                ZSUBCATEGORYNAME: question.ZSUBCATEGORYNAME,
                ZNUMBER: question.ZNUMBER,
                ZQUESTION: question.ZQUESTION,
                ZANSWERONE: question.ZANSWERONE,
                ZANSWERTWO: question.ZANSWERTWO,
                ZANSWERTHREE: question.ZANSWERTHREE,
                ZANSWERFOUR: question.ZANSWERFOUR,
                ZINBOOKCATEGORY: question.ZINBOOKCATEGORY,
                ZANSWER: question.ZANSWER,
                ZSLUG: question.ZSLUG,
                ZIMAGE: question.ZIMAGE,
                ZIMAGENAME: question.ZIMAGENAME,
                _id: question._id,
            });
        }

        if (nextProps.test.testlist) {
            const testlist = nextProps.test.testlist;

            testlist.status = !isEmpty(testlist.status) ? testlist.status : "";
            testlist.zmcq_id = !isEmpty(testlist.zmcq_id)
                ? testlist.zmcq_id
                : "";
            testlist.answer_type = !isEmpty(testlist.answer_type)
                ? testlist.answer_type
                : "";

            this.setState({
                status: testlist.status,
                answer_type: testlist.answer_type,
            });
            if (testlist.zmcq_id === queryStringValuesn.zid) {
                if (testlist.answer_type === "a") {
                    this.setState({
                        bgcolora: "darkorange",
                        bgcolorb: "",
                        bgcolorc: "",
                        bgcolord: "",
                    });
                } else if (testlist.answer_type === "b") {
                    this.setState({
                        bgcolora: "",
                        bgcolorb: "darkorange",
                        bgcolorc: "",
                        bgcolord: "",
                    });
                } else if (testlist.answer_type === "c") {
                    this.setState({
                        bgcolora: "",
                        bgcolorb: "",
                        bgcolorc: "darkorange",
                        bgcolord: "",
                    });
                } else if (testlist.answer_type === "d") {
                    this.setState({
                        bgcolora: "",
                        bgcolorb: "",
                        bgcolorc: "",
                        bgcolord: "darkorange",
                    });
                }
            }
        }
    }

    //======onAnswerClick============
    onAnswerClick(id) {
        const testData = {
            answer_type: id,
            test_num: this.state.test,
            test_id: this.state.test_id,
        };
        this.props.postTest(testData, this.props.history);

        if (id === "a") {
            this.setState({
                bgcolora: "darkorange",
                bgcolorb: "",
                bgcolorc: "",
                bgcolord: "",
            });
        } else if (id === "b") {
            this.setState({
                bgcolorb: "darkorange",
                bgcolora: "",
                bgcolorc: "",
                bgcolord: "",
            });
        } else if (id === "c") {
            this.setState({
                bgcolorc: "darkorange",
                bgcolora: "",
                bgcolorb: "",
                bgcolord: "",
            });
        } else if (id === "d") {
            this.setState({
                bgcolord: "darkorange",
                bgcolora: "",
                bgcolorb: "",
                bgcolorc: "",
            });
        }
        this.setState({
            peventa: "none",
            peventb: "none",
            peventc: "none",
            peventd: "none",
        });
    }

    render() {
        const { next, previous, loading } = this.props.question;
        let mainButton;
        //if (next === null || previous === null || loading) {
        //mainButton = "";
        // } else {
        //console.log(blogcats);
        if (next === "") {
            mainButton = (
                <div className="col-md-5 text-right for-only-q">
                    <button
                        onClick={() => this.toggleSweetAlert("info")}
                        type="button"
                        className="forward adjust-btn demo4"
                    >
                        Submit Test <i className="fa fa-angle-double-right"></i>
                    </button>
                </div>
            );
        } else {
            mainButton = (
                <Button
                    next={next}
                    previous={previous}
                    test={this.state.test}
                    num={this.state.num}
                />
            );
        }
        // }

        const { modalDialog } = this.state;
        let ChangeCatModals;
        if (modalDialog === true) {
            ChangeCatModals = (
                <ChangeModal
                    modalDialog={this.state.modalDialog}
                    test={this.state.test}
                    test_id={this.state.test_id}
                />
            );
        }

        let bookCategoryName;
        if (this.state.ZINBOOKCATEGORY === "1") {
            bookCategoryName = "Engine";
        } else {
            bookCategoryName = "Deck";
        }

        let testContent;
        if (loading) {
            //testContent = "";
            testContent = <Spinner />;
        } else {
            testContent = (
                <div className="dashboard">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 col-xs-12"></div>
                        </div>
                        <div className="post-list" id="postList">
                            <div className="row">
                                <div className="col-sm-12 col-xs-12">
                                    <div className="dashright dass-wraper">
                                        <div className="row">
                                            <div className="col-md-8 padd">
                                                <div className="bomb-wrapper">
                                                    <div className="col-md-6 for-logo">
                                                        {/*<!--	<figure><img src="" alt="" width="250"></figure> -->*/}
                                                    </div>
                                                    <div className="col-md-6 text-right for-bomb-icon"></div>
                                                </div>
                                                <div
                                                    id="wizard_container"
                                                    className="wizard"
                                                    noValidate="novalidate"
                                                >
                                                    <div className="q-number-wrapper">
                                                        <div className="col-md-6 for-only-q">
                                                            <span
                                                                title=""
                                                                className="user-badge"
                                                                style={{
                                                                    backgroundcolor:
                                                                        "#04aad4",
                                                                }}
                                                            >
                                                                {
                                                                    bookCategoryName
                                                                }
                                                            </span>
                                                            <span className="author-name">
                                                                {
                                                                    this.state
                                                                        .ZSUBCATEGORYNAME
                                                                }{" "}
                                                            </span>
                                                        </div>

                                                        <div className="col-md-6 text-right for-only-q">
                                                            <span
                                                                className="num-hov"
                                                                onClick={() =>
                                                                    this.toggleModal(
                                                                        "modalDialog"
                                                                    )
                                                                }
                                                            >
                                                                {" "}
                                                                <strong>
                                                                    NUMBER: #
                                                                    {
                                                                        this
                                                                            .state
                                                                            .num
                                                                    }
                                                                    /
                                                                    {
                                                                        this
                                                                            .state
                                                                            .total_ques
                                                                    }
                                                                </strong>{" "}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {/*<!-- /top-wizard -->*/}
                                                    <form
                                                        name="example-1"
                                                        id="wrapped"
                                                        method="POST"
                                                        action="questionare_send.php"
                                                        className="wizard-form"
                                                    >
                                                        <div
                                                            id="middle-wizard"
                                                            className="wizard-branch wizard-wrapper for-qs"
                                                        >
                                                            <div
                                                                className="step wizard-step current"
                                                                style={{
                                                                    display:
                                                                        "block",
                                                                }}
                                                            >
                                                                <p className="main_question wizard-header">
                                                                    <strong>
                                                                        {
                                                                            this
                                                                                .state
                                                                                .ZNUMBER
                                                                        }
                                                                    </strong>
                                                                </p>
                                                                <h3 className="main_question wizard-header q-text">
                                                                    <strong>
                                                                        {
                                                                            this
                                                                                .state
                                                                                .ZQUESTION
                                                                        }
                                                                    </strong>
                                                                </h3>

                                                                <div
                                                                    id="main"
                                                                    className="row"
                                                                >
                                                                    <div className="col-sm-12">
                                                                        <div className="form-group radio_input q-hover">
                                                                            <label
                                                                                className="text-s"
                                                                                id="a"
                                                                                style={{
                                                                                    backgroundColor:
                                                                                        this
                                                                                            .state
                                                                                            .bgcolora,
                                                                                    pointerEvents:
                                                                                        this
                                                                                            .state
                                                                                            .peventa,
                                                                                }}
                                                                                onClick={this.onAnswerClick.bind(
                                                                                    this,
                                                                                    "a"
                                                                                )}
                                                                            >
                                                                                <b>
                                                                                    A){" "}
                                                                                </b>{" "}
                                                                                <span className="f-c">
                                                                                    {
                                                                                        this
                                                                                            .state
                                                                                            .ZANSWERONE
                                                                                    }
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-group radio_input q-hover">
                                                                            <label
                                                                                className="text-s"
                                                                                id="b"
                                                                                style={{
                                                                                    backgroundColor:
                                                                                        this
                                                                                            .state
                                                                                            .bgcolorb,
                                                                                    pointerEvents:
                                                                                        this
                                                                                            .state
                                                                                            .peventb,
                                                                                }}
                                                                                onClick={this.onAnswerClick.bind(
                                                                                    this,
                                                                                    "b"
                                                                                )}
                                                                            >
                                                                                <b>
                                                                                    B){" "}
                                                                                </b>{" "}
                                                                                <span className="f-c">
                                                                                    {
                                                                                        this
                                                                                            .state
                                                                                            .ZANSWERTWO
                                                                                    }
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-group radio_input q-hover">
                                                                            <label
                                                                                className="text-s"
                                                                                id="c"
                                                                                style={{
                                                                                    backgroundColor:
                                                                                        this
                                                                                            .state
                                                                                            .bgcolorc,
                                                                                    pointerEvents:
                                                                                        this
                                                                                            .state
                                                                                            .peventc,
                                                                                }}
                                                                                onClick={this.onAnswerClick.bind(
                                                                                    this,
                                                                                    "c",
                                                                                    this
                                                                                        .state
                                                                                        .correctanswer,
                                                                                    this
                                                                                        .state
                                                                                        .wronganswer,
                                                                                    this
                                                                                        .state
                                                                                        .percentage
                                                                                )}
                                                                            >
                                                                                <b>
                                                                                    C){" "}
                                                                                </b>{" "}
                                                                                <span className="f-c">
                                                                                    {
                                                                                        this
                                                                                            .state
                                                                                            .ZANSWERTHREE
                                                                                    }
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-group radio_input q-hover">
                                                                            <label
                                                                                className="text-s"
                                                                                id="d"
                                                                                style={{
                                                                                    backgroundColor:
                                                                                        this
                                                                                            .state
                                                                                            .bgcolord,
                                                                                    pointerEvents:
                                                                                        this
                                                                                            .state
                                                                                            .peventd,
                                                                                }}
                                                                                onClick={this.onAnswerClick.bind(
                                                                                    this,
                                                                                    "d"
                                                                                )}
                                                                            >
                                                                                <b>
                                                                                    D){" "}
                                                                                </b>{" "}
                                                                                <span className="f-c">
                                                                                    {
                                                                                        this
                                                                                            .state
                                                                                            .ZANSWERFOUR
                                                                                    }
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {/*<!-- /row -->*/}
                                                            </div>
                                                            {/*<!-- /step -->*/}
                                                        </div>
                                                    </form>
                                                    <div className="q-number-wrapper for-btn for-top-btn">
                                                        <div
                                                            id="main-score"
                                                            className="col-md-7 text-left for-only-q"
                                                        >
                                                            <ul
                                                                className="question-statistic and-dsc"
                                                                id="question"
                                                            >
                                                                <li className="s-text">
                                                                    <p className="question-views clr">
                                                                        Progress:{" "}
                                                                        {
                                                                            this
                                                                                .state
                                                                                .num
                                                                        }
                                                                        /
                                                                        {
                                                                            this
                                                                                .state
                                                                                .total_ques
                                                                        }
                                                                    </p>
                                                                </li>
                                                            </ul>
                                                        </div>

                                                        {mainButton}
                                                    </div>
                                                </div>
                                            </div>
                                            {/*<!-- left col-end-->*/}

                                            <div className="col-md-4">
                                                <div id="right-form">
                                                    <figure>
                                                        {this.state.ZIMAGE ? (
                                                            <ModalImage
                                                                small={
                                                                    this.state
                                                                        .ZIMAGE
                                                                }
                                                                large={
                                                                    this.state
                                                                        .ZIMAGE
                                                                }
                                                                alt={
                                                                    this.state
                                                                        .ZIMAGENAME
                                                                }
                                                            />
                                                        ) : (
                                                            <img
                                                                id="myImg"
                                                                src="https://seatrials-bucket.s3.us-east-2.amazonaws.com/main/q-logo.png"
                                                                alt="logo-q"
                                                            />
                                                        )}
                                                    </figure>
                                                </div>
                                                {/*<!-- right col-end-->*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <React.Fragment>
                <Container>
                    {ChangeCatModals}
                    {this.state.sweetAlertInfo && (
                        <SweetAlert
                            info
                            success
                            showCancel
                            confirmBtnText="View Result"
                            cancelBtnText="Print Test"
                            confirmBtnBsStyle="info"
                            cancelBtnBsStyle="success"
                            title="You have completed Your test?"
                            onConfirm={() =>
                                window.open(
                                    `/user/test/result/${this.state.test}`,
                                    "_blank"
                                )
                            }
                            onCancel={() =>
                                window.open(
                                    `/user/test/print-result/${this.state.test}`,
                                    "_blank"
                                )
                            }
                        >
                            You will not be able to recover this imaginary file!
                        </SweetAlert>
                    )}
                    <div className="content">{testContent}</div>
                </Container>
            </React.Fragment>
        );
    }
}

UserQuestion.propTypes = {
    getNextLink: PropTypes.func.isRequired,
    get_Test_New: PropTypes.func.isRequired,
    postTest: PropTypes.func.isRequired,
    getPreviousLink: PropTypes.func.isRequired,
    getQuestion: PropTypes.func.isRequired,
    totalTestQues: PropTypes.func.isRequired,
    question: PropTypes.object.isRequired,
    test: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    question: state.question,
    test: state.test,
});

export default connect(mapStateToProps, {
    get_Test_New,
    getNextLink,
    getPreviousLink,
    getQuestion,
    totalTestQues,
    postTest,
})(withRouter(UserQuestion));
