import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "../common/common.jsx";
import Header from "../common/Header";
import Footer from "../common/Footer";
import { getPagesbySlug } from "../../../actions/pageActions.jsx";
import isEmpty from "../../../validation/is-empty";
import PageNotFound from "../../PageNotFound";

class BlogPageDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page_title: "",
            data: "",
            description: "",
            image_alt: "",
            meta_desc: "",
            tags: "",
            type: "",
            imagePreviewUrl: "",
            errors: {},
        };
    }

    componentDidMount() {
        if (this.props.match.params.Id) {
            this.props.getPagesbySlug(this.props.match.params.Id);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({ errors: nextProps.errors });
        }
        if (nextProps.page.page) {
            const page = nextProps.page.page;

            page._id = !isEmpty(page._id) ? page._id : "";
            page.page_title = !isEmpty(page.page_title) ? page.page_title : "";
            page.slug = !isEmpty(page.slug) ? page.slug : "";
            page.description = !isEmpty(page.description)
                ? page.description
                : "";
            page.meta_desc = !isEmpty(page.meta_desc) ? page.meta_desc : "";
            page.tags = !isEmpty(page.tags) ? page.tags : "";
            page.type = !isEmpty(page.type) ? page.type : "";
            page.image_alt = !isEmpty(page.image_alt) ? page.image_alt : "";

            page.page_image = !isEmpty(page.page_image) ? page.page_image : "";

            // Set component fields state for blog
            this.setState({
                id: page._id,
                page_title: page.page_title,
                slug: page.slug,
                data: page.description,
                meta_desc: page.meta_desc,
                tags: page.tags,
                image_alt: page.image_alt,
                type: page.type,
                imagePreviewUrl: page.page_image,
            });
        }
    }
    render() {
        if (this.props.pageNotFound) {
            return <PageNotFound />;
        }

        return (
            <div>
                <Header />
                <nav className="breadcrumb">
                    <div className="container">
                        <a className="breadcrumb-item" href="/">
                            Home
                        </a>
                        <span className="breadcrumb-item active">
                            {this.state.page_title}
                        </span>
                    </div>
                </nav>

                <section
                    className="content-section"
                    id="service-section"
                    style={{ paddingtop: "35px" }}
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-9 col-sm-12 col-xm-12">
                                <div className="blog-post-wrapper">
                                    <article className="blog-post-item">
                                        <div className="blog-ttl">
                                            <h1 className="section-head about-head text-capitalize">
                                                {this.state.page_title}
                                            </h1>
                                        </div>
                                        {this.state.imagePreviewUrl ? (
                                            <div className="blog-post-img">
                                                <figure>
                                                    <img
                                                        id="myImg"
                                                        className="img-responsive"
                                                        src={
                                                            this.state
                                                                .imagePreviewUrl
                                                        }
                                                        alt={
                                                            this.state.image_alt
                                                        }
                                                    />
                                                </figure>
                                                <br />
                                            </div>
                                        ) : (
                                            ""
                                        )}

                                        <div className="blog-post-content-desc">
                                            <div className="blog-post-content">
                                                <div className="blog-article-text">
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: this.state
                                                                .data,
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        );
    }
}

BlogPageDetails.propTypes = {
    getPagesbySlug: PropTypes.func.isRequired,
    page: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    pageNotFound: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    admin: state.admin,
    page: state.page,
    errors: state.errors,
    pageNotFound: state.page.pageNotFound,
});

export default connect(mapStateToProps, { getPagesbySlug })(
    withRouter(BlogPageDetails)
);
