import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";
import Container from "../content/Container.jsx";
import { Panel, PanelHeader, PanelBody } from "../common/panel/Panel.jsx";
import TextFieldArea from "../common/textfield/TextFieldArea";
import TextAreaField from "../common/textfield/TextAreaField";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import ReactNotification from "react-notifications-component";
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import {
    updateComments,
    getCommentsbyId,
} from "../../../actions/commentActions.jsx";
import isEmpty from "../../../validation/is-empty";

class EditComment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            comments: "",
            status: "",
            alert: false,
            AlertBox: false,
            visible: false,
            errors: {},
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onDismiss = this.onDismiss.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    onDismiss() {
        this.setState({ visible: false, visible1: false, visible2: false });
    }

    componentDidMount() {
        if (this.props.match.params.Id) {
            this.props.getCommentsbyId(this.props.match.params.Id);
        }
    }
    handleChange(evt) {
        this.setState({
            data: evt,
        });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({ errors: nextProps.errors });
        }
        if (nextProps.comment.comment) {
            const comment = nextProps.comment.comment;

            comment._id = !isEmpty(comment._id) ? comment._id : "";
            comment.comments = !isEmpty(comment.comments)
                ? comment.comments
                : "";
            comment.status = !isEmpty(comment.status) ? comment.status : "";
            comment.zmcq_id.ZQUESTION = !isEmpty(comment.zmcq_id.ZQUESTION)
                ? comment.zmcq_id.ZQUESTION
                : "";
            comment.user_id.user_name = !isEmpty(comment.user_id.user_name)
                ? comment.user_id.user_name
                : "";
            comment.user_id.email = !isEmpty(comment.user_id.email)
                ? comment.user_id.email
                : "";

            // Set component fields state for blog
            this.setState({
                id: comment._id,
                question: comment.zmcq_id.ZQUESTION,
                user_name: comment.user_id.user_name,
                email: comment.user_id.email,
                data: comment.comments,
                status: comment.status,
            });
        }
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    onSubmit(e) {
        e.preventDefault();
        const page_id = this.props.match.params.Id;

        const formData = {
            comments: this.state.data,
            status: this.state.status,
        };
        console.log(formData);
        this.props.updateComments(page_id, formData, this.props.history);
        this.setState({ alert: true, visible: true });
        store.addNotification({
            title: "Success",
            message: "Comment Saved successfully",
            type: "success",
            insert: "top",
            container: "top-center",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: { duration: 2000 },
            dismissable: { click: true },
            // content: notificationContent
        });
    }

    render() {
        const { errors } = this.state;
        return (
            <React.Fragment>
                <ReactNotification />
                <Container>
                    <div className="content">
                        <div>
                            <ol className="breadcrumb float-xl-right">
                                <li className="breadcrumb-item">
                                    <Link to="/admin/dashboard">Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item active">
                                    Edit Comments{" "}
                                </li>
                            </ol>
                            <h1 className="page-header">Edit Comments </h1>
                            <div className="row">
                                <div className="col-xl-12">
                                    <Panel>
                                        <PanelHeader>
                                            Edit Comments{" "}
                                        </PanelHeader>
                                        <PanelBody className="panel-form">
                                            <div className="panel-form">
                                                <form
                                                    className="form-horizontal form-bordered"
                                                    onSubmit={this.onSubmit}
                                                >
                                                    <div className="form-group row">
                                                        <label className="col-lg-3 col-form-label">
                                                            Question
                                                        </label>
                                                        <div className="col-lg-9">
                                                            {
                                                                this.state
                                                                    .question
                                                            }
                                                        </div>
                                                    </div>

                                                    <TextFieldArea
                                                        label="Client Name"
                                                        placeholder="User Name"
                                                        name="user_name"
                                                        type="text"
                                                        value={
                                                            this.state.user_name
                                                        }
                                                        onChange={this.onChange}
                                                        error={errors.user_name}
                                                        disabled="disabled"
                                                    />
                                                    <TextFieldArea
                                                        label="Email"
                                                        placeholder="example@emapmle.com"
                                                        name="email"
                                                        type="email"
                                                        value={this.state.email}
                                                        onChange={this.onChange}
                                                        error={errors.email}
                                                        disabled="disabled"
                                                    />

                                                    <div className="form-group row">
                                                        <label className="col-lg-3 col-form-label">
                                                            Comments
                                                            <span className="text-danger">
                                                                *
                                                            </span>
                                                        </label>
                                                        <div className="col-lg-9">
                                                            <SunEditor
                                                                name="data"
                                                                type="text"
                                                                value={
                                                                    this.state
                                                                        .data
                                                                }
                                                                setContents={
                                                                    this.state
                                                                        .data
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleChange
                                                                }
                                                                height="200"
                                                            />
                                                            {errors.Comments && (
                                                                <div
                                                                    className="invalid-feedback"
                                                                    style={{
                                                                        display:
                                                                            "block",
                                                                    }}
                                                                >
                                                                    {
                                                                        errors.Comments
                                                                    }
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <TextAreaField
                                                        label="Comments"
                                                        placeholder="Comments"
                                                        name="comments"
                                                        type="text"
                                                        value={
                                                            this.state.comments
                                                        }
                                                        onChange={this.onChange}
                                                        error={errors.comments}
                                                    />

                                                    <div className="form-group row ">
                                                        <label className="col-lg-3 col-form-label">
                                                            Status{" "}
                                                            <span className="text-danger">
                                                                *
                                                            </span>
                                                        </label>
                                                        <div className="col-lg-5">
                                                            <select
                                                                className={classnames(
                                                                    "form-control ",
                                                                    {
                                                                        "is-invalid":
                                                                            errors.status,
                                                                    }
                                                                )}
                                                                data-size="10"
                                                                data-live-search="true"
                                                                data-style="btn-white"
                                                                name="status"
                                                                value={
                                                                    this.state
                                                                        .status
                                                                }
                                                                onChange={
                                                                    this
                                                                        .onChange
                                                                }
                                                            >
                                                                <option value="Active">
                                                                    Active
                                                                </option>
                                                                <option value="Inactive">
                                                                    Inactive
                                                                </option>
                                                            </select>

                                                            {errors.type && (
                                                                <div
                                                                    className="invalid-feedback"
                                                                    style={{
                                                                        display:
                                                                            "block",
                                                                    }}
                                                                >
                                                                    {
                                                                        errors.type
                                                                    }
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <div className="col-md-12 offset-md-5">
                                                            <button
                                                                type="submit"
                                                                className="btn btn-sm btn-primary m-r-5"
                                                            >
                                                                Submit
                                                            </button>

                                                            <button
                                                                type="button"
                                                                onClick={
                                                                    this
                                                                        .handleCancel
                                                                }
                                                                className="btn btn-sm btn-default"
                                                            >
                                                                Cancel
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </PanelBody>
                                    </Panel>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </React.Fragment>
        );
    }
}

EditComment.propTypes = {
    updateComments: PropTypes.func.isRequired,
    getCommentsbyId: PropTypes.func.isRequired,
    admin: PropTypes.object.isRequired,
    comment: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    admin: state.admin,
    comment: state.comment,
    errors: state.errors,
});

export default connect(mapStateToProps, { updateComments, getCommentsbyId })(
    withRouter(EditComment)
);
