import axios from "axios";
//import { setAlert } from "./alertActions";
import { CLEAR_ERRORS, GET_PRICE, GET_ERRORS } from "./types";

// Clear errors
export const clearErrors = () => {
    return {
        type: CLEAR_ERRORS,
    };
};

// Get current Pricing Information
export const getCurrentPrice = () => (dispatch) => {
    dispatch(clearErrors());
    axios
        .get("/api/v1/private/price")
        .then((res) =>
            dispatch({
                type: GET_PRICE,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_PRICE,
                payload: null,
            })
        );
};

// Get current Pricing Information
export const contactPost = (contactData) => (dispatch) => {
    axios.post("/api/mlp_email/contact", contactData).catch((err) =>
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
        })
    );
};
