import {
    GET_TOPICS,
    GET_TESTS,
    GET_TEST_DETAILS,
    TEST_LOADING,
    DELETE_TEST,
    GET_TEST,
    GET_WRITE_TESTS,
    GET_TOTAL_COUNT,
    GET_TEST_LISTS,
    GET_TEST_LIST,
    GET_WRITE_COUNT,
} from "../actions/types";

const initialState = {
    topics: [],
    tests: [],
    test: null,
    testlists: [],
    count: null,
    testlist: null,
    write_tests: null,
    write_count: null,
    test_details: [],
    loading: true,
    error: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case TEST_LOADING:
            return {
                ...state,
                loading: true,
            };

        case GET_TOTAL_COUNT:
            return {
                ...state,
                count: action.payload,
                loading: false,
            };

        case GET_TEST_LIST:
            return {
                ...state,
                testlist: action.payload,
                loading: false,
            };

        case GET_WRITE_COUNT:
            return {
                ...state,
                write_count: action.payload,
                loading: false,
            };

        case GET_TOPICS:
            return {
                ...state,
                topics: action.payload,
                loading: false,
            };

        case GET_TEST_LISTS:
            return {
                ...state,
                testlists: action.payload,
                loading: false,
            };

        case GET_TEST:
            return {
                ...state,
                test: action.payload,
                loading: false,
            };

        case GET_WRITE_TESTS:
            return {
                ...state,
                write_tests: action.payload,
                test: null,
                loading: false,
            };

        case GET_TESTS:
            return {
                ...state,
                tests: action.payload,
                loading: false,
            };

        case GET_TEST_DETAILS:
            return {
                ...state,
                test_details: action.payload,
                loading: false,
            };

        case DELETE_TEST:
            return {
                ...state,
                tests: state.tests.filter(
                    (test) => test._id !== action.payload
                ),
            };

        default:
            return state;
    }
}
