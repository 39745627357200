import axios from "axios";

import {
    DIRECTORY_DECKS,
    DIRECTORY_ENGINS,
    DIRECTORY_DECK_COUNT,
    DIRECTORY_ENGINE_COUNT,
    DIRECTORY_LOADING,
} from "./types";

// Get current Invoice
export const getdeck = () => (dispatch) => {
    dispatch(setDirecoryLoading());
    axios
        .get(`/api/mlp_directory/get_decks`)
        .then((res) =>
            dispatch({
                type: DIRECTORY_DECKS,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: DIRECTORY_DECKS,
                payload: null,
            })
        );
};

// Get current Invoice
export const getDeckCount = () => (dispatch) => {
    dispatch(setDirecoryLoading());
    axios
        .get(`/api/mlp_directory/get_decks_count`)
        .then((res) =>
            dispatch({
                type: DIRECTORY_DECK_COUNT,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: DIRECTORY_DECK_COUNT,
                payload: null,
            })
        );
};
// Get current Invoice
export const getEngine = () => (dispatch) => {
    dispatch(setDirecoryLoading());
    axios
        .get(`/api/mlp_directory/get_engine`)
        .then((res) =>
            dispatch({
                type: DIRECTORY_ENGINS,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: DIRECTORY_ENGINS,
                payload: null,
            })
        );
};
// Get current Invoice
export const getEngineCount = () => (dispatch) => {
    dispatch(setDirecoryLoading());
    axios
        .get(`/api/mlp_directory/get_engine_count`)
        .then((res) =>
            dispatch({
                type: DIRECTORY_ENGINE_COUNT,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: DIRECTORY_ENGINE_COUNT,
                payload: null,
            })
        );
};

// Directory loading
export const setDirecoryLoading = () => {
    return {
        type: DIRECTORY_LOADING,
    };
};
