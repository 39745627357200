import {
    ADD_COUPAN,
    GET_COUPAN,
    GET_COUPANS,
    USED_COUPANS,
    COUPAN_EMAILS,
    GET_PRO_EMAIL,
    COUPAN_LOADING,
    DELETE_COUPAN,
    DELETE_EMAILS,
} from "../actions/types";

const initialState = {
    coupans: [],
    used_coupans: [],
    coupan_emails: [],
    coupan: null,
    promo: null,
    loading: true,
    error: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case COUPAN_LOADING:
            return {
                ...state,
                loading: true,
            };

        case ADD_COUPAN:
            return {
                ...state,
                coupans: [action.payload, ...state.coupans],
            };

        case GET_COUPAN:
            return {
                ...state,
                coupan: action.payload,
                loading: false,
            };

        case GET_PRO_EMAIL:
            return {
                ...state,
                promo: action.payload,
                loading: false,
            };

        case DELETE_EMAILS:
            return {
                ...state,
                coupan_emails: state.coupan_emails.filter(
                    (promo) => promo._id !== action.payload
                ),
            };

        case DELETE_COUPAN:
            return {
                ...state,
                coupans: action.payload,
                loading: false,
            };

        case GET_COUPANS:
            return {
                ...state,
                coupans: action.payload,
                loading: false,
            };

        case USED_COUPANS:
            return {
                ...state,
                used_coupans: action.payload,
                loading: false,
            };

        case COUPAN_EMAILS:
            return {
                ...state,
                coupan_emails: action.payload,
                loading: false,
            };

        default:
            return state;
    }
}
