import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    USER_LOADED,
    GET_NAVS,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
} from "../actions/types";

const initialState = {
    token1: localStorage.getItem("token1"),
    isAuthenticated1: false,
    loading: true,
    user: null,
    navs: [],
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case USER_LOADED:
            return {
                ...state,
                isAuthenticated1: true,
                loading: false,
                user: payload,
            };

        case REGISTER_SUCCESS:
        case LOGIN_SUCCESS:
            localStorage.setItem("token1", payload.token1);
            return {
                ...state,
                ...payload,
                isAuthenticated1: true,
                loading: false,
            };

        case GET_NAVS:
            return {
                ...state,
                navs: action.payload,
                loading: false,
            };

        case REGISTER_FAIL:
        case AUTH_ERROR:
        case LOGIN_FAIL:
        case LOGOUT:
            localStorage.removeItem("token1");
            return {
                ...state,
                token1: null,
                user: null,
                isAuthenticated1: false,
                loading: false,
            };
        default:
            return state;
    }
}
