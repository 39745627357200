import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Container from "../content/Container.jsx";
import UsedCodeList from "./UsedCodeList.jsx";
import Spinner from "../common/textfield/Spinner";
import { usedCoupans } from "../../../actions/coupanActions.jsx";
import { Panel, PanelHeader, PanelBody } from "../common/panel/Panel.jsx";

class UsedCodes extends Component {
    componentDidMount() {
        this.props.usedCoupans();
    }
    render() {
        const { used_coupans, loading } = this.props.coupan;

        let mainCoupanContent;
        if (used_coupans === null || loading) {
            mainCoupanContent = <Spinner />;
        } else {
            //console.log(blogcats);
            if (used_coupans.length > 0) {
                mainCoupanContent = (
                    <UsedCodeList used_coupans={used_coupans} />
                );
            } else {
                mainCoupanContent = (
                    <div className="row">
                        <div className="col-12">
                            <Panel>
                                <PanelHeader>Used Coupon Codes</PanelHeader>
                                <PanelBody>
                                    <div className="note note-secondary m-b-15">
                                        <div className="note-icon">
                                            <i className="fa fa-lightbulb"></i>
                                        </div>
                                        <div className="note-content text-right">
                                            <h4>
                                                <b>This Table Is Empty</b>
                                            </h4>
                                            <p>
                                                {" "}
                                                No data found to show here. This
                                                table is empty.{" "}
                                            </p>
                                        </div>
                                    </div>
                                </PanelBody>
                            </Panel>
                        </div>
                    </div>
                );
            }
        }
        return (
            <React.Fragment>
                <Container>
                    <div id="content" className="content ">
                        <ol className="breadcrumb float-xl-right">
                            <li className="breadcrumb-item">
                                <a href="/admin/dashboard">Dashboard</a>
                            </li>
                            <li className="breadcrumb-item active">
                                Used Coupon Codes
                            </li>
                        </ol>
                        <h1 className="page-header">Used Coupon Codes </h1>

                        {mainCoupanContent}
                    </div>
                </Container>
            </React.Fragment>
        );
    }
}

UsedCodes.propTypes = {
    usedCoupans: PropTypes.func.isRequired,
    coupan: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    coupan: state.coupan,
});

export default connect(mapStateToProps, { usedCoupans })(UsedCodes);
