import React, { Component } from "react";
import ReactTable from "react-table";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Panel, PanelHeader } from "../common/panel/Panel.jsx";
import { ButtonGroup } from "reactstrap";
import "react-table/react-table.css";
import { deletePage } from "../../../actions/pageActions.jsx";
import SweetAlert from "react-bootstrap-sweetalert";

class TableList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sweetAlertError: false,
        };

        //this.data = makeData();
        this.defaultSorted = [
            {
                id: "_id",
                desc: false,
            },
        ];
        this.tableColumns = [
            {
                Header: "Id",
                id: "row",
                maxWidth: 50,
                filterable: false,
                Cell: (row) => {
                    return <div>{row.index + 1}</div>;
                },
            },
            {
                Header: "Title",
                id: "page_title",
                accessor: (d) => d.page_title,
            },

            {
                Header: "Image",
                accessor: "page_image",
                filterable: false,
                sortable: false,
                Cell: (row) => (
                    <span>
                        {row.value ? (
                            <img
                                src={`https://seatrials-bucket.s3.us-east-2.amazonaws.com/page/${row.value}`}
                                className="img height-50"
                                alt=""
                            />
                        ) : (
                            <span className="label label-default m-r-3">
                                No Files
                            </span>
                        )}
                    </span>
                ),
            },

            {
                Header: "Status",
                accessor: "type",
                Cell: (row) => (
                    <span>
                        {row.value === "published" ? (
                            <span className="label label-green m-r-3">
                                Published
                            </span>
                        ) : row.value === "draft" ? (
                            <span className="label label-warning m-r-3">
                                Draft
                            </span>
                        ) : (
                            <span className="label label-danger m-r-3">
                                None
                            </span>
                        )}
                    </span>
                ),
            },
            {
                Header: "Actions",
                accessor: "_id",
                filterable: false,
                sortable: false,
                Cell: (row) => (
                    <div>
                        <Link
                            to={`/admin/page/edit/${row.value}`}
                            className="btn btn-info btn-sm m-r-5 "
                        >
                            <i className="fas fa-sm fa-fw m-r-2 fa-edit"></i>
                            Edit
                        </Link>
                        <button
                            onClick={() =>
                                this.toggleSweetAlert("error", row.value)
                            }
                            className="btn btn-danger btn-sm m-r-5 "
                        >
                            <i className="fas fa-sm fa-fw m-r-2 fa-trash"></i>
                            Delete
                        </button>
                        <Link
                            onClick={() =>
                                window.open(`/preview/${row.value}`, "_blank")
                            }
                            className="btn btn-pink btn-sm m-r-5 "
                        >
                            <i className="fas fa-sm fa-fw m-r-2 fa-eye"></i>View
                        </Link>
                    </div>
                ),
            },
        ];
        this.toggleSweetAlert = this.toggleSweetAlert.bind(this);
    }
    toggleSweetAlert(name, id) {
        switch (name) {
            case "error":
                this.setState({
                    sweetAlertError: !this.state.sweetAlertError,
                    id: id,
                });
                break;
            default:
                break;
        }
    }

    onDeleteClick(id) {
        this.props.deletePage(id);
        this.setState({ sweetAlertError: !this.state.sweetAlertError });
    }

    render() {
        const data = this.props.pages;

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-12">
                        {this.state.sweetAlertError && (
                            <SweetAlert
                                danger
                                showCancel
                                confirmBtnText="Yes, delete it!"
                                confirmBtnBsStyle="danger"
                                cancelBtnBsStyle="default"
                                title="Are you sure?"
                                onConfirm={this.onDeleteClick.bind(
                                    this,
                                    this.state.id
                                )}
                                onCancel={() => this.toggleSweetAlert("error")}
                            >
                                You will not be able to recover this again!
                            </SweetAlert>
                        )}
                        <Panel>
                            <PanelHeader>
                                <ButtonGroup className="pull-right">
                                    {" "}
                                    <Link to="/admin/pages/add-pages">
                                        <button className="btn btn-sm btn-green ">
                                            <i className="fas fa-plus" />
                                            &nbsp; New Pages
                                        </button>
                                    </Link>
                                    &nbsp; &nbsp;
                                </ButtonGroup>
                                List of Pages
                            </PanelHeader>

                            <ReactTable
                                filterable
                                data={data}
                                columns={this.tableColumns}
                                defaultPageSize={10}
                                defaultSorted={this.defaultSorted}
                                className="-highlight"
                            />
                        </Panel>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

TableList.propTypes = {
    deletePage: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
    errors: state.errors,
});
export default connect(mapStateToProps, { deletePage })(withRouter(TableList));
