import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Container from "../content/Container.jsx";
import { Panel, PanelHeader, PanelBody } from "../common/panel/Panel.jsx";
import TextFieldArea from "../common/textfield/TextFieldArea";
import {
    getCurrentPrice,
    updatePrice,
} from "../../../actions/adminActions.jsx";
//import AlertBox from "../common/textfield/AlertBox";
import ReactNotification from "react-notifications-component";
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import isEmpty from "../../../validation/is-empty";

class Pricing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            one_month: "",
            six_month: "",
            twelve_month: "",
            life_time: "",
            errors: {},
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onDismiss = this.onDismiss.bind(this);
    }

    onDismiss() {
        this.setState({ visible: false, visible1: false });
    }

    componentDidMount() {
        this.props.getCurrentPrice();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors,
                visible: true,
                visible1: true,
            });
        }

        if (nextProps.price.price) {
            const price = nextProps.price.price;
            // console.log(price);
            // If profile field doesnt exist, make empty string

            price.one_month = !isEmpty(price.one_month) ? price.one_month : "";
            price.six_month = !isEmpty(price.six_month) ? price.six_month : "";
            price.twelve_month = !isEmpty(price.twelve_month)
                ? price.twelve_month
                : "";
            price.life_time = !isEmpty(price.life_time) ? price.life_time : "";

            // Set component fields state
            this.setState({
                one_month: price.one_month,
                six_month: price.six_month,
                twelve_month: price.twelve_month,
                life_time: price.life_time,
            });
        }
    }

    onSubmit(e) {
        e.preventDefault();
        const adminemail = {
            one_month: this.state.one_month,
            six_month: this.state.six_month,
            twelve_month: this.state.twelve_month,
            life_time: this.state.life_time,
        };
        this.props.updatePrice(adminemail, this.props.history);
        if (!Object.keys(this.state.errors).length) {
            store.addNotification({
                title: "Success!",
                message: "Pricing information Updated Successfully",
                type: "success",
                insert: "top",
                container: "top-center",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: { duration: 2000 },
                dismissable: { click: true },
                // content: notificationContent
            });
        }
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }
    render() {
        const { errors } = this.state;
        return (
            <React.Fragment>
                <ReactNotification />
                <Container>
                    <div className="content">
                        <div>
                            <ol className="breadcrumb float-xl-right">
                                <li className="breadcrumb-item">
                                    <Link to="/admin/dashboard">Dashboard</Link>
                                </li>

                                <li className="breadcrumb-item active">
                                    Price
                                </li>
                            </ol>
                            <h1 className="page-header">Price</h1>

                            <div className="row">
                                <div className="col-xl-12">
                                    <Panel>
                                        <PanelHeader>Edit Price</PanelHeader>
                                        <PanelBody className="panel-form">
                                            <form
                                                className="form-horizontal form-bordered"
                                                onSubmit={this.onSubmit}
                                            >
                                                <fieldset>
                                                    <TextFieldArea
                                                        label="One Month"
                                                        placeholder="One Month Price"
                                                        name="one_month"
                                                        type="text"
                                                        value={
                                                            this.state.one_month
                                                        }
                                                        onChange={this.onChange}
                                                        error={errors.one_month}
                                                        striks="*"
                                                    />

                                                    <TextFieldArea
                                                        label="Six Month"
                                                        placeholder="Six Month price"
                                                        name="six_month"
                                                        type="text"
                                                        value={
                                                            this.state.six_month
                                                        }
                                                        onChange={this.onChange}
                                                        error={errors.six_month}
                                                        striks="*"
                                                    />
                                                    <TextFieldArea
                                                        label="Twelve Month"
                                                        placeholder="Twelve Month price"
                                                        name="twelve_month"
                                                        type="text"
                                                        value={
                                                            this.state
                                                                .twelve_month
                                                        }
                                                        onChange={this.onChange}
                                                        error={
                                                            errors.twelve_month
                                                        }
                                                        striks="*"
                                                    />

                                                    <TextFieldArea
                                                        label="Life Time"
                                                        placeholder="Life Time Price"
                                                        name="life_time"
                                                        type="text"
                                                        value={
                                                            this.state.life_time
                                                        }
                                                        onChange={this.onChange}
                                                        error={errors.life_time}
                                                        striks="*"
                                                    />

                                                    <div className="form-group row">
                                                        <div className="col-md-12 offset-md-5">
                                                            <button
                                                                type="submit"
                                                                className="btn btn-sm btn-primary m-r-5"
                                                            >
                                                                Submit
                                                            </button>
                                                        </div>
                                                    </div>
                                                </fieldset>
                                            </form>
                                        </PanelBody>
                                    </Panel>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </React.Fragment>
        );
    }
}

Pricing.propTypes = {
    price: PropTypes.object.isRequired,
    getCurrentPrice: PropTypes.func.isRequired,
    updatePrice: PropTypes.func.isRequired,

    errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    admin: state.admin,
    price: state.price,
    errors: state.errors,
});

export default connect(mapStateToProps, {
    getCurrentPrice,
    updatePrice,
})(withRouter(Pricing));
