import axios from "axios";

import {
    GET_SEARCHS,
    SEARCH_LOADING,
    GET_PRIVIOUS,
    GET_NEXT,
    TOTAL_QUESTION,
    QUESTION_LOADING,
    // GET_ERRORS,
    // CLEAR_ERRORS
} from "./types";

// Get current Pages
export const getSearch = (keyword, topic, area) => (dispatch) => {
    dispatch(setSearchLoading());
    axios
        .get(`/api/mlp_search/get_user_search/${keyword}/${topic}/${area}`)
        .then((res) =>
            dispatch({
                type: GET_SEARCHS,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_SEARCHS,
                payload: null,
            })
        );
};

// Contact loading
export const setSearchLoading = () => {
    return {
        type: SEARCH_LOADING,
    };
};

// Get Total Question by id
export const searchtotalQues = (keyword, topic, area) => (dispatch) => {
    dispatch(setQuestionLoading());
    axios
        .get(`/api/mlp_search/total_search_ques/${keyword}/${topic}/${area}`)
        .then((res) =>
            dispatch({
                type: TOTAL_QUESTION,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: TOTAL_QUESTION,
                payload: null,
            })
        );
};

// Get Next link by id
export const searchPreviousLink =
    (keyword, topic, area, num, mode) => (dispatch) => {
        dispatch(setQuestionLoading());
        axios
            .get(
                `/api/mlp_search/search_prev_link/${keyword}/${topic}/${area}/${num}/${mode}`
            )
            .then((res) =>
                dispatch({
                    type: GET_PRIVIOUS,
                    payload: res.data,
                })
            )
            .catch((err) =>
                dispatch({
                    type: GET_PRIVIOUS,
                    payload: null,
                })
            );
    };

// Get Next link by id
export const searchNextLink =
    (keyword, topic, area, num, mode) => (dispatch) => {
        dispatch(setQuestionLoading());
        axios
            .get(
                `/api/mlp_search/search_next_link/${keyword}/${topic}/${area}/${num}/${mode}`
            )
            .then((res) =>
                dispatch({
                    type: GET_NEXT,
                    payload: res.data,
                })
            )
            .catch((err) =>
                dispatch({
                    type: GET_NEXT,
                    payload: null,
                })
            );
    };

// Question loading
export const setQuestionLoading = () => {
    return {
        type: QUESTION_LOADING,
    };
};
