import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Container from "./../content/Container.jsx";
import AlertBox from "./../common/textfield/AlertBox";
import { Panel, PanelHeader, PanelBody } from "./../common/panel/Panel.jsx";
import TextFieldArea from "./../common/textfield/TextFieldArea";
import TextImageFileUpload from "./../common/textfield/TextImageFileUpload";
import TextFieldOption from "../common/textfield/TextFieldOption";
import {
    userprofileEdit,
    getCurrentProfile,
    sendVeriEmail,
    loadUser,
} from "../../../actions/userActions.jsx";
import ReactNotification from "react-notifications-component";
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import classnames from "classnames";
import isEmpty from "../../../validation/is-empty";

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user_id: "",
            user_name: "",
            question_type: "",
            license_rating: "",
            visible: false,
            file: "",
            imagePreview: "",
            verification_status: "",
            imagePreviewUrl: "",

            errors: {},
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.fileChangedHandler = this.fileChangedHandler.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }
    handleCancel() {
        this.setState({
            user_name: "",
            question_type: "",
            license_rating: "",
            visible: false,
            file: "",
            imagePreview: "",
            verification_status: "",
            imagePreviewUrl: "",
        });
    }
    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    componentDidMount() {
        this.props.getCurrentProfile();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({ errors: nextProps.errors });
        }

        if (nextProps.profile.profile) {
            const profile = nextProps.profile.profile;
            // console.log(profile);
            // If profile field doesnt exist, make empty string

            profile.user_name = !isEmpty(profile.user_name)
                ? profile.user_name
                : "";

            profile.question_type = !isEmpty(profile.question_type)
                ? profile.question_type
                : "";
            profile.license_rating = !isEmpty(profile.license_rating)
                ? profile.license_rating
                : "";

            const profileImage = !isEmpty(profile.image)
                ? "https://seatrials-bucket.s3.us-east-2.amazonaws.com/client/" +
                  profile.image
                : "./../../../assets/admin/img/noimage.jpg";

            // Set component fields state
            this.setState({
                user_name: profile.user_name,
                question_type: profile.question_type,
                license_rating: profile.license_rating,
                imagePreviewUrl: profileImage,
            });
        }

        if (nextProps.auth.user) {
            const user = nextProps.auth.user;
            user._id = !isEmpty(user._id) ? user._id : "";
            user.verification_status = !isEmpty(user.verification_status)
                ? user.verification_status
                : "";
            this.setState({
                verification_status: user.verification_status,
                user_id: user._id,
            });
        }
    }

    onEmailClick(id) {
        this.props.sendVeriEmail(id);
        store.addNotification({
            title: "Success!",
            message:
                " An email is sent to your registered email id. Click on the link to verify.",
            type: "success",
            insert: "top",
            container: "top-center",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: { duration: 3000 },
            dismissable: { click: true },
            // content: notificationContent
        });
    }

    fileChangedHandler(e) {
        this.setState({
            file: e.target.files[0],
        });
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            this.setState({
                file: file,
                imagePreviewUrl: reader.result,
            });
        };
        reader.readAsDataURL(file);
    }

    onSubmit(e) {
        e.preventDefault();
        const formData = new FormData();
        formData.append("license_rating", this.state.license_rating);
        formData.append("question_type", this.state.question_type);
        formData.append("image", this.state.file);

        this.props.userprofileEdit(formData, this.props.history);
        if (!Object.keys(this.state.errors).length) {
            store.addNotification({
                title: "Success",
                message: "profile updated successfully.",
                type: "success",
                insert: "top",
                container: "top-center",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: { duration: 2000 },
                dismissable: { click: true },
                // content: notificationContent
            });
        }
    }
    render() {
        const { errors } = this.state;

        let EmailVerificationContent;
        // console.log(this.state.verification_status)
        if (this.state.verification_status === "No") {
            EmailVerificationContent = (
                <AlertBox
                    classes="danger"
                    isOpen={this.state.visible}
                    toggle={this.onDismiss}
                    content="Your Email verification is pending!"
                    linkContent="resend verification link"
                    onClick={this.onEmailClick.bind(this, this.state.user_id)}
                />
            );
        }

        let ratingContent;

        if (this.state.question_type === "Deck") {
            if (this.state.license_rating === "ZDL1") {
                ratingContent =
                    "Master & Chief Mate (Any Gross Registered Tons (AGT)) (Oceans or Near Coastal)";
            } else if (this.state.license_rating === "ZDL2") {
                ratingContent =
                    "Master (NMT 500/1600 Gross Registered Tons) (Oceans or Near Coastal)";
            } else if (this.state.license_rating === "ZDL3") {
                ratingContent =
                    "2nd & 3rd Mate (Any Gross Registered Tons (AGT)) (Oceans or Near Coastal)";
            } else if (this.state.license_rating === "ZDL4") {
                ratingContent =
                    "Mate (NMT 500/1600 Gross Registered Tons) (NMT 500/1600 Gross Registered Tons)";
            } else if (this.state.license_rating === "ZDL5") {
                ratingContent =
                    "Master (NMT 200 Gross Registered Tons) (Oceans or Near Coastal)";
            } else if (this.state.license_rating === "ZDL6") {
                ratingContent =
                    "Operator (Uninspected Passenger Vessel) (Near Coastal)";
            } else if (this.state.license_rating === "ZDL7") {
                ratingContent =
                    "Operator (Uninspected Passenger Vessel) (Great Lakes and Inland or Inland)";
            } else if (this.state.license_rating === "ZDL8") {
                ratingContent =
                    "Apprentice Mate Steersman (Any Appropriate Tonnage) (Oceans or Near Coastal)";
            } else if (this.state.license_rating === "ZDL9") {
                ratingContent =
                    "Apprentice Mate Steersman (Any Appropriate Tonnage) (Great Lakes and Inland)";
            } else if (this.state.license_rating === "ZDL10") {
                ratingContent =
                    "Master (Any Gross Registered Tons (AGT)) (Great Lakes and Inland or Inland)";
            } else if (this.state.license_rating === "ZDL11") {
                ratingContent =
                    "Mate (Any Gross Registered Tons (AGT)) (Great Lakes and Inland)";
            } else if (this.state.license_rating === "ZDL12") {
                ratingContent =
                    "Master (NMT 500/1600 Gross Registered Tons) (Great Lakes and Inland)";
            } else if (this.state.license_rating === "ZDL13") {
                ratingContent =
                    "Mate (NMT 500/1600 Gross Registered Tons) (Great Lakes and Inland)";
            } else if (this.state.license_rating === "ZDL14") {
                ratingContent =
                    "Master (NMT 200 Gross Registered Tons) (Great Lakes and Inland or Inland)";
            } else if (this.state.license_rating === "ZDL15") {
                ratingContent = "Master (AGT OR NMT 500/1600 GRT) (Rivers)";
            } else if (this.state.license_rating === "ZDL16") {
                ratingContent = "Master (AGT OR NMT 500/1600 GRT) (Rivers)";
            } else if (this.state.license_rating === "ZDL17") {
                ratingContent = "Mate (NMT 500/1600 GRT) (Rivers)";
            } else if (this.state.license_rating === "ZDL18") {
                ratingContent =
                    "Master or Mate (NMT 200 Gross Registered Tons) (Rivers)";
            } else if (this.state.license_rating === "ZDL19") {
                ratingContent =
                    "Master Uninspected Fishing Vessel (Any Appropriate Tonnage) (Oceans or Near Coastal)";
            } else if (this.state.license_rating === "ZDL20") {
                ratingContent =
                    "Mate Uninspected Fishing Vessel (Any Appropriate Tonnage) (Oceans or Near Coastal)";
            } else if (this.state.license_rating === "ZDL21") {
                ratingContent =
                    "First Class Pilot (Any Appropriate Tonnage) (Any Appropriate Waters)";
            } else if (this.state.license_rating === "ZDL22") {
                ratingContent =
                    "Offshore Installation Manager (Mobile Offshore Drilling Unit) (Unrestricted)";
            } else if (this.state.license_rating === "ZDL23") {
                ratingContent =
                    "Offshore Installation Manager (Mobile Offshore Drilling Unit) (Surface Units Underway)";
            } else if (this.state.license_rating === "ZDL24") {
                ratingContent =
                    "Offshore Installation Manager (Mobile Offshore Drilling Unit) (Surface Units On Location)";
            } else if (this.state.license_rating === "ZDL25") {
                ratingContent =
                    "Offshore Installation Manager (Mobile Offshore Drilling Unit) (Bottom Bearing Units Underway)";
            } else if (this.state.license_rating === "ZDL26") {
                ratingContent =
                    "Offshore Installation Manager (Mobile Offshore Drilling Unit) (Bottom Bearing Units On Location)";
            } else if (this.state.license_rating === "ZDL27") {
                ratingContent =
                    "Barge Supervisor (Mobile Offshore Drilling Unit) (N/A)";
            } else if (this.state.license_rating === "ZDL28") {
                ratingContent =
                    "Ballast Control Operator (Mobile Offshore Drilling Unit) (N/A)";
            }
        } else if (this.state.question_type === "Engine") {
            if (this.state.license_rating === "ZDL1") {
                ratingContent = "First Assistant Engineer (Unlimited)";
            } else if (this.state.license_rating === "ZDL2") {
                ratingContent = "Third Assistant Engineer (Unlimited)";
            } else if (this.state.license_rating === "ZDL19") {
                ratingContent = "MEWB (Marine Engineering Workbook)";
            } else if (this.state.license_rating === "ZDL3") {
                ratingContent = "Chief Engineer (Limited)";
            } else if (this.state.license_rating === "ZDL4") {
                ratingContent = "Assistant Engineer (Limited)";
            } else if (this.state.license_rating === "ZDL5") {
                ratingContent = "Designated Duty Engineer (Unlimited";
            } else if (this.state.license_rating === "ZDL6") {
                ratingContent = "Designated Duty Engineer (4,000 HP)";
            } else if (this.state.license_rating === "ZDL7") {
                ratingContent = "Designated Duty Engineer (1,000 HP)";
            } else if (this.state.license_rating === "ZDL8") {
                ratingContent =
                    "Chief Engineer (Uninspected Fishing Industry Vessels)";
            } else if (this.state.license_rating === "ZDL9") {
                ratingContent =
                    "Assistant Engineer (Uninspected Fishing Industry Vessels)";
            } else if (this.state.license_rating === "ZDL10") {
                ratingContent = "Chief Engineer (MODU)";
            } else if (this.state.license_rating === "ZDL11") {
                ratingContent = "Assistant Engineer (MODU)";
            } else if (this.state.license_rating === "ZDL12") {
                ratingContent = "Chief Engineer (OSV Unlimited)";
            } else if (this.state.license_rating === "ZDL13") {
                ratingContent = "Assistant Engineer (OSV Unlimited)";
            } else if (this.state.license_rating === "ZDL14") {
                ratingContent = "QMED (Junior Engineer)";
            } else if (this.state.license_rating === "ZDL15") {
                ratingContent = "QMED (Electrician - Refrigeration Engineer)";
            } else if (this.state.license_rating === "ZDL16") {
                ratingContent = "QMED (Oiler)";
            } else if (this.state.license_rating === "ZDL17") {
                ratingContent = "QMED (Fireman - Watertender)";
            } else if (this.state.license_rating === "ZDL18") {
                ratingContent = "QMED (Machinist - Pumpman)";
            }
        }

        let RateContent;
        if (this.state.question_type === "Deck") {
            RateContent = (
                <div className="form-group row ">
                    <label className="col-lg-3 col-form-label">
                        Deck Rating <span className="text-danger">*</span>
                    </label>
                    <div className="col-lg-9">
                        <select
                            className={classnames("form-control ", {
                                "is-invalid": errors.license_rating,
                            })}
                            data-size="10"
                            data-live-search="true"
                            data-style="btn-white"
                            name="license_rating"
                            value={this.state.license_rating}
                            onChange={this.onChange}
                        >
                            <option value="">Select Deck Rating</option>
                            <option value="ZDL1">
                                Master & Chief Mate (Any Gross Registered Tons
                                (AGT)) (Oceans or Near Coastal)
                            </option>
                            <option value="ZDL2">
                                Master (NMT 500/1600 Gross Registered Tons)
                                (Oceans or Near Coastal)
                            </option>
                            <option value="ZDL3">
                                2nd & 3rd Mate (Any Gross Registered Tons (AGT))
                                (Oceans or Near Coastal)
                            </option>
                            <option value="ZDL4">
                                Mate (NMT 500/1600 Gross Registered Tons) (NMT
                                500/1600 Gross Registered Tons)
                            </option>
                            <option value="ZDL5">
                                Master (NMT 200 Gross Registered Tons) (Oceans
                                or Near Coastal)
                            </option>
                            <option value="ZDL6">
                                Operator (Uninspected Passenger Vessel) (Near
                                Coastal)
                            </option>
                            <option value="ZDL7">
                                Operator (Uninspected Passenger Vessel) (Great
                                Lakes and Inland or Inland)
                            </option>
                            <option value="ZDL8">
                                Apprentice Mate Steersman (Any Appropriate
                                Tonnage) (Oceans or Near Coastal)
                            </option>
                            <option value="ZDL9">
                                Apprentice Mate Steersman (Any Appropriate
                                Tonnage) (Great Lakes and Inland)
                            </option>
                            <option value="ZDL10">
                                Master (Any Gross Registered Tons (AGT)) (Great
                                Lakes and Inland or Inland)
                            </option>
                            <option value="ZDL11">
                                Mate (Any Gross Registered Tons (AGT)) (Great
                                Lakes and Inland)
                            </option>
                            <option value="ZDL12">
                                Master (NMT 500/1600 Gross Registered Tons)
                                (Great Lakes and Inland)
                            </option>
                            <option value="ZDL13">
                                Mate (NMT 500/1600 Gross Registered Tons) (Great
                                Lakes and Inland)
                            </option>
                            <option value="ZDL14">
                                Master (NMT 200 Gross Registered Tons) (Great
                                Lakes and Inland or Inland)
                            </option>
                            <option value="ZDL15">
                                Master (AGT OR NMT 500/1600 GRT) (Rivers)
                            </option>
                            <option value="ZDL16">
                                Master (AGT OR NMT 500/1600 GRT) (Rivers)
                            </option>
                            <option value="ZDL17">
                                Mate (NMT 500/1600 GRT) (Rivers)
                            </option>
                            <option value="ZDL18">
                                Master or Mate (NMT 200 Gross Registered Tons)
                                (Rivers)
                            </option>
                            <option value="ZDL19">
                                Master Uninspected Fishing Vessel (Any
                                Appropriate Tonnage) (Oceans or Near Coastal)
                            </option>
                            <option value="ZDL20">
                                Mate Uninspected Fishing Vessel (Any Appropriate
                                Tonnage) (Oceans or Near Coastal)
                            </option>
                            <option value="ZDL21">
                                First Class Pilot (Any Appropriate Tonnage) (Any
                                Appropriate Waters)
                            </option>
                            <option value="ZDL22">
                                Offshore Installation Manager (Mobile Offshore
                                Drilling Unit) (Unrestricted)
                            </option>
                            <option value="ZDL23">
                                Offshore Installation Manager (Mobile Offshore
                                Drilling Unit) (Surface Units Underway)
                            </option>
                            <option value="ZDL24">
                                Offshore Installation Manager (Mobile Offshore
                                Drilling Unit) (Surface Units On Location)
                            </option>
                            <option value="ZDL25">
                                Offshore Installation Manager (Mobile Offshore
                                Drilling Unit) (Bottom Bearing Units Underway)
                            </option>
                            <option value="ZDL26">
                                Offshore Installation Manager (Mobile Offshore
                                Drilling Unit) (Bottom Bearing Units On
                                Location)
                            </option>
                            <option value="ZDL27">
                                Barge Supervisor (Mobile Offshore Drilling Unit)
                                (N/A)
                            </option>
                            <option value="ZDL28">
                                Ballast Control Operator (Mobile Offshore
                                Drilling Unit) (N/A)
                            </option>
                        </select>

                        {errors.license_rating && (
                            <div
                                className="invalid-feedback"
                                style={{
                                    display: "block",
                                }}
                            >
                                {errors.license_rating}
                            </div>
                        )}
                    </div>
                </div>
            );
        } else if (this.state.question_type === "Engine") {
            RateContent = (
                <div className="form-group row ">
                    <label className="col-lg-3 col-form-label">
                        Engine rating <span className="text-danger">*</span>
                    </label>
                    <div className="col-lg-9">
                        <select
                            className={classnames("form-control ", {
                                "is-invalid": errors.license_rating,
                            })}
                            data-size="10"
                            data-live-search="true"
                            data-style="btn-white"
                            name="license_rating"
                            value={this.state.license_rating}
                            onChange={this.onChange}
                        >
                            <option value="">Select Engine Rating</option>
                            <option value="ZDL1">
                                First Assistant Engineer (Unlimited)
                            </option>
                            <option value="ZDL2">
                                Third Assistant Engineer (Unlimited)
                            </option>
                            <option value="ZDL19">
                                MEWB (Marine Engineering Workbook)
                            </option>
                            <option value="ZDL3">
                                Chief Engineer (Limited)
                            </option>
                            <option value="ZDL4">
                                Assistant Engineer (Limited)
                            </option>
                            <option value="ZDL5">
                                Designated Duty Engineer (Unlimited)
                            </option>
                            <option value="ZDL6">
                                Designated Duty Engineer (4,000 HP)
                            </option>
                            <option value="ZDL7">
                                Designated Duty Engineer (1,000 HP)
                            </option>
                            <option value="ZDL8">
                                Chief Engineer (Uninspected Fishing Industry
                                Vessels)
                            </option>
                            <option value="ZDL9">
                                Assistant Engineer (Uninspected Fishing Industry
                                Vessels)
                            </option>
                            <option value="ZDL10">Chief Engineer (MODU)</option>
                            <option value="ZDL11">
                                Assistant Engineer (MODU)
                            </option>
                            <option value="ZDL12">
                                Chief Engineer (OSV Unlimited)
                            </option>
                            <option value="ZDL13">
                                Assistant Engineer (OSV Unlimited)
                            </option>
                            <option value="ZDL14">
                                QMED (Junior Engineer)
                            </option>
                            <option value="ZDL15">
                                QMED (Electrician - Refrigeration Engineer)
                            </option>
                            <option value="ZDL16">QMED (Oiler)</option>
                            <option value="ZDL17">
                                QMED (Fireman - Watertender)
                            </option>
                            <option value="ZDL18">
                                QMED (Machinist - Pumpman)
                            </option>
                        </select>

                        {errors.license_rating && (
                            <div
                                className="invalid-feedback"
                                style={{
                                    display: "block",
                                }}
                            >
                                {errors.license_rating}
                            </div>
                        )}
                    </div>
                </div>
            );
        }

        return (
            <React.Fragment>
                <ReactNotification />
                <Container>
                    <div id="content" className="content">
                        {EmailVerificationContent}
                        <ol className="breadcrumb float-xl-right">
                            <li className="breadcrumb-item">
                                <Link to="/dashboard">Dashboard</Link>
                            </li>
                            <li className="breadcrumb-item active">Profile</li>
                        </ol>
                        <h1 className="page-header">Profile</h1>

                        <div className="row">
                            <div className="col-xl-12">
                                <Panel className="member-panel">
                                    <PanelHeader>Edit Profile</PanelHeader>
                                    <PanelBody className="panel-form">
                                        <form
                                            className="form-horizontal form-bordered"
                                            onSubmit={this.onSubmit}
                                        >
                                            <fieldset>
                                                <TextFieldArea
                                                    label="User Name"
                                                    placeholder="User Name"
                                                    name="user_name"
                                                    type="text"
                                                    value={this.state.user_name}
                                                    onChange={this.onChange}
                                                    error={errors.user_name}
                                                    disabled="disabled"
                                                    striks="*"
                                                />

                                                <TextFieldOption
                                                    label="Which questions do you plan on studying?"
                                                    type="radio"
                                                    name="question_type"
                                                    checked1={
                                                        this.state
                                                            .question_type ===
                                                        "Deck"
                                                    }
                                                    checked2={
                                                        this.state
                                                            .question_type ===
                                                        "Engine"
                                                    }
                                                    value1="Deck"
                                                    value2="Engine"
                                                    option1="Deck"
                                                    option2={`Engine (${this.state.question_type}/${ratingContent})`}
                                                    onChange={this.onChange}
                                                    error={errors.question_type}
                                                    striks="*"
                                                />

                                                {RateContent}

                                                <TextImageFileUpload
                                                    src={
                                                        this.state
                                                            .imagePreviewUrl
                                                    }
                                                    type="file"
                                                    name="image"
                                                    text="Upload new image"
                                                    label="upload image"
                                                    //  value={this.state.file}
                                                    onChange={
                                                        this.fileChangedHandler
                                                    }
                                                    imgextension={[
                                                        ".jpg",
                                                        ".gif",
                                                        ".png",
                                                        ".jpeg",
                                                    ]}
                                                    upload="Upload Image"
                                                />
                                                <div className="form-group row">
                                                    <div className="col-md-12 offset-md-5">
                                                        <button
                                                            type="submit"
                                                            className="btn btn-sm btn-primary m-r-5"
                                                        >
                                                            Submit
                                                        </button>
                                                        <button
                                                            type="button"
                                                            onClick={
                                                                this
                                                                    .handleCancel
                                                            }
                                                            className="btn btn-sm btn-default"
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </form>
                                    </PanelBody>
                                </Panel>
                            </div>
                        </div>
                    </div>
                </Container>
            </React.Fragment>
        );
    }
}

Profile.propTypes = {
    userprofileEdit: PropTypes.func.isRequired,
    sendVeriEmail: PropTypes.func.isRequired,
    loadUser: PropTypes.func.isRequired,
    getCurrentProfile: PropTypes.func.isRequired,
    profile: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    profile: state.profile,
    errors: state.errors,
});

export default connect(mapStateToProps, {
    userprofileEdit,
    getCurrentProfile,
    loadUser,
    sendVeriEmail,
})(withRouter(Profile));
