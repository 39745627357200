import axios from "axios";
//import { setAlert } from "./alertActions";
import { GET_ERRORS, GET_COUPAN, PAYMENT_INPROGRESS } from "./types";

// Get current Pricing Information
export const ApplyCoupanCode = (coupanData) => (dispatch) => {
    axios
        .post("/api/mlp_auth/apply_coupan", coupanData)
        .then((res) =>
            dispatch({
                type: GET_COUPAN,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            })
        );
};

// Get current Pricing Information
export const paymentUser = (coupanData, history) => (dispatch) => {
    dispatch(setPaymetInProgress(true));
    axios
        .post("/api/mlp_auth/payment_user", coupanData)
        .then((res) => {
            dispatch(setPaymetInProgress(false));
            history.push("/dashboard/?payment=success");
        })
        .catch((err) => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            });
            dispatch(setPaymetInProgress(false));
        });
};

// Get current Pricing Information
export const paymentReg = (coupanData, history) => (dispatch) => {
    axios
        .post("/api/mlp_auth/payment_reg", coupanData)
        .then((res) => history.push("/dashboard/?payment=success"))
        .catch((err) =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            })
        );
};
// Set the payment processing status
export const setPaymetInProgress = (value) => {
    return {
        type: PAYMENT_INPROGRESS,
        payload: value,
    };
};
