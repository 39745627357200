import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../common/common.jsx";
import Header from "../common/Header";
import Footer from "../common/Footer";

class Disclaimer extends Component {
    render() {
        return (
            <div>
                <Header />

                <nav className="breadcrumb">
                    <div className="container">
                        <Link className="breadcrumb-item" to="/">
                            Home
                        </Link>
                        <span className="breadcrumb-item active">
                            Disclaimer
                        </span>
                    </div>
                </nav>
                {/*<!-- Breadcrumb End -->*/}

                {/*<!-- About -->*/}
                <div className="weare-section">
                    <div className="container">
                        <div className="abt-wrapper">
                            <h1>Terms and Conditions</h1>
                            <p>Mariner License Prep, LLC.</p>
                            <div className="clearfix bottom"></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-xs-12">
                                <div className="wearetext-right">
                                    <h3>
                                        By downloading or using the Mariner
                                        License Prep platform
                                    </h3>

                                    <p>
                                        these terms will automatically apply to
                                        you – ensure you read and understand
                                        these terms before using thisstudy tool.
                                        You’re not allowed to copy, or modify
                                        the application, any part of the
                                        application, or our trademarks in any
                                        way. You’re not allowed to attempt to
                                        extract the source code of the
                                        application, and you also shouldn’t try
                                        to translate the applicationto other
                                        languages or make derivative versions.
                                        The application itself, and all the
                                        trade marks, copyright, database rights
                                        and other intellectual property rights
                                        related to it, still belong to Mariner
                                        License Prep, LLC.
                                        <br />
                                        <br />
                                        Mariner License Prep, LLC is committed
                                        to ensuring that the application is as
                                        useful and effective as possible. For
                                        that reason, we reserve the right to
                                        make changes to the application or to
                                        charge for its services, at any time and
                                        for any reason. Any changes made in
                                        relation to pricing will be clearly
                                        communicated with the users.
                                    </p>
                                </div>
                            </div>

                            {/*	<!--<div className="col-sm-4 col-xs-12">
				<div className="ads1 pull-right"><img src="<?=base_url()?>assets/user_assets/images/ads1.jpg" alt="ads1"></div>
			</div>-->*/}
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-xs-12 wearetext-right one-up">
                                <p>
                                    The Mariner License Prep application stores
                                    and processes personal data that you have
                                    provided to us, in order to provide our
                                    service. It’s your responsibility to keep
                                    your phone and access to the application
                                    secure. We therefore recommend that you do
                                    not jailbreak or root your phone, which is
                                    the process of removing software
                                    restrictions and limitations imposed by the
                                    official operating system of your device. It
                                    could make your phone vulnerable to
                                    malware/viruses/malicious programs,
                                    compromise your phone’s security features
                                    and it could mean that the Mariner License
                                    Prepapplication won’t work properly or at
                                    all.
                                </p>

                                <p>
                                    You should be aware that there are certain
                                    things that Mariner License Prep, LLC will
                                    not take responsibility for. Certain
                                    functions of the application will require
                                    the application to have an active internet
                                    connection. The connection can be Wi-Fi, or
                                    provided by your mobile network provider,
                                    but Mariner License Prep, LLC cannot take
                                    responsibility for the application not
                                    working at full functionality if you don’t
                                    have access to Wi-Fi, and/or you don’t have
                                    any of your data allowance left.
                                </p>

                                <p>
                                    If you’re using the application outside of
                                    an area with Wi-Fi, you should remember that
                                    your terms of the agreement with your mobile
                                    network provider will still apply. As a
                                    result, you may be charged by your mobile
                                    provider for the cost of data for the
                                    duration of the connection while accessing
                                    the application, or other third-party
                                    charges. In using the application, you’re
                                    accepting responsibility for any such
                                    charges, including roaming data charges if
                                    you use the application outside of your home
                                    territory (i.e. region or country) without
                                    turning off data roaming. If you are not the
                                    bill payer for the device on which you’re
                                    using the application, please be aware that
                                    we assume that you have received permission
                                    from the bill payer for using the app.
                                </p>

                                <p>
                                    Along the same lines, Mariner License Prep,
                                    LLC cannot always take responsibility for
                                    the way you use the application. Mariner
                                    License Prep, LLC cannot be held responsible
                                    for any unexpected malfunctions on your
                                    respective devices.
                                </p>

                                <p>
                                    Mariner License Prep, LLC relies on third
                                    parties to provide information in the
                                    application so that we can make it available
                                    to you. Mariner License Prep, LLC accepts no
                                    liability for any loss, direct or indirect,
                                    you experience as a result of relying wholly
                                    on this functionality of the app.
                                </p>

                                <p>
                                    At some point, Mariner License Prep, LLC may
                                    wish to update the app. The applicationis
                                    currently available on iOS and as a web
                                    application – the requirements for these
                                    platforms may change, and you’ll need to
                                    download the updates if you want to keep
                                    using the application.Mariner License Prep,
                                    LLC does not promise that it will always
                                    update the applicationso that it is relevant
                                    to you and/or works with your specific
                                    platform. However, you promise to always
                                    accept updates to the application when
                                    offered to you, we may also wish to stop
                                    providing the application, and may terminate
                                    use of it at any time without giving notice
                                    of termination to you. Unless we tell you
                                    otherwise, upon any termination, (a) the
                                    rights and licenses granted to you in these
                                    terms will end; (b) you must stop using the
                                    application, and (if needed) delete it from
                                    your device.
                                </p>

                                <h3 className="privacy-sub-head">
                                    Changes to This Terms and Conditions
                                </h3>

                                <p>
                                    We may update our Terms and Conditions from
                                    time to time. Thus, you are advised to
                                    review this page periodically for any
                                    changes. We will notify you of any changes
                                    by posting the new Terms and Conditions on
                                    this page. These changes are effective
                                    immediately after they are posted on this
                                    page.
                                </p>

                                <h3 className="privacy-sub-head">Contact Us</h3>

                                <p>
                                    If you have any questions or suggestions
                                    about my Terms and Conditions, do not
                                    hesitate to contact us.
                                    <span>
                                        <a href="mailto:support@seatrials.net">
                                            support@seatrials.net
                                        </a>
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                {/*<!-- End Desclaimer -->*/}
                <Footer />
            </div>
        );
    }
}
export default Disclaimer;
