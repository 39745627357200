import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import GenarateCodes from "../../components/admin/coupan/GenarateCodes";
import SendPromo from "../../components/admin/coupan/SendPromo";
import SentMail from "../../components/admin/coupan/SentMail";
import UsedCodes from "../../components/admin/coupan/UsedCodes";
import ViewMessage from "../../components/admin/coupan/ViewMessage";
import AdminRoute from "./AdminRoute";

const CoupanCodeRoutes = () => {
    let { path } = useRouteMatch();

    return (
        <Switch>
            <AdminRoute
                exact
                path={`${path}/generate-codes`}
                component={GenarateCodes}
            />
            <AdminRoute
                exact
                path={`${path}/used-codes`}
                component={UsedCodes}
            />
            <AdminRoute exact path={`${path}/sent-mail`} component={SentMail} />
            <AdminRoute
                exact
                path={`${path}/send-promo/:Id`}
                component={SendPromo}
            />
            <AdminRoute
                exact
                path={`${path}/view-message/:Id`}
                component={ViewMessage}
            />
        </Switch>
    );
};

export default CoupanCodeRoutes;
