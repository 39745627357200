import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

const TextAreaField = ({
    name,
    placeholder,
    value,
    label,
    error,
    info,
    striks,
    type,
    onChange,
    disabled,
}) => {
    return (
        <div className="form-group row">
            <label className="col-lg-3 col-form-label">
                {label}
                <span className="text-danger">{striks}</span>
            </label>
            <div className="col-lg-9">
                <textarea
                    type={type}
                    className={classnames("form-control m-b-5", {
                        "is-invalid": error,
                    })}
                    placeholder={placeholder}
                    name={name}
                    onChange={onChange}
                    disabled={disabled}
                    value={value}
                >
                    {" "}
                </textarea>
                {error && <div className="invalid-feedback">{error}</div>}
            </div>
        </div>
    );
};

TextAreaField.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.string.isRequired,
    info: PropTypes.string,
    error: PropTypes.string,
    type: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.string,
};

export default TextAreaField;
