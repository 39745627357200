import React, { Component } from "react";
import ReactTable from "react-table";
import { Link } from "react-router-dom";
import { Panel, PanelHeader } from "../common/panel/Panel.jsx";
import "react-table/react-table.css";

class SearchTable extends Component {
    constructor(props) {
        super(props);

        //this.data = makeData();
        this.defaultSorted = [
            {
                id: "_id",
                desc: false,
            },
        ];
        this.tableColumns = [
            {
                Header: "#Q.Id",
                accessor: "ZNUMBER",
                maxWidth: 100,
                Cell: (row) => <span>#{row.value}</span>,
            },
            {
                Header: "Subject",
                accessor: "ZBOOKNAME",
                maxWidth: 100,
                style: { whiteSpace: "unset" },
            },
            {
                Header: "Topic",
                accessor: "ZSUBCATEGORYNAME",
                maxWidth: 200,
                style: { whiteSpace: "unset" },
                cell: (row) => (
                    <span>
                        {row.original.ZSUBCATEGORYNAME ? (
                            <span>{row.original.ZSUBCATEGORYNAME}</span>
                        ) : (
                            <span>Null</span>
                        )}
                    </span>
                ),
            },

            {
                Header: "Questions",
                id: "row",
                accessor: "zmcq_id",
                style: { whiteSpace: "unset" },
                Cell: (row) => (
                    <Link
                        to={`/user/search/watch?mode=normal&keyword=${localStorage.getItem(
                            "keyword"
                        )}&topic=${localStorage.getItem(
                            "topic"
                        )}&area=${localStorage.getItem("area")}&num=${
                            row.index + 1
                        }&zid=${row.original._id}`}
                    >
                        {row.original.ZQUESTION}
                        <br />
                        <span>
                            <span> A) {row.original.ZANSWERONE}</span> <br />
                            <span> B) {row.original.ZANSWERTWO}</span> <br />
                            <span> C) {row.original.ZANSWERTHREE}</span> <br />
                            <span> D) {row.original.ZANSWERFOUR}</span>
                        </span>
                    </Link>
                ),
            },
        ];
    }

    render() {
        const data = this.props.searchs;
        return (
            <div>
                <Panel>
                    <PanelHeader>Search Result</PanelHeader>

                    <ReactTable
                        filterable
                        useSortBy
                        data={data}
                        columns={this.tableColumns}
                        defaultPageSize={10}
                        defaultSorted={this.defaultSorted}
                        className="-highlight"
                    />
                </Panel>
            </div>
        );
    }
}
export default SearchTable;
