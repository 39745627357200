import React, { Component } from "react";
import ReactTable from "react-table";
import { Link } from "react-router-dom";
import { Panel, PanelHeader } from "../common/panel/Panel.jsx";
import "react-table/react-table.css";
import Moment from "react-moment";

class InvoiceList extends Component {
    constructor(props) {
        super(props);

        //this.data = makeData();
        this.defaultSorted = [
            {
                id: "_id",
                desc: true,
            },
        ];
        this.tableColumns = [
            {
                Header: "Id",
                id: "row",
                maxWidth: 50,
                filterable: false,
                Cell: (row) => {
                    return <div>{row.index + 1}</div>;
                },
            },

            {
                Header: "User Name",
                accessor: "user_id.user_name",
                Cell: (row) => (
                    <span>
                        {row.original.user_id ? (
                            row.original.user_id.user_name
                        ) : (
                            <span className="label label-default m-r-3">
                                Undefine
                            </span>
                        )}
                    </span>
                ),
            },

            {
                Header: "Email",
                accessor: "user_id.email",
                Cell: (row) => (
                    <span>
                        {row.original.user_id ? (
                            row.original.user_id.email
                        ) : (
                            <span className="label label-default m-r-3">
                                Undefine
                            </span>
                        )}
                    </span>
                ),
            },

            {
                Header: "Purchase date",
                accessor: "subs_date",
                filterable: false,
                sortable: false,
                Cell: (row) => (
                    <div>
                        <i className="fa fa-calendar-alt col-blue mr-2 "></i>
                        <Moment format="D MMM YYYY" withTitle>
                            {row.value}
                        </Moment>
                    </div>
                ),
            },

            {
                Header: "Subscription Type",
                accessor: "subscription_type",
                Cell: (row) => (
                    <span>
                        {row.value === "Trial" ? (
                            <span className="label label-warning m-r-3">
                                {row.value}
                            </span>
                        ) : (
                            <span className="label label-lime m-r-3">
                                {row.value}
                            </span>
                        )}
                    </span>
                ),
            },

            {
                Header: "Amount",
                accessor: "amount",
                Cell: (row) => (
                    <span>
                        <span className="badge badge-default badge-square">
                            {" "}
                            $ {row.value}
                        </span>
                    </span>
                ),
            },

            {
                Header: "Valid Till",
                accessor: "unsubs_date",
                filterable: false,
                sortable: false,
                Cell: (row) => (
                    <div>
                        <i className="fa fa-calendar-alt col-blue mr-2 "></i>
                        <Moment format="D MMM YYYY" withTitle>
                            {row.value}
                        </Moment>
                    </div>
                ),
            },

            {
                Header: "PlatForm",
                accessor: "platform",
                Cell: (row) => (
                    <span>
                        {row.value ? (
                            row.value
                        ) : (
                            <span className="label label-default m-r-3">
                                Undefine
                            </span>
                        )}
                    </span>
                ),
            },

            {
                Header: "Actions",
                accessor: "_id",
                filterable: false,
                sortable: false,
                Cell: (row) => (
                    <div>
                        <Link
                            to={`/admin/subscribers/view-invoice/${row.value}`}
                            className="btn btn-primary btn-sm m-r-5 "
                        >
                            <i className="fas fa-sm fa-fw m-r-2 fa-print"> </i>{" "}
                            Invoice
                        </Link>
                    </div>
                ),
            },
        ];
    }

    render() {
        const data = this.props.invoices;

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-12">
                        <Panel>
                            <PanelHeader>
                                List of All Invoices Table
                            </PanelHeader>

                            <ReactTable
                                filterable
                                useSortBy
                                data={data}
                                columns={this.tableColumns}
                                defaultPageSize={10}
                                defaultSorted={this.defaultSorted}
                                className="-highlight"
                            />
                        </Panel>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default InvoiceList;
