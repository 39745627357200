import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Container from "../content/Container.jsx";
import { Panel, PanelHeader, PanelBody } from "../common/panel/Panel.jsx";
import InvoiceList from "./InvoiceList.jsx";
import Spinner from "../common/textfield/Spinner";
import {
    getInvoicebyDate,
    getInvoices,
} from "../../../actions/invoiceActions.jsx";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class mainInvoice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            start: new Date(),
            end: new Date(),
            errors: {},
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onDismiss = this.onDismiss.bind(this);
    }

    handleChange = (date) => {
        this.setState({
            start: date,
        });
    };

    handleChange1 = (date) => {
        this.setState({
            end: date,
        });
    };

    componentDidMount() {
        this.props.getInvoices();
    }
    onDismiss() {
        this.setState({ visible: false, visible1: false, visible2: false });
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    onSubmit(e) {
        e.preventDefault();
        //console.log(this.state.file);
        const formData = {
            start: this.state.start,
            end: this.state.end,
        };
        this.props.getInvoicebyDate(formData, this.props.history);

        this.setState({
            start: "",
            end: "",
            error: {},
        });
    }
    render() {
        const { errors } = this.state;

        const { invoices, loading } = this.props.invoice;

        let mainInvoiceContent;
        if (invoices === null || loading) {
            mainInvoiceContent = <Spinner />;
        } else {
            //console.log(blogcats);
            if (invoices.length > 0) {
                mainInvoiceContent = <InvoiceList invoices={invoices} />;
            } else {
                mainInvoiceContent = (
                    <div className="row">
                        <div className="col-12">
                            <Panel>
                                <PanelHeader>Promo Code List</PanelHeader>
                                <PanelBody>
                                    <div className="note note-secondary m-b-15">
                                        <div className="note-icon">
                                            <i className="fa fa-lightbulb"></i>
                                        </div>
                                        <div className="note-content text-right">
                                            <h4>
                                                <b>This Table Is Empty</b>
                                            </h4>
                                            <p>
                                                {" "}
                                                No data found to show here. This
                                                table is empty.{" "}
                                            </p>
                                        </div>
                                    </div>
                                </PanelBody>
                            </Panel>
                        </div>
                    </div>
                );
            }
        }
        return (
            <React.Fragment>
                <Container>
                    <div className="content">
                        <div>
                            <ol className="breadcrumb float-xl-right">
                                <li className="breadcrumb-item">
                                    <Link to="/admin/dashboard">Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item active">
                                    {" "}
                                    Invoices{" "}
                                </li>
                            </ol>
                            <h1 className="page-header"> Invoices </h1>
                            <div className="row">
                                <div className="col-xl-12">
                                    <Panel>
                                        <PanelHeader>
                                            Select Invoice Range
                                        </PanelHeader>
                                        <PanelBody className="panel-form">
                                            <div className="panel-form">
                                                <form
                                                    className="form-horizontal form-bordered"
                                                    onSubmit={this.onSubmit}
                                                >
                                                    <div className="form-group row">
                                                        <label className="col-lg-3 col-form-label">
                                                            Range Pickers
                                                        </label>
                                                        <div className="col-lg-9">
                                                            <div className="row row-space-10">
                                                                <div className="col-6">
                                                                    <DatePicker
                                                                        selected={
                                                                            this
                                                                                .state
                                                                                .start
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .handleChange
                                                                        }
                                                                        className="form-control"
                                                                        name="start"
                                                                        timeFormat="HH:mm"
                                                                        placeholder="From"
                                                                        timeIntervals={
                                                                            15
                                                                        }
                                                                        timeCaption="time"
                                                                        dateFormat="MMMM d, yyyy "
                                                                    />
                                                                    {errors.start && (
                                                                        <div
                                                                            className="invalid-feedback"
                                                                            style={{
                                                                                display:
                                                                                    "block",
                                                                            }}
                                                                        >
                                                                            {
                                                                                errors.start
                                                                            }
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <div className="col-6">
                                                                    <DatePicker
                                                                        selected={
                                                                            this
                                                                                .state
                                                                                .end
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .handleChange1
                                                                        }
                                                                        className="form-control"
                                                                        name="end"
                                                                        placeholder="To"
                                                                        timeFormat="HH:mm"
                                                                        timeIntervals={
                                                                            15
                                                                        }
                                                                        timeCaption="time"
                                                                        dateFormat="MMMM d, yyyy "
                                                                    />
                                                                    {errors.end && (
                                                                        <div
                                                                            className="invalid-feedback"
                                                                            style={{
                                                                                display:
                                                                                    "block",
                                                                            }}
                                                                        >
                                                                            {
                                                                                errors.end
                                                                            }
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <div className="col-md-12 offset-md-5">
                                                            <button
                                                                type="submit"
                                                                className="btn btn-sm btn-primary m-r-5"
                                                            >
                                                                Submit
                                                            </button>

                                                            <button
                                                                type="button"
                                                                onClick={
                                                                    this
                                                                        .handleCancel
                                                                }
                                                                className="btn btn-sm btn-default"
                                                            >
                                                                Cancel
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </PanelBody>
                                    </Panel>
                                </div>
                            </div>

                            {/*-----------table-------------------*/}
                            <div className="row">
                                <div className="col-xl-12">
                                    {mainInvoiceContent}
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </React.Fragment>
        );
    }
}

mainInvoice.propTypes = {
    getInvoices: PropTypes.func.isRequired,
    getInvoicebyDate: PropTypes.func.isRequired,
    invoice: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    invoice: state.invoice,
});

export default connect(mapStateToProps, { getInvoices, getInvoicebyDate })(
    mainInvoice
);
