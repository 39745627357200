import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Container from "../content/Container.jsx";
import SentMailList from "./SentMailList.jsx";
import Spinner from "../common/textfield/Spinner";
import { sentCoupans } from "../../../actions/coupanActions.jsx";
import { Panel, PanelHeader, PanelBody } from "../common/panel/Panel.jsx";

class SentMail extends Component {
    componentDidMount() {
        this.props.sentCoupans();
    }
    render() {
        const { coupan_emails, loading } = this.props.coupan;

        let mainMailContent;
        if (coupan_emails === null || loading) {
            mainMailContent = <Spinner />;
        } else {
            //console.log(blogcats);
            if (coupan_emails.length > 0) {
                mainMailContent = (
                    <SentMailList coupan_emails={coupan_emails} />
                );
            } else {
                mainMailContent = (
                    <div className="row">
                        <div className="col-12">
                            <Panel>
                                <PanelHeader>Sent Promo Codes</PanelHeader>
                                <PanelBody>
                                    <div className="note note-secondary m-b-15">
                                        <div className="note-icon">
                                            <i className="fa fa-lightbulb"></i>
                                        </div>
                                        <div className="note-content text-right">
                                            <h4>
                                                <b>This Table Is Empty</b>
                                            </h4>
                                            <p>
                                                {" "}
                                                No data found to show here. This
                                                table is empty.{" "}
                                            </p>
                                        </div>
                                    </div>
                                </PanelBody>
                            </Panel>
                        </div>
                    </div>
                );
            }
        }
        return (
            <React.Fragment>
                <Container>
                    <div id="content" className="content ">
                        <ol className="breadcrumb float-xl-right">
                            <li className="breadcrumb-item">
                                <a href="/admin/dashboard">Dashboard</a>
                            </li>
                            <li className="breadcrumb-item active">
                                Sent Promo Codes
                            </li>
                        </ol>
                        <h1 className="page-header">Sent promo Codes </h1>

                        {mainMailContent}
                    </div>
                </Container>
            </React.Fragment>
        );
    }
}

SentMail.propTypes = {
    sentCoupans: PropTypes.func.isRequired,
    coupan: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    coupan: state.coupan,
});

export default connect(mapStateToProps, { sentCoupans })(SentMail);
