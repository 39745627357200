import axios from "axios";
//import { setAlert } from "./alertActions";
import {
    DASHBOARD_USER,
    DASHBOARD_SUBSCRIBER,
    DASHBOARD_TREVENUE,
    DASHBOARD_NREVENUE,
    DASHBOARD_WEBSITES,
    DASHBOARD_ANDROIDS,
    DASHBOARD_IOS,
    DASHBOARD_COMMENTS,
    DASHBOARD_LOADING,
} from "./types";

// Get current Ios
export const getDUsers = () => (dispatch) => {
    // dispatch(setDashboardLoading());
    axios
        .get("/api/mlp_dashboard/users")
        .then((res) =>
            dispatch({
                type: DASHBOARD_USER,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: DASHBOARD_USER,
                payload: null,
            })
        );
};

// Get current Subscriber
export const getSubscriber = () => (dispatch) => {
    dispatch(setDashboardLoading());
    axios
        .get("/api/mlp_dashboard/subscriber")
        .then((res) =>
            dispatch({
                type: DASHBOARD_SUBSCRIBER,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: DASHBOARD_SUBSCRIBER,
                payload: null,
            })
        );
};

// Get current Total Revenue
export const getTRevenue = () => (dispatch) => {
    dispatch(setDashboardLoading());
    axios
        .get("/api/mlp_dashboard/trevenue")
        .then((res) =>
            dispatch({
                type: DASHBOARD_TREVENUE,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: DASHBOARD_TREVENUE,
                payload: null,
            })
        );
};

// Get current Total Revenue
export const getNRevenue = () => (dispatch) => {
    dispatch(setDashboardLoading());
    axios
        .get("/api/mlp_dashboard/nrevenue")
        .then((res) =>
            dispatch({
                type: DASHBOARD_NREVENUE,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: DASHBOARD_NREVENUE,
                payload: null,
            })
        );
};

// Get current Comments
export const getComments = () => (dispatch) => {
    dispatch(setDashboardLoading());
    axios
        .get("/api/mlp_dashboard/limit_comment")
        .then((res) =>
            dispatch({
                type: DASHBOARD_COMMENTS,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: DASHBOARD_COMMENTS,
                payload: null,
            })
        );
};

// Get current Dashboard
export const getWebsite = () => (dispatch) => {
    dispatch(setDashboardLoading());
    axios
        .get("/api/mlp_dashboard/limit_website")
        .then((res) =>
            dispatch({
                type: DASHBOARD_WEBSITES,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: DASHBOARD_WEBSITES,
                payload: null,
            })
        );
};

// Get current Dashboard
export const getAndroid = () => (dispatch) => {
    dispatch(setDashboardLoading());
    axios
        .get("/api/mlp_dashboard/limit_android")
        .then((res) =>
            dispatch({
                type: DASHBOARD_ANDROIDS,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: DASHBOARD_ANDROIDS,
                payload: null,
            })
        );
};

// Get current Ios
export const getIos = () => (dispatch) => {
    dispatch(setDashboardLoading());
    axios
        .get("/api/mlp_dashboard/limit_ios")
        .then((res) =>
            dispatch({
                type: DASHBOARD_IOS,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: DASHBOARD_IOS,
                payload: null,
            })
        );
};

// Dashboard loading
export const setDashboardLoading = () => {
    return {
        type: DASHBOARD_LOADING,
    };
};
