import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import queryString from "qs";
import {
    getCommentsOnQues,
    getCommentsCount,
    addComments,
} from "../../../../actions/commentActions.jsx";
import CommentList from "./CommentList.jsx";
import ReactNotification from "react-notifications-component";
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import ReactQuill from "react-quill";

class Comments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            comments: "",
            Id: this.props.id,
            _id: "",
            zmcq_id: "",
            comment_count: "",
            data: "",
            comment: "",
            collapse: false,
            editor: {
                height: 250,
            },
            errors: {},
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.closeComment = this.closeComment.bind(this);
        this.openComment = this.openComment.bind(this);
        //this.onChange = editorState => this.setState({editorState});
    }

    handleChange(evt) {
        this.setState({
            comments: evt,
        });
    }

    handleCancel(e) {
        this.setState({
            comments: "",
        });
    }

    componentDidMount() {
        const queryStringValues = queryString.parse(
            this.props.location.search.slice(1)
        );
        //  console.log(this.state.Id)
        // console.log(this.props.id)
        this.props.getCommentsOnQues(queryStringValues.zid);
        this.props.getCommentsCount(queryStringValues.zid);
        // this.props(queryStringValues.view,queryStringValues.num);
        this.setState({
            zmcq_id: queryStringValues.zid,
        });
    }

    componentWillReceiveProps(nextProps) {
        // console.log(this.props.id)
        const queryStringValues = queryString.parse(
            this.props.location.search.slice(1)
        );
        const queryStringValuesn = queryString.parse(
            nextProps.location.search.slice(1)
        );
        this.setState({
            zmcq_id: queryStringValuesn.zid,
        });
        if (queryStringValues.zid !== queryStringValuesn.zid) {
            // console.log(queryStringValuesn.zid)
            this.props.getCommentsOnQues(queryStringValuesn.zid);
            this.props.getCommentsCount(queryStringValuesn.zid);
            this.setState({
                zmcq_id: queryStringValuesn.zid,
            });
        }
        if (nextProps.comment.count) {
            this.setState({ comment_count: nextProps.comment.count });
        }

        if (nextProps.errors) {
            this.setState({ errors: nextProps.errors });
        }
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    closeComment() {
        localStorage.setItem("collapse", "1");
        this.setState({ collapse: false });
    }

    openComment() {
        localStorage.removeItem("collapse");
        this.setState({ collapse: true });
    }

    onSubmit(e) {
        e.preventDefault();
        const commentData = {
            comments: this.state.comments,
            zmcq_id: this.state.zmcq_id,
        };
        this.props.addComments(commentData, this.props.history);

        this.setState({
            comments: "",
        });
        console.log(this.state.comments);
        store.addNotification({
            title: "Success",
            message: "Comment Save Successfully",
            type: "success",
            insert: "top",
            container: "top-center",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: { duration: 3000 },
            dismissable: { click: true },
            // content: notificationContent
        });
    }

    render() {
        const { errors } = this.state;
        const { comment_ques, loading } = this.props.comment;
        let CommentContent;
        if (comment_ques === null || loading) {
            CommentContent = "";
        } else {
            if (comment_ques !== "") {
                CommentContent = <CommentList comment_ques={comment_ques} />;
            }
        }

        let collapseContent;
        let buttonContent;

        if (this.state.collapse) {
            collapseContent = (
                <div id="demo" className="active collapse in">
                    <div className="col-md-12 col-sm-12 cmnt-history">
                        {CommentContent}
                        <form onSubmit={this.onSubmit}>
                            <div className="form-group adjust">
                                <div className="col-sm-12 col-xs-12">
                                    <ReactQuill
                                        value={this.state.comments}
                                        name="comments"
                                        onChange={this.handleChange}
                                        style={{
                                            height: "250px",
                                            marginBottom: "20px",
                                        }}
                                    />

                                    {errors.comments && (
                                        <div
                                            className="invalid-feedback"
                                            style={{
                                                display: "block",
                                            }}
                                        >
                                            {errors.comments}
                                        </div>
                                    )}
                                    <br />
                                    <input
                                        type="hidden"
                                        className="form-control"
                                        placeholder="id"
                                        name="zmcq_id"
                                        id="zmcq_id"
                                        value={this.state.zmcq_id}
                                        onChange={this.onChange}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-md-12 ">
                                    <button
                                        type="submit"
                                        className="btn btn-primary  btn-sub m-r-5"
                                    >
                                        Add comment
                                    </button>
                                    <button
                                        type="button"
                                        onClick={this.handleCancel}
                                        className="btn btn-default btn-sub"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            );
        } else {
            collapseContent = "";
        }

        if (localStorage.getItem("collapse") === "1") {
            collapseContent = "";

            buttonContent = (
                <button
                    onClick={this.openComment}
                    type="button"
                    className="cmnts-dts show-comments active"
                >
                    <i className="fa fa-align-justify"></i>
                </button>
            );
        } else {
            collapseContent = (
                <div id="demo" className="active collapse in">
                    <div className="col-md-12 col-sm-12 cmnt-history">
                        {CommentContent}
                        <form onSubmit={this.onSubmit}>
                            <div className="form-group adjust">
                                <div className="col-sm-12 col-xs-12">
                                    <ReactQuill
                                        value={this.state.comments}
                                        name="comments"
                                        onChange={this.handleChange}
                                        style={{
                                            height: "250px",
                                            marginBottom: "20px",
                                        }}
                                    />
                                    {errors.comments && (
                                        <div
                                            className="invalid-feedback"
                                            style={{
                                                display: "block",
                                            }}
                                        >
                                            {errors.comments}
                                        </div>
                                    )}
                                    <br />
                                    <input
                                        type="hidden"
                                        className="form-control"
                                        placeholder="id"
                                        name="zmcq_id"
                                        id="zmcq_id"
                                        value={this.state.zmcq_id}
                                        onChange={this.onChange}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-md-12 ">
                                    <button
                                        type="submit"
                                        className="btn btn-primary  btn-sub m-r-5"
                                    >
                                        Add comment
                                    </button>
                                    <button
                                        type="button"
                                        onClick={this.handleCancel}
                                        className="btn btn-default btn-sub"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            );

            buttonContent = (
                <button
                    onClick={this.closeComment}
                    type="button"
                    className="cmnts-dts show-comments active"
                >
                    <i className="fa fa-align-justify"></i>
                </button>
            );
        }

        return (
            <React.Fragment>
                <ReactNotification />
                <div className="m-t-40">
                    <div className="q-number-wrapper for-btn for-top-btn">
                        <div className="col-md-6 for-only-q">
                            <span className="author-name cmt">
                                Comments <span>{this.state.comment_count}</span>{" "}
                            </span>
                        </div>
                        <div className="col-md-6 text-right for-only-q">
                            {/*  <button onClick={this.closeComment} type="button" className="cmnts-dts show-comments active" >
                  <i className="fa fa-align-justify"></i>              
                  </button> */}
                            {buttonContent}
                        </div>
                    </div>

                    <div className="col-sm-12 col-xs-12">
                        {/* <div id="demo" className={"active " + ((this.state.collapse) ? "collapse " : "collapse in")}>*/}
                        {collapseContent}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

Comments.propTypes = {
    getCommentsOnQues: PropTypes.func.isRequired,
    addComments: PropTypes.func.isRequired,
    getCommentsCount: PropTypes.func.isRequired,
    comment: PropTypes.object.isRequired,
    // question: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    comment: state.comment,
    errors: state.errors,
});

export default connect(mapStateToProps, {
    getCommentsOnQues,
    getCommentsCount,
    addComments,
})(withRouter(Comments));
