import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Container from "../content/Container.jsx";
import { Panel, PanelHeader, PanelBody } from "./../common/panel/Panel.jsx";
import TextFieldArea from "./../common/textfield/TextFieldArea";
import AlertBox from "./../common/textfield/AlertBox";
import classnames from "classnames";
import { getTopic, generateNewTest } from "../../../actions/testActions.jsx";
import TopicList from "./TopicList.jsx";
import TopicListEngine from "./TopicListEngine.jsx";
import isEmpty from "../../../validation/is-empty";
import { loadUser, sendVeriEmail } from "../../../actions/userActions";
import ReactNotification from "react-notifications-component";
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

class GenerateTest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            test_name: "",
            test_subject: "",
            question_count: "",
            visible: false,
            verification_status: "",
            user_id: "",
            question_type: "",
            errors: {},
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }
    handleCancel(e) {
        this.setState({
            test_name: "",
            test_subject: "",
            question_count: "",
            test_num: "",
        });
    }

    componentDidMount() {
        if (localStorage.getItem("user") === "Inactive") {
            this.props.history.push("/user/alert");
        }
        this.props.getTopic();
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors,
            });
        }

        if (nextProps.test.test) {
            const test = nextProps.test.test;
            test.test_num = !isEmpty(test.test_num) ? test.test_num : "";
            if (!isEmpty(test.test_num)) {
                this.props.history.push(`/user/test/print/${test.test_num}`);
            }
        }
        if (nextProps.auth.user) {
            const user = nextProps.auth.user;
            user._id = !isEmpty(user._id) ? user._id : "";
            user.verification_status = !isEmpty(user.verification_status)
                ? user.verification_status
                : "";
            user.question_type = !isEmpty(user.question_type)
                ? user.question_type
                : "";
            this.setState({
                verification_status: user.verification_status,
                user_id: user._id,
                question_type: user.question_type,
            });
        }
    }

    onSubmit(e) {
        e.preventDefault();
        const testData = {
            test_name: this.state.test_name,
            test_subject: this.state.test_subject,
            question_count: this.state.question_count,
        };

        this.props.generateNewTest(testData, this.props.history);
    }

    onEmailClick(id) {
        this.props.sendVeriEmail(id);
        store.addNotification({
            title: "Success!",
            message:
                " An email is sent to your registered email id. Click on the link to verify.",
            type: "success",
            insert: "top",
            container: "top-center",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: { duration: 3000 },
            dismissable: { click: true },
            // content: notificationContent
        });
    }

    render() {
        const { errors } = this.state;
        const { topics, loading } = this.props.test;
        let topicContent;

        if (topics === null || loading) {
            topicContent = <option>No customer</option>;
        } else {
            if (topics.length > 0) {
                if (this.state.question_type === "Deck") {
                    topicContent = <TopicList topics={topics} />;
                } else {
                    topicContent = <TopicListEngine topics={topics} />;
                }
            }
        }

        let EmailVerificationContent;
        if (this.state.verification_status === "No") {
            EmailVerificationContent = (
                <AlertBox
                    classes="danger"
                    isOpen={this.state.visible}
                    toggle={this.onDismiss}
                    content="Your Email verification is pending!"
                    linkContent="resend verification link"
                    onClick={this.onEmailClick.bind(this, this.state.user_id)}
                />
            );
        }
        return (
            <React.Fragment>
                <ReactNotification />
                <Container>
                    <div className="content">
                        {EmailVerificationContent}
                        <ol className="breadcrumb float-xl-right">
                            <li className="breadcrumb-item">
                                <Link to="/dashboard">Dashboard</Link>
                            </li>
                            <li className="breadcrumb-item active">
                                Generate A Test{" "}
                            </li>
                        </ol>
                        <h1 className="page-header">GENERATE A TEST </h1>
                        <div className="row">
                            <div className="col-xl-12">
                                <Panel className="member-panel">
                                    <PanelHeader>Generate A Test</PanelHeader>
                                    <PanelBody className="panel-form">
                                        <form
                                            className="form-horizontal form-bordered"
                                            onSubmit={this.onSubmit}
                                        >
                                            <fieldset>
                                                <TextFieldArea
                                                    label="Test Name"
                                                    placeholder="Test Name"
                                                    name="test_name"
                                                    type="text"
                                                    value={this.state.test_name}
                                                    onChange={this.onChange}
                                                    error={errors.test_name}
                                                />

                                                <div className="form-group row ">
                                                    <label className="col-lg-3 col-form-label">
                                                        Select A Topic
                                                        <span className="text-danger">
                                                            *
                                                        </span>
                                                    </label>
                                                    <div className="col-lg-9">
                                                        <select
                                                            className={classnames(
                                                                "form-control ",
                                                                {
                                                                    "is-invalid":
                                                                        errors.test_subject,
                                                                }
                                                            )}
                                                            data-size="10"
                                                            data-live-search="true"
                                                            data-style="btn-white"
                                                            name="test_subject"
                                                            value={
                                                                this.state
                                                                    .test_subject
                                                            }
                                                            onChange={
                                                                this.onChange
                                                            }
                                                        >
                                                            <option value="">
                                                                Select A Topic
                                                            </option>
                                                            {topicContent}
                                                        </select>

                                                        {errors.test_subject && (
                                                            <div
                                                                className="invalid-feedback"
                                                                style={{
                                                                    display:
                                                                        "block",
                                                                }}
                                                            >
                                                                {
                                                                    errors.test_subject
                                                                }
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="form-group row ">
                                                    <label className="col-lg-3 col-form-label">
                                                        Number of Questions To
                                                        Generate
                                                        <span className="text-danger">
                                                            *
                                                        </span>
                                                    </label>
                                                    <div className="col-lg-9">
                                                        <select
                                                            className={classnames(
                                                                "form-control ",
                                                                {
                                                                    "is-invalid":
                                                                        errors.question_count,
                                                                }
                                                            )}
                                                            data-size="10"
                                                            data-live-search="true"
                                                            data-style="btn-white"
                                                            name="question_count"
                                                            value={
                                                                this.state
                                                                    .question_count
                                                            }
                                                            onChange={
                                                                this.onChange
                                                            }
                                                        >
                                                            <option value="">
                                                                Select Number of
                                                                Question to
                                                                Generate{" "}
                                                            </option>
                                                            <option value="10">
                                                                10
                                                            </option>
                                                            <option value="15">
                                                                15
                                                            </option>
                                                            <option value="50">
                                                                50
                                                            </option>
                                                            <option value="70">
                                                                70
                                                            </option>
                                                        </select>

                                                        {errors.question_count && (
                                                            <div
                                                                className="invalid-feedback"
                                                                style={{
                                                                    display:
                                                                        "block",
                                                                }}
                                                            >
                                                                {
                                                                    errors.question_count
                                                                }
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <div className="col-md-12 offset-md-5">
                                                        <button
                                                            type="submit"
                                                            className="btn btn-sm btn-primary m-r-5"
                                                        >
                                                            Submit
                                                        </button>
                                                        <button
                                                            type="button"
                                                            onClick={
                                                                this
                                                                    .handleCancel
                                                            }
                                                            className="btn btn-sm btn-default"
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </form>
                                    </PanelBody>
                                </Panel>
                            </div>
                        </div>
                    </div>
                </Container>
            </React.Fragment>
        );
    }
}

GenerateTest.propTypes = {
    getTopic: PropTypes.func.isRequired,
    loadUser: PropTypes.func.isRequired,
    sendVeriEmail: PropTypes.func.isRequired,
    generateNewTest: PropTypes.func.isRequired,
    test: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    test: state.test,
    auth: state.auth,
    errors: state.errors,
});

export default connect(mapStateToProps, {
    getTopic,
    generateNewTest,
    loadUser,
    sendVeriEmail,
})(GenerateTest);
