import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../common/common.jsx";
import Header from "../common/Header";
import Footer from "../common/Footer";

class Faq extends Component {
    constructor(props) {
        super(props);
        this.state = { activeTab: "1" };
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    }
    render() {
        return (
            <React.Fragment>
                <Header />

                {/*<!-- Breadcrumb -->*/}
                <nav className="breadcrumb">
                    <div className="container">
                        <Link className="breadcrumb-item" to="/">
                            Home
                        </Link>
                        <span className="breadcrumb-item active">Faq</span>
                    </div>
                </nav>
                {/*<!-- Breadcrumb End -->*/}
                {/*<!-- About -->*/}
                <div className="weare-section">
                    <div className="container">
                        <div className="abt-wrapper">
                            <h1>General FAQ</h1>
                            <p>Answers to your Frequently Asked Questions</p>
                            <div className="clearfix bottom"></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-8 col-xs-12">
                                <div id="accordion">
                                    <div className="card">
                                        <div
                                            className="card-header"
                                            id="headingOne"
                                        >
                                            <h5 className="mb-0">
                                                <button
                                                    onClick={() => {
                                                        this.toggleTab("1");
                                                    }}
                                                    className="btn btn-link lnk-faq"
                                                >
                                                    Where is the question bank
                                                    from? Are they accurate?
                                                </button>
                                            </h5>
                                        </div>

                                        <div
                                            id="collapseOne"
                                            className={
                                                "collapse " +
                                                (this.state.activeTab === "1"
                                                    ? "show "
                                                    : "")
                                            }
                                        >
                                            <div className="card-body">
                                                The question bank, all
                                                illustrations, and study
                                                material on this site has been
                                                DIRECTLY released from the
                                                United States Coast Guard. These
                                                questions are the most accurate
                                                and up-to-date out there. All
                                                tests since 2014 have been
                                                generated based off of this
                                                question bank and every year we
                                                add new questions.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div
                                            className="card-header"
                                            id="headingOne"
                                        >
                                            <h5 className="mb-0">
                                                <button
                                                    onClick={() => {
                                                        this.toggleTab("2");
                                                    }}
                                                    className="btn btn-link lnk-faq collapsed"
                                                >
                                                    Does my subscription give me
                                                    access to the iOS App too?
                                                </button>
                                            </h5>
                                        </div>
                                        <div
                                            id="collapseTwo"
                                            className={
                                                "collapse " +
                                                (this.state.activeTab === "2"
                                                    ? "show "
                                                    : "")
                                            }
                                        >
                                            <div className="card-body">
                                                Yes! After getting a premium
                                                subscription, you have access to
                                                this website and the iOS App by
                                                simply logging in with your
                                                information.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div
                                            className="card-header"
                                            id="headingOne"
                                        >
                                            <h5 className="mb-0">
                                                <button
                                                    onClick={() => {
                                                        this.toggleTab("3");
                                                    }}
                                                    className="btn btn-link lnk-faq collapsed"
                                                >
                                                    What does favoriting a
                                                    question do?
                                                </button>
                                            </h5>
                                        </div>
                                        <div
                                            id="collapseThree"
                                            className={
                                                "collapse " +
                                                (this.state.activeTab === "3"
                                                    ? "show "
                                                    : "")
                                            }
                                        >
                                            <div className="card-body">
                                                When you favorite a question,
                                                this allows you to come back to
                                                it later. You can view it from
                                                your dashboard and review the
                                                questions that are giving you
                                                trouble.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div
                                            className="card-header"
                                            id="headingOne"
                                        >
                                            <h5 className="mb-0">
                                                <button
                                                    onClick={() => {
                                                        this.toggleTab("4");
                                                    }}
                                                    className="btn btn-link lnk-faq collapsed"
                                                >
                                                    What does hiding a question
                                                    do?
                                                </button>
                                            </h5>
                                        </div>
                                        <div
                                            id="collapsefour"
                                            className={
                                                "collapse " +
                                                (this.state.activeTab === "4"
                                                    ? "show "
                                                    : "")
                                            }
                                        >
                                            <div className="card-body">
                                                Hiding a question removes the
                                                specific questions from the bank
                                                you are studying.This allows you
                                                to reduce the questions which
                                                you find extremely easy and
                                                don’t want to see again. You can
                                                always undo this from your
                                                dashboard.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div
                                            className="card-header"
                                            id="headingOne"
                                        >
                                            <h5 className="mb-0">
                                                <button
                                                    onClick={() => {
                                                        this.toggleTab("5");
                                                    }}
                                                    className="btn btn-link lnk-faq collapsed"
                                                >
                                                    How do I generate a test?
                                                </button>
                                            </h5>
                                        </div>
                                        <div
                                            id="collapsefive"
                                            className={
                                                "collapse " +
                                                (this.state.activeTab === "5"
                                                    ? "show "
                                                    : "")
                                            }
                                        >
                                            <div className="card-body">
                                                By going to the user dashboard,
                                                you can generate a realistic
                                                practice test from any section
                                                you would like. This allows you
                                                to either print it or take the
                                                test on the computer.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </React.Fragment>
        );
    }
}

export default Faq;
