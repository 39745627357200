import React, { Component } from "react";
import { Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";

class Button extends Component {
    constructor() {
        super();
        this.state = {
            sweetAlertInfo: false,
        };
        this.toggleSweetAlert = this.toggleSweetAlert.bind(this);
        this.routeChange = this.routeChange.bind(this);
    }

    toggleSweetAlert(name) {
        switch (name) {
            case "info":
                this.setState({ sweetAlertInfo: !this.state.sweetAlertInfo });
                break;

            default:
                break;
        }
    }

    routeChange() {
        let path = `/user/account/subscription`;
        this.props.history.push(path);
    }

    render() {
        const keyword = this.props.keyword;
        const mode = this.props.mode;
        const topic = this.props.topic;
        const area = this.props.area;
        const num = this.props.num;
        const total_ques = this.props.total_ques;
        var adding = 1;
        var nextnum = num - 0 + (adding - 0);
        var prevnum = num - 1;
        let prevbutton;
        let nextbutton;

        if (localStorage.getItem("user") === "Inactive") {
            if (localStorage.getItem("time") === "50") {
                prevbutton = (
                    <button
                        type="button"
                        className="backward demo4"
                        onClick={() => this.toggleSweetAlert("info")}
                    >
                        <i className="fa fa-angle-double-left"></i> Previous
                    </button>
                );

                nextbutton = (
                    <button
                        type="button"
                        className="forward adjust-btn demo4"
                        onClick={() => this.toggleSweetAlert("info")}
                    >
                        <i className="fa fa-angle-double-right"></i> Previous
                    </button>
                );
            } else {
                prevbutton = this.props.previous.map((pques) => (
                    <span key={pques._id}>
                        {prevnum === 0 ? (
                            <span>
                                {" "}
                                <button
                                    type="button"
                                    className="backward demo4 disabled"
                                    disabled
                                >
                                    <i className="fa fa-angle-double-left"></i>{" "}
                                    Previous
                                </button>{" "}
                            </span>
                        ) : (
                            <Link
                                to={`/user/search/watch?mode=${mode}&keyword=${keyword}&topic=${topic}&area=${area}&num=${prevnum}&zid=${pques._id}`}
                                type="button"
                                className="backward demo4"
                            >
                                <i className="fa fa-angle-double-left"></i>{" "}
                                Previous{" "}
                            </Link>
                        )}
                    </span>
                ));

                if (total_ques > num) {
                    nextbutton = this.props.next.map((nques) => (
                        <span key={nques._id}>
                            <Link
                                to={`/user/search/watch?mode=${mode}&keyword=${keyword}&topic=${topic}&area=${area}&num=${nextnum}&zid=${nques._id}`}
                                type="button"
                                className="forward adjust-btn demo4"
                            >
                                Next{" "}
                                <i className="fa fa-angle-double-right"></i>{" "}
                            </Link>
                        </span>
                    ));
                }
            }
        } else {
            prevbutton = this.props.previous.map((pques) => (
                <span key={pques._id}>
                    {prevnum === 0 ? (
                        <span>
                            {" "}
                            <button
                                type="button"
                                className="backward demo4 disabled"
                                disabled
                            >
                                <i className="fa fa-angle-double-left"></i>{" "}
                                Previous
                            </button>{" "}
                        </span>
                    ) : (
                        <Link
                            to={`/user/search/watch?mode=${mode}&keyword=${keyword}&topic=${topic}&area=${area}&num=${prevnum}&zid=${pques._id}`}
                            type="button"
                            className="backward demo4"
                        >
                            <i className="fa fa-angle-double-left"></i> Previous{" "}
                        </Link>
                    )}
                </span>
            ));

            if (total_ques > num) {
                nextbutton = this.props.next.map((nques) => (
                    <span key={nques._id}>
                        <Link
                            to={`/user/search/watch?mode=${mode}&keyword=${keyword}&topic=${topic}&area=${area}&num=${nextnum}&zid=${nques._id}`}
                            type="button"
                            className="forward adjust-btn demo4"
                        >
                            Next <i className="fa fa-angle-double-right"></i>{" "}
                        </Link>
                    </span>
                ));
            }
        }
        return (
            <React.Fragment>
                {this.state.sweetAlertInfo && (
                    <SweetAlert
                        info
                        showCancel
                        confirmBtnText="Upgrade Account"
                        confirmBtnBsStyle="info"
                        cancelBtnBsStyle="default"
                        title="Subscription Alerts"
                        onConfirm={this.routeChange}
                        onCancel={() => this.toggleSweetAlert("info")}
                    >
                        To go through more questions today, you must upgrade to
                        premium account.
                    </SweetAlert>
                )}
                <div className="col-md-5 text-right for-only-q">
                    {prevbutton}
                    {nextbutton}
                </div>
            </React.Fragment>
        );
    }
}

export default Button;
