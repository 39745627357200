import {
    DIRECTORY_DECKS,
    DIRECTORY_ENGINS,
    DIRECTORY_DECK_COUNT,
    DIRECTORY_ENGINE_COUNT,
    DIRECTORY_LOADING,
} from "../actions/types";

const initialState = {
    decks: [],
    engines: [],
    deck_count: null,
    engine_count: null,
    loading: true,
    error: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case DIRECTORY_LOADING:
            return {
                ...state,
                loading: true,
            };

        case DIRECTORY_DECKS:
            return {
                ...state,
                decks: action.payload,
                loading: false,
            };

        case DIRECTORY_ENGINS:
            return {
                ...state,
                engines: action.payload,
                loading: false,
            };

        case DIRECTORY_DECK_COUNT:
            return {
                ...state,
                deck_count: action.payload,
                loading: false,
            };

        case DIRECTORY_ENGINE_COUNT:
            return {
                ...state,
                engine_count: action.payload,
                loading: false,
            };

        default:
            return state;
    }
}
