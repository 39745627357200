import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { hideQuestion } from "../../../../actions/v1/questionActions.jsx";

class Hide extends Component {
    onHideClick(zid, mode, type, id, nextnum, _id, ZINBOOKCATEGORY) {
        this.props.hideQuestion(zid, () => {
            let path = `/user/watch?mode=${mode}&type=${type}&id=${id}&num=${nextnum}&zid=${_id}&zinbookcat=${ZINBOOKCATEGORY}`;
            this.props.history.push(path);
        });
    }
    render() {
        const type = this.props.type;
        const mode = this.props.mode;
        const id = this.props.id;
        const num = this.props.num;
        const zid = this.props.zid;
        var prevnum = num - 1;

        let hidebutton;

        if (!this.props.nextId) {
            if (this.props.prevId) {
                hidebutton = (
                    <span
                        key={this.props.prevId}
                        onClick={this.onHideClick.bind(
                            this,
                            zid,
                            mode,
                            type,
                            id,
                            prevnum,
                            this.props.prevId,
                            this.props.ZINBOOKCATEGORY
                        )}
                    >
                        <img
                            src="https://seatrials-bucket.s3.us-east-2.amazonaws.com/main/icons8-explosive-100.png"
                            alt="explosive"
                        />{" "}
                    </span>
                );
            }
        } else {
            hidebutton = (
                <span
                    key={this.props.nextId}
                    onClick={this.onHideClick.bind(
                        this,
                        zid,
                        mode,
                        type,
                        id,
                        num,
                        this.props.nextId,
                        this.props.ZINBOOKCATEGORY
                    )}
                >
                    <img
                        src="https://seatrials-bucket.s3.us-east-2.amazonaws.com/main/icons8-explosive-100.png"
                        alt="explosive"
                    />{" "}
                </span>
            );
        }

        return <React.Fragment>{hidebutton}</React.Fragment>;
    }
}

Hide.propTypes = {
    hideQuestion: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { hideQuestion })(withRouter(Hide));
