import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { PageSettings } from "../../content/page-settings.jsx";
import {
    adminlogout,
    getadminCurrentProfile,
} from "../../../../actions/adminActions";
import isEmpty from "../../../../validation/is-empty";

class SidebarProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            first_name: "",
            last_name: "",
            profileActive: 0,
        };
        this.handleProfileExpand = this.handleProfileExpand.bind(this);
    }

    componentDidMount() {
        this.props.getadminCurrentProfile();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({ errors: nextProps.errors });
        }

        if (nextProps.admin_profile.admin_profile) {
            const admin_profile = nextProps.admin_profile.admin_profile;
            //console.log(admin_profile);
            // If profile field doesnt exist, make empty string
            admin_profile.first_name = !isEmpty(admin_profile.first_name)
                ? admin_profile.first_name
                : "";
            admin_profile.last_name = !isEmpty(admin_profile.last_name)
                ? admin_profile.last_name
                : "";

            admin_profile.image = !isEmpty(admin_profile.image)
                ? admin_profile.image
                : "/noimage.jpg";

            // Set component fields state
            this.setState({
                first_name: admin_profile.first_name,
                last_name: admin_profile.last_name,
                imagePreviewUrl: admin_profile.image,
            });
        }
    }

    handleProfileExpand(e) {
        e.preventDefault();
        this.setState((state) => ({
            profileActive: !this.state.profileActive,
        }));
    }

    onLogoutClick(e) {
        e.preventDefault();
        this.props.adminlogout();
    }

    render() {
        const { isAuthenticated } = this.props.admin;

        const authLinks = (
            <li>
                <a onClick={this.onLogoutClick.bind(this)} href="#!">
                    <i className="fa fa-sign-out-alt"></i> Logout
                </a>
            </li>
        );

        const gustLinks = (
            <li>
                <Link to="/">
                    <i className="fa fa-question-circle" /> Login
                </Link>
            </li>
        );

        return (
            <PageSettings.Consumer>
                {({ pageSidebarMinify }) => (
                    <ul className="nav">
                        <li
                            className={
                                "nav-profile " +
                                (this.state.profileActive ? "expand " : "")
                            }
                        >
                            <Link to="/" onClick={this.handleProfileExpand}>
                                <div className="cover with-shadow"></div>
                                <div className="image">
                                    <img
                                        src={`https://seatrials-bucket.s3.us-east-2.amazonaws.com/admin/${this.state.imagePreviewUrl}`}
                                        alt=""
                                    />
                                </div>
                                <div className="info">
                                    <b className="caret pull-right"></b>
                                    {this.state.first_name}{" "}
                                    {this.state.last_name}
                                    <small>Admin</small>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <ul
                                className={
                                    "nav nav-profile " +
                                    (this.state.profileActive &&
                                    !pageSidebarMinify
                                        ? "d-block "
                                        : "")
                                }
                            >
                                <li>
                                    <Link to="/admin/account/profile">
                                        <i className="fa fa-pencil-alt"></i>{" "}
                                        Edit Profile
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/admin/account/settings">
                                        <i className="fa fa-cog"></i> Settings
                                    </Link>
                                </li>
                                {isAuthenticated ? authLinks : gustLinks}
                            </ul>
                        </li>
                    </ul>
                )}
            </PageSettings.Consumer>
        );
    }
}

SidebarProfile.propTypes = {
    adminlogout: PropTypes.func.isRequired,
    admin: PropTypes.object.isRequired,
    getadminCurrentProfile: PropTypes.func.isRequired,
    admin_profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    admin: state.admin,
    admin_profile: state.admin_profile,
});

export default connect(mapStateToProps, {
    adminlogout,
    getadminCurrentProfile,
})(SidebarProfile);
