import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Container from "../content/Container.jsx";
import TestResultList from "./TestResultList.jsx";
import Spinner from "../common/textfield/Spinner";
import { GetTestList } from "../../../actions/testActions.jsx";
import { loadUser, sendVeriEmail } from "../../../actions/userActions";
import { Panel, PanelHeader, PanelBody } from "../common/panel/Panel.jsx";
import { Alert } from "reactstrap";
import isEmpty from "../../../validation/is-empty";
import ReactNotification from "react-notifications-component";
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

class TestResult extends Component {
    constructor(props) {
        super(props);
        this.state = {
            verification_status: "",
            user_id: "",
        };
    }

    componentDidMount() {
        if (this.props.match.params.Id) {
            this.props.GetTestList(this.props.match.params.Id);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.auth.user) {
            const user = nextProps.auth.user;
            user._id = !isEmpty(user._id) ? user._id : "";
            user.verification_status = !isEmpty(user.verification_status)
                ? user.verification_status
                : "";
            this.setState({
                verification_status: user.verification_status,
                user_id: user._id,
            });
        }
    }

    onEmailClick(id) {
        this.props.sendVeriEmail(id);
        store.addNotification({
            title: "Success!",
            message:
                " An email is sent to your registered email id. Click on the link to verify.",
            type: "success",
            insert: "top",
            container: "top-center",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: { duration: 3000 },
            dismissable: { click: true },
            // content: notificationContent
        });
    }

    render() {
        const { testlists, loading } = this.props.test;
        let maintestContent;
        if (testlists === null || loading) {
            maintestContent = <Spinner />;
        } else {
            //console.log(blogcats);
            if (testlists.length > 0) {
                maintestContent = <TestResultList testlists={testlists} />;
            } else {
                maintestContent = (
                    <div className="row">
                        <div className="col-12">
                            <Panel className="member-panel">
                                <PanelHeader>List of Tests</PanelHeader>
                                <PanelBody>
                                    <div className="note note-secondary m-b-15">
                                        <div className="note-icon">
                                            <i className="fa fa-lightbulb"></i>
                                        </div>
                                        <div className="note-content text-right">
                                            <h4>
                                                <b>This Table Is Empty</b>
                                            </h4>
                                            <p>
                                                {" "}
                                                No data found to show here. This
                                                table is empty.{" "}
                                            </p>
                                        </div>
                                    </div>
                                </PanelBody>
                            </Panel>
                        </div>
                    </div>
                );
            }
        }

        let EmailVerificationContent;
        if (this.state.verification_status === "No") {
            EmailVerificationContent = (
                <Alert
                    color="danger"
                    isOpen={this.state.visible}
                    toggle={this.onDismiss}
                >
                    Your Email verification is pending!
                    <Link
                        to="#"
                        onClick={this.onEmailClick.bind(
                            this,
                            this.state.user_id
                        )}
                        className="alert-link"
                    >
                        {" "}
                        resend verification link
                    </Link>
                    .
                </Alert>
            );
        }
        return (
            <React.Fragment>
                <ReactNotification />
                <Container>
                    <div className="content">
                        {EmailVerificationContent}
                        <ol className="breadcrumb float-xl-right">
                            <li className="breadcrumb-item">
                                <Link to="/dashboard">Dashboard</Link>
                            </li>
                            <li className="breadcrumb-item">
                                <Link to="/user/test/manage-test">
                                    Manage Test
                                </Link>
                            </li>
                            <li className="breadcrumb-item active">
                                Test Result
                            </li>
                        </ol>
                        <h1 className="page-header">Test Result </h1>
                        <div>{maintestContent}</div>
                    </div>
                </Container>
            </React.Fragment>
        );
    }
}
TestResult.propTypes = {
    GetTestList: PropTypes.func.isRequired,
    test: PropTypes.object.isRequired,
    loadUser: PropTypes.func.isRequired,
    sendVeriEmail: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    test: state.test,
    auth: state.auth,
});

export default connect(mapStateToProps, {
    GetTestList,
    loadUser,
    sendVeriEmail,
})(TestResult);
