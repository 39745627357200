import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import AddPages from "../../components/admin/pages/AddPages";
import MainDraft from "../../components/admin/pages/MainDraft";
import MainPublished from "../../components/admin/pages/MainPublished";
import AdminRoute from "./AdminRoute";

const PagesRoutes = () => {
    let { path } = useRouteMatch();

    return (
        <Switch>
            <AdminRoute exact path={`${path}/add-pages`} component={AddPages} />
            <AdminRoute
                exact
                path={`${path}/draft-pages`}
                component={MainDraft}
            />
            <AdminRoute
                exact
                path={`${path}/published-pages`}
                component={MainPublished}
            />
        </Switch>
    );
};

export default PagesRoutes;
