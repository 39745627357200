import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
    getDeckBook,
    getEngineBook,
    getEngine,
    getDeck,
} from "../../../actions/questionActions.jsx";
import DeckBookNav from "./nav/DeckBookNav";
import EngineBookNav from "./nav/EngineBookNav";
import { Navbar, NavDropdown, Nav } from "react-bootstrap";
import { getCurrentProfile, logout } from "../../../actions/userActions";
import isEmpty from "../../../validation/is-empty";

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            engine_slug: "",
            deck_slug: "",
            deck_id: "",
            engine_id: "",
            imagePreviewUrl: "",
            user_name: "",
            errors: {},
        };
    }
    componentDidMount() {
        this.props.getDeckBook();
        this.props.getEngineBook();
        this.props.getEngine();
        this.props.getDeck();
        this.props.getCurrentProfile();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.question.engine) {
            const engine = nextProps.question.engine;
            engine._id = !isEmpty(engine._id) ? engine._id : "";
            engine.Z_PK = !isEmpty(engine.Z_PK) ? engine.Z_PK : "";
            engine.ZSLUG = !isEmpty(engine.ZSLUG) ? engine.ZSLUG : "";

            // Set component fields state for blog
            this.setState({
                engine_slug: engine.ZSLUG,
                engine_id: engine.Z_PK,
                engine_zid: engine._id,
            });
        }

        if (nextProps.question.deck) {
            const deck = nextProps.question.deck;
            deck._id = !isEmpty(deck._id) ? deck._id : "";
            deck.Z_PK = !isEmpty(deck.Z_PK) ? deck.Z_PK : "";
            deck.ZSLUG = !isEmpty(deck.ZSLUG) ? deck.ZSLUG : "";

            // Set component fields state for blog
            this.setState({
                deck_slug: deck.ZSLUG,
                deck_id: deck.Z_PK,
                deck_zid: deck._id,
            });
        }

        if (nextProps.auth.user) {
            const user = nextProps.auth.user;
            //console.log(user);
            // If user field doesnt exist, make empty string
            user.user_name = !isEmpty(user.user_name) ? user.user_name : "";
            user.image = !isEmpty(user.image)
                ? user.image
                : "./../../../assets/admin/img/noimage.jpg";

            // Set component fields state
            this.setState({
                user_name: user.user_name,
                imagePreviewUrl: user.image,
            });
        }
    }

    onLogoutClick(e) {
        e.preventDefault();
        this.props.logout();
    }
    render() {
        const { deck_books, engine_books } = this.props.question;
        let deckBookContent;
        if (deck_books.length > 0) {
            deckBookContent = <DeckBookNav deck_books={deck_books} />;
        }
        let engineBookContent;
        if (engine_books.length > 0) {
            engineBookContent = <EngineBookNav engine_books={engine_books} />;
        }

        const { isAuthenticated1 } = this.props.auth;

        const authLinks = (
            <React.Fragment>
                {/* 	<div className="col-8 col-sm-6 col-md-8"> */}

                <Nav className="mr-auto"></Nav>
                <Nav>
                    <Link
                        rel="tab"
                        href="https://app.seatrials.net/login"
                        className="btn btn-primary my-2 my-sm-0"
                        type="LOGIN"
                    >
                        {" "}
                        DASHBOARD
                    </Link>

                    {/*	<img src={this.state.imagePreviewUrl} alt="user" />*/}
                    <NavDropdown
                        title={this.state.user_name}
                        id="basic-nav-dropdown"
                    >
                        <NavDropdown.Item href="/user/account/profile">
                            User Profile
                        </NavDropdown.Item>
                        <NavDropdown.Item href="/user/account/settings">
                            Settings
                        </NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item
                            href="#"
                            onClick={this.onLogoutClick.bind(this)}
                        >
                            Logout
                        </NavDropdown.Item>
                    </NavDropdown>
                </Nav>
            </React.Fragment>
        );

        const gustLinks = (
            <React.Fragment>
                {/* 	<div className="col-8 col-sm-6 col-md-8"> */}
                <Nav className="mr-auto">
                    <NavDropdown title="Deck" id="basic-nav-dropdown">
                        <NavDropdown.Item
                            href={`/question/watch?mode=normal&type=bookcat&id=2&num=1&zid=${this.state.deck_zid}`}
                        >
                            All Deck
                        </NavDropdown.Item>
                        {deckBookContent}
                    </NavDropdown>
                    <NavDropdown title="Engine" id="basic-nav-dropdown">
                        <NavDropdown.Item
                            href={`/question/watch?mode=normal&type=bookcat&id=1&num=1&zid=${this.state.engine_zid}`}
                        >
                            All Engine
                        </NavDropdown.Item>
                        {engineBookContent}
                    </NavDropdown>
                    <Nav.Link href="/search">Search</Nav.Link>
                    {/* <Nav.Link href="/pricing">Pricing</Nav.Link> */}
                    {/* <Nav.Link href="/forum">Forum</Nav.Link> */}
                    <Nav.Link href="/contact">Contact</Nav.Link>
                </Nav>
                <Nav>
                    <a
                        rel="tab"
                        href="https://app.seatrials.net/login"
                        className="btn btn-primary my-2 my-sm-0"
                        type="LOGIN"
                    >
                        {" "}
                        LOGIN
                    </a>

                    <a
                        rel="tab"
                        href="https://app.seatrials.net/signup"
                        className="btn btn-primary my-2 my-sm-0"
                        variant="outline-success"
                        type="REGISTER"
                    >
                        {" "}
                        REGISTER
                    </a>
                </Nav>
            </React.Fragment>
        );
        return (
            <header>
                <Navbar bg="light" expand="lg" className="navbar-me">
                    <div className="container">
                        <Navbar.Brand href="/">
                            <img
                                className="logos"
                                width="170"
                                src="https://seatrials-bucket.s3.us-east-2.amazonaws.com/main/logo.png"
                                alt="Marinerlicenseprep"
                            />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            {isAuthenticated1 ? authLinks : gustLinks}
                        </Navbar.Collapse>
                    </div>
                </Navbar>
            </header>
        );
    }
}

Header.propTypes = {
    getDeckBook: PropTypes.func.isRequired,
    getEngineBook: PropTypes.func.isRequired,
    getEngine: PropTypes.func.isRequired,
    getDeck: PropTypes.func.isRequired,
    question: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,
    getCurrentProfile: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    question: state.question,
    auth: state.auth,
});

export default connect(mapStateToProps, {
    getDeckBook,
    getEngineBook,
    getEngine,
    getDeck,
    logout,
    getCurrentProfile,
})(Header);
