import axios from "axios";
//import { setAlert } from "./alertActions";
import {
    USER_LOADED,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    GET_PROFILE,
    GET_NAVS,
    LOGOUT,
    CLEAR_PROFILE,
    PROFILE_LOADING,
    CLEAR_ERRORS,
    GET_ERRORS,
} from "./types";
import setAuthToken1 from "../utils/setAuthToken1";

// Load Admin
export const loadUser = () => async (dispatch) => {
    if (localStorage.token1) {
        setAuthToken1(localStorage.token1);
    }
    try {
        const res = await axios.get("/api/mlp_auth");
        dispatch({
            type: USER_LOADED,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: AUTH_ERROR,
        });
    }
};

// Load Admin
export const sendVeriEmail = (id) => (dispatch) => {
    axios
        .get(`/api/mlp_email/send_email_verification/${id}`)
        .then((res) =>
            dispatch({
                type: PROFILE_LOADING,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            })
        );
};

// Login User
export const userlogin = (userloginData) => (dispatch) => {
    axios
        .post("/api/v1/public/login", userloginData)
        .then((res) => {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: res.data,
            });
            dispatch(loadUser());
        })
        .catch((err) =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            })
        );
};

// Login User
export const userRegister = (userloginData) => (dispatch) => {
    axios
        .post("/api/mlp_email/register", userloginData)
        .then((res) => {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: res.data,
            });
            dispatch(loadUser());
        })
        .catch((err) =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            })
        );
};

// Forgot password
export const forgotUser = (forgotData, history) => (dispatch) => {
    axios
        .post("/api/mlp_email/forgotUsermail", forgotData)
        .then((res) => history.push("/login/?mail=send"))
        .catch((err) =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            })
        );
};

// Forgotpassword verify
export const userPassverify = (forgotData, history) => (dispatch) => {
    axios
        .post("/api/mlp_auth/changepassword", forgotData)
        .then((res) => history.push("/login/?password=change"))
        .catch((err) =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            })
        );
};

// Get current profile
export const getCurrentProfile = () => (dispatch) => {
    dispatch(setProfileLoading());
    axios
        .get("/api/mlp_auth")
        .then((res) =>
            dispatch({
                type: GET_PROFILE,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_PROFILE,
                payload: {},
            })
        );
};
// Get current profile
export const emailver = (forgotData, history) => (dispatch) => {
    axios
        .post(`/api/mlp_auth/emailverify`, forgotData)
        .then((res) => history.push("/login/?veri=verify"))
        .then((res) =>
            dispatch({
                type: USER_LOADED,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: USER_LOADED,
                payload: {},
            })
        );
};

//  Edit Profile
export const userprofileEdit = (profileData) => (dispatch) => {
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
    };
    dispatch(clearErrors());
    axios
        .post("/api/mlp_auth/profile_update", profileData, config)
        .then((res) =>
            dispatch({
                type: GET_PROFILE,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            })
        );
};

//  Edit Admin Email
export const emailEdit = (emailData) => (dispatch) => {
    dispatch(clearErrors());
    axios
        .post("/api/mlp_email/email_update", emailData)
        .then((res) =>
            dispatch({
                type: GET_PROFILE,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            })
        );
};

//  Edit Admin Password
export const passwordEdit = (passwordData) => (dispatch) => {
    dispatch(clearErrors());
    axios
        .post("/api/mlp_auth/password_update", passwordData)
        .then((res) =>
            dispatch({
                type: GET_PROFILE,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            })
        );
};

// Get current Navigation
export const getActiveNavs = () => (dispatch) => {
    axios
        .get("/api/mlp_auth/active_nav")
        .then((res) =>
            dispatch({
                type: GET_NAVS,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_NAVS,
                payload: null,
            })
        );
};

// Logout / Clear Profile
export const logout = () => (dispatch) => {
    dispatch({ type: CLEAR_PROFILE });
    dispatch({ type: LOGOUT });
};

// Profile loading
export const setProfileLoading = () => {
    return {
        type: PROFILE_LOADING,
    };
};

// Clear profile
export const clearCurrentProfile = () => {
    return {
        type: CLEAR_PROFILE,
    };
};

// Clear errors
export const clearErrors = () => {
    return {
        type: CLEAR_ERRORS,
    };
};
