import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import BillingInformation from "../../components/admin/account/BillingInformation";
import EditProfile from "../../components/admin/account/EditProfile";
import Settings from "../../components/admin/account/Settings";
import AdminRoute from "./AdminRoute";

const AccountRoutes = () => {
    let { path } = useRouteMatch();

    return (
        <Switch>
            <AdminRoute
                exact
                path={`${path}/profile`}
                component={EditProfile}
            />
            <AdminRoute exact path={`${path}/settings`} component={Settings} />
            <AdminRoute
                exact
                path={`${path}/billing-information`}
                component={BillingInformation}
            />
        </Switch>
    );
};

export default AccountRoutes;
