import React, { Component } from "react";
import { Panel, PanelHeader, PanelBody } from "./../common/panel/Panel.jsx";
import Container from "../content/Container.jsx";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
    getTestQuestion,
    getTestQuestionCount,
} from "../../../actions/testActions.jsx";
import isEmpty from "../../../validation/is-empty";

class PrintTest extends Component {
    constructor(props) {
        super(props);
        this.state = { id: "", ZSLUG: "", test_id: "" };
    }

    componentDidMount() {
        if (this.props.match.params.Id) {
            this.setState({ id: this.props.match.params.Id });
            this.props.getTestQuestion(this.props.match.params.Id);
            this.props.getTestQuestionCount(this.props.match.params.Id);
            localStorage.removeItem("Printtest");
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.test.write_tests) {
            const write_tests = nextProps.test.write_tests;
            write_tests.zmcq_id.ZSLUG = !isEmpty(write_tests.zmcq_id.ZSLUG)
                ? write_tests.zmcq_id.ZSLUG
                : "";
            write_tests._id = !isEmpty(write_tests._id) ? write_tests._id : "";
            write_tests.zmcq_id._id = !isEmpty(write_tests.zmcq_id._id)
                ? write_tests.zmcq_id._id
                : "";
            this.setState({
                ZSLUG: write_tests.zmcq_id.ZSLUG,
                zmcq_id: write_tests.zmcq_id._id,
                test_id: write_tests._id,
            });
        }
        if (nextProps.test.write_count) {
            const write_count = nextProps.test.write_count;
            this.setState({
                write_count: write_count,
            });
        }
    }
    render() {
        return (
            <React.Fragment>
                <Container>
                    <div className="content">
                        <ol className="breadcrumb float-xl-right">
                            <li className="breadcrumb-item">
                                <Link to="/dashboard">Dashboard</Link>
                            </li>
                            <li className="breadcrumb-item">
                                <Link to="/user/test/generate-test">
                                    Generate Test
                                </Link>
                            </li>
                            <li className="breadcrumb-item active">
                                Manage Test{" "}
                            </li>
                        </ol>
                        <h1 className="page-header">Manage Test </h1>
                        <div className="row">
                            <div className="col-xl-12">
                                <Panel className="member-panel">
                                    <PanelHeader>Manage Test</PanelHeader>
                                    <PanelBody className="panel-form">
                                        <form className="form-horizontal form-bordered">
                                            <fieldset>
                                                <div className="form-group row">
                                                    <div className="col-md-4 offset-md-2">
                                                        <button
                                                            onClick={() =>
                                                                window.open(
                                                                    `/user/test/print-blank/${this.state.id}`,
                                                                    "_blank"
                                                                )
                                                            }
                                                            type="button"
                                                            className="btn btn-outline btn-warning"
                                                        >
                                                            <i className="fa fa-print"></i>{" "}
                                                            Print Blank Test
                                                        </button>
                                                    </div>

                                                    <div className="col-md-4 offset-md-2">
                                                        <Link
                                                            to={`/user/test/watch?test=${this.state.id}&num=1&id=${this.state.test_id}&zid=${this.state.zmcq_id}`}
                                                            type="button"
                                                            className="btn btn-outline btn-success"
                                                        >
                                                            <i className="fa fa-check"></i>{" "}
                                                            Take Online Test
                                                        </Link>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </form>
                                    </PanelBody>
                                </Panel>
                            </div>
                        </div>
                    </div>
                </Container>
            </React.Fragment>
        );
    }
}
PrintTest.propTypes = {
    getTestQuestion: PropTypes.func.isRequired,
    getTestQuestionCount: PropTypes.func.isRequired,
    test: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    test: state.test,
});

export default connect(mapStateToProps, {
    getTestQuestion,
    getTestQuestionCount,
})(withRouter(PrintTest));
