import React, { Component } from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";

class WebTable extends Component {
    render() {
        const websiteslist = this.props.websites.map((web) => (
            <tr key={web._id}>
                <td>
                    <i className="fa fa-calendar-alt col-blue ml-3 mr-2"></i>{" "}
                    <Moment format="D MMM YYYY" withTitle>
                        {web.insert_date}
                    </Moment>
                </td>
                <td className="hidden-sm">
                    <Link to={`/admin/subscribers/edit-subscriber/${web._id}`}>
                        {web.user_name}
                    </Link>
                </td>
                <td className="text-nowrap">
                    <span className="label label-success m-r-3">
                        {web.subscription_type}
                    </span>
                </td>
                <td>
                    {(
                        <Moment format="YYYY-MM-DD HH:II:SS" withTitle>
                            {web.insert_date}
                        </Moment>
                    ) >=
                    (
                        <Moment format="YYYY-MM-DD HH:II:SS" withTitle>
                            {new Date()}
                        </Moment>
                    ) ? (
                        <span className="label label-primary m-r-3">
                            <Moment format="D MMM YYYY" withTitle>
                                {web.unsubs_date}
                            </Moment>
                        </span>
                    ) : (
                        <span className="label label-danger m-r-3">
                            <Moment format="D MMM YYYY" withTitle>
                                {web.unsubs_date}
                            </Moment>
                        </span>
                    )}
                </td>
                <td>{web.question_type}</td>
                <td className="text-nowrap">{web.platform}</td>
            </tr>
        ));
        return (
            <div>
                <table className="table">
                    <thead>
                        <tr>
                            <th className="hidden-sm">Joined date</th>
                            <th>User Name</th>
                            <th>Subscription type</th>
                            <th>Subscription Exp</th>
                            <th>Question Type</th>
                            <th>Platform</th>
                        </tr>
                    </thead>
                    <tbody>{websiteslist}</tbody>
                </table>
            </div>
        );
    }
}
export default WebTable;
