import { V1_GET_PROFILE } from "../../actions/v1/types";

const initialState = {
    profile: null,

    loading: true,
    error: {},
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case V1_GET_PROFILE:
            return {
                ...state,
                profile: payload,
                loading: false,
            };

        default:
            return state;
    }
}
