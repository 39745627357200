import React, { Component } from "react";
import { Panel, PanelHeader, PanelBody } from "../common/panel/Panel.jsx";

class TestResultList extends Component {
    render() {
        const blankContent = this.props.testlists.map((list, index) => (
            <React.Fragment key={list._id}>
                {list.zmcq_id.ZANSWER === list.answer_type ? (
                    <tr className="table-green">
                        <td>#{index + 1}</td>
                        <td>{list.test_num}</td>
                        <td>
                            {list.zmcq_id ? (
                                <span>
                                    <strong>{list.zmcq_id.ZBOOKNAME}</strong>
                                    <br />
                                    {list.zmcq_id.ZQUESTION}
                                </span>
                            ) : (
                                <span></span>
                            )}
                        </td>
                        <td>
                            {list.zmcq_id.ZANSWER === "a" ? (
                                <span>a)&nbsp;{list.zmcq_id.ZANSWERONE}</span>
                            ) : list.zmcq_id.ZANSWER === "b" ? (
                                <span>b)&nbsp;{list.zmcq_id.ZANSWERTWO}</span>
                            ) : list.zmcq_id.ZANSWER === "c" ? (
                                <span>c)&nbsp;{list.zmcq_id.ZANSWERTHREE}</span>
                            ) : (
                                <span>d)&nbsp;{list.zmcq_id.ZANSWERFOUR}</span>
                            )}
                        </td>

                        <td>
                            {list.answer_type === "a" ? (
                                <span>a)&nbsp;{list.zmcq_id.ZANSWERONE}</span>
                            ) : list.answer_type === "b" ? (
                                <span>b)&nbsp;{list.zmcq_id.ZANSWERTWO}</span>
                            ) : list.answer_type === "c" ? (
                                <span>c)&nbsp;{list.zmcq_id.ZANSWERTHREE}</span>
                            ) : (
                                <span>d)&nbsp;{list.zmcq_id.ZANSWERFOUR}</span>
                            )}
                        </td>
                    </tr>
                ) : (
                    <tr className="table-red">
                        <td>#{index + 1}</td>
                        <td>{list.test_num}</td>
                        <td>
                            {list.zmcq_id ? (
                                <span>
                                    <strong>{list.zmcq_id.ZBOOKNAME}</strong>
                                    <br />
                                    {list.zmcq_id.ZQUESTION}
                                </span>
                            ) : (
                                <span></span>
                            )}
                        </td>
                        <td>
                            {list.zmcq_id.ZANSWER === "a" ? (
                                <span>a)&nbsp;{list.zmcq_id.ZANSWERONE}</span>
                            ) : list.zmcq_id.ZANSWER === "b" ? (
                                <span>b)&nbsp;{list.zmcq_id.ZANSWERTWO}</span>
                            ) : list.zmcq_id.ZANSWER === "c" ? (
                                <span>c)&nbsp;{list.zmcq_id.ZANSWERTHREE}</span>
                            ) : (
                                <span>d)&nbsp;{list.zmcq_id.ZANSWERFOUR}</span>
                            )}
                        </td>

                        <td>
                            {list.answer_type === "a" ? (
                                <span>a)&nbsp;{list.zmcq_id.ZANSWERONE}</span>
                            ) : list.answer_type === "b" ? (
                                <span>b)&nbsp;{list.zmcq_id.ZANSWERTWO}</span>
                            ) : list.answer_type === "c" ? (
                                <span>c)&nbsp;{list.zmcq_id.ZANSWERTHREE}</span>
                            ) : (
                                <span>d)&nbsp;{list.zmcq_id.ZANSWERFOUR}</span>
                            )}
                        </td>
                    </tr>
                )}
            </React.Fragment>
        ));
        return (
            <Panel className="member-panel">
                <PanelHeader>Result List</PanelHeader>
                <PanelBody>
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th className="text-nowrap">Test Id</th>
                                    <th className="text-nowrap">Question</th>
                                    <th className="text-nowrap">Correct</th>
                                    <th className="text-nowrap">Incorrect</th>
                                </tr>
                            </thead>
                            <tbody>{blankContent}</tbody>
                        </table>
                    </div>
                </PanelBody>
            </Panel>
        );
    }
}
export default TestResultList;
