import axios from "axios";
//import { setAlert } from "./alertActions";
import {
    ADD_PAGE,
    GET_PAGE,
    GET_PAGES,
    PAGE_LOADING,
    DELETE_PAGE,
    GET_ERRORS,
    CLEAR_ERRORS,
    PAGE_NOT_FOUND,
} from "./types";

// Clear errors
export const clearErrors = () => {
    return {
        type: CLEAR_ERRORS,
    };
};

//Add Pages
export const addPages = (pageData) => (dispatch) => {
    axios
        .post("/api/mlp_page/add_pages", pageData)
        .then((res) =>
            dispatch({
                type: ADD_PAGE,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            })
        );
};

// Get current Pages
export const getActivePage = () => (dispatch) => {
    dispatch(setPageLoading());
    axios
        .get("/api/mlp_page/active_page")
        .then((res) =>
            dispatch({
                type: GET_PAGES,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_PAGES,
                payload: null,
            })
        );
};

// Get Draft Pages
export const getDraftPage = () => (dispatch) => {
    dispatch(setPageLoading());
    axios
        .get("/api/mlp_page/draft_page")
        .then((res) =>
            dispatch({
                type: GET_PAGES,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_PAGES,
                payload: null,
            })
        );
};

// Delete blog
export const deletePage = (id) => (dispatch) => {
    // if (window.confirm("Are you sure? This can NOT be undone!")) {
    axios
        .delete(`/api/mlp_page/delete/${id}`)
        .then((res) =>
            dispatch({
                type: DELETE_PAGE,
                payload: id,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            })
        );
    //  }
};

// Get Page by id
export const getPagesbyId = (id) => (dispatch) => {
    dispatch(setPageLoading());
    axios
        .get(`/api/mlp_page/page/${id}`)
        .then((res) =>
            dispatch({
                type: GET_PAGE,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_PAGE,
                payload: null,
            })
        );
};

// Edit Page
export const updatePages = (page_Id, editData) => (dispatch) => {
    axios
        .post(`/api/mlp_page/page_edit/${page_Id}`, editData)
        .then((res) =>
            dispatch({
                type: GET_PAGE,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            })
        );
};

// Edit Page
export const deleteImage = (page_Id) => (dispatch) => {
    axios
        .post(`/api/mlp_page/image_edit/${page_Id}`)
        .then((res) =>
            dispatch({
                type: GET_PAGE,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data,
            })
        );
};

// Contact loading
export const setPageLoading = () => {
    return {
        type: PAGE_LOADING,
    };
};

// Get Page by id
export const getPagesbySlug = (id) => (dispatch) => {
    dispatch(setPageLoading());
    axios
        .get(`/api/mlp_page/page_slug/${id}`)
        .then((res) =>
            dispatch({
                type: GET_PAGE,
                payload: res.data,
            })
        )
        .catch((err) =>
            dispatch({
                type: PAGE_NOT_FOUND,
                payload: null,
            })
        );
};
