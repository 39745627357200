import React, { Component } from "react";
import { getEngineSubCat } from "../../../../actions/questionActions.jsx";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Dropdown } from "react-bootstrap";

class EngineCatNav extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: "1",
        };
    }
    onChangecategoryClick(id) {
        this.props.getEngineSubCat(id);
        //console.log(id)
        // this.setState({ bookId: id });
    }
    render() {
        const bookCatlist = this.props.engine_cats.map((cats) => (
            <span key={cats._id}>
                <Dropdown.Item
                    href={`/question/watch?mode=normal&type=subcat&id=${cats.ZINSUBCATEGORY}&num=1&zid=${cats._id}&zinbookcat=${cats.ZINBOOKCATEGORY}`}
                >
                    {" "}
                    {cats.ZSUBCATEGORYNAME}
                </Dropdown.Item>
            </span>
        ));
        return <div>{bookCatlist}</div>;
    }
}

EngineCatNav.propTypes = {
    getEngineSubCat: PropTypes.func.isRequired,
    question: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    question: state.question,
});

export default connect(mapStateToProps, { getEngineSubCat })(EngineCatNav);
