import { GET_SEARCHS, SEARCH_LOADING } from "../actions/types";

const initialState = {
    searchs: [],
    loading: true,
    error: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SEARCH_LOADING:
            return {
                ...state,
                loading: true,
            };

        case GET_SEARCHS:
            return {
                ...state,
                searchs: action.payload,
                loading: false,
            };

        default:
            return state;
    }
}
