const Menu = [
    {
        path: "/admin/dashboard",
        icon: "fa fa-th",
        title: "Dashboard",
    },
    {
        path: "/admin/subscribers",
        icon: "fa fa-user",
        title: "Subscribers",

        children: [
            {
                path: "/admin/subscribers/details",
                title: "Subscribers Details",
            },
            { path: "/admin/subscribers/invoice", title: "Invoices" },
        ],
    },

    {
        path: "/admin/comments",
        icon: "fa fa-file",
        title: "Comments",

        children: [{ path: "/admin/comments", title: "Comments" }],
    },

    {
        path: "/admin/pages",
        icon: "fa fa-user-secret",
        title: "Pages",

        children: [
            { path: "/admin/pages/add-pages", title: "Add Pages" },
            { path: "/admin/pages/draft-pages", title: "Draft Pages" },
            { path: "/admin/pages/published-pages", title: "Published Pages" },
        ],
    },

    {
        path: "/admin/coupan-code",
        icon: "fa fa-share",
        title: "Coupan Codes",

        children: [
            {
                path: "/admin/coupan-code/generate-codes",
                title: "Generate Codes",
            },
            { path: "/admin/coupan-code/used-codes", title: "Used Codes" },
            { path: "/admin/coupan-code/sent-mail", title: "Sent mail" },
        ],
    },

    {
        path: "/admin/pricing",
        icon: "fa fa-snowflake",
        title: "Pricing",

        children: [{ path: "/admin/pricing", title: "Pricing" }],
    },

    // {
    //     path: "/admin/forum",
    //     icon: "fa fa-align-left",
    //     title: "Forum",

    //     children: [
    //         { path: "/admin/forum/add-forum", title: "Add Forum" },
    //         { path: "/admin/forum/forum-List", title: "Forum List" },
    //     ],
    // },
    {
        path: "/admin/account",
        icon: "fa fa-tasks",
        title: "Account",
        children: [
            { path: "/admin/account/profile", title: "Profile" },
            { path: "/admin/account/settings", title: "Settings" },
            {
                path: "/admin/account/billing-information",
                title: "Billing Information",
            },
        ],
    },
];

export default Menu;
