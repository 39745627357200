import { PAYMENT_INPROGRESS } from "../actions/types";

const initialState = {
    loading: false,
    error: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case PAYMENT_INPROGRESS:
            return {
                ...state,
                loading: action.payload,
            };
        default:
            return state;
    }
}
