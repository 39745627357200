import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import "../common.jsx";
import {
    adminlogout,
    getadminCurrentProfile,
} from "../../../../actions/adminActions";
import isEmpty from "../../../../validation/is-empty";
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";

class DropdownProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            first_name: "",
            last_name: "",
        };

        this.toggle = this.toggle.bind(this);
        this.state = {
            dropdownOpen: false,
        };
    }

    componentDidMount() {
        this.props.getadminCurrentProfile();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({ errors: nextProps.errors });
        }

        if (nextProps.admin_profile.admin_profile) {
            const admin_profile = nextProps.admin_profile.admin_profile;
            // console.log(admin_profile);
            // If profile field doesnt exist, make empty string
            admin_profile.first_name = !isEmpty(admin_profile.first_name)
                ? admin_profile.first_name
                : "";
            admin_profile.last_name = !isEmpty(admin_profile.last_name)
                ? admin_profile.last_name
                : "";

            admin_profile.image = !isEmpty(admin_profile.image)
                ? admin_profile.image
                : "";

            // Set component fields state
            this.setState({
                first_name: admin_profile.first_name,
                last_name: admin_profile.last_name,

                imagePreviewUrl: admin_profile.image,
            });
        }
    }

    toggle() {
        this.setState((prevState) => ({
            dropdownOpen: !prevState.dropdownOpen,
        }));
    }
    onLogoutClick(e) {
        e.preventDefault();

        this.props.adminlogout();
    }
    render() {
        const { isAuthenticated } = this.props.admin;

        const authLinks = (
            <DropdownItem onClick={this.onLogoutClick.bind(this)}>
                Log Out
            </DropdownItem>
        );

        const gustLinks = <DropdownItem>Log in</DropdownItem>;
        return (
            <Dropdown
                isOpen={this.state.dropdownOpen}
                toggle={this.toggle}
                className="dropdown navbar-user"
                tag="li"
            >
                <DropdownToggle tag="a">
                    <img
                        src={`https://seatrials-bucket.s3.us-east-2.amazonaws.com/admin/${this.state.imagePreviewUrl}`}
                        alt=""
                    />
                    <span className="d-none d-md-inline">
                        {this.state.first_name} {this.state.last_name}
                    </span>
                    <b className="caret"></b>{" "}
                </DropdownToggle>
                <DropdownMenu
                    className="dropdown-menu dropdown-menu-right"
                    tag="ul"
                >
                    <Link to="/admin/account/profile">
                        <DropdownItem>Edit Profile</DropdownItem>
                    </Link>

                    <Link to="/admin/account/settings">
                        {" "}
                        <DropdownItem>Setting</DropdownItem>
                    </Link>
                    <div className="dropdown-divider"></div>
                    {isAuthenticated ? authLinks : gustLinks}
                </DropdownMenu>
            </Dropdown>
        );
    }
}

DropdownProfile.propTypes = {
    adminlogout: PropTypes.func.isRequired,
    admin: PropTypes.object.isRequired,
    getadminCurrentProfile: PropTypes.func.isRequired,
    admin_profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    admin: state.admin,
    admin_profile: state.admin_profile,
});

export default connect(mapStateToProps, {
    adminlogout,
    getadminCurrentProfile,
})(DropdownProfile);
