import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "../common/common.jsx";
import {
    getdeck,
    getDeckCount,
    getEngine,
    getEngineCount,
} from "../../../actions/directoryActions.jsx";
import DeckList from "./DeckList.jsx";
import EngineList from "./EngineList.jsx";
import Spinner from "../common/textfield/Spinner";

class Directory extends Component {
    constructor() {
        super();
        this.state = {
            deck_count: "",
            engine_count: "",
        };
    }

    componentDidMount() {
        this.props.getdeck();
        this.props.getDeckCount();
        this.props.getEngine();
        this.props.getEngineCount();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.directory.deck_count) {
            this.setState({ deck_count: nextProps.directory.deck_count });
        }
        if (nextProps.directory.engine_count) {
            this.setState({ engine_count: nextProps.directory.engine_count });
        }
    }
    render() {
        const { decks, engines, loading } = this.props.directory;

        let deckContent;

        if (decks === null || loading) {
            deckContent = <Spinner />;
        } else {
            //console.log(blogcats);
            if (decks.length > 0) {
                deckContent = <DeckList decks={decks} />;
            }
        }

        let engineContent;
        if (engines === null || loading) {
            engineContent = <Spinner />;
        } else {
            //console.log(blogcats);
            if (engines.length > 0) {
                engineContent = <EngineList engines={engines} />;
            }
        }
        return (
            <React.Fragment>
                <div class="container-fluid directory-page">
                    <div class="row">
                        <h1>Deck</h1>
                        <br />({this.state.deck_count}){deckContent}
                    </div>
                    <div className="row">
                        <h1>Engine</h1>
                        <br />({this.state.engine_count}){engineContent}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

Directory.propTypes = {
    getdeck: PropTypes.func.isRequired,
    getDeckCount: PropTypes.func.isRequired,
    getEngine: PropTypes.func.isRequired,
    getEngineCount: PropTypes.func.isRequired,
    directory: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    directory: state.directory,
});

export default connect(mapStateToProps, {
    getdeck,
    getDeckCount,
    getEngine,
    getEngineCount,
})(Directory);
