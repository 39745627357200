import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import queryString from "qs";
import TextFieldArea from "../common/textfield/TextFieldArea";
import { userPassverify } from "../../../actions/userActions.jsx";
import ReactNotification from "react-notifications-component";
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
//import  logo from "../../.././assets/user/images/logo.png";

class ChangePassword extends Component {
    constructor() {
        super();
        this.state = {
            email: "",
            token: "",
            password: "",
            password2: "",
            errors: {},
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        if (this.props.auth.isAuthenticated) {
            this.props.history.push("/admin/dashboard");
        }
        const queryStringValues = queryString.parse(
            this.props.location.search.slice(1)
        );
        if (queryStringValues) {
            // User is redirected by us after registration.
            this.setState({
                email: queryStringValues.email,
                token: queryStringValues.token,
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.auth.isAuthenticated) {
            this.props.history.push("/dashboard");
        }

        if (nextProps.errors) {
            this.setState({ errors: nextProps.errors });
            this.state.errors.token &&
                store.addNotification({
                    title: "Success",
                    message: this.state.errors.token,
                    type: "danger",
                    insert: "top",
                    container: "top-center",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: { duration: 3000 },
                    dismissable: { click: true },
                    // content: notificationContent
                });
            this.state.errors.token1 &&
                store.addNotification({
                    title: "Success",
                    message: this.state.errors.token1,
                    type: "danger",
                    insert: "top",
                    container: "top-center",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: { duration: 3000 },
                    dismissable: { click: true },
                    // content: notificationContent
                });
        }
    }
    onSubmit(e) {
        e.preventDefault();

        const forgotData = {
            email: this.state.email,
            token: this.state.token,
            password: this.state.password,
            password2: this.state.password2,
        };
        //console.log(adminloginData);
        this.props.userPassverify(forgotData, this.props.history);
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }
    render() {
        const { errors } = this.state;
        return (
            <React.Fragment>
                <ReactNotification />
                <div className="loginbg">
                    <div className="container">
                        <div className="row">
                            <form
                                className="form-signin"
                                id="login_form"
                                onSubmit={this.onSubmit}
                            >
                                <div className="text-center mb-4">
                                    <Link to="/">
                                        <img
                                            src="https://seatrials-bucket.s3.us-east-2.amazonaws.com/main/logo.png"
                                            alt="Marinerlicenseprep"
                                            className="login_logo"
                                            width="400"
                                        />
                                    </Link>
                                </div>

                                <div className="form-label-group">
                                    <div className="row">
                                        <TextFieldArea
                                            label="Password"
                                            placeholder="Password"
                                            name="password"
                                            type="password"
                                            value={this.state.password}
                                            onChange={this.onChange}
                                            error={errors.password}
                                        />

                                        <TextFieldArea
                                            label="Confirm Password"
                                            placeholder="Password"
                                            name="password2"
                                            type="password"
                                            value={this.state.password2}
                                            onChange={this.onChange}
                                            error={errors.password2}
                                        />
                                        <input
                                            placeholder="Token"
                                            name="token"
                                            type="hidden"
                                            value={this.state.token}
                                            onChange={this.onChange}
                                        />
                                        <input
                                            placeholder="Email Address"
                                            name="email"
                                            type="hidden"
                                            value={this.state.email}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                </div>
                                <div className="form-label-group">
                                    <div className="row">
                                        <div className="col-sm-12 col-xs-12">
                                            <button
                                                className="btn btn-lg btn-primary btn-block"
                                                type="submit"
                                            >
                                                LOGIN
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-label-group">
                                    <div className="row">
                                        <div className="col-sm-6 col-xs-12">
                                            <p className="text-muted text-center">
                                                <a href="/forgot-password">
                                                    <i className="fa fa-lock"></i>{" "}
                                                    Forgot password?{" "}
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <p className="mt-4 mb-3 text-muted text-center">
                                    Don't have account ?{" "}
                                    <Link to="/register">Sign Up</Link>.
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

ChangePassword.propTypes = {
    userPassverify: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
});

export default connect(mapStateToProps, { userPassverify })(ChangePassword);
