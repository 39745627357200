import React, { Component } from "react";
import ReactImageFallback from "react-image-fallback";

class BlankTestList extends Component {
    render() {
        const blankContent = this.props.testlists.map((list, index) => (
            <React.Fragment key={list._id}>
                <br />
                <tr>
                    <td className="print-font">
                        {index + 1})&nbsp;
                        {list.zmcq_id ? (
                            <span>{list.zmcq_id.ZQUESTION}</span>
                        ) : (
                            <span></span>
                        )}
                    </td>
                </tr>
                <tr>
                    <td className="print-font">
                        a)&nbsp;{" "}
                        {list.zmcq_id ? (
                            <span>{list.zmcq_id.ZANSWERONE}</span>
                        ) : (
                            <span></span>
                        )}
                    </td>
                </tr>

                <tr>
                    <td className="print-font">
                        b)&nbsp;{" "}
                        {list.zmcq_id ? (
                            <span>{list.zmcq_id.ZANSWERTWO}</span>
                        ) : (
                            <span></span>
                        )}
                    </td>
                </tr>

                <tr>
                    <td className="print-font">
                        c)&nbsp;{" "}
                        {list.zmcq_id ? (
                            <span>{list.zmcq_id.ZANSWERTHREE}</span>
                        ) : (
                            <span></span>
                        )}
                    </td>
                </tr>

                <tr>
                    <td className="print-font">
                        d)&nbsp;{" "}
                        {list.zmcq_id ? (
                            <span>{list.zmcq_id.ZANSWERFOUR}</span>
                        ) : (
                            <span></span>
                        )}
                    </td>
                </tr>

                <tr>
                    <td>
                        {" "}
                        {list.zmcq_id.ZIMAGE ? (
                            <span>
                                {" "}
                                <ReactImageFallback
                                    src={`https://seatrials-bucket.s3.us-east-2.amazonaws.com/images/${list.zmcq_id.ZIMAGE}.jpg`}
                                    fallbackImage={`https://seatrials-bucket.s3.us-east-2.amazonaws.com/images/${list.zmcq_id.ZIMAGE}.png`}
                                    initialImage="loader.gif"
                                    alt={list.zmcq_id.ZIMAGE}
                                    id="print_image"
                                />
                            </span>
                        ) : (
                            <span></span>
                        )}
                    </td>
                </tr>
            </React.Fragment>
        ));
        return (
            <React.Fragment>
                <table className="pad-top">{blankContent}</table>
            </React.Fragment>
        );
    }
}
export default BlankTestList;
