import React, { Component } from "react";
import ReactTable from "react-table";
//import { Link } from "react-router-dom";
import "react-table/react-table.css";
import Moment from "react-moment";

class InvoiceTable extends Component {
    constructor(props) {
        super(props);

        //this.data = makeData();
        this.defaultSorted = [
            {
                id: "_id",
                desc: false,
            },
        ];
        this.tableColumns = [
            {
                Header: "Id",
                id: "row",
                maxWidth: 50,
                filterable: false,
                Cell: (row) => {
                    return <div>{row.index + 1}</div>;
                },
            },

            {
                Header: "Subscription Plan",
                accessor: "subscription_type",
                Cell: (row) => (
                    <span>
                        {row.value === "Trial" ? (
                            <span className="label label-warning m-r-3">
                                {row.value}
                            </span>
                        ) : (
                            <span className="label label-lime m-r-3">
                                {row.value}
                            </span>
                        )}
                    </span>
                ),
            },

            {
                Header: "Subscription On",
                accessor: "subs_date",
                filterable: false,
                Cell: (row) => (
                    <div>
                        <i className="fa fa-calendar-alt col-blue ml-3 mr-2"></i>
                        <Moment format="D MMM YYYY" withTitle>
                            {row.value}
                        </Moment>
                    </div>
                ),
            },

            {
                Header: "Expiry Till",
                accessor: "unsubs_date",
                filterable: false,
                Cell: (row) => (
                    <div>
                        <i className="fa fa-calendar-alt col-blue ml-3 mr-2"></i>
                        <Moment format="D MMM YYYY" withTitle>
                            {row.value}
                        </Moment>
                    </div>
                ),
            },

            {
                Header: "Platform",
                accessor: "platform",
                Cell: (row) => (
                    <span>
                        {row.value ? (
                            row.value
                        ) : (
                            <span className="label label-default m-r-3">
                                Undefined
                            </span>
                        )}
                    </span>
                ),
            },

            {
                Header: "Actions",
                accessor: "_id",
                Cell: (row) => (
                    <div>
                        <button
                            onClick={() =>
                                window.open(
                                    `/user/account/view-invoice/${row.value}`,
                                    "_blank"
                                )
                            }
                            className="btn btn-primary btn-sm m-r-5 "
                        >
                            <i className="fas fa-sm fa-fw m-r-2 fa-print"> </i>{" "}
                            Invoice
                        </button>
                    </div>
                ),
            },
        ];
    }

    render() {
        const data = this.props.invoices;
        return (
            <div>
                <ReactTable
                    filterable
                    useSortBy
                    data={data}
                    columns={this.tableColumns}
                    defaultPageSize={10}
                    defaultSorted={this.defaultSorted}
                    className="-highlight"
                />
            </div>
        );
    }
}
export default InvoiceTable;
