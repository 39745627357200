import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import TextFieldArea from "./../../common/textfield/TextFieldArea";
import { postNum } from "../../../../actions/testActions.jsx";
import isEmpty from "../../../../validation/is-empty";

class ChangeModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalDialog: this.props.modalDialog,
            number: "",
            test: this.props.test,
            test_id: this.props.test_id,
            num: "",
            zinbookcat: this.props.zinbookcat,
            mode: this.props.mode,
            errors: {},
        };
        this.toggleModal = this.toggleModal.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    toggleModal(name) {
        switch (name) {
            case "modalDialog":
                this.setState({ modalDialog: !this.state.modalDialog });
                break;
            default:
                break;
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({ errors: nextProps.errors });
        }

        if (nextProps.question.num) {
            const nums = nextProps.question.num;

            nums.zmcq_id = !isEmpty(nums.zmcq_id) ? nums.zmcq_id : "";
            // console.log(nums.zmcq_id)

            let path = `/user/test/watch?test=${this.state.test}&num=${this.state.number}&id=${this.state.test_id}&zid=${nums.zmcq_id}&zinbookcat=${this.state.zinbookcat}`;
            this.props.history.push(path);
            window.location.reload();
        }
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }
    onSubmit(e) {
        e.preventDefault();

        const numtData = {
            test: this.state.test,
            number: this.state.number,
        };
        this.props.postNum(numtData, this.props.history);
        this.setState({ modalDialog: false });
    }

    render() {
        const { errors } = this.state;

        return (
            <React.Fragment>
                <div className="member-modal">
                    <Modal
                        isOpen={this.state.modalDialog}
                        toggle={() => this.toggleModal("modalDialog")}
                    >
                        <ModalHeader
                            toggle={() => this.toggleModal("modalDialog")}
                        >
                            Get to Number
                        </ModalHeader>
                        <form
                            className="form-horizontal form-bordered"
                            onSubmit={this.onSubmit}
                        >
                            <ModalBody>
                                <TextFieldArea
                                    label="Enter Number"
                                    placeholder=""
                                    name="number"
                                    striks="*"
                                    type="number"
                                    value={this.state.number}
                                    onChange={this.onChange}
                                    error={errors.number}
                                />
                            </ModalBody>
                            <ModalFooter>
                                <button
                                    className="btn btn-white"
                                    onClick={() =>
                                        this.toggleModal("modalDialog")
                                    }
                                >
                                    Close
                                </button>
                                <button
                                    type="submit"
                                    className="btn btn-success"
                                >
                                    Submit
                                </button>
                            </ModalFooter>
                        </form>
                    </Modal>
                </div>
            </React.Fragment>
        );
    }
}

ChangeModal.propTypes = {
    postNum: PropTypes.func.isRequired,
    question: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    question: state.question,
    errors: state.errors,
});

export default connect(mapStateToProps, { postNum })(withRouter(ChangeModal));
