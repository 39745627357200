import React, { Component } from "react";
import ReactTable from "react-table";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Panel, PanelHeader } from "../common/panel/Panel.jsx";
import AlertBox from "../common/textfield/AlertBox";
import "react-table/react-table.css";
import Moment from "react-moment";
import { deleteTest } from "../../../actions/testActions.jsx";
import SweetAlert from "react-bootstrap-sweetalert";

class ManageTestList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sweetAlertError: false,
            sweetAlertPrimary: false,
        };

        //this.data = makeData();
        this.defaultSorted = [
            {
                id: "_id",
                desc: false,
            },
        ];
        this.tableColumns = [
            {
                Header: "Name",
                accessor: "test_name",
                maxWidth: 80,
            },
            {
                Header: "Topic",
                accessor: "topic",
                Cell: (row) => <span>{row.original.topic}</span>,
            },
            {
                Header: " Questions",
                accessor: "question_count",
                maxWidth: 100,
            },
            {
                Header: "Date",
                accessor: "insert_date",
                filterable: false,
                Cell: (row) => (
                    <div>
                        <i className="fa fa-calendar-alt col-blue mr-2"></i>
                        <Moment format="D MMM YYYY" withTitle>
                            {row.value}
                        </Moment>
                    </div>
                ),
            },
            {
                Header: "Resume Test",
                accessor: "test_num",
                filterable: false,
                sortable: false,
                Cell: (row) => (
                    <div>
                        <button
                            onClick={() =>
                                window.open(
                                    `/user/test/result-model/${row.value}`,
                                    "_blank"
                                )
                            }
                            className="btn btn-indigo btn-sm m-r-5 "
                        >
                            <i className="fas fa-sm fa-fw m-r-2 fa-list"> </i>{" "}
                            Resume
                        </button>
                    </div>
                ),
            },
            {
                Header: " Results",
                accessor: "test_num",
                filterable: false,
                sortable: false,
                Cell: (row) => (
                    <div>
                        <button
                            onClick={() =>
                                window.open(
                                    `/user/test/print-result/${row.value}`,
                                    "_blank"
                                )
                            }
                            className="btn btn-lime btn-sm m-r-5 "
                        >
                            <i className="fas fa-sm fa-fw m-r-2 fa-print"> </i>{" "}
                            Result
                        </button>
                    </div>
                ),
            },

            {
                Header: "Practice Test",
                accessor: "test_num",
                filterable: false,
                sortable: false,
                Cell: (row) => (
                    <div>
                        <button
                            onClick={() =>
                                window.open(
                                    `/user/test/print-blank/${row.value}`,
                                    "_blank"
                                )
                            }
                            className="btn btn-warning btn-sm m-r-5 "
                        >
                            <i className="fas fa-sm fa-fw m-r-2 fa-print"> </i>{" "}
                            Print Blank Test
                        </button>
                    </div>
                ),
            },
            {
                Header: "Answer Key",
                accessor: "test_num",
                filterable: false,
                sortable: false,
                Cell: (row) => (
                    <div>
                        <button
                            onClick={() =>
                                window.open(
                                    `/user/test/answer-key/${row.value}`,
                                    "_blank"
                                )
                            }
                            type="button"
                            className="btn btn-purple btn-sm m-r-5 "
                        >
                            <i className="fas fa-sm fa-fw m-r-2 fa-print"> </i>{" "}
                            Print Ans Key
                        </button>
                    </div>
                ),
            },

            {
                Header: "Delete",
                accessor: "_id",
                filterable: false,
                sortable: false,
                Cell: (row) => (
                    <div>
                        <button
                            onClick={() =>
                                this.toggleSweetAlert("error", row.value)
                            }
                            className="btn btn-danger btn-sm m-r-5 "
                        >
                            <i className="fas fa-sm fa-fw m-r-2 fa-trash"></i>{" "}
                            Delete
                        </button>
                    </div>
                ),
            },
        ];
        this.toggleSweetAlert = this.toggleSweetAlert.bind(this);
    }
    toggleSweetAlert(name, id) {
        switch (name) {
            case "primary":
                this.setState({
                    sweetAlertPrimary: !this.state.sweetAlertPrimary,
                });
                break;
            case "error":
                this.setState({
                    sweetAlertError: !this.state.sweetAlertError,
                    id: id,
                });
                break;
            default:
                break;
        }
    }

    onDeleteClick(id) {
        this.props.deleteTest(id);
        this.setState({ sweetAlertError: !this.state.sweetAlertError });
    }

    render() {
        const data = this.props.tests;
        const { alertpage } = this.state;
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-12">
                        {this.state.sweetAlertError && (
                            <SweetAlert
                                danger
                                showCancel
                                confirmBtnText="Yes"
                                confirmBtnBsStyle="danger"
                                cancelBtnBsStyle="default"
                                title="Delete Test?"
                                onConfirm={this.onDeleteClick.bind(
                                    this,
                                    this.state.id
                                )}
                                onCancel={() => this.toggleSweetAlert("error")}
                            ></SweetAlert>
                        )}
                        <Panel className="member-panel">
                            <PanelHeader>List of Tests</PanelHeader>
                            {alertpage && (
                                <AlertBox
                                    content="Pages deleted successfully"
                                    classes={"green"}
                                    bold="Success!"
                                    visible={this.state.visible}
                                    tongle={this.onDismiss}
                                />
                            )}
                            <ReactTable
                                filterable
                                useSortBy
                                data={data}
                                columns={this.tableColumns}
                                defaultPageSize={10}
                                defaultSorted={this.defaultSorted}
                                className="-highlight"
                            />
                        </Panel>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

ManageTestList.propTypes = {
    deleteTest: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
    errors: state.errors,
});

export default connect(mapStateToProps, { deleteTest })(
    withRouter(ManageTestList)
);
