import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
//import Container from "../content/Container.jsx";
import { getInvoiceNew } from "../../../actions/invoiceActions.jsx";
import { getCurrentBilling } from "../../../actions/adminActions.jsx";
import isEmpty from "../../../validation/is-empty";
import Moment from "react-moment";

class Invoice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            user_name: "",
            subscription_type: "",
            mode: "",
            status: "",
        };
        this.print = () => {
            window.print();
        };
    }

    componentDidMount() {
        if (this.props.match.params.Id) {
            this.props.getInvoiceNew(this.props.match.params.Id);
        }
        this.props.getCurrentBilling();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.billing.billing) {
            const billing = nextProps.billing.billing;
            billing.company_name = !isEmpty(billing.company_name)
                ? billing.company_name
                : "";
            billing.address = !isEmpty(billing.address) ? billing.address : "";
            billing.phone_no = !isEmpty(billing.phone_no)
                ? billing.phone_no
                : "";
            billing.email = !isEmpty(billing.email) ? billing.email : "";
            billing.website = !isEmpty(billing.website) ? billing.website : "";

            // Set component fields state
            this.setState({
                company_name: billing.company_name,
                address: billing.address,
                phone_no: billing.phone_no,
                email: billing.email,
                website: billing.website,
            });
        }

        if (nextProps.invoice.invoice) {
            const invoice = nextProps.invoice.invoice;
            invoice.user_id.user_name = !isEmpty(invoice.user_id.user_name)
                ? invoice.user_id.user_name
                : "";
            invoice.subs_date = !isEmpty(invoice.subs_date)
                ? invoice.subs_date
                : "";
            invoice._id = !isEmpty(invoice._id) ? invoice._id : "";
            invoice.subscription_type = !isEmpty(invoice.subscription_type)
                ? invoice.subscription_type
                : "";
            invoice.amount = !isEmpty(invoice.amount) ? invoice.amount : "";

            // Set component fields state
            this.setState({
                user_name: invoice.user_id.user_name,
                subs_date: invoice.subs_date,
                id: invoice._id,
                subscription_type: invoice.subscription_type,
                amount: invoice.amount,
            });
        }
    }
    render() {
        return (
            <React.Fragment>
                <div className="container">
                    <div className="invoice">
                        <div className="invoice-company text-inverse f-w-600">
                            <span className="pull-right hidden-print">
                                <button
                                    onClick={this.print}
                                    className="btn btn-sm btn-white m-b-10 p-l-5"
                                >
                                    <i className="fa fa-print t-plus-1 fa-fw fa-lg"></i>{" "}
                                    Print
                                </button>
                            </span>
                            {this.state.company_name}
                        </div>
                        <div className="invoice-header">
                            <div className="invoice-from">
                                <small>from</small>
                                <address className="m-t-5 m-b-5">
                                    <strong className="text-inverse">
                                        {this.state.company_name}
                                    </strong>
                                    <br />
                                    {this.state.website}
                                    <br />
                                    Email: {this.state.email}
                                    <br />
                                </address>
                            </div>
                            <div className="invoice-to">
                                <small>to</small>
                                <address className="m-t-5 m-b-5">
                                    <strong className="text-inverse">
                                        {this.state.user_name}
                                    </strong>
                                </address>
                            </div>
                            <div className="invoice-date">
                                <small>Invoice</small>
                                <div className="date text-inverse m-t-5">
                                    <Moment format="D MMM YYYY" withTitle>
                                        {this.state.subs_date}
                                    </Moment>
                                </div>
                                <div className="invoice-detail">
                                    #{this.state.id}
                                    <br />
                                    Subscription Invoice
                                </div>
                            </div>
                        </div>
                        <div className="invoice-content">
                            <div className="table-responsive">
                                <table className="table table-invoice">
                                    <thead>
                                        <tr>
                                            <th>Subscription type</th>
                                            <th
                                                className="text-center"
                                                width="10%"
                                            >
                                                Rate
                                            </th>
                                            <th
                                                className="text-center"
                                                width="10%"
                                            >
                                                Quantity
                                            </th>
                                            <th
                                                className="text-right"
                                                width="20%"
                                            >
                                                LINE TOTAL
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <span className="text-inverse">
                                                    {
                                                        this.state
                                                            .subscription_type
                                                    }
                                                </span>
                                            </td>
                                            <td className="text-center">
                                                ${this.state.amount}
                                            </td>
                                            <td className="text-center">1</td>
                                            <td className="text-right">
                                                ${this.state.amount}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="invoice-price">
                                <div className="invoice-price-left">
                                    <div className="invoice-price-row">
                                        <div className="sub-price">
                                            <small>SUBTOTAL</small>
                                            <span className="text-inverse">
                                                ${this.state.amount}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="invoice-price-right">
                                    <small>TOTAL</small>{" "}
                                    <span className="f-w-600">
                                        ${this.state.amount}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="invoice-footer">
                            <p className="text-center m-b-5 f-w-600">
                                THANK YOU FOR YOUR BUSINESS
                            </p>
                            <p className="text-center">
                                <span className="m-r-10">
                                    <i className="fa fa-fw fa-lg fa-globe"></i>{" "}
                                    {this.state.website}
                                </span>

                                <span className="m-r-10">
                                    <i className="fa fa-fw fa-lg fa-envelope"></i>{" "}
                                    {this.state.email}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

Invoice.propTypes = {
    getInvoiceNew: PropTypes.func.isRequired,
    getCurrentBilling: PropTypes.func.isRequired,
    billing: PropTypes.object.isRequired,
    invoice: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    billing: state.billing,
    invoice: state.invoice,
    errors: state.errors,
});

export default connect(mapStateToProps, { getInvoiceNew, getCurrentBilling })(
    withRouter(Invoice)
);
