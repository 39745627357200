import {
    GET_ADMIN_PROFILE,
    ADMIN_PROFILE_ERROR,
    ADMIN_CLEAR_PROFILE,
    UPDATE_ADMIN_PROFILE,
    GET_ADMIN_PROFILES,
} from "../actions/types";

const initialState = {
    admin_profile: null,
    admin_profiles: [],
    loading: true,
    error: {},
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_ADMIN_PROFILE:
        case UPDATE_ADMIN_PROFILE:
            return {
                ...state,
                admin_profile: payload,
                loading: false,
            };
        case GET_ADMIN_PROFILES:
            return {
                ...state,
                admin_profiles: payload,
                loading: false,
            };
        case ADMIN_PROFILE_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            };
        case ADMIN_CLEAR_PROFILE:
            return {
                ...state,
                admin_profile: null,
                repos: [],
                loading: false,
            };

        default:
            return state;
    }
}
