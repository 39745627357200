import React, { Component } from "react";
import ReactTable from "react-table";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Panel, PanelHeader } from "../common/panel/Panel.jsx";
import "react-table/react-table.css";
import { deleteComment } from "../../../actions/commentActions.jsx";
import SweetAlert from "react-bootstrap-sweetalert";

class CommentList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sweetAlertError: false,
        };

        //this.data = makeData();
        this.defaultSorted = [
            {
                id: "_id",
                desc: false,
            },
        ];
        this.tableColumns = [
            {
                Header: "Id",
                id: "row",
                maxWidth: 50,
                filterable: false,
                Cell: (row) => {
                    return <div>{row.index + 1}</div>;
                },
            },

            {
                Header: "Question",
                accessor: "zmcq_id",
                style: { whiteSpace: "unset" },
                Cell: (row) => (
                    <span>
                        {row.original.zmcq_id ? (
                            <span>
                                {row.original.zmcq_id.ZQUESTION}
                                <br />
                                <span>
                                    <span>
                                        {" "}
                                        A) {row.original.zmcq_id.ZANSWERONE}
                                    </span>{" "}
                                    <br />
                                    <span>
                                        {" "}
                                        B) {row.original.zmcq_id.ZANSWERTWO}
                                    </span>{" "}
                                    <br />
                                    <span>
                                        {" "}
                                        C) {row.original.zmcq_id.ZANSWERTHREE}
                                    </span>{" "}
                                    <br />
                                    <span>
                                        {" "}
                                        D) {row.original.zmcq_id.ZANSWERFOUR}
                                    </span>
                                </span>
                            </span>
                        ) : (
                            <span className="label label-default m-r-3">
                                Undefine
                            </span>
                        )}
                    </span>
                ),
            },
            {
                Header: "Comment",
                accessor: "comments",
                maxWidth: 400,
                style: { whiteSpace: "unset" },
                Cell: (row) => (
                    <span dangerouslySetInnerHTML={{ __html: row.value }} />
                ),
            },
            {
                Header: "Actions",
                accessor: "_id",
                filterable: false,
                sortable: false,
                maxWidth: 200,
                Cell: (row) => (
                    <div className="com-button">
                        <Link
                            to={`/user/manage-comment/edit-comment/${row.value}`}
                            className="btn btn-outline btn-info btn-sm m-r-5 "
                        >
                            <i className="fas fa-sm  m-r-2 fa-edit"> </i> Edit
                        </Link>
                        <button
                            onClick={() =>
                                this.toggleSweetAlert("error", row.value)
                            }
                            className="btn btn-outline btn-danger btn-sm m-r-5 "
                        >
                            <i className="fas fa-sm  m-r-2 fa-trash"></i> Delete
                        </button>
                    </div>
                ),
            },
        ];

        this.toggleSweetAlert = this.toggleSweetAlert.bind(this);
    }
    toggleSweetAlert(name, id) {
        switch (name) {
            case "error":
                this.setState({
                    sweetAlertError: !this.state.sweetAlertError,
                    id: id,
                });
                break;
            default:
                break;
        }
    }

    onDeleteClick(id) {
        this.props.deleteComment(id);
        this.setState({ sweetAlertError: !this.state.sweetAlertError });
    }

    render() {
        const data = this.props.commentlists;

        return (
            <React.Fragment>
                <div className="row">
                    {this.state.sweetAlertError && (
                        <SweetAlert
                            danger
                            showCancel
                            confirmBtnText="Yes"
                            confirmBtnBsStyle="danger"
                            cancelBtnBsStyle="default"
                            title="Delete Comment?"
                            onConfirm={this.onDeleteClick.bind(
                                this,
                                this.state.id
                            )}
                            onCancel={() => this.toggleSweetAlert("error")}
                        ></SweetAlert>
                    )}
                    <div className="col-12">
                        <Panel className="member-panel">
                            <PanelHeader>Comment List Table</PanelHeader>

                            <ReactTable
                                filterable
                                useSortBy
                                data={data}
                                columns={this.tableColumns}
                                defaultPageSize={10}
                                defaultSorted={this.defaultSorted}
                                className="-highlight"
                            />
                        </Panel>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

CommentList.propTypes = {
    deleteComment: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
    errors: state.errors,
});

export default connect(mapStateToProps, { deleteComment })(
    withRouter(CommentList)
);
