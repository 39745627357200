import React, { Component } from "react";
import { Link } from "react-router-dom";

class TopContent extends Component {
    render() {
        const booklist = this.props.pages.map((books) => (
            <li key={books._id}>
                <Link to={`/${books.slug}`}>{books.page_title}</Link>
            </li>
        ));
        return booklist;
    }
}
export default TopContent;
