import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import queryString from "qs";
import { emailver } from "../../../actions/userActions.jsx";
import TextFieldArea from "../common/textfield/TextFieldArea";
//import  logo from "../../.././assets/user/images/logo.png";

class EmailVerify extends Component {
    constructor() {
        super();
        this.state = {
            email: "",
            token: "",
            errors: {},
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        const queryStringValues = queryString.parse(
            this.props.location.search.slice(1)
        );

        if (queryStringValues.email === "") {
            this.props.history.push("/login");
            // User is redirected by us after registration.
            //  this.props.emailver(queryStringValues.type,queryStringValues.id,queryStringValues.num,queryStringValues.mode);
        }
        if (queryStringValues) {
            // User is redirected by us after registration.
            this.setState({
                email: queryStringValues.email,
                token: queryStringValues.token,
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.auth.isAuthenticated1) {
            // console.log("Hii")
            // this.props.history.push("/dashboard");
        }
    }

    onSubmit(e) {
        e.preventDefault();

        const forgotData = {
            email: this.state.email,
            token: this.state.token,
        };
        //console.log(adminloginData);
        this.props.emailver(forgotData, this.props.history);
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    render() {
        const { errors } = this.state;
        return (
            <React.Fragment>
                <div className="loginbg">
                    <div className="container">
                        <div className="row">
                            <form
                                className="form-signin"
                                id="login_form"
                                onSubmit={this.onSubmit}
                            >
                                <div className="text-center mb-4">
                                    <Link to="/">
                                        <img
                                            src="https://seatrials-bucket.s3.us-east-2.amazonaws.com/main/logo.png"
                                            alt="Marinerlicenseprep"
                                            className="login_logo"
                                            width="400"
                                        />
                                    </Link>
                                </div>

                                <div className="form-label-group">
                                    <div className="row">
                                        <TextFieldArea
                                            label="Email"
                                            placeholder="email"
                                            name="email"
                                            type="email"
                                            value={this.state.email}
                                            onChange={this.onChange}
                                            error={errors.email}
                                            disabled="disabled"
                                        />

                                        <TextFieldArea
                                            label="Verification code"
                                            placeholder="verification code"
                                            name="token"
                                            type="text"
                                            value={this.state.token}
                                            onChange={this.onChange}
                                            error={errors.token}
                                            disabled="disabled"
                                        />
                                    </div>
                                </div>
                                <div className="form-label-group">
                                    <div className="row">
                                        <div className="col-sm-12 col-xs-12">
                                            <button
                                                className="btn btn-lg btn-primary btn-block"
                                                type="submit"
                                            >
                                                Click to Verify Account
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-label-group">
                                    <div className="row">
                                        <div className="col-sm-6 col-xs-12">
                                            <p className="text-muted text-center">
                                                <a href="/forgot-password">
                                                    <i className="fa fa-lock"></i>{" "}
                                                    Forgot password?{" "}
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <p className="mt-4 mb-3 text-muted text-center">
                                    Don't have account ?{" "}
                                    <Link to="/register">Sign Up</Link>.
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

EmailVerify.propTypes = {
    emailver: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
});

export default connect(mapStateToProps, { emailver })(EmailVerify);
